import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';
import { PushNotificationsService } from './services/push-notifications.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  showTravelingModal = new BehaviorSubject<boolean>(false);
  travelingData = new BehaviorSubject<{
    travelingRandom: number;
    travelingTitle: string | null;
    travelingLink: string;
    travelingBg: string;
  }>({
    travelingRandom: null,
    travelingTitle: '',
    travelingLink: '',
    travelingBg: '',
  });

  constructor(
    private location: Location,
    private notificationsService: PushNotificationsService
  ) {}
  appBackground = new BehaviorSubject(
    "url('./assets/bg/home.webp') no-repeat center center / cover"
  );
  currentBg = "url('./assets/bg/home.webp') no-repeat center center / cover";
  homeBg = "url('./assets/bg/home.webp') no-repeat center center / cover";
  bazarBg = "url('./assets/bg/bazar.webp') no-repeat center center / cover";
  eventsBg = "url('./assets/bg/events.webp') no-repeat center center / cover";
  mediaBg = "url('./assets/bg/media.webp') no-repeat center center / cover";
  merfolkBg = "url('./assets/bg/merfolk.webp') no-repeat center center / cover";
  planetBg = "url('./assets/bg/planet.webp') no-repeat center center / cover";
  profileBg = "url('./assets/bg/profile.webp') no-repeat center center / cover";
  premiumBg = "url('./assets/bg/premium.webp') no-repeat center center / cover";

  detectUrl() {
    this.location.onUrlChange(url => {
      if (url.includes('/bazaar')) {
        if (this.currentBg !== this.bazarBg) {
          this.appBackground.next(this.bazarBg);
          this.currentBg = this.bazarBg;
        }
      } else if (url.includes('/events')) {
        if (this.currentBg !== this.eventsBg) {
          this.appBackground.next(this.eventsBg);
          this.currentBg = this.eventsBg;
        }
      } else if (url.includes('/media')) {
        if (this.currentBg !== this.mediaBg) {
          this.appBackground.next(this.mediaBg);
          this.currentBg = this.mediaBg;
        }
      } else if (url.includes('/merfolk') || url.includes('/chat')) {
        if (this.currentBg !== this.merfolkBg) {
          this.appBackground.next(this.merfolkBg);
          this.currentBg = this.merfolkBg;
        }
      } else if (url.includes('/planet')) {
        if (this.currentBg !== this.planetBg) {
          this.appBackground.next(this.planetBg);
          this.currentBg = this.planetBg;
        }
      } else if (url.includes('/profile')) {
        if (this.currentBg !== this.profileBg) {
          this.appBackground.next(this.profileBg);
          this.currentBg = this.profileBg;
        }
      } else if (url.includes('/payments')) {
        if (this.currentBg !== this.premiumBg) {
          this.appBackground.next(this.premiumBg);
          this.currentBg = this.premiumBg;
        }
      } else {
        if (this.currentBg !== this.homeBg || url.includes('/check-version')) {
          this.appBackground.next(this.homeBg);
          this.currentBg = this.homeBg;
        }
      }
    });
  }
}
