import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IPod } from 'src/app/shared/models/item.models';
import { confirmInvitationModalData } from '../pods/pods';
import { Router } from '@angular/router';
import { UserService } from 'src/app/auth/user.service';
import { Subject, takeUntil } from 'rxjs';
import { PodStore } from '../pod-store';
import { PodService } from '../pods.service';

@Component({
  selector: 'app-pod-item',
  templateUrl: './pod-item.component.html',
  styleUrls: ['./pod-item.component.scss'],
})
export class PodItemComponent implements OnDestroy {
  @Input() pods: IPod[];
  @Input() feedItemId: string;
  @Input() divider: { title: string; icon: string; color: string };
  @Input() transparent: boolean;
  @Input() status: string;
  showConfirmModal = false;

  confirmInvitationModalData = confirmInvitationModalData;
  showStatusModal: boolean = false;
  itemStatus: string;
  pod: IPod;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private podService: PodService,
    private userService: UserService
  ) {}

  closeModal(e) {
    this.showConfirmModal = false;
  }

  openConfirmModal(e, pod) {
    e.stopPropagation();
    this.showConfirmModal = true;
    this.pod = pod;
  }

  openStatusModal(status?: string) {
    status ? (this.itemStatus = status) : null;
    this.showStatusModal = !this.showStatusModal;
  }

  goToPod(route) {
    this.router.navigate(route);
  }

  deleteInvite(e) {
    const { uid } = this.userService.isUserAuth();
    const invites = this.pod?.invites.filter(invite => invite.invited !== uid);
    const newItem = { ...this.pod, invites };
    this.showConfirmModal = false;
    this.podService.deleteInvitation(newItem);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
