import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SecondHandItem } from '../../bazaar';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import { FormService } from 'src/app/shared/components/form/form.service';
import {
  categoryOptions,
  conditionOptions,
  confirmAddingModalData,
  formAddTitle,
  newSecondFormData,
  shippingOptions,
} from '../second-hand';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SecondHandService } from '../second-hand.service';
import { countries } from 'src/app/auth/sign-up/countries';
import { Router } from '@angular/router';
import { SecondHandStore } from '../second-hand-store';
import { showLoading } from 'src/app/shared/components/modals/loading';
import { environment } from '../../../../environments/environment';
import mixpanel from 'mixpanel-browser';
import { UserService } from 'src/app/auth/user.service';
import { ImagesService } from 'src/app/services/images.service';
@Component({
  selector: 'app-add-second-hand-item',
  templateUrl: './add-second-hand-item.component.html',
  styleUrls: ['./add-second-hand-item.component.scss'],
})
export class AddSecondHandItemComponent implements OnInit, OnDestroy {
  @Output() isClose = new EventEmitter<boolean>(false);
  @ViewChild('imageLoader') imageLoader;

  secondHandForm: FormGroup;
  showSuccessModal = false;
  newSecondHandItem: SecondHandItem;
  disabled = true;
  addingBtnDisabled = true;
  newSecondFormData = newSecondFormData;
  imageField = newSecondFormData.filter(i => i.type === 'ARRAY');
  conditionOptions = conditionOptions;
  categoryOptions = categoryOptions;
  shippingOptions = shippingOptions;
  urls;

  successModalData;
  imagesControls = 1;
  formIsReset = new BehaviorSubject(false);
  status;
  currentImage: string;
  loadingImage: boolean = false;
  imagesArr: string[];
  loader = showLoading();
  currentUser;
  countries = countries;
  formTitle = formAddTitle;
  confirmModalData = confirmAddingModalData;
  showConfirmModal = false;
  properties;
  private destroy$ = new Subject<void>();

  constructor(
    private formService: FormService,
    private imagesService: ImagesService,
    private router: Router,
    private userService: UserService,
    private secondHandService: SecondHandService,
    private secondHandStore: SecondHandStore,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    mixpanel.init(environment.mixpanelToken, { debug: true });
    this.getUser();
    this.secondHandStore
      .selectId()
      .pipe(filter(id => !!id))
      .subscribe(id => {
        this.isClose.next(true);
        this.secondHandStore.updateShowFormModal(false);
        this.secondHandService.isOpenForm.next(true);
        setTimeout(() => this.router.navigate(['/bazaar/second-hand/', id]), 0);
      });
    this.secondHandForm = this.fb.group({
      images: this.fb.array([
        {
          0: this.fb.group({
            image: [null],
          }),
        },
      ]),
      name: ['', [Validators.required, Validators.minLength(7)]],
      brand: [''],
      condition: ['', [Validators.required]],
      category: ['', [Validators.required]],
      swapOption: [null],
      description: ['', [Validators.required, , Validators.minLength(20)]],
      price: this.fb.group({
        value: [null, [Validators.required]],
        currency: ['$'],
      }),
      country: [null, [Validators.required]],
      state: [null, [Validators.required]],
      shippingOption: [null, [Validators.required]],
    });

    this.listenStatusChanges();
    this.listenItemId();

    this.imagesService.imagesLoading
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => {
        this.loadingImage = loading;
        this.disabled = loading || this.secondHandForm.status === 'INVALID';
      });

    this.imagesService.compressedImages
      .pipe(takeUntil(this.destroy$))
      .subscribe(images => {
        this.imagesArr = images;
      });

    combineLatest([
      this.secondHandForm.valueChanges,
      this.imagesService.compressedImages,
      this.imagesService.imagesLoading,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([value, images, loading]) => {
        this.imagesArr = images;
        this.loadingImage = loading;
        this.disabled =
          this.secondHandForm.status === 'INVALID' ||
          this.status === 'loading' ||
          this.loadingImage ||
          !this.imagesArr.length;
      });
  }

  ngOnDestroy() {
    this.imagesService.reset();
    this.destroy$.next();
    this.destroy$.complete();
  }

  trackEvent(event, parameter) {
    if (parameter === 'content-type') {
      this.properties = {
        'content-type': 'Second Hand Article',
      };

      mixpanel.track(event, this.properties);
    } else if (parameter === 'new event') {
      let mySecondHands = this.secondHandService.getMyItems(
        'secondHand',
        this.currentUser.uid
      );
      mySecondHands.pipe(take(1)).subscribe(data => {
        let secondHandsIdArray = [];
        data.forEach(pod => {
          secondHandsIdArray.push(pod.id);
        });
        mixpanel.people.set({ '$second-hand-id': [...secondHandsIdArray] });
      });
    }
  }

  getFormsControls(): FormArray {
    return <FormArray>this.secondHandForm.controls['images'];
  }

  close(e: boolean) {
    e ? (this.showConfirmModal = true) : null;
  }

  discardChanges(e: boolean) {
    if (e) {
      this.isClose.next(true);
      this.showConfirmModal = false;
    }
  }

  closeModal(e: boolean) {
    if (e) {
      this.showConfirmModal = false;
    }
  }

  async addSecondHandItem() {
    this.secondHandStore.setLoading();
    await this.imageLoader.upLoadImages();
    this.imagesService.imageUrls
      .pipe(
        filter(data => !!data),
        takeUntil(this.destroy$)
      )
      .subscribe(urls => {
        if (urls.length) {
          this.disabled = true;
          this.newSecondHandItem = {
            ...this.secondHandForm.value,
            createdAt: new Date(),
            creator: {
              id: this.currentUser.uid,
            },
          };
          this.newSecondHandItem.images = [...urls].filter(image => !!image);

          this.secondHandStore.createItem(this.newSecondHandItem);
          this.trackEvent('Content Submitted', 'content-type');
          this.trackEvent('', 'new event');
        }
      });
  }

  listenItemId() {
    this.secondHandStore
      .selectId()
      .pipe(filter(id => !!id))
      .subscribe(id => {
        this.isClose.next(true);
        this.secondHandStore.updateShowFormModal(false);
        this.secondHandStore.updateShowPendingModal(true);
        setTimeout(() => {
          this.secondHandStore.patchState({ createdId: null });
          this.router.navigate(['/bazaar/second-hand/', id]);
        }, 0);
      });
  }

  private listenStatusChanges() {
    this.secondHandStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => {
        loading
          ? this.loader.then(data => data.present())
          : this.loader.then(data => data.dismiss());
      });
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.currentUser = user;
        mixpanel.identify(user.uid);
      });
  }
}
