import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import OpenAI from 'openai';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private openai: OpenAI;
  private currentLanguage = new BehaviorSubject<string>('en');
  private translationCache: Map<string, Map<string, string>> = new Map();
  private storage: Storage | null = null;
  private initialized = false;
  private initPromise: Promise<void>;

  constructor(
    private http: HttpClient,
    private platform: Platform,
    private ionicStorage: Storage,
    private ngZone: NgZone
  ) {
    this.initPromise = this.init();
    this.initPromise.then(() => {
      this.listenToSystemLanguageChanges();
      this.checkAutoTranslateSetting();
    });
  }

  public async checkAutoTranslateSetting() {
    const autoTranslate = localStorage.getItem('autoTranslate');

    if (autoTranslate === 'true') {
      await this.detectAndSetLanguage();
    } else {
      this.currentLanguage.next('en');
      document.documentElement.lang = 'en';
    }
  }

  private async init() {
    if (this.initialized) return;

    await this.initStorage();
    await this.checkAutoTranslateSetting();

    this.openai = new OpenAI({
      apiKey:
        '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',
      dangerouslyAllowBrowser: true,
    });

    this.initialized = true;
  }

  private async initStorage() {
    this.storage = await this.ionicStorage.create();
    await this.loadCacheFromStorage();
  }

  private async loadCacheFromStorage() {
    const cached = await this.storage?.get('translationCache');
    if (cached) {
      const parsedCache = JSON.parse(cached);
      this.translationCache = new Map();
      for (const [key, value] of Object.entries(parsedCache)) {
        this.translationCache.set(
          key,
          new Map(Object.entries(value as object))
        );
      }
    }
  }

  private async saveCacheToStorage() {
    const cacheObj = {};
    for (const [key, value] of this.translationCache.entries()) {
      cacheObj[key] = Array.from(value).reduce((obj, [k, v]) => {
        obj[k] = v;
        return obj;
      }, {});
    }
    await this.storage?.set('translationCache', JSON.stringify(cacheObj));
  }

  private listenToSystemLanguageChanges() {
    window.addEventListener('languagechange', () => {
      this.ngZone.run(() => {
        this.detectAndSetLanguage();
      });
    });
  }

  private async detectAndSetLanguage() {
    let detectedLang: string;

    if (this.platform.is('cordova')) {
      detectedLang = navigator.language.split('-')[0];
    } else {
      detectedLang = navigator.language.split('-')[0];
    }

    const autoTranslate = localStorage.getItem('autoTranslate');
    if (autoTranslate === 'true') {
      const currentLang = this.currentLanguage.getValue();
      if (currentLang !== detectedLang) {
        this.currentLanguage.next(detectedLang);
        document.documentElement.lang = detectedLang;
        this.translationCache.clear();
        await this.saveCacheToStorage();
      }
    }
  }

  async translateText(text: string, targetLang: string): Promise<string> {
    await this.initPromise;

    const autoTranslate = localStorage.getItem('autoTranslate');
    console.log('Translation requested:', { text, targetLang, autoTranslate });

    if (autoTranslate !== 'true' || targetLang === 'en') {
      console.log(
        'Translation skipped - auto translate disabled or English target'
      );
      return text;
    }

    if (!text || this.isNumeric(text)) {
      return text;
    }

    if (this.isPodTerm(text)) {
      return this.handlePodTermTranslation(text, targetLang);
    }

    const sourceLang = 'en';
    const cacheKey = `${sourceLang}-${targetLang}`;

    if (!this.translationCache.has(cacheKey)) {
      this.translationCache.set(cacheKey, new Map());
    }

    const langCache = this.translationCache.get(cacheKey)!;

    if (langCache.has(text)) {
      return langCache.get(text)!;
    }

    try {
      if (!this.openai) {
        throw new Error('OpenAI not initialized');
      }

      const completion = await this.openai.chat.completions.create({
        model: 'gpt-3.5-turbo',
        messages: [
          {
            role: 'system',
            content: `You are a professional translator. Translate the following text from ${sourceLang} to ${targetLang}. Preserve any formatting and maintain the same tone and style. Only return the translated text without any explanations.`,
          },
          {
            role: 'user',
            content: text,
          },
        ],
        temperature: 0.3,
        max_tokens: 150,
      });

      const translation = completion.choices[0].message.content?.trim() || text;

      langCache.set(text, translation);
      await this.saveCacheToStorage();

      return translation;
    } catch (error) {
      console.error('Translation error:', error);
      return text;
    }
  }

  getCurrentLanguage(): Observable<string> {
    return this.currentLanguage.asObservable();
  }

  private isNumeric(value: string): boolean {
    return /^-?\d+(\.\d+)?$/.test(value.trim());
  }

  private isPodTerm(text: string): boolean {
    const podTerms = [
      'Pod',
      'pod',
      'everyone can edit/delete',
      'only Pod creator can edit/delete',
      'everyone can approve',
      'only Pod creator can approve',
      'Pod Activity',
      'Member rights',
      'automatic',
      'request based',
    ];

    return podTerms.some(term => text.includes(term));
  }

  private handlePodTermTranslation(text: string, targetLang: string): string {
    if (targetLang === 'de') {
      const germanTranslations = {
        Pod: 'Gruppe',
        pod: 'gruppe',
        'everyone can edit/delete': 'alle können bearbeiten/löschen',
        'only Pod creator can edit/delete':
          'nur Gruppengründer kann bearbeiten/löschen',
        'everyone can approve': 'alle können genehmigen',
        'only Pod creator can approve': 'nur Gruppengründer kann genehmigen',
        'Pod Activity': 'Gruppenaktivität',
        'Member rights': 'Mitgliederrechte',
        automatic: 'automatisch',
        'request based': 'anforderungsbasiert',
      };

      if (germanTranslations[text]) {
        return germanTranslations[text];
      }

      for (const [en, de] of Object.entries(germanTranslations)) {
        if (text.includes(en)) {
          return text.replace(en, de);
        }
      }
    }

    return text;
  }

  public async setAutoTranslate(enabled: boolean) {
    localStorage.setItem('autoTranslate', enabled.toString());

    if (enabled) {
      const detectedLang = navigator.language.split('-')[0];
      this.currentLanguage.next(detectedLang);
      document.documentElement.lang = detectedLang;
    } else {
      this.currentLanguage.next('en');
      document.documentElement.lang = 'en';
    }

    this.translationCache.clear();
    await this.saveCacheToStorage();
  }
}

