import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { getAuth } from 'firebase/auth';
import { PaymentsStore } from 'src/app/payments/payments-store';
import { PaymentsService } from 'src/app/payments/payments.service';
import { FormService } from 'src/app/shared/components/form/form.service';
import { travelingQuotes } from 'src/app/shared/components/modals/traveling-modal/traveling-quotes';
import { ProfileService } from '../profile.service';
import { AuthService } from 'src/app/firebase/auth.service';

@Component({
  selector: 'app-profile-items-component',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss'],
})
export class ProfileItemsComponent {
  @Input() title: string;
  @Input() count: number;
  @Input() button: {
    title: string;
    path: string;
    name: string;
    userId: string;
  };
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onShowMoreClicked = new EventEmitter<boolean>(false);
  currentUserId = this.activeRouter.snapshot.params.id;
  isUserPremium = false;
  isAdmin = false;
  showTravelingModal = false;
  travelingBg: string;
  random: number;
  routerLink: string[] | null;
  routerTitle: string;

  authUser: string;
  isMobile = this.platform.platforms().includes('capacitor');

  constructor(
    private router: Router,
    private activeRouter: ActivatedRoute,
    private formService: FormService,
    private platform: Platform,
    private profileService: ProfileService
  ) {}

  showMoreClick() {
    this.onShowMoreClicked.next(true);
  }

  goTo(link, name, userId, premium, admin) {
    this.profileService.setFilterData(userId, name);
    const max = travelingQuotes.length;
    this.random = Math.round(0 - 0.5 + Math.random() * (max + 1));
    if (!premium && !admin) {
      this.showTravelingModal = true;
    } else {
      this.router.navigate(link);
      this.formService.formInitialValues.next({ myContent: true });
    }
  }

  closeTravelingModal(e) {
    this.showTravelingModal = !e;
  }
}
