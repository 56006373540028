export const environment = {
  production: true,
  api: '/api',
  hostUrl: 'https://app.mermapp.com/',
  firebaseConfig: {
   apiKey: 'AIzaSyDxlrnP5iPP5ZHjpJpF4HfHljc9go7HMAs',
   authDomain: 'mermapp-production.firebaseapp.com',
   projectId: 'mermapp-production',
   storageBucket: 'mermapp-production.appspot.com',
   messagingSenderId: '241717412079',
   appId: '1:241717412079:web:5a9b609e6be5195f1c5b1a',
   measurementId: 'G-MRPTJWTRYV',
  },
  googleMapKey: 'AIzaSyDEvI_b9iqwj_YzueWIhYf3QJPyxQis5YQ',
  routers: {},
  payment: {
   stripePublickKey:
    'pk_live_51Lv5YTL1hrltBe0fEcnOndSSHuAqhwxGxOqMF37PgTff3CO1uuxCSlxWKIojQJ8lCrwgbv3tBSz1bBjZ3CCFeyrz00ZU2hKjmc',
   PERLS_MODE: 'payment',
   SUBSCRIPTION_MODE: 'subscription',
   EIGHT_PEARLS: {
    PRODUCT_ID: 'prod_NwPDTsKbeJtb33',
    PRICE_ID: 'price_1NAWPTL1hrltBe0fLdrHt2MC',
    pearls: 8,
   },
   FORTY_TWO_PEARLS: {
    PRODUCT_ID: 'prod_NwPEQMXXUpVw7Y',
    PRICE_ID: 'price_1NAWPYL1hrltBe0fk5H8OcDI',
    pearls: 42,
   },
   NINETY_PEARLS: {
    PRODUCT_ID: 'prod_NwPEAH0I8k6Puc',
    PRICE_ID: 'price_1NAWPdL1hrltBe0fnrRzrywW',
    pearls: 90,
   },
   TWO_HUNDRES_PEARLS: {
    PRODUCT_ID: 'prod_NwPEwhVIaoyCIq',
    PRICE_ID: 'price_1NAWPhL1hrltBe0fuF5hEAMJ',
    pearls: 200,
   },
   FIVE_HUNDRED_AND_FORTY_PEARLS: {
    PRODUCT_ID: 'prod_NwPEepEstBQnYx',
    PRICE_ID: 'price_1NAWPlL1hrltBe0fLhAqWt8u',
    pearls: 540,
   },
   ONE_THOUSAND_TWO_HUNDRED_PEARLS: {
    PRODUCT_ID: 'prod_NwPEkUUzHgNBIt',
    PRICE_ID: 'price_1NAWPpL1hrltBe0f4Owftvsy',
    pearls: 1200,
   },
   ONE_MONTH_SUBSCRIPTION: {
    PRODUCT_ID: 'prod_NwPCYnDNjJlBg1',
    PRICE_ID: 'price_1NAWNiL1hrltBe0fwIBVfJ2x',
    period: '1 Month',
   },
   THREE_MONTHS_SUBSCRIPTION: {
    PRODUCT_ID: 'prod_NwPCYnDNjJlBg1',
    PRICE_ID: 'price_1NAWNiL1hrltBe0f3Y5X45Wk',
    period: '3 Months',
   },
   ONE_YEAR_SUBSCRIPTION: {
    PRODUCT_ID: 'prod_NwPCYnDNjJlBg1',
    PRICE_ID: 'price_1NAWNiL1hrltBe0fIJoKFd08',
    period: '1 Year',
   },
  },
  mixpanelToken: '05a8bcd630dd63d4c734c08ebe954e1a',
  discordServerId: '920296167177355284',
  discordServerInviteLink: 'https://discord.com/invite/PM89UWcsgt',
  pipeDreamUrl: 'https://eowmxurvi9ulyx.m.pipedream.net',
  discordServerLink: 'https://discord.com/channels/920296167177355284',
 };
 