import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-mailing',
  templateUrl: './mailing.component.html',
  styleUrls: ['./mailing.component.scss'],
})
export class MailingComponent implements OnInit {
  emails$ = new BehaviorSubject<string[]>([]);
  constructor() {}

  async ngOnInit() {
    await this.getEmails();
  }

  async getEmails() {
    const functions = getFunctions();
    const data = httpsCallable(functions, 'getUsersEmails');
    data().then((data: any) => this.emails$.next(data.data.usersEmails));
  }
}
