import { Directive, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { TranslationService } from '../services/translation.service';
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged, take } from 'rxjs/operators';

@Directive({
  selector: '[appAutoTranslate]',
})
export class AutoTranslateDirective implements OnInit, OnDestroy {
  private originalText: string = '';
  private destroy$ = new Subject<void>();
  private observer: MutationObserver;
  private translating = false;

  constructor(
    private el: ElementRef,
    private translationService: TranslationService
  ) {
    console.log('AutoTranslate directive instantiated');
    this.observer = new MutationObserver(() => {
      if (!this.translating) {
        this.translateContent();
      }
    });
  }

  ngOnInit() {
    this.originalText = this.el.nativeElement.textContent?.trim() || '';
    console.log('AutoTranslate initialized with text:', this.originalText);

    // Watch for language changes
    this.translationService
      .getCurrentLanguage()
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(lang => {
        console.log('Language changed in directive:', lang);
        this.translateContent();
      });

    // Watch for content changes
    this.observer.observe(this.el.nativeElement, {
      characterData: true,
      childList: true,
      subtree: true,
    });

    // Initial translation
    this.translateContent();
  }

  private async translateContent() {
    if (this.translating) return;

    const currentText = this.el.nativeElement.textContent?.trim();
    if (!currentText) return;

    const currentLang = await this.translationService
      .getCurrentLanguage()
      .pipe(take(1))
      .toPromise();

    try {
      this.translating = true;
      const translatedText = await this.translationService.translateText(
        currentText,
        currentLang!
      );

      if (translatedText && translatedText !== currentText) {
        this.el.nativeElement.textContent = translatedText;
      }
    } catch (error) {
      console.error('Translation error:', error);
    } finally {
      this.translating = false;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.observer.disconnect();
  }
}

