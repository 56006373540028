// @ts-ignore

import { Item } from '../shared/models/item.models';
import { IUser } from '../auth/auth.models';
import { FormField, FormFieldTypes } from '../shared/components/form/form';

export interface PlanetItem extends Item {
  title: string;
  challengeDate: any;
  description: string;
  creator: { id: string; photo: string; username: string };
  date: string;
  image: string;
  value?: string;
  participants?: IUser[];
  experiences: any;
}

export const successPlanetModalData = {
  title: 'Thanks for adding content!',
  pic: 'some sea animal with magnifying glass',
  description:
    "You'll get rewarded as soon as your content has been verified by 3 moderators.",
  okBtn: {
    title: 'CONTINUE',
  },
  cancelBtn: {
    title: 'SPLASH MERFRIENDS',
  },
};

export interface IPlanetFilter {
  myContent: boolean;
}

export const confirmAddingPlanetModalData = {
  title: 'Propose a Daily Challenge ',
  iconPath: '/assets/icon/hand-holding-heart-solid_1.svg',
  okBtn: {
    title: 'YES, DISCARD',
  },
  cancelBtn: {
    title: 'NO, GO BACK',
  },
};

export const confirmEditingPlanetModalData = {
  title: 'Edit a Daily Challenge ',
  iconPath: '/assets/icon/hand-holding-heart-solid_1.svg',
  okBtn: {
    title: 'YES, DISCARD',
  },
  cancelBtn: {
    title: 'NO, GO BACK',
  },
};

export const confirmDeletingPlanetItemModalData = {
  title: 'Deleting a challenge item',
  icon: 'shirt',
  okBtn: {
    title: 'YES, DELETE',
  },
  cancelBtn: {
    title: 'NO, KEEP',
  },
};

export const planetFormData: FormField[] = [
  {
    name: 'myContent',
    type: FormFieldTypes.TOGGLE,
    label: 'see my content only',
    required: false,
    icon: 'person',
    line: 'full',
  },
];

export const getTimeUntilNextSunday = () => {
  const now = new Date();
  const daysUntilSunday = 7 - now.getUTCDay();
  let nextSunday = new Date(
    Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0) +
      daysUntilSunday * 24 * 60 * 60 * 1000
  ).getTime();

  let timeUntilNextSunday = nextSunday - now.getTime();
  let days = Math.floor(timeUntilNextSunday / (1000 * 60 * 60 * 24));
  let hours = Math.floor(
    (timeUntilNextSunday % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  let minutes = Math.floor(
    (timeUntilNextSunday % (1000 * 60 * 60)) / (1000 * 60)
  );
  let dayString = '',
    hourString = '';
  if (days > 0) {
    dayString = `${days} ${days !== 1 ? 'days' : 'day'}`;
  }
  if (hours > 0) {
    hourString = `${hours} ${hours !== 1 ? 'hours' : 'hour'}`;
  }
  const minutesString = `${minutes} ${minutes !== 1 ? 'minutes' : 'minute'}`;

  let result = '';
  if (days > 0) {
    result = dayString;
  }
  if (hours > 0) {
    if (result.length) {
      result = `${result} ${hourString}`;
    } else {
      result = hourString;
    }
  }
  if (minutes > 0) {
    if (result.length) {
      result = `${result} ${minutesString}`;
    } else {
      result = minutesString;
    }
  }
  if (days === 0 && hours === 0) {
    result = minutesString;
  }
  return result;
};
