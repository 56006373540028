import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import {Router} from "@angular/router";

@Component({
  selector: 'app-suspend-footer',
  templateUrl: './suspend-footer.component.html',
  styleUrls: ['./suspend-footer.component.scss'],
})
export class SuspendFooterComponent {
  @Input() itemName: string;
  isMobile = this.platform.platforms().includes('capacitor');
  isIosApp = this.platform.platforms().includes('ios') && this.isMobile;
  constructor(private router: Router, private platform: Platform) {}

  navigateToSub() {
    if (this.isIosApp) {
      window.open('https://www.mermapp.com/rewards-and-payment-faq', '_blank');
    } else {
      this.router.navigate(['/payments/subscription']);
    }
  }
}
