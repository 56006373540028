import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IonModal } from '@ionic/angular';
import { BehaviorSubject, Subject, filter, switchMap, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/firebase/auth.service';
import { Clipboard } from '@capacitor/clipboard';
import {
  FormService,
  ICommunityFilter,
} from 'src/app/shared/components/form/form.service';
import { confirmModalData, successModalData } from './community';
import {
  createToaster,
  positionType,
} from 'src/app/shared/components/modals/toaster';
import { CommunityStore } from './community-store';
import { CommunityService } from './community.service';
import { IPod } from 'src/app/shared/models/item.models';
import { UserService } from 'src/app/auth/user.service';
import { successInviteModalData } from '../pods/pods/pods';
import { PodStore } from '../pods/pod-store';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { CrudHelper } from 'src/app/firebase/helpers/crudHelper';

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss'],
})
export class CommunityComponent implements OnInit, OnDestroy {
  searchString: BehaviorSubject<string> = new BehaviorSubject<string>('');
  users;
  p;
  showInviting = false;
  showSuccessModal = false;
  successModalData = successModalData;
  showConfirmModal = false;
  confirmModalData = confirmModalData;
  form: FormGroup;
  status: string;
  loading = false;
  loadingPage = false;
  total;

  itemsPerPage = 10;
  start = 0;
  end = 10;
  currentPage = new BehaviorSubject<number>(0);
  countPages: number;
  previewUsers$ = new BehaviorSubject(null);
  usersLength$ = new BehaviorSubject(0);
  filteredData$ = new BehaviorSubject({});
  usersCount = 0;
  isLastBtnDisabled = false;
  isNextBtnDisabled = false;
  isFirstBtnDisabled = true;
  isPrevBtnDisabled = true;
  reminder;
  searchStringValue = '';
  color = 'dark';

  currentUser;
  showInvitationModal = false;
  userPods = new BehaviorSubject<IPod[]>([]);

  inviteModalData = successInviteModalData;
  showInviteModal = false;

  private destroy$ = new Subject<void>();

  @ViewChild(IonModal) modal: IonModal;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private formService: FormService,
    private communityStore: CommunityStore,
    private communityService: CommunityService,
    private userService: UserService,
    private podStore: PodStore,
    private crudHelper: CrudHelper
  ) {}

  async ngOnInit() {
    this.getUser();
    this.listenFilter();
    this.listenFilterChanges();
    this.podStore.selectInviteModal().subscribe(isOpen => {
      this.showInviteModal = isOpen;
    });
    await this.currentPage.subscribe(page => {
      this.start = 1 + page * this.itemsPerPage;
      this.end = (page + 1) * this.itemsPerPage;

      if (
        this.searchString.value.length > 0 &&
        this.filteredData$.value &&
        Object.keys(this.filteredData$.value).length === 0
      ) {
        this.getUsersList(page, this.searchString.value, {});
      } else if (
        this.filteredData$.value &&
        this.searchString.value.length === 0
      ) {
        this.getUsersList(page, '', this.filteredData$.value);
      } else if (
        this.searchString.value.length > 0 &&
        this.filteredData$.value
      ) {
        this.getUsersList(
          page,
          this.searchString.value,
          this.filteredData$.value
        );
      } else {
        this.getUsersList(page, '', {});
      }

      if (this.end > this.usersCount && this.usersCount) {
        this.end = this.usersCount;
      }

      this.isFirstBtnDisabled = !!(this.start === 1);
      this.isPrevBtnDisabled = !!(this.start === 1);
      this.isNextBtnDisabled =
        !!(page === this.countPages - 1) || this.countPages === 0;
      this.isLastBtnDisabled =
        !!(page === this.countPages - 1) || this.countPages === 0;
    });
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      message: [null],
      link: [false],
    });

    this.communityStore.selectShowPendingModal().subscribe(isOpen => {
      this.showSuccessModal = isOpen;
    });
  }

  getUsersList(page, searchString?, filteredData?) {
    this.loading = true;
    const functions = getFunctions();
    const getUsers = httpsCallable(functions, 'getUsersList');
    if (
      searchString &&
      searchString?.length > 0 &&
      filteredData &&
      Object.keys(filteredData).length === 0
    ) {
      getUsers({ page: page, searchString: searchString, currentUser: this.currentUser })
        .then(res => {
          this.start = 1 + page * this.itemsPerPage;
          this.end = (page + 1) * this.itemsPerPage;

          this.previewUsers$.next((res as any).data.data);
          this.usersLength$.next((res as any).data.totalCount);

          this.countPages = Math.ceil(
            (res as any).data.totalCount / this.itemsPerPage
          );

          this.usersCount = (res as any).data.totalCount;
          if (
            this.end > (res as any).data.totalCount &&
            (res as any).data.totalCount
          ) {
            this.end = (res as any).data.totalCount;
          }

          this.isFirstBtnDisabled = !!(this.start === 1);
          this.isPrevBtnDisabled = !!(this.start === 1);
          this.isNextBtnDisabled =
            !!(page === this.countPages - 1) || this.countPages === 0;
          this.isLastBtnDisabled =
            !!(page === this.countPages - 1) || this.countPages === 0;
        })
        .finally(() => {
          this.loading = false;
        });
    } else if (
      filteredData &&
      Object.keys(filteredData).length > 0 &&
      searchString.length === 0
    ) {
      getUsers({ page: page, filter: filteredData, currentUser: this.currentUser })
        .then(res => {
          this.start = 1 + page * this.itemsPerPage;
          this.end = (page + 1) * this.itemsPerPage;

          this.previewUsers$.next((res as any).data.data);
          this.usersLength$.next((res as any).data.totalCount);

          this.countPages = Math.ceil(
            (res as any).data.totalCount / this.itemsPerPage
          );

          this.usersCount = (res as any).data.totalCount;
          if (
            this.end > (res as any).data.totalCount &&
            (res as any).data.totalCount
          ) {
            this.end = (res as any).data.totalCount;
          }

          this.isFirstBtnDisabled = !!(this.start === 1);
          this.isPrevBtnDisabled = !!(this.start === 1);
          this.isNextBtnDisabled =
            !!(page === this.countPages) || this.countPages === 0;
          this.isLastBtnDisabled =
            !!(page === this.countPages) || this.countPages === 0;
        })
        .finally(() => {
          this.loading = false;
        });
    } else if (
      filteredData &&
      Object.keys(filteredData).length > 0 &&
      searchString &&
      searchString?.length > 0
    ) {
      getUsers({
        page: page,
        searchString: searchString,
        filter: filteredData,
        currentUser: this.currentUser
      })
        .then(res => {
          this.start = 1 + page * this.itemsPerPage;
          this.end = (page + 1) * this.itemsPerPage;

          this.previewUsers$.next((res as any).data.data);
          this.usersLength$.next((res as any).data.totalCount);

          this.countPages = Math.ceil(
            (res as any).data.totalCount / this.itemsPerPage
          );

          this.usersCount = (res as any).data.totalCount;
          if (
            this.end > (res as any).data.totalCount &&
            (res as any).data.totalCount
          ) {
            this.end = (res as any).data.totalCount;
          }

          this.isFirstBtnDisabled = !!(this.start === 1);
          this.isPrevBtnDisabled = !!(this.start === 1);
          this.isNextBtnDisabled =
            !!(page === this.countPages) || this.countPages === 0;
          this.isLastBtnDisabled =
            !!(page === this.countPages) || this.countPages === 0;
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      getUsers({ page: page, currentUser: this.currentUser })
        .then(res => {
          this.start = 1 + page * this.itemsPerPage;
          this.end = (page + 1) * this.itemsPerPage;

          this.previewUsers$.next((res as any).data.data);
          this.usersLength$.next((res as any).data.totalCount);

          this.countPages = Math.ceil(
            (res as any).data.totalCount / this.itemsPerPage
          );

          this.usersCount = (res as any).data.totalCount;
          if (
            this.end > (res as any).data.totalCount &&
            (res as any).data.totalCount
          ) {
            this.end = (res as any).data.totalCount;
          }

          this.isFirstBtnDisabled = !!(this.start === 1);
          this.isPrevBtnDisabled = !!(this.start === 1);
          this.isNextBtnDisabled =
            !!(page === this.countPages) || this.countPages === 0;
          this.isLastBtnDisabled =
            !!(page === this.countPages) || this.countPages === 0;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  private listenFilterChanges() {
    this.formService.communityMenuData$
      .pipe(
        takeUntil(this.destroy$),
        filter(data => !!data)
      )
      .subscribe((filterData: ICommunityFilter) => {
        this.filteredData$.next(filterData);
        this.currentPage.next(0);
      });
  }

  private listenFilter() {
    this.formService.communityMenuData$.subscribe(data => {
      const initialData = this.communityService.checkFilter(data);

      if (!initialData) {
        this.filteredData$.next({});
        this.currentPage.next(0);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.formService.communityMenuData$.next(false);
  }

  getNextPage() {
    this.currentPage.next(this.currentPage.value + 1);
  }

  getPrevPage() {
    this.currentPage.next(this.currentPage.value - 1);
  }

  getLastPage() {
    this.currentPage.next(this.countPages - 1);
  }

  getFirstPage() {
    this.currentPage.next(0);
  }

  searchUser(searchString) {
    this.searchString.next(searchString.target.value.toLowerCase());
    this.searchStringValue = searchString.target.value.toLowerCase();
    this.currentPage.next(0);
    this.listenFilterChanges();
  }

  inviteMerfriend() {
    const { uid } = this.authService.isUserAuth();
    const sender = this.users?.filter(user => user.uid === uid);
    this.loading = true;

    this.authService
      .inviteMerfriend(
        this.form.value.email,
        this.form.value.message,
        this.currentUser.username
      )
      .then(() => {
        this.loading = false;
        this.form.reset();
        this.communityStore.updateShowPendingModal(true);
        this.showInviting = false;
        this.modal.dismiss(null, 'cancel');
      })
      .catch(() =>
        createToaster('something went wrong', 'danger', positionType.BOTTOM)
      );
  }

  async copyLink() {
    await Clipboard.write({
      string: 'https://app.mermapp.com',
    }).then(() => {
      this.showInviting = false;
      createToaster(
        'App link "https://app.mermapp.com" copied on clipboard',
        'success',
        positionType.BOTTOM
      );
      this.form.reset();
    });
  }

  discardChanges(e) {
    if (e) {
      this.showInviting = false;
      this.showConfirmModal = false;
      this.form.reset();
    }
  }

  closeModal(e) {
    if (e) {
      this.showConfirmModal = false;
    }
  }

  handleOk(e) {
    e ? this.communityStore.updateShowPendingModal(false) : null;
  }

  handleCancel(e) {
    e ? this.communityStore.updateShowPendingModal(false) : null;
  }

  handleInviteOk(e) {
    e ? this.podStore.updateInviteModal(false) : null;
  }

  handleInviteCancel(e) {
    e ? this.podStore.updateInviteModal(false) : null;
  }

  getUser() {
    this.userService.currentUser$
      .pipe(
        filter(user => !!user.uid),
        takeUntil(this.destroy$),
        switchMap(user => {
          this.currentUser = user;
          return this.crudHelper.getCollectionItems({
            collectionName: 'pods',
            queryFn: query =>
              query
                .where('status', '==', 'approved')
                .where('participants', 'array-contains', user.id),
          });
        })
      )
      .subscribe(pods => this.userPods.next(pods as any));
  }
}
