import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AdminPageRoutingModule } from './admin-page-routing.module';

import { AdminPage } from './admin.page';
import { PendingApprovalPageModule } from '../pending-approval/pending-approval.module';
import { SharedModule } from '../shared/shared.module';
import { PipesModule } from '../pipes/pipes.module';
import { MailingComponent } from './mailing/mailing.component';
import { UserGeneratedContentComponent } from './user-generated-content/user-generated-content.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AdminPageRoutingModule,
    PendingApprovalPageModule,
    SharedModule,
    PipesModule,
  ],
  declarations: [AdminPage, MailingComponent,
    UserGeneratedContentComponent],
})
export class AdminPageModule {}
