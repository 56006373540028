import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {}

  setMidNight = () => {
    const now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setMinutes(0);
    return now;
  };

  getTimestamp = () => {
    return new Date().getTime();
  };

  setNow = () => {
    const now = new Date();
    return now;
  };

  get midNight() {
    return this.setMidNight();
  }

  get now() {
    return this.setNow();
  }

  getToday = date => {
    return date.seconds
      ? new Date(date.seconds * 1000).getDate()
      : new Date(date._seconds * 1000).getDate();
  };

  formatDate = (date): string | null => {
    if (date?._seconds) return new Date(date?._seconds * 1000).toISOString();
    if (date?.seconds) return new Date(date?.seconds * 1000).toISOString();
    if (date && !date?.seconds && !date?._seconds)
      return new Date(date).toISOString();
    return null;
  };

  checkSameDate(startDate, endDate) {
    if (startDate && endDate) {
      const startDay = this.getToday(startDate);
      const endDay = this.getToday(endDate);
      const period = startDate.seconds
        ? (endDate.seconds - startDate.seconds) / 3600
        : (endDate._seconds - startDate._seconds) / 3600;
      const isLessThanDay = period < 24;
      return startDay === endDay && isLessThanDay;
    }
  }

  getScheduledItems = (
    items: any[],
    myContent: boolean,
    isArchiveTab: boolean = false,
    showPastEvents: boolean = false,
    filterStartDate?: string,
    filterEndDate?: string,
    locationFilters: string[] = []
  ) => {
    if (!items || !items.length) {
      return items;
    }

    // If we're in the Archive tab or explicitly showing past events, don't filter out past events
    if (isArchiveTab || showPastEvents) {
      // Even in archive tab, apply date filters if provided
      if (filterStartDate || filterEndDate) {
        return this.applyDateFilters(
          items,
          filterStartDate,
          filterEndDate,
          locationFilters
        );
      }
      return this.sortEventsByDate(items);
    }

    // For normal view, filter out past events
    const filteredItems = items.filter(item => {
      if (myContent) {
        return true;
      }

      const now = this.now.getTime() / 1000;

      // Prioritize startDate for determining if an event is active
      // Handle startDate first
      if (item.startDate) {
        // Handle string dates (YYYY-MM-DD format)
        if (
          typeof item.startDate === 'string' &&
          item.startDate.match(/^\d{4}-\d{2}-\d{2}$/)
        ) {
          const [year, month, day] = item.startDate.split('-').map(Number);
          const startDate = new Date(year, month - 1, day); // Start of day
          const startTimestamp = startDate.getTime() / 1000;
          return startTimestamp >= now;
        }

        // Handle Firestore Timestamp objects
        if (item.startDate._seconds) {
          return item.startDate._seconds >= now;
        }
        if (item.startDate.seconds) {
          return item.startDate.seconds >= now;
        }
      }

      // Fall back to endDate if startDate is not available
      if (item.endDate) {
        // Handle string dates (YYYY-MM-DD format)
        if (
          typeof item.endDate === 'string' &&
          item.endDate.match(/^\d{4}-\d{2}-\d{2}$/)
        ) {
          const [year, month, day] = item.endDate.split('-').map(Number);
          const endDate = new Date(year, month - 1, day, 23, 59, 59); // Set to end of day
          const endTimestamp = endDate.getTime() / 1000;
          return endTimestamp >= now;
        }

        // Handle Firestore Timestamp objects
        if (item.endDate._seconds) {
          return item.endDate._seconds >= now;
        }
        if (item.endDate.seconds) {
          return item.endDate.seconds >= now;
        }
      }

      // If no valid date, default to showing the item
      return true;
    });

    // Apply date filters if provided
    if (filterStartDate || filterEndDate) {
      return this.applyDateFilters(
        filteredItems,
        filterStartDate,
        filterEndDate,
        locationFilters
      );
    }

    return this.sortEventsByDate(filteredItems);
  };

  /**
   * Sort events by upcoming date (closest date first)
   * @param events Array of events to sort
   * @returns Sorted array of events
   */
  private sortEventsByDate(events: any[]): any[] {
    if (!events || !events.length) {
      return events;
    }

    return [...events].sort((a, b) => {
      // Get start dates for comparison
      const dateA = this.getEventStartTimestamp(a);
      const dateB = this.getEventStartTimestamp(b);

      // If both dates are valid, compare them
      if (dateA && dateB) {
        return dateA - dateB;
      }

      // If only one date is valid, prioritize the event with a valid date
      if (dateA && !dateB) return -1;
      if (!dateA && dateB) return 1;

      // If neither date is valid, keep original order
      return 0;
    });
  }

  /**
   * Get the timestamp for an event's start date
   * @param event The event object
   * @returns Timestamp in seconds or null if date is invalid
   */
  private getEventStartTimestamp(event: any): number | null {
    // Prefer startDate over endDate for sorting
    if (event.startDate) {
      // Handle string dates (YYYY-MM-DD format)
      if (
        typeof event.startDate === 'string' &&
        event.startDate.match(/^\d{4}-\d{2}-\d{2}$/)
      ) {
        const [year, month, day] = event.startDate.split('-').map(Number);
        return new Date(year, month - 1, day).getTime() / 1000;
      }

      // Handle Firestore Timestamp objects
      if (event.startDate._seconds) {
        return event.startDate._seconds;
      }
      if (event.startDate.seconds) {
        return event.startDate.seconds;
      }
    }

    // Fall back to endDate if startDate is not available
    if (event.endDate) {
      // Handle string dates (YYYY-MM-DD format)
      if (
        typeof event.endDate === 'string' &&
        event.endDate.match(/^\d{4}-\d{2}-\d{2}$/)
      ) {
        const [year, month, day] = event.endDate.split('-').map(Number);
        return new Date(year, month - 1, day).getTime() / 1000;
      }

      // Handle Firestore Timestamp objects
      if (event.endDate._seconds) {
        return event.endDate._seconds;
      }
      if (event.endDate.seconds) {
        return event.endDate.seconds;
      }
    }

    return null;
  }

  // Helper method to apply date filters
  private applyDateFilters = (
    items: any[],
    filterStartDate?: string,
    filterEndDate?: string,
    locationFilters: string[] = []
  ) => {
    if (
      !filterStartDate &&
      !filterEndDate &&
      (!locationFilters || locationFilters.length === 0)
    ) {
      return this.sortEventsByDate(items);
    }

    const filteredItems = items.filter(item => {
      // Check for online events if "Online" is in the location filters
      if (
        locationFilters &&
        locationFilters.includes('Online') &&
        this.isOnlineEvent(item)
      ) {
        return true;
      }

      // Parse filter dates
      const filterStartTimestamp = filterStartDate
        ? new Date(filterStartDate).getTime() / 1000
        : null;
      const filterEndTimestamp = filterEndDate
        ? new Date(filterEndDate).getTime() / 1000 + 86399 // End of day
        : null;

      // Get item dates
      let itemStartTimestamp = null;
      let itemEndTimestamp = null;

      // Parse item start date
      if (
        typeof item.startDate === 'string' &&
        item.startDate.match(/^\d{4}-\d{2}-\d{2}$/)
      ) {
        const [startYear, startMonth, startDay] = item.startDate
          .split('-')
          .map(Number);
        itemStartTimestamp =
          new Date(startYear, startMonth - 1, startDay).getTime() / 1000;
      } else if (item.startDate?._seconds) {
        itemStartTimestamp = item.startDate._seconds;
      } else if (item.startDate?.seconds) {
        itemStartTimestamp = item.startDate.seconds;
      }

      // Apply date filters
      if (filterStartTimestamp && itemStartTimestamp) {
        if (itemStartTimestamp < filterStartTimestamp) {
          return false;
        }
      }

      if (filterEndTimestamp && itemEndTimestamp) {
        if (itemEndTimestamp > filterEndTimestamp) {
          return false;
        }
      }

      return true;
    });

    return this.sortEventsByDate(filteredItems);
  };

  /**
   * Check if an event is online based on keywords in title, description, or location
   * @param event Event to check
   * @returns True if the event is online
   */
  private isOnlineEvent(event: any): boolean {
    const onlineKeywords = [
      'online',
      'virtual',
      'zoom',
      'webinar',
      'web conference',
      'livestream',
      'live stream',
      'remote',
      'digital',
      'stream',
      'streaming',
      'webcast',
    ];

    // Check title, description, and location for online keywords
    const titleLower = (event.title || '').toLowerCase();
    const descriptionLower = (event.description || '').toLowerCase();
    const locationLower = (event.location || '').toLowerCase();

    // Check if any online keywords are present in the event details
    return onlineKeywords.some(
      keyword =>
        titleLower.includes(keyword) ||
        descriptionLower.includes(keyword) ||
        locationLower.includes(keyword)
    );
  }

  checkSuspendedNotification(notification) {
    if (notification.suspendedUntil) {
      const now = this.getTimestamp();
      return now - notification.suspendedUntil.seconds * 1000 > 86400000;
    } else return true;
  }
}

