import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { combineLatest, filter, map, Subject, take, takeUntil } from 'rxjs';
import { FormService } from '../../shared/components/form/form.service';
import { Router } from '@angular/router';
import { AuthService } from '../../firebase/auth.service';
import { EventsStore } from '../events-store';
import { EventsService } from '../events.service';
import { confirmAddingEventModalData, EventItem } from '../events';
import { showLoading } from '../../shared/components/modals/loading';
import { IPod } from 'src/app/shared/models/item.models';
import { PodStore } from 'src/app/merfolk/pods/pod-store';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../../environments/environment';
import { UserService } from 'src/app/auth/user.service';
import { ImagesService } from 'src/app/services/images.service';

@Component({
  selector: 'app-add-events',
  templateUrl: './add-events.component.html',
  styleUrls: ['./add-events.component.scss'],
})
export class AddEventsComponent implements OnInit, OnDestroy {
  @Input() pod: IPod;
  @Input() events: EventItem[];
  @Output() isClose = new EventEmitter<boolean>(false);
  @ViewChild('eventFormComponent') eventFormComponent;
  podConnected;
  eventForm: FormGroup;
  loader = showLoading();
  isOpen = false;
  isOnline = false;
  currentUser;
  status;
  newEventItem: EventItem;
  item = { icon: 'calendar-outline', title: 'Add an Event' };
  pods: IPod[];
  showSuccessModal = false;
  showConfirmModal = false;
  confirmModalData = confirmAddingEventModalData;
  imagesArr: string[];
  disabled = true;
  properties;
  private destroy$ = new Subject<void>();

  constructor(
    private formService: FormService,
    private imagesService: ImagesService,
    private router: Router,
    private podStore: PodStore,
    private authService: AuthService,
    private eventsService: EventsService,
    private eventsStore: EventsStore,
    private userService: UserService
  ) {}

  ngOnInit() {
    mixpanel.init(environment.mixpanelToken, { debug: true });

    this.disabled = true;
    this.eventsStore
      .selectId()
      .pipe(takeUntil(this.destroy$))
      .pipe(filter(id => !!id))
      .subscribe(id => {
        this.isClose.next(true);
        this.eventsStore.updateShowFormModal(false);
        this.eventsService.isOpenForm.next(true);
      });
    this.podStore.getItems({});
    this.listenPodsChanges();
    this.listenStatusChanges();
    this.listenItemId();
    this.getUser();
    this.eventsService.isOpenForm.subscribe(
      isOpen => (this.showSuccessModal = isOpen)
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.eventsService.isOpenForm.subscribe(
      isOpen => (this.showSuccessModal = false)
    );
  }

  trackEvent(event, parameter, eventData) {
    if (parameter === 'content-type') {
      this.properties = {
        'content-type': 'Event',
      };
      mixpanel.track(event, this.properties);
    } else if (parameter === 'events') {
      const { uid } = this.authService.isUserAuth();
      let myEvents = this.eventsService.getMyItems('events', uid);
      myEvents.pipe(take(1)).subscribe(data => {
        let eventsIdArray = [];
        data.forEach(event => {
          eventsIdArray.push(event.id);
        });
        mixpanel.people.set({ '$event-id': [...eventsIdArray] });
      });
    }
  }

  handleOk(e) {
    e ? this.eventsStore.updateShowPendingModal(false) : null;
  }

  handleCancel(e) {
    e ? this.eventsStore.updateShowPendingModal(false) : null;
  }

  close(e) {
    e ? (this.showConfirmModal = true) : null;
  }

  discardChanges(e) {
    if (e) {
      this.isClose.next(true);
      this.showConfirmModal = false;
    }
  }

  closeModal(e) {
    if (e) {
      this.showConfirmModal = false;
    }
  }

  async addEventItem(e) {
    if (this.eventForm) {
      await this.eventFormComponent.imageLoader.upLoadImages();
      this.imagesService.imageUrls
        .pipe(
          filter(data => !!data),
          takeUntil(this.destroy$)
        )
        .subscribe(urls => {
          if (urls.length) {
            this.newEventItem = {
              ...this.eventForm?.value,
              creator: { id: this.currentUser.id },
              participants: [
                {
                  id: this.currentUser?.id,
                  profilePicture: this.currentUser?.profilePicture,
                  username: this.currentUser?.username,
                },
              ],
              location: this.eventForm?.value.online
                ? Object.assign({
                    ...this.eventForm?.value.location,
                    country: { region: 'online' },
                  })
                : this.eventForm?.value.location,
              startDate: this.eventForm.get('startDate').value
                ? new Date(this.eventForm.get('startDate').value)
                : null,
              endDate: this.eventForm.get('endDate').value
                ? new Date(this.eventForm.get('endDate').value)
                : null,
              createdAt: new Date(),
              price:
                Object.keys(this.eventForm?.value.price).length === 0
                  ? { value: 0 }
                  : this.eventForm?.value.price,
            };

            this.newEventItem.images = [...urls].filter(image => !!image);
            this.formService.itemGallery.next([]);
            this.eventsStore.createItem(this.newEventItem);
            if (this.newEventItem.podName) {
              this.eventsStore.getItemByByValue({title: this.newEventItem.title})
              this.eventsStore.selectItem()
                .pipe(
                  filter(item => !!item),
                  take(1),
                  takeUntil(this.destroy$)
                )
                .subscribe(item => {
                  this.newEventItem.id = item.id;

                  let currentPod = this.pods.find((item) => item.id === this.newEventItem.podName)

                  currentPod.events.push(this.newEventItem.id);
                  this.podStore.updateProfile(currentPod);
                })
            }
            this.trackEvent('Content Submitted', 'content-type', '');
            this.trackEvent('', 'events', '');
          }
        });
    } else {
      this.disabled = true;
    }
  }

  listenItemId() {
    this.eventsStore
      .selectId()
      .pipe(filter(id => !!id))
      .subscribe(id => {
        this.isClose.next(true);
        this.eventsStore.updateShowFormModal(false);
        this.eventsService.isOpenForm.next(true);
        this.eventsStore.updateShowPendingModal(true);
        setTimeout(() => {
          this.eventsStore.patchState({ createdId: null });
          this.eventsStore.getItemByByValue({ documentId: id });
          this.router.navigate(['/events', id]);
        }, 0);
      });
  }

  private listenPodsChanges() {
    combineLatest([this.podStore.selectItems()])
      .pipe(
        takeUntil(this.destroy$),
        map(([items]) => {
          return (
            items &&
            items.filter(
              item =>
                (item.participants.includes(this.currentUser?.uid) &&
                  item.memberRights === 'everyone can edit/delete') ||
                item.creator?.id === this.currentUser?.uid
            )
          );
        })
      )
      .subscribe(items => {
        this.pods = items;
      });
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.currentUser = user;

        mixpanel.identify(user.uid);
      });
  }

  private listenStatusChanges() {
    this.eventsStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => {
        loading
          ? this.loader.then(data => data.present())
          : this.loader.then(data => data.dismiss());
      });
  }
}
