import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import pick from 'lodash.pick';
import mixpanel from 'mixpanel-browser';
import { combineLatest, filter, Subject, takeUntil } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Navigation, SwiperOptions } from 'swiper';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { AuthService } from '../../firebase/auth.service';
import { ItemStatus } from '../../shared/models/item.models';
import { IToolbarData } from '../../shared/models/components-data.models';
import { EventsStore } from '../events-store';
import { EventItem, successEventModalData, EVENT_TYPE_ENUMS } from '../events';
import { PodStore } from 'src/app/merfolk/pods/pod-store';
import { environment } from '../../../environments/environment';
import { UserService } from 'src/app/auth/user.service';
import { ReactionsService } from '../../services/reactions.service';

const TOOLBAR_DATA: IToolbarData = {
  arrowRouter: '/events',
  btnSlot: 'end',
  title: 'Event',
};

const TOOLBAR_SWIPER: IToolbarData = {
  arrow: true,
  btnSlot: 'end',
  title: 'Events',
};

@Component({
  selector: 'app-events-details',
  templateUrl: './events-details.component.html',
  styleUrls: ['./events-details.component.scss'],
})
export class EventsDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('col') col: ElementRef;

  TOOLBAR_DATA = TOOLBAR_DATA;
  TOOLBAR_SWIPER = TOOLBAR_SWIPER;
  config: SwiperOptions;

  eventItem;
  podItem;
  galleryUrls: string[];
  currentUser;
  currentUserID;
  creator;
  eventCreator;
  status: ItemStatus;
  isEventFree: boolean = false;
  isEventPaid: boolean = false;
  event: EventItem;
  isOpen = false;
  showSuccessModal = false;
  successModalData;
  isOpenFormModal = false;
  isEventJoined: boolean = false;
  isCanJoin: boolean = false;
  infoSnapshot;
  userInfo;
  showStatusModal: boolean = false;
  itemStatus;
  properties;
  isAdmin;
  flagData;
  isContentFlaggedModal;
  isOpenFlagModal;
  isFlagging = false;

  discordServerInviteLink = environment.discordServerInviteLink;

  contactLink: string;

  likesInfo;
  loadingLikeButton;
  itemId;

  private destroy$ = new Subject<void>();

  constructor(
    private eventStore: EventsStore,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private podStore: PodStore,
    private userService: UserService,
    private reactionsService: ReactionsService,
    private router: Router
  ) {
    this.itemId = this.activatedRoute.snapshot.params.id;
  }

  ngOnInit() {
    mixpanel.init(environment.mixpanelToken, { debug: true });
    const { uid } = this.authService.isUserAuth();
    mixpanel.identify(uid);
    this.getUser();
    this.eventStore.getItemByByValue({
      documentId: this.activatedRoute.snapshot.params.id,
    });

    this.listenSecondHandDetails();
    this.eventStore.selectShowFormModal().subscribe(isOpen => {
      this.isOpenFormModal = isOpen;
    });
    this.eventStore.selectShowPendingModal().subscribe(isOpen => {
      this.showSuccessModal = isOpen;
    });
    this.successModalData = successEventModalData;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.eventItem = null;
    this.podItem = null;
    this.isCanJoin = false;
    this.isEventJoined = false;
    if (this.infoSnapshot) {
      this.infoSnapshot.unsubscribe();
    }
  }

  trackEvent(event, eventValue, creator) {
    this.properties = {
      type: eventValue?.eventType,
      organizer: [creator?.username, eventValue?.creator],
    };

    mixpanel.track(event, this.properties);
  }

  toggleGallery(i?) {
    this.isOpen = !this.isOpen;
    this.config = {
      modules: [Navigation],
      loop: true,
      navigation: true,
      initialSlide: i,
    };
  }

  openModal() {
    this.eventStore.updateShowFormModal(true);
  }

  joinEvent() {
    const newEvent = Object.assign({
      ...this.eventItem,
      participants: this.eventItem?.participants?.length
        ? [
            ...this.eventItem?.participants,
            pick(this.currentUser, ['id', 'profilePicture', 'username']),
          ]
        : [pick(this.currentUser, ['id', 'profilePicture', 'username'])],
    });

    this.eventStore.updateProfile(newEvent);
    this.trackEvent('Joined Event', newEvent, this.creator);
  }

  async flagContent(slide) {
    if (this.isFlagging) return;

    this.isFlagging = true;
    slide.type = 'events';
    this.flagData = slide;

    try {
      const functions = getFunctions();
      const isContentFlagged = httpsCallable(functions, 'isContentFlagged');
      const res = await isContentFlagged({
        itemId: slide.id,
        url: slide.images[0],
        type: this.flagData.type,
      });

      if (res.data === true) {
        this.isContentFlaggedModal = true;
      } else {
        this.isOpenFlagModal = true;
      }
    } catch (error) {
      console.error('Error checking flag status:', error);
    } finally {
      this.isFlagging = false;
    }
  }

  closeModal(e) {
    e ? this.eventStore.updateShowFormModal(false) : null;
  }

  handleOk(e) {
    if (e) {
      this.eventStore.updateShowPendingModal(false);
      this.showSuccessModal = false;
      // Navigate back to events list
      this.router.navigate(['/events']);
    }
  }

  handleCancel(e) {
    if (e) {
      this.eventStore.updateShowPendingModal(false);
      this.showSuccessModal = false;
      // Navigate back to events list
      this.router.navigate(['/events']);
    }
  }

  openStatusModal(status?) {
    this.showStatusModal = !this.showStatusModal;
    if (status) this.itemStatus = status;
  }

  //Not Used
  /*
  navigateToMeetingLink() {
    window.location.href = this.eventItem?.meetingLink;
  }
  */
  private listenSecondHandDetails() {
    this.eventStore
      .selectItem()
      .pipe(
        filter(item => !!item),
        takeUntil(this.destroy$)
      )
      .subscribe(item => {
        this.eventItem = item;
        this.galleryUrls = item.images;
        this.status = item.status as ItemStatus;
        this.isEventFree = item.price?.value === '0';
        this.isEventPaid = item.price?.value !== '0';
        this.event = item;

        if (item.podName) {
          this.listenPodDetails(item.podName);
        }

        this.isEventJoined = this.eventItem.participants?.some(
          element => element.id === this.currentUser?.uid
        );

        if (item.podName) {
          this.podStore.getItems({});
          this.listenPodDetails(item.podName);
        } else {
          this.isCanJoin =
            this.eventItem?.participants?.find(
              element => element.id !== this.currentUser?.uid
            ) && !this.isEventJoined;
        }

        this.authService.getUserByUid(item.creator.id).subscribe(user => {
          this.creator = user;
          this.eventCreator = user;
          if (this.eventItem?.externalEventLink) {
            this.contactLink = this.eventItem.externalEventLink.startsWith(
              'http'
            )
              ? this.eventItem.externalEventLink
              : `https://${this.eventItem.externalEventLink}`;
          } else {
            this.contactLink = this.creator?.discordLink;
          }
        });
      });
  }

  private listenPodDetails(id) {
    combineLatest([this.podStore.selectItems()])
      .pipe(
        takeUntil(this.destroy$),
        map(([items]) => items && items.find(item => item.id === id))
      )
      .subscribe(item => {
        this.podItem = item;
        if (this.eventItem.onlyPodMembers) {
          this.isCanJoin =
            item?.participants.includes(this.currentUser?.uid) &&
            !this.isEventJoined;
        } else {
          this.isCanJoin = !this.eventItem.participants.some(
            participant => participant.id === this.currentUser?.uid
          );
        }
      });
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.currentUser = user;
        this.currentUserID = user.uid;
        this.isAdmin = this.userService.isAdmin;
        this.userInfo = this.userService.balanceInfo;
        mixpanel.identify(user.uid);
      });
  }

  isExternallyHosted(event) {
    return (
      event.eventType === EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_PAID ||
      event.eventType === EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_FREE
    );
  }

  /**
   * Limits a description to the first 5 words
   * @param description The full description text
   * @returns The first 5 words of the description followed by ellipsis if needed
   */
  limitDescriptionToFiveWords(description: string): string {
    if (!description) return '';

    const words = description.trim().split(/\s+/);
    if (words.length <= 15) return description;

    return words.slice(0, 5).join(' ') + '...';
  }

  /**
   * Formats the date in the "Fri, Mar 15" format always,
   * because time is displayed separately
   * @param date The date to format
   * @returns Formatted date string
   */
  formatDateForDisplay(date: any): string {
    if (!date) return '';

    try {
      let startDateObj: Date;
      let endDateObj: Date;

      // Handle Firebase Timestamp objects for start date
      if (this.eventItem?.startDate?.seconds) {
        startDateObj = new Date(this.eventItem.startDate.seconds * 1000);
      }
      // Handle Firebase Timestamp objects with _seconds for start date
      else if (this.eventItem?.startDate?._seconds) {
        startDateObj = new Date(this.eventItem.startDate._seconds * 1000);
      }
      // Handle ISO string date (YYYY-MM-DD format) for start date
      else if (
        typeof this.eventItem?.startDate === 'string' &&
        this.eventItem?.startDate.match(/^\d{4}-\d{2}-\d{2}$/)
      ) {
        startDateObj = new Date(this.eventItem.startDate);
      }
      // Handle regular date strings or objects for start date
      else if (
        typeof this.eventItem?.startDate === 'string' ||
        this.eventItem?.startDate instanceof Date
      ) {
        startDateObj = new Date(this.eventItem.startDate);
      } else {
        return '';
      }

      // Handle Firebase Timestamp objects for end date
      if (this.eventItem?.endDate?.seconds) {
        endDateObj = new Date(this.eventItem.endDate.seconds * 1000);
      }
      // Handle Firebase Timestamp objects with _seconds for end date
      else if (this.eventItem?.endDate?._seconds) {
        endDateObj = new Date(this.eventItem.endDate._seconds * 1000);
      }
      // Handle ISO string date (YYYY-MM-DD format) for end date
      else if (
        typeof this.eventItem?.endDate === 'string' &&
        this.eventItem?.endDate.match(/^\d{4}-\d{2}-\d{2}$/)
      ) {
        endDateObj = new Date(this.eventItem.endDate);
      }
      // Handle regular date strings or objects for end date
      else if (
        typeof this.eventItem?.endDate === 'string' ||
        this.eventItem?.endDate instanceof Date
      ) {
        endDateObj = new Date(this.eventItem.endDate);
      }

      const options: Intl.DateTimeFormatOptions = {
        month: 'long',
        day: 'numeric',
      };

      const startFormatted = startDateObj
        .toLocaleDateString('en-US', options)
        .toUpperCase();

      // If no end date or same date, just show start date
      if (
        !endDateObj ||
        startDateObj.toDateString() === endDateObj.toDateString()
      ) {
        // For detail page, include time if showTimeFields is true
        if (this.router.url.includes('/events/')) {
          if (this.eventItem?.showTimeFields) {
            const startTime = this.formatTimeForDisplay(
              this.eventItem?.startTime
            );
            const endTime = this.formatTimeForDisplay(this.eventItem?.endTime);
            return `${startFormatted}, ${startTime}${
              endTime ? ` - ${endTime}` : ''
            }`;
          } else {
            return startFormatted;
          }
        } else {
          // For overview page, just show date
          return startFormatted;
        }
      }

      // Format for date range
      const endFormatted = endDateObj
        .toLocaleDateString('en-US', options)
        .toUpperCase();

      // For detail page, include time if showTimeFields is true
      if (this.router.url.includes('/events/')) {
        if (this.eventItem?.showTimeFields) {
          const startTime = this.formatTimeForDisplay(
            this.eventItem?.startTime
          );
          const endTime = this.formatTimeForDisplay(this.eventItem?.endTime);
          return `${startFormatted}, ${startTime} - ${endFormatted}, ${endTime}`;
        } else {
          return `${startFormatted} - ${endFormatted}`;
        }
      } else {
        // For overview page, just show date range
        return `${startFormatted} - ${endFormatted}`;
      }
    } catch (e) {
      console.warn('Error formatting date for display:', e);
      return '';
    }
  }

  /**
   * Formats a time value for display
   * @param time The time to format
   * @returns Formatted time string (12-hour format)
   */
  formatTimeForDisplay(time: any): string {
    if (!time) return '';

    try {
      let timeObj: Date;

      // Handle different time formats
      if (time.seconds) {
        // Firebase Timestamp
        timeObj = new Date(time.seconds * 1000);
      } else if (time._seconds) {
        // Firebase Timestamp with _seconds
        timeObj = new Date(time._seconds * 1000);
      } else if (typeof time === 'number') {
        // Timestamp in milliseconds
        timeObj = new Date(time);
      } else if (typeof time === 'string') {
        // ISO string or other string format
        timeObj = new Date(time);
      } else if (time instanceof Date) {
        // Date object
        timeObj = time;
      } else {
        return '';
      }

      // Format time in 12-hour format without minutes if they're 00
      const hours = timeObj.getHours();
      const minutes = timeObj.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';
      const hour12 = hours % 12 || 12;

      return minutes === 0
        ? `${hour12}${ampm}`
        : `${hour12}:${minutes.toString().padStart(2, '0')}${ampm}`;
    } catch (e) {
      console.warn('Error formatting time for display:', e);
      return '';
    }
  }
}

