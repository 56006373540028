import {
  Component,
  Input,
  OnDestroy,
  ViewChild,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { EventsService } from '../events.service';
import {
  createToaster,
  positionType,
} from 'src/app/shared/components/modals/toaster';
import { confirmDeletingModalData, EVENT_TYPE_ENUMS } from '../events';
import { EventsStore } from '../events-store';
import {
  ActionSheetController,
  AlertController,
  IonItemSliding,
} from '@ionic/angular';
import { getFunctions, httpsCallable } from '@firebase/functions';

@Component({
  selector: 'app-event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.scss'],
})
export class EventItemComponent implements OnDestroy {
  @Input() items;
  @Input() feedItemId: string;
  @Input() divider: { title: string; icon: string; color: string };
  @Input() currentUserId;
  @Input() isAdmin;
  @Input() transparent: boolean;
  @Input() status: string;

  @ViewChildren(IonItemSliding) slidingItems: QueryList<IonItemSliding>;

  showStatusModal: boolean = false;
  itemStatus;

  showConfirmModal;
  confirmDeletingModalData = confirmDeletingModalData;
  disabled = false;
  loading = false;
  selectedEvent;

  private destroy$ = new Subject<void>();

  constructor(
    private eventsService: EventsService,
    private router: Router,
    private store: EventsStore,
    private actionSheetCtrl: ActionSheetController,
    private alertController: AlertController
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openStatusModal(status?) {
    this.showStatusModal = !this.showStatusModal;
    if (status) this.itemStatus = status;
  }

  openConfirmModal(item, e) {
    e.stopPropagation();
    this.showConfirmModal = true;
    this.selectedEvent = item;
  }

  closeModal(e) {
    this.showConfirmModal = false;
  }

  openItem(job, currentUserId: string, isAdmin: boolean) {
    if (!this.eventSuspendedCheck(job, currentUserId, isAdmin)) {
      if (this.isExternallyHosted(job) && job.externalEventLink) {
        const formattedUrl = job.externalEventLink.startsWith('http')
          ? job.externalEventLink
          : 'https://' + job.externalEventLink;
        window.open(formattedUrl, '_blank');
      } else if (this.feedItemId) {
        this.router.navigate(['/events/', this.feedItemId]);
      } else {
        this.router.navigate(['/events/', job.id]);
      }
    }
  }

  removeItem() {
    this.disabled = true;
    this.loading = true;
    this.store.removeItem(this.selectedEvent.id);
    this.store
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data) {
          // Still loading, keep the loading state
        } else {
          // Loading complete, reset states
          this.loading = false;
          this.disabled = false;
          this.showConfirmModal = false;
          createToaster(
            'Successfully deleted!',
            'success',
            positionType.BOTTOM
          );
        }
      });
  }

  eventSuspendedCheck(event, currentUserId, isAdmin) {
    return isAdmin
      ? false
      : this.eventsService.eventSuspendedCheck(event, currentUserId);
  }

  isExternallyHosted(event) {
    return (
      event.eventType === EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_PAID ||
      event.eventType === EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_FREE
    );
  }

  /**
   * Limits a description to the first 5 words
   * @param description The full description text
   * @returns The first 5 words of the description followed by ellipsis if needed
   */
  limitDescriptionToFiveWords(description: string): string {
    if (!description) return '';

    const words = description.trim().split(/\s+/);
    if (words.length <= 15) return description;

    return words.slice(0, 5).join(' ') + '...';
  }

  async presentActionSheet(event: Event, item) {
    event.stopPropagation();

    const buttons = [
      {
        text: 'Edit',
        icon: 'create-outline',
        handler: () => {
          this.editEvent(item);
        },
      },
    ];

    // Only add flag option for admin users
    if (this.isAdmin) {
      buttons.push({
        text: item.isFlagged ? 'Remove Flag' : 'Flag Event',
        icon: item.isFlagged ? 'flag' : 'flag-outline',
        handler: () => {
          this.toggleFlag(item);
        },
      });
    }

    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Event Options',
      buttons: buttons,
    });

    await actionSheet.present();
  }

  editEvent(event) {
    // Set the event ID in the store
    this.store.patchState({ item: event });

    // Open the edit modal
    this.store.updateShowFormModal(true);
  }

  toggleFlag(event) {
    // Prevent flagging your own events
    if (event.creator?.id === this.currentUserId) {
      createToaster(
        'You cannot report your own events',
        'warning',
        positionType.BOTTOM
      );
      return;
    }

    if (event.isFlagged) {
      // If already flagged, show the reason and option to unflag
      const alert = this.alertController.create({
        header: 'Flagged Event',
        message: `This event was flagged as: ${event.flagReason}`,
        buttons: [
          {
            text: 'Keep Flag',
            role: 'cancel',
          },
          {
            text: 'Remove Flag',
            handler: () => {
              event.isFlagged = false;
              event.flagReason = null;

              // Update the event in the database
              this.eventsService.updateItemById('events', event, event.id);

              createToaster(
                'Flag removed from event',
                'success',
                positionType.BOTTOM
              );
            },
          },
        ],
      });

      alert.then(alertElement => {
        alertElement.present();
      });
    } else {
      // Show options to flag the event
      const alert = this.alertController.create({
        header: 'Flag This Event',
        message: 'Please select a reason for flagging this event:',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Flag',
            handler: reason => {
              if (reason === 'Other issue') {
                // If "Other issue" is selected, show a second alert for custom reason
                this.showOtherReasonInput(event);
              } else if (reason) {
                // Store original status if not already flagged
                if (!event.originalStatus) {
                  event.originalStatus = event.status;
                }

                // Set flag properties
                event.isFlagged = true;
                event.flagReason = reason;

                // IMPORTANT: Do not change the event status or remove the user from participants
                // This ensures the user remains in the community event

                // Update the event in the database
                this.eventsService.updateItemById('events', event, event.id);

                createToaster(
                  `Event flagged as ${reason}`,
                  'danger',
                  positionType.BOTTOM
                );

                // Send notification email to admin
                this.notifyAdminAboutFlaggedEvent(event, reason);
              }
            },
          },
        ],
        inputs: [
          {
            name: 'not-mermaid',
            type: 'radio',
            label: 'Not mermaid-related',
            value: 'Not mermaid-related',
          },
          {
            name: 'scam',
            type: 'radio',
            label: 'Potential scam',
            value: 'Potential scam',
          },
          {
            name: 'low-quality',
            type: 'radio',
            label: 'Low-quality event',
            value: 'Low-quality event',
          },
          {
            name: 'incorrect-info',
            type: 'radio',
            label: 'Incorrect information',
            value: 'Incorrect information',
          },
          {
            name: 'other',
            type: 'radio',
            label: 'Other issue',
            value: 'Other issue',
          },
        ],
      });

      alert.then(alertElement => {
        alertElement.present();
      });
    }
  }

  // Show second alert for custom reason input when "Other" is selected
  private showOtherReasonInput(event) {
    const otherReasonAlert = this.alertController.create({
      header: 'Other Issue',
      message: 'Please describe the issue:',
      inputs: [
        {
          name: 'customReason',
          type: 'text',
          placeholder: 'Describe the issue...',
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Submit',
          handler: data => {
            if (data.customReason && data.customReason.trim() !== '') {
              // Store original status if not already flagged
              if (!event.originalStatus) {
                event.originalStatus = event.status;
              }

              // Set flag properties
              event.isFlagged = true;
              event.flagReason = `Other: ${data.customReason}`;

              // IMPORTANT: Do not change the event status or remove the user from participants
              // This ensures the user remains in the community event

              // Update the event in the database
              this.eventsService.updateItemById('events', event, event.id);

              createToaster('Event flagged', 'danger', positionType.BOTTOM);

              // Send notification email to admin with custom reason
              this.notifyAdminAboutFlaggedEvent(
                event,
                `Other: ${data.customReason}`
              );
            } else {
              createToaster(
                'Please provide a description of the issue',
                'warning',
                positionType.BOTTOM
              );
              return false; // Keep the dialog open if no description
            }
          },
        },
      ],
    });

    otherReasonAlert.then(alertElement => {
      alertElement.present();
    });
  }

  // Send email notification to admin about flagged event
  private notifyAdminAboutFlaggedEvent(event, reason) {
    // Get Firebase functions
    const functions = getFunctions();
    const sendAdminNotification = httpsCallable(functions, 'sendMail');

    // Prepare notification data
    const notificationData = {
      recipientEmail: 'admin@mermapp.com', // Replace with actual admin email
      subject: 'Event Flagged: Action Required',
      message: `
        An event has been flagged by a user:

        Event Title: ${event.title}
        Event ID: ${event.id}
        Flagged Reason: ${reason}

        Please review this event at your earliest convenience.
      `,
      sender: 'Mermapp System',
    };

    // Send the notification
    sendAdminNotification(notificationData).catch(error => {
      console.error('Error sending admin notification:', error);
    });
  }

  isEventPast(event): boolean {
    if (!event || (!event.startDate && !event.endDate)) {
      return false;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to beginning of today

    let eventEndDate: Date;

    // Use endDate if available, otherwise use startDate
    if (event.endDate) {
      eventEndDate = this.parseEventDate(event.endDate);
    } else if (event.startDate) {
      eventEndDate = this.parseEventDate(event.startDate);
    } else {
      return false; // No valid date found
    }

    // If we couldn't parse the date, assume it's not past
    if (!eventEndDate || isNaN(eventEndDate.getTime())) {
      return false;
    }

    // Return true if the event is in the past
    return eventEndDate < today;
  }

  private parseEventDate(date: any): Date | null {
    if (!date) return null;

    try {
      // Handle Firebase Timestamp objects
      if (date.seconds) {
        return new Date(date.seconds * 1000);
      }
      if (date._seconds) {
        return new Date(date._seconds * 1000);
      }

      // Handle ISO string date (YYYY-MM-DD format)
      if (typeof date === 'string' && date.match(/^\d{4}-\d{2}-\d{2}$/)) {
        return new Date(date);
      }

      // Handle regular date strings or objects
      return new Date(date);
    } catch (e) {
      console.warn('Could not parse date:', date);
      return null;
    }
  }

  formatDate(date: any): string {
    if (!date) return '';

    try {
      // Handle Firebase Timestamp objects
      if (date.seconds) {
        return new Date(date.seconds * 1000).toISOString().split('T')[0];
      }

      // Handle Firebase Timestamp objects with _seconds
      if (date._seconds) {
        return new Date(date._seconds * 1000).toISOString().split('T')[0];
      }

      // Handle ISO string date (YYYY-MM-DD format)
      if (typeof date === 'string' && date.match(/^\d{4}-\d{2}-\d{2}$/)) {
        return date;
      }

      // Handle regular date strings or objects
      if (typeof date === 'string' || date instanceof Date) {
        return new Date(date).toISOString().split('T')[0];
      }

      return '';
    } catch (e) {
      console.warn('Error formatting date:', e);
      return '';
    }
  }

  formatTime(date: any): string {
    if (!date) return '';

    try {
      // Handle Firebase Timestamp objects
      if (date.seconds) {
        return new Date(date.seconds * 1000).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        });
      }

      // Handle Firebase Timestamp objects with _seconds
      if (date._seconds) {
        return new Date(date._seconds * 1000).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        });
      }

      // Don't format time for ISO string dates (YYYY-MM-DD format)
      if (typeof date === 'string' && date.match(/^\d{4}-\d{2}-\d{2}$/)) {
        return '';
      }

      // Handle regular date strings or objects
      if (typeof date === 'string' || date instanceof Date) {
        return new Date(date).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        });
      }

      return '';
    } catch (e) {
      console.warn('Error formatting time:', e);
      return '';
    }
  }

  /**
   * Determines if time should be shown for an event
   * @param event The event object
   * @returns True if time should be shown, false otherwise
   */
  shouldShowTime(event: any): boolean {
    console.log('shouldShowTime check for event:', event);

    // Check if the event has the showTimeFields flag
    if (event.showTimeFields === true) {
      console.log('Event has showTimeFields true');
      return true;
    }

    // Check if both startTime and endTime are available
    if (event.startTime && event.endTime) {
      console.log('Event has both startTime and endTime');
      return true;
    }

    // For events with just startTime
    if (event.startTime) {
      try {
        // Try to parse the startTime
        let date: Date;

        if (typeof event.startTime === 'string') {
          date = new Date(event.startTime);
        } else if (event.startTime.seconds) {
          date = new Date(event.startTime.seconds * 1000);
        } else if (event.startTime._seconds) {
          date = new Date(event.startTime._seconds * 1000);
        } else if (event.startTime instanceof Date) {
          date = event.startTime;
        } else {
          console.log('Could not parse startTime');
          return false;
        }

        console.log('Parsed startTime:', date);

        // Don't show time for midnight or noon exactly (these are likely defaults)
        if (
          (date.getHours() === 0 && date.getMinutes() === 0) ||
          (date.getHours() === 12 && date.getMinutes() === 0)
        ) {
          console.log('startTime is midnight or noon, not showing');
          return false;
        }
      } catch (e) {
        console.warn('Error parsing time:', e);
        return false;
      }

      console.log('Event has valid startTime, showing time');
      return true;
    }

    console.log('Event has no time information, not showing time');
    return false;
  }

  /**
   * Formats a time value for display
   * @param time The time to format
   * @returns Formatted time string (HH:MM format)
   */
  formatTimeForDisplay(time: any): string {
    if (!time) return '';

    try {
      let timeObj: Date;

      // Handle different time formats
      if (time.seconds) {
        // Firebase Timestamp
        timeObj = new Date(time.seconds * 1000);
      } else if (time._seconds) {
        // Firebase Timestamp with _seconds
        timeObj = new Date(time._seconds * 1000);
      } else if (typeof time === 'number') {
        // Timestamp in milliseconds
        timeObj = new Date(time);
      } else if (typeof time === 'string') {
        // ISO string or other string format
        timeObj = new Date(time);
      } else if (time instanceof Date) {
        // Date object
        timeObj = time;
      } else {
        return '';
      }

      // Format time in 12-hour format without minutes if they're 00
      const hours = timeObj.getHours();
      const minutes = timeObj.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';
      const hour12 = hours % 12 || 12;

      return minutes === 0
        ? `${hour12}${ampm}`
        : `${hour12}:${minutes.toString().padStart(2, '0')}${ampm}`;
    } catch (e) {
      console.warn('Error formatting time for display:', e);
      return '';
    }
  }

  /**
   * Formats the date in the "MONTH DAY" format
   * @param date The date to format
   * @param event The event object
   * @returns Formatted date string
   */
  formatDateForDisplay(date: any, event?: any): string {
    if (!date) return '';

    try {
      let dateObj: Date;

      // Handle Firebase Timestamp objects
      if (date.seconds) {
        dateObj = new Date(date.seconds * 1000);
      }
      // Handle Firebase Timestamp objects with _seconds
      else if (date._seconds) {
        dateObj = new Date(date._seconds * 1000);
      }
      // Handle ISO string date (YYYY-MM-DD format)
      else if (typeof date === 'string' && date.match(/^\d{4}-\d{2}-\d{2}$/)) {
        dateObj = new Date(date);
      }
      // Handle regular date strings or objects
      else if (typeof date === 'string' || date instanceof Date) {
        dateObj = new Date(date);
      } else {
        return '';
      }

      console.log('formatDateForDisplay input:', date, 'event:', event);

      // Format the date to match event details format
      const options: Intl.DateTimeFormatOptions = {
        month: 'long',
        day: 'numeric',
      };

      const formattedDate = dateObj
        .toLocaleDateString('en-US', options)
        .toUpperCase();
      console.log('formatDateForDisplay output:', formattedDate);
      return formattedDate;
    } catch (e) {
      console.warn('Error formatting date for display:', e);
      return '';
    }
  }

  /**
   * Close the sliding item after an action is performed
   * @param slidingItem The sliding item to close
   */
  closeSlidingItem(slidingItem: IonItemSliding) {
    if (slidingItem) {
      slidingItem.close();
    }
  }
}

