import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoTranslateDirective } from '../directives/auto-translate.directive';
import { IonicStorageModule } from '@ionic/storage-angular';

@NgModule({
  declarations: [AutoTranslateDirective],
  imports: [CommonModule, IonicStorageModule.forRoot()],
  exports: [AutoTranslateDirective],
})
export class TranslationModule {}

