export enum FormFieldTypes {
  TEXT_INPUT = 'TEXT_INPUT',
  TEXT_AREA = 'TEXT_AREA',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  TOGGLE = 'TOGGLE',
  SELECT = 'SELECT',
  GROUP = 'GROUP',
  ARRAY = 'ARRAY',
  FILE = 'FILE',
}

export interface FormField {
  id?: string;
  name: string;
  type: FormFieldTypes;
  label: string;
  required?: boolean;
  line?: string;
  multiple?: boolean;
  disabled?: boolean;
  options?:
    | Array<{
        value: string; // Original English value
        label: string; // Translated label
        icon?: string; // Optional icon for the option
      }>
    | string[]; // For backward compatibility
  children?: FormField[];
  defaultValue?: any;
  icon?: string;
  addBtn?: string;
  template?: FormField;
  if?: string;
  value?: string;
  modal?: string;
  lines?: string;
}

