import {
  bannerBottomOptions,
  insideAppTravelingOptions,
  insideIosAppTravelingOptions,
  leavingAppInterstitialOptions,
  shellConvertingOptions,
  treasureOpeningOptions,
} from './ad.options';
import {
  AdLoadInfo,
  AdMob,
  AdMobBannerSize,
  AdMobRewardItem,
  AdOptions,
  BannerAdOptions,
  BannerAdPluginEvents,
  RewardAdOptions,
  RewardAdPluginEvents,
} from '@capacitor-community/admob';
import { Injectable, OnInit } from '@angular/core';
import { PluginListenerHandle } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { Location } from '@angular/common';
import { getAuth } from 'firebase/auth';
import { PaymentsStore } from 'src/app/payments/payments-store';
import { PaymentsService } from 'src/app/payments/payments.service';
import { BehaviorSubject, filter, takeUntil } from 'rxjs';
import { UserService } from 'src/app/auth/user.service';

@Injectable({
  providedIn: 'root',
})
export class AdmobService {
  constructor(
    private platform: Platform,
    private location: Location,
    private paymentsStore: PaymentsStore,
    private paymentService: PaymentsService,
    private userService: UserService
  ) {}

  appMargin = 0;
  bannerLoaded = false;

  isMobile = this.platform.platforms().includes('capacitor');
  isMobileWeb = this.platform.platforms().includes('mobileweb');
  isIosApp = this.platform.platforms().includes('ios') && this.isMobile;

  resizedAd = new BehaviorSubject<boolean>(false);
  isPremium = false;
  isAdmin = false;
  isRewarded = new BehaviorSubject<boolean>(false);

  private readonly listenerHandlers: PluginListenerHandle[] = [];

  async showBottomBanner() {
    await this.showBanner(bannerBottomOptions);
  }

  async showTravelingAd() {
    if (this.isIosApp) {
      await this.showBanner(insideIosAppTravelingOptions);
    } else {
      await this.showBanner(insideAppTravelingOptions);
    }
  }

  async showMediaAdv() {
    await this.removeBanner();
    this.ionViewWillEnter();
    if (
      this.location.path().includes('bazaar/') ||
      this.location.path().includes('media')
    ) {
      await this.showBottomBanner();
    }
  }

  async showLeavingAd() {
    await this.showInterstitialAd(leavingAppInterstitialOptions);
  }

  async showTreasureOpeningAd() {
    await this.showRewardAd(treasureOpeningOptions);
  }

  async showShellConvertingAd() {
    await this.showRewardAd(shellConvertingOptions);
  }

  ionViewWillEnter() {
    if (this.isMobile && !this.isPremium && !this.isAdmin) {
      this.resizedAd.next(false);
      const resizeHandler = AdMob.addListener(
        BannerAdPluginEvents.SizeChanged,
        (info: AdMobBannerSize) => {
          if (
            this.location.path().includes('bazaar/') ||
            (this.location.path().includes('media') && info.height !== 250)
          ) {
            this.appMargin = info.height;
          }

          this.resizedAd.next(!!info.height);
          const app: HTMLElement = document.querySelector('ion-router-outlet');

          if (this.appMargin === 0) {
            this.resizedAd.next(false);
            app.style.marginTop = '';
            return;
          }

          if (this.appMargin > 0) {
            const body = document.querySelector('body');
            const bodyStyles = window.getComputedStyle(body);
            const safeAreaBottom = bodyStyles.getPropertyValue(
              '--ion-safe-area-bottom'
            );

            app.style.marginBottom = `calc(${safeAreaBottom} + ${this.appMargin}px)`;
          }
        }
      );

      const adLoaded = AdMob.addListener(BannerAdPluginEvents.Opened, () => {
        this.bannerLoaded = true;
      });

      const rewardHandler = AdMob.addListener(
        RewardAdPluginEvents.Loaded,
        (info: AdLoadInfo) => {
          console.log(info);
          // Subscribe prepared rewardVideo
        }
      );

      const rewardUserHandler = AdMob.addListener(
        RewardAdPluginEvents.Rewarded,
        (rewardItem: AdMobRewardItem) => {
          this.isRewarded.next(true);
        }
      );

      this.listenerHandlers.push(rewardHandler);
      this.listenerHandlers.push(rewardUserHandler);
      this.listenerHandlers.push(adLoaded);
      this.listenerHandlers.push(resizeHandler);
    }
  }

  async ionViewWillLeave() {
    if (this.isMobile) {
      this.listenerHandlers.forEach(handler => handler.remove());
      this.resizedAd.next(false);
      await this.removeBanner();
      const app: HTMLElement = document.querySelector('ion-router-outlet');
      app.style.marginBottom = '0px';
      this.appMargin = 0;
      this.isRewarded.next(false);
    }
  }

  async removeBanner() {
    if (this.isMobile) {
      const result = await AdMob.removeBanner().catch(e => console.log(e));
      if (result === undefined) {
        return;
      }
    }
  }

  async hideBanner() {
    if (this.isMobile) {
      this.resizedAd.next(false);
      const result = await AdMob.hideBanner().catch(e => console.log(e));
      if (result === undefined) {
        return;
      }
      const app: HTMLElement = document.querySelector('ion-router-outlet');
      app.style.marginBottom = '0px';
      this.appMargin = 0;
    }
  }

  async resumeBanner() {
    if (this.isMobile && !this.isPremium && !this.isAdmin) {
      const result = await AdMob.resumeBanner().catch(e => console.log(e));
      if (result === undefined) {
        return;
      }
    }
  }

  private async showBanner(options: BannerAdOptions): Promise<void> {
    if (this.isMobile && !this.isPremium && !this.isAdmin) {
      this.resizedAd.next(false);

      await this.getUser();

      if (!this.isAdmin && !this.isPremium) {
        const bannerOptions: BannerAdOptions = { ...options };
        const result = await AdMob.showBanner(bannerOptions).catch(e =>
          console.error(e)
        );
        if (result === undefined) {
          return;
        }
      }
    }
  }

  private async showInterstitialAd(options: AdOptions) {
    if (this.isMobile && !this.isPremium && !this.isAdmin) {
      const interstitialOptions: BannerAdOptions = { ...options };

      const result1 = await AdMob.prepareInterstitial(
        interstitialOptions
      ).catch(e => console.error(e));
      const result2 = await AdMob.showInterstitial().catch(e =>
        console.error(e)
      );

      if (result1 === undefined || result2 === undefined) {
        return;
      }
    }
  }

  private async showRewardAd(options: RewardAdOptions) {
    const rewardOptions: RewardAdOptions = { ...options };
    const result = await AdMob.prepareRewardVideoAd(rewardOptions).catch(e =>
      console.error(e)
    );
    const rewardItem = await AdMob.showRewardVideoAd();
    if (result === undefined) {
      return;
    }
  }
  private getUser() {
    this.userService.currentUser$
      .pipe(filter(user => !!user.uid))
      .subscribe(user => {
        this.isPremium = this.userService.isPremium;
        this.isAdmin = this.userService.isAdmin;
      });
  }
}
