import { FormField, FormFieldTypes } from 'src/app/shared/components/form/form';

export const communityFilterData: FormField[] = [
  {
    name: 'country',
    type: FormFieldTypes.SELECT,
    label: 'Country',
    multiple: true,
    required: false,
    icon: 'location',
  },
  {
    name: 'experience',
    type: FormFieldTypes.SELECT,
    label: 'Mermaid Experience Level',
    required: false,
    multiple: true,
    options: ['Newby', 'Regular Hobbyist', 'Semi-Professional', 'Professional'],
    icon: 'trending-up',
  },
  {
    name: 'gender',
    type: FormFieldTypes.SELECT,
    label: 'Gender',
    required: false,
    multiple: true,
    options: ['Male', 'Female', 'Other'],
    icon: 'transgender',
  },
];
export const successModalData = {
  title: 'Thanks for sharing MerMapp with your Merfriends!',
  pic: 'some sea animal in company with others',
  description:
    "It's thanks to people like you that we can grow a great community and connect with each other!",
  okBtn: {
    title: 'CONTINUE',
    link: `merfolk/community`,
  },
  cancelBtn: {
    title: 'SPLASH MERFRIENDS',
    link: '/',
  },
};
export const confirmModalData = {
  title: 'Invite a Merfriend',
  icon: 'people',
  okBtn: {
    title: 'YES, DISCARD',
  },
  cancelBtn: { title: 'NO, GO BACK' },
};
