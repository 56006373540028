import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ACCOUNT_EVENT_TYPE_ENUMS,
  categoryOptions,
  EVENT_TYPE_ENUMS,
  eventFormData,
} from '../events';
import { countries } from '../../auth/sign-up/countries';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  FormService,
  linkRegex,
} from '../../shared/components/form/form.service';
import { Router } from '@angular/router';
import { AuthService } from '../../firebase/auth.service';
import { EventsService } from '../events.service';
import { EventsStore } from '../events-store';
import { filter, map, Subject, takeUntil } from 'rxjs';
import { GoogleMap } from '@capacitor/google-maps';
import { environment } from '../../../environments/environment';
import { IonDatetimeButton, IonModal } from '@ionic/angular';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { PodStore } from 'src/app/merfolk/pods/pod-store';
import * as dayjs from 'dayjs';
import { DateService } from 'src/app/services/date.service';
import { UserService } from 'src/app/auth/user.service';
import { ImagesService } from 'src/app/services/images.service';
import { MapService } from 'src/app/services/map.service';

@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss'],
})
export class EventFormComponent implements OnInit, OnDestroy {
  @Input() eventForm;
  @Input() eventItem;
  @Input() imagesArr;
  @Input() disabled;
  @Input() comePod;
  @Output() eventFormChange = new EventEmitter<any>();
  @Output() imagesArrChange = new EventEmitter<any>();
  @Output() disabledChange = new EventEmitter<any>();
  @ViewChild(IonModal) modal: IonModal;
  @ViewChild('datetime') datetime: IonDatetimeButton;
  @ViewChild('map') mapRef;
  @ViewChild('placesRef', { static: false }) placesRef: GooglePlaceDirective;
  @ViewChild('imageLoader') imageLoader;
  currentUser;
  map;
  creator;
  pods;
  form: FormGroup;
  status;
  isOpen = false;
  isOnline = false;
  postingEventOptions = [
    ACCOUNT_EVENT_TYPE_ENUMS.MY_MEMBER_ACCOUNT,
    ACCOUNT_EVENT_TYPE_ENUMS.MY_BUSINESS_ACCOUNT,
  ];
  categoryOptions = categoryOptions;
  eventTypeOptions = [
    {
      name: EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_PAID,
    },
    {
      name: EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_FREE,
    },
    {
      name: EVENT_TYPE_ENUMS.INTERNALLY_HOSTED_AND_PAID,
    },
    {
      name: EVENT_TYPE_ENUMS.INTERNALLY_HOSTED_AND_FREE,
    },
  ];
  today = new Date().toISOString();
  paymentLinkLabel =
    "*If you don't add a payment link, a member that buys your ticket will message you via a private Discord message to pay you privately.";
  countries = countries;
  meetingLink = false;
  businessName = false;
  businessWebsiteURLValue = null;
  businessNameValue = null;
  businessWebsiteURL = false;
  addingBtnDisabled = true;
  concreteAddress = false;
  googleMapsLink = false;
  externalEventLink = true;
  paymentLink = false;
  priceContainer = true;
  paymentLinkLabelVisible = false;
  loadingImage = false;
  currentImage: string;
  imageField = eventFormData.filter(i => i.type === 'ARRAY');
  imagesControls = 1;
  isPodActivity = false;
  properties;
  private destroy$ = new Subject<void>();
  constructor(
    private formService: FormService,
    private imagesService: ImagesService,
    private authService: AuthService,
    private podStore: PodStore,
    private eventsService: EventsService,
    private dateService: DateService,
    private userService: UserService,
    private fb: FormBuilder,
    private mapService: MapService
  ) {}

  ngOnInit() {
    this.createForm();
    this.podStore.getItems({});
    this.getUser();

    if (this.eventItem) {
      this.eventForm.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(value => {
          if (value) {
            this.eventForm.value = value;
            this.imagesArrChange.next(this.imagesArr);
            this.eventFormChange.next(this.eventForm);
            this.disabled =
              this.eventForm.status === 'INVALID' ||
              this.status === 'loading' ||
              this.loadingImage ||
              !this.imagesArr ||
              !this.imagesArr.length;
          }
        });

      this.eventItem.businessName
        ? (this.businessNameValue = this.eventItem.businessName)
        : null;

      this.eventItem.businessWebsiteURL
        ? (this.businessWebsiteURLValue = this.eventItem.businessWebsiteURL)
        : null;

      if (
        this.eventItem.account === ACCOUNT_EVENT_TYPE_ENUMS.MY_MEMBER_ACCOUNT
      ) {
        this.businessName = false;
        this.businessWebsiteURL = false;
        this.eventForm.removeControl('businessName');
        this.eventForm.removeControl('businessWebsiteURL');
      } else if (
        this.eventItem.account === ACCOUNT_EVENT_TYPE_ENUMS.MY_BUSINESS_ACCOUNT
      ) {
        this.businessName = true;
        this.businessWebsiteURL = true;
        this.eventForm.addControl(
          'businessName',
          new FormControl(this.businessNameValue, [Validators.required])
        );
        this.eventForm.addControl(
          'businessWebsiteURL',
          new FormControl(this.businessWebsiteURLValue, [
            Validators.required,
            Validators.pattern(linkRegex),
          ])
        );
      }

      this.eventForm.patchValue(
        Object.assign({
          ...this.eventItem,
          createdAt: this.eventItem.createdAt,
          startDate: this.dateService.formatDate(this.eventItem.startDate),
          endDate: this.eventItem.endDate
            ? this.dateService.formatDate(this.eventItem.endDate)
            : '',
        })
      );
      this.imagesService.compressedImages.next(this.eventItem.images);

      this.imagesService.compressedImages
        .pipe(takeUntil(this.destroy$))
        .subscribe(images => {
          this.imagesArrChange.next(images);
          this.imagesArr = images;
        });

      if (this.eventForm.get('category').value === 'Pod Activity') {
        const podName = this.eventForm.addControl(
          'podName',
          new FormControl(null, [Validators.required])
        );
        this.eventForm.addControl('onlyPodMembers', new FormControl(false));
        this.isPodActivity = true;
      } else {
        this.isPodActivity = false;
        this.eventForm.removeControl('podName');
        this.eventForm.removeControl('onlyPodMembers');
      }

      if (this.eventItem.podName) {
        this.eventForm.get('podName')?.patchValue(this.eventItem.podName);
        this.eventForm
          .get('onlyPodMembers')
          ?.patchValue(this.eventItem.onlyPodMembers);
      }

      this.showLocation();
      this.eventForm.get('meetingLink')?.patchValue(this.eventItem.meetingLink);
      this.eventTypesChanges(this.eventItem.eventType);
      this.eventForm.get('paymentLink')?.patchValue(this.eventItem.paymentLink);
    } else {
      this.eventForm
        .get('eventType')
        .setValue(EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_PAID);
      this.eventForm
        .get('account')
        .setValue(ACCOUNT_EVENT_TYPE_ENUMS.MY_MEMBER_ACCOUNT);

        this.formService.location$.next({ googleMapLink: null });
        this.eventForm.patchValue({
          location: {
            state: null,
            googleMapLink: null,
          },
        });
    } // if end

    this.eventForm.get('startDate').valueChanges.subscribe(() => {
      this.eventForm.get('endDate').patchValue(null);
    });

    this.eventForm
      .get('online')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => {
        this.showLocation();
      });

    this.eventForm
      .get('eventType')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.eventTypesChanges(value);
      });
    if (this.comePod) {
      this.eventForm.patchValue({ category: 'Pod Activity' });
      this.eventForm.addControl(
        'podName',
        new FormControl(this.comePod?.id, [Validators.required])
      );
      this.eventForm.addControl('onlyPodMembers', new FormControl(false));
      this.isPodActivity = true;
    }
    this.eventForm.get('category').valueChanges.subscribe(value => {
      if (value === 'Pod Activity') {
        this.eventForm.addControl(
          'podName',
          new FormControl(null, [Validators.required])
        );
        this.eventForm.addControl('onlyPodMembers', new FormControl(false));
        this.isPodActivity = true;
      } else {
        this.isPodActivity = false;
        this.eventForm.removeControl('podName');
        this.eventForm.removeControl('onlyPodMembers');
      }
    });

    this.eventForm
      .get('account')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => {
        if (value === ACCOUNT_EVENT_TYPE_ENUMS.MY_MEMBER_ACCOUNT) {
          this.businessName = false;
          this.businessWebsiteURL = false;
          this.eventForm.removeControl('businessName');
          this.eventForm.removeControl('businessWebsiteURL');
        } else if (value === ACCOUNT_EVENT_TYPE_ENUMS.MY_BUSINESS_ACCOUNT) {
          this.businessName = true;
          this.businessWebsiteURL = true;
          this.eventForm.addControl(
            'businessName',
            new FormControl(this.businessNameValue, [Validators.required])
          );
          this.eventForm.addControl(
            'businessWebsiteURL',
            new FormControl(this.businessWebsiteURLValue, [
              Validators.required,
              Validators.pattern(linkRegex),
            ])
          );
        }
      });

    this.eventForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.disabledChange.next(
          this.eventForm.status === 'INVALID' ||
            this.status === 'loading' ||
            this.loadingImage ||
            !this.imagesArr?.length
        );
      });

    this.imagesService.compressedImages
      .pipe(takeUntil(this.destroy$))
      .subscribe(images => {
        this.imagesArrChange.next(images);
        this.imagesArr = images;
      });

    this.imagesService.imagesLoading.subscribe(loading => {
      this.loadingImage = loading;
      this.disabled =
        loading ||
        this.eventForm.status === 'INVALID' ||
        !this.imagesArr?.length;
      this.disabledChange.next(loading || this.eventForm.status === 'INVALID');
    });
  }

  saveBusinessName(e) {
    this.businessNameValue = e.target.value;
  }

  saveBusinessWebsiteURL(e) {
    this.businessWebsiteURLValue = e.target.value;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.eventForm.reset();
    this.formService.itemGallery.next([]);
    this.businessWebsiteURLValue = null;
    this.businessNameValue = null;
    this.formService.location$.next({
      googleMapLink: null,
    });
  }

  public handleAddressChange(address: any) {
    this.createMap({
      lat: address.geometry.location.lat(),
      lng: address.geometry.location.lng(),
    }).then(() => {
      this.map.setOnMapClickListener(async ({ latitude, longitude }) => {
        const baseMapUrl = 'https://www.google.com/maps/search/?api=1&query=';
        this.eventForm.patchValue({
          googleMapLink: `${baseMapUrl}${latitude},${longitude}`,
        });

        this.isOpen = false;
      });
    });
  }

  showLocation() {
    if (this.eventForm.get('online').value === true) {
      this.isOnline = true;
      (this.eventForm.get('location') as FormGroup).removeControl(
        'googleMapLink'
      );

      this.meetingLink = true;
      this.eventForm.addControl(
        'meetingLink',
        new FormControl(null, [
          Validators.required,
          Validators.pattern(linkRegex),
        ])
      );
    } else {
      const country = this.eventItem?.location?.country || null;
      this.isOnline = false;
      (this.eventForm.get('location') as FormGroup).addControl(
        'googleMapLink',
        new FormControl(this.eventItem?.location?.googleMapLink)
      );
      this.formService.location$.next({
        googleMapLink: this.eventItem?.location?.googleMapLink,
      });
      this.meetingLink = false;
      this.eventForm.removeControl('meetingLink');
    }

    if (this.eventForm.get('location.country')) {
      this.eventForm
        .get('location.country')
        .valueChanges.pipe(
          filter(value => !!value),
          filter(
            (value: any) =>
              value.name !== this.eventItem?.location?.country?.name
          )
        )
        .subscribe(() => {
          this.formService.location$.next({ googleMapLink: null });
          this.eventForm.patchValue({
            location: {
              state: null,
              googleMapLink: null,
            },
          });
        });
    }
  }

  addMapLink(e) {
    e.valueChanges.subscribe(async () => {
      const location =
        e.get('googleMapLink')?.value ||
        this.eventItem?.location?.googleMapLink;

      const { country } = await this.mapService.getAdDress(location);

      let currentCountry = countries.find(item => item.name === country);
      
      this.eventForm.get('location.googleMapLink')?.patchValue(location);
      this.eventForm.get('location.country').patchValue(currentCountry);
      
    });
  }

  getFormsControls(): FormArray {
    return <FormArray>this.eventForm.controls['images'];
  }

  createMapHandler() {
    this.isOpen = true;
    setTimeout(() => {
      this.createMap(this.currentUser.location).then(() => {
        this.map.setOnMapClickListener(({ latitude, longitude }) => {
          const baseMapUrl = 'https://www.google.com/maps/search/?api=1&query=';
          this.eventForm.get('location').patchValue({
            googleMapLink: `${baseMapUrl}${latitude},${longitude}`,
          });
          this.isOpen = false;
        });
      });
    });
  }

  getRandomId() {
    return (Math.random() + 1).toString(36).substring(7);
  }

  eventTypesChanges(value) {
    if (value === EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_PAID) {
      this.hidePaymentLink();
      this.showPriceContainer();
      this.showExternalEventLink(value);
    } else if (value === EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_FREE) {
      this.hidePaymentLink();
      this.hidePriceContainer();
      this.showExternalEventLink(value);
    } else if (value === EVENT_TYPE_ENUMS.INTERNALLY_HOSTED_AND_PAID) {
      this.showPaymentLink();
      this.showPriceContainer();
      this.hideExternalEventLink(value);
    } else if (value === EVENT_TYPE_ENUMS.INTERNALLY_HOSTED_AND_FREE) {
      this.hidePaymentLink();
      this.hidePriceContainer();
      this.hideExternalEventLink(value);
    }
  }

  eventFormSubmit($event) {
    this.imagesArrChange.next(this.imagesArr);
    this.eventFormChange.next(this.eventForm);
  }

  async createMap({ lat, lng }) {
    this.map = await GoogleMap.create({
      id: this.getRandomId(),
      apiKey: environment.googleMapKey,
      element: this.mapRef.nativeElement,
      config: {
        center: {
          lat,
          lng,
        },
        zoom: 10,
        keyboardShortcuts: false,
        disableDefaultUI: true,
      },
    });
  }

  private listenPodsChanges() {
    this.podStore
      .selectItems()
      .pipe(
        takeUntil(this.destroy$),
        filter(item => !!item),
        map(items => {
          return (
            items &&
            items.filter(
              item =>
                (item.participants.includes(this.currentUser?.uid) &&
                  item.memberRights === 'everyone can edit/delete') ||
                item.creator?.id === this.currentUser?.uid
            )
          );
        })
      )
      .subscribe(items => {
        this.pods = items;
      });
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.currentUser = user;
        this.listenPodsChanges();
      });
  }

  private showExternalEventLink(value) {
    if (
      value.includes('Externally') &&
      !this.eventForm.get('externalEventLink')
    ) {
      this.externalEventLink = true;
      this.eventForm.addControl(
        'externalEventLink',
        new FormControl(null, [
          Validators.required,
          Validators.pattern(linkRegex),
        ])
      );
    }
  }

  private showPaymentLink() {
    this.paymentLink = true;
    this.eventForm.addControl(
      'paymentLink',
      new FormControl(null, [Validators.pattern(linkRegex)])
    );
    this.paymentLinkLabelVisible = true;
  }

  private showPriceContainer() {
    this.priceContainer = true;
    (this.eventForm.get('price') as FormGroup).addControl(
      'value',
      new FormControl('', [Validators.required])
    );
    (this.eventForm.get('price') as FormGroup).addControl(
      'currency',
      new FormControl('$', [Validators.required])
    );
  }

  private hideExternalEventLink(value) {
    if (
      value.includes('Internally') &&
      this.eventForm.get('externalEventLink')
    ) {
      this.externalEventLink = false;
      this.eventForm.removeControl('externalEventLink');
    }
  }

  private hidePaymentLink() {
    this.paymentLink = false;
    this.eventForm.removeControl('paymentLink');
    this.paymentLinkLabelVisible = false;
  }

  private hidePriceContainer() {
    this.priceContainer = false;
    (this.eventForm.get('price') as FormGroup).removeControl('value');
    (this.eventForm.get('price') as FormGroup).removeControl('currency');
  }

  private createForm() {
    this.eventForm = this.fb.group({
      images: this.fb.array([
        {
          0: this.fb.group({
            image: [null],
          }),
        },
      ]),
      title: ['', [Validators.required, Validators.minLength(7)]],
      startDate: [new Date().toISOString(), [Validators.required]],
      endDate: [null],
      category: ['', [Validators.required]],
      online: [false, [Validators.required]],
      location: this.fb.group({
        country: [null],
        googleMapLink: [null, [Validators.required]],
      }),
      eventType: ['', [Validators.required]],
      externalEventLink: [
        '',
        [Validators.required, Validators.pattern(linkRegex)],
      ],
      price: this.fb.group({
        value: [null, [Validators.required]],
        currency: ['$'],
      }),
      description: ['', [Validators.required, Validators.minLength(20)]],
      attendeeLimit: [''],
      account: ['', [Validators.required]],
      participants: [],
    });
  }
}
