import { FormField, FormFieldTypes } from '../shared/components/form/form';
import { Item } from '../shared/models/item.models';

export interface EventItem extends Item {
  creator: any;
  title: string;
  description: string;
  startDate: any;
  endDate: any;
  createdAt: any;
  images: string[];
  value?: string;
  organizer?: string;
  organizerUrl?: string;
  online: boolean;
  location: {
    country: {
      name: string;
      code: string;
    };
    state: string;
    googleMapLink: string;
  };
  eventType: string;
  participants?: { id: string }[];
  podName?: string;
  onlyPodMembers?: boolean;
  category: string;
  externalEventLink: string;
  price: {
    value: string;
    currency: string;
  };
  attendeeLimit: string;
  account: string;
  suspended?: boolean;
  meetingLink: string;
  paymentLink: string;
  status: string;
  isFlagged?: boolean;
  flagReason?: string;
  businessName?: string;
  businessWebsiteURL?: string;
  includeTime?: boolean;
}

export interface IEventFilter {
  myContent: boolean;
  category?: string;
  priceRange?: string;
  price?: 'free' | 'paid';
  location?: string;
  startDate?: string;
  endDate?: string;
  creator?: { id: string };
}

export interface IFormState {
  online: boolean;
  type: string;
  business: boolean;
}

export const eventTypeOptions = [
  {
    name: 'A different website informs & sells tickets',
  },
  {
    name: "A different website informs & it's free",
  },
  {
    name: 'I want to inform & sell tickets only here',
  },
  {
    name: "I want to inform only here & it's free",
  },
];

export const EVENT_TYPE_ENUMS = {
  EXTERNALLY_HOSTED_AND_PAID: 'A different website informs & sells tickets',
  EXTERNALLY_HOSTED_AND_FREE: "A different website informs & it's free",
  INTERNALLY_HOSTED_AND_PAID: 'I want to inform & sell tickets only here',
  INTERNALLY_HOSTED_AND_FREE: "I want to inform only here & it's free",
};

export const ACCOUNT_EVENT_TYPE_ENUMS = {
  MY_MEMBER_ACCOUNT: 'My member account',
  MY_BUSINESS_ACCOUNT: 'My business account',
};

export const categoryOptions = [
  'Convention',
  'Course',
  'Competition',
  'Festival',
  'Meeting',
  'Pod Activity',
  'Other',
];

export const confirmDeletingModalData = {
  title: 'Deleting an Event',
  icon: 'calendar-outline',
  okBtn: {
    title: 'YES, DELETE',
  },
  cancelBtn: {
    title: 'NO, KEEP',
  },
};

export const eventPremiumModalData =
  "Have a great event planned? As a premium mer you'll be a good to go!";

export const eventDiscordModalData = {
  icon: 'calendar-outline',
  title: 'Add an event',
  text: 'In order for your event participants to contact you, you need to join our chat group on Discord first.',
};
export const successEventModalData = {
  title: 'Thanks for adding content!',
  pic: 'some sea animal with magnifying glass',
  description:
    "You'll get rewarded as soon as your content has been verified by 3 moderators.",
  okBtn: {
    title: 'CONTINUE',
  },
  cancelBtn: {
    title: 'SPLASH MERFRIENDS',
  },
};

export const confirmAddingEventModalData = {
  title: 'Add an Event',
  icon: 'calendar-outline',
  okBtn: {
    title: 'YES, DISCARD',
  },
  cancelBtn: {
    title: 'NO, GO BACK',
  },
};

export const confirmEditEventModalData = {
  title: 'Edit an Event',
  icon: 'calendar-outline',
  okBtn: {
    title: 'YES, DISCARD',
  },
  cancelBtn: {
    title: 'NO, GO BACK',
  },
};

export const confirmAddingParticipantsModalData = {
  title: 'Add Participants',
  icon: 'calendar-outline',
  okBtn: {
    title: 'ADD',
  },
  cancelBtn: {
    title: 'CANCEL',
  },
};

export const eventFormData: FormField[] = [
  {
    name: 'myContent',
    type: FormFieldTypes.TOGGLE,
    label: 'See my content only',
    required: false,
    icon: 'person',
    line: 'full',
  },
  {
    name: 'category',
    type: FormFieldTypes.SELECT,
    label: 'Category',
    multiple: true,
    required: false,
    line: 'full',
    options: [
      'Convention',
      'Course',
      'Competition',
      'Festival',
      'Meeting',
      'Pod Activity',
      'Other',
    ],
    icon: 'grid',
  },
  {
    name: 'priceRange',
    type: FormFieldTypes.SELECT,
    label: 'Price Range',
    multiple: false,
    required: false,
    line: 'full',
    options: ['0-10', '11-50', '51-100', '100+'],
    icon: 'wallet',
  },
  {
    name: 'location',
    type: FormFieldTypes.SELECT,
    label: 'Location',
    multiple: true,
    required: false,
    line: 'full',
    options: [
      'online',
      'Africa',
      'Asia',
      'Europe',
      'North America',
      'Oceania',
      'South America',
    ],
    icon: 'location',
  },
  {
    name: 'startDate',
    type: FormFieldTypes.DATE,
    label: 'Start Date',
    required: false,
    line: 'full',
    icon: 'calendar',
  },
  {
    name: 'endDate',
    type: FormFieldTypes.DATE,
    label: 'End Date',
    required: false,
    line: 'full',
    icon: 'calendar',
  },
];

export const newEventFormData = [
  {
    name: 'imageUrl',
    type: FormFieldTypes.TEXT_INPUT,
    label: 'Picture Link',
    required: true,
    lines: 'full',
  },
  {
    name: 'title',
    type: FormFieldTypes.TEXT_INPUT,
    label: 'Title',
    required: true,
    lines: 'full',
  },
  {
    name: 'date',
    type: FormFieldTypes.DATE,
    label: 'Date',
    required: true,
    lines: 'full',
    multiple: false,
    defaultValue: new Date().toISOString(),
  },
  {
    name: 'category',
    type: FormFieldTypes.SELECT,
    label: 'Category',
    required: true,
    lines: 'full',
    multiple: true,
    options: [
      'Convention',
      'Course',
      'Competition',
      'Festival',
      'Meeting',
      'Pop Activity',
      'Other',
    ],
  },
  {
    name: 'locationInfo',
    type: FormFieldTypes.GROUP,
    children: [
      {
        name: 'location',
        type: FormFieldTypes.SELECT,
        label: 'General Location',
        required: true,
        options: [
          'online',
          'Africa',
          'Asia',
          'Oceania',
          'North America',
          'Europe',
          'South America',
        ],
        defaultValue: 'online',
      },
      {
        name: 'meetingLink',
        type: FormFieldTypes.TEXT_INPUT,
        label: 'Meeting link',
        required: true,
        lines: 'full',
        dependOn: 'online',
      },
      {
        name: 'concreteAddress',
        type: FormFieldTypes.TEXT_INPUT,
        label: 'Concrete Address',
        required: true,
        lines: 'full',
        dependOn: '!online',
      },
      {
        name: 'googleMapsLink',
        type: FormFieldTypes.TEXT_INPUT,
        label: 'GoogleMaps Link',
        required: false,
        lines: 'full',
        dependOn: '!online',
      },
    ],
  },
  {
    name: 'typeInfo',
    type: FormFieldTypes.GROUP,
    children: [
      {
        name: 'eventType',
        type: FormFieldTypes.RADIO,
        label: 'Event Type',
        required: true,
        lines: 'none',
        options: [
          'A different website informs & sells tickets',
          "A different website informs & it's free",
          'I want to inform & sell tickets only here',
          "I want to inform only here & it's free",
        ],
      },
      {
        name: 'eventLink',
        type: FormFieldTypes.TEXT_INPUT,
        label: 'External event website link',
        required: true,
        dependOn: 'A different website informs & sells tickets',
        // 'A different website informs & sells tickets' ||
        // "A different website informs & it's free",
      },
      {
        name: 'price',
        type: FormFieldTypes.GROUP,
        children: [
          {
            name: 'value',
            type: FormFieldTypes.TEXT_INPUT,
            label: 'Price',
            required: true,
            lines: 'full',
          },
          {
            name: 'currency',
            type: FormFieldTypes.SELECT,
            label: 'Currency',
            required: true,
            lines: 'none',
            defaultValue: 'USD',
            options: [],
          },
        ],
        dependOn: 'A different website informs & sells tickets',
        // 'A different website informs & sells tickets' ||
        // 'I want to inform & sell tickets only here',
      },
      {
        name: 'paymentLink',
        type: FormFieldTypes.TEXT_INPUT,
        label: 'Payment link',
        required: true,
        lines: 'full',
        dependOn: 'A different website informs & sells tickets',
        // 'A different website informs & sells tickets' ||
        // 'I want to inform & sell tickets only here',
      },
      {
        name: 'description',
        type: FormFieldTypes.TEXT_INPUT,
        label: 'Description',
        required: true,
        lines: 'full',
      },
      {
        name: 'attendeeLimit',
        type: FormFieldTypes.TEXT_INPUT,
        label: 'Attendee limit',
        required: false,
        lines: true,
      },
    ],
  },
  {
    name: 'memberArr',
    type: FormFieldTypes.GROUP,
    children: [
      {
        name: 'postingEvent',
        type: FormFieldTypes.RADIO,
        label: 'Posting Event as',
        required: true,
        lines: 'none',
        options: ['my member account', 'my business account'],
      },
      {
        name: 'organizer',
        type: FormFieldTypes.TEXT_INPUT,
        label: 'Business name',
        required: true,
        lines: 'full',
        dependOn: 'my business account',
      },
      {
        name: 'organizerUrl',
        type: FormFieldTypes.TEXT_INPUT,
        label: 'Business Website URL',
        required: false,
        lines: true,
        dependOn: 'my business account',
      },
    ],
  },
];

