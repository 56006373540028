import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, IonModal } from '@ionic/angular';
import { filter, Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/firebase/auth.service';
import { IPod } from 'src/app/shared/models/item.models';
import { PodService } from '../../pods.service';
import { PodStore } from '../../pod-store';
import { ProfileService } from 'src/app/profile/profile.service';

@Component({
  selector: 'app-question-modal',
  templateUrl: './question-modal.component.html',
  styleUrls: ['./question-modal.component.scss'],
})
export class QuestionModalComponent implements OnInit, OnDestroy {
  @Input() questions: string[];
  @ViewChild(IonModal) modal: IonModal;
  id = this.router.snapshot.params.id;
  form: FormGroup;
  status = 'unsaved';
  pod$ = this.podStore.selectItem().pipe(filter(item => !!item));
  pod: IPod;
  userId: string;
  isOpen: boolean;
  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private podStore: PodStore,
    private router: ActivatedRoute,
    private route: Router,
    private podsService: PodService,
    private alertController: AlertController,
    private authService: AuthService,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    this.podsService.showQuestionsModal
      .pipe(takeUntil(this.destroy$))
      .subscribe(isOpen => (this.isOpen = isOpen));
    this.pod$.pipe(filter(pod => !!pod)).subscribe(pod => {
      this.pod = pod;
      this.form = this.fb.group({});
      this.createControls(pod);
    });
    const { uid } = this.authService.isUserAuth();

    this.authService
      .getUserByUid(uid)
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user)
      )
      .subscribe((user: any) => {
        this.userId = user.id;
        const authUser = this.authService.getCurrentUser();
        const createdAt = new Date(authUser.metadata.creationTime);
        const newUser = {
          ...user,
          createdAt,
        };
        if (!user.createdAt)
          this.profileService.updateItemById('users', newUser, this.userId);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createControls(pod: IPod) {
    if (pod.joinMechanismArr) {
      for (let control of pod.joinMechanismArr) {
        let i = 0;
        const newFormControl = new FormControl(null, Validators.required);
        this.form.addControl(control.memberQuestion, newFormControl);
        i++;
      }
    }
  }

  async presentCancelAlert() {
    const alert = await this.alertController.create({
      message:
        '<div class="color-dark d-flex flex-column ion-align-items-center"><ion-icon name="radio-button-on" class="font-24"></ion-icon><p class="font-16">Cancel your Pod Join Request?</p><p class="font-14 font-opacity">Are you sure you want to discard your changes? This will result in you not joining the Pod.</p></div>',
      buttons: [
        {
          text: 'YES, DISCARD',
          handler: () => {
            this.form.reset();
            this.podsService.showQuestionsModal.next(false);
            this.route.navigate(['/pod/', this.id]);
          },
        },
        {
          text: 'NO, GO BACK',
        },
      ],
    });
    await alert.present();
  }

  async presentConfirmAlert() {
    const alert = await this.alertController.create({
      cssClass: 'alert',
      message:
        '<div class="color-dark d-flex flex-column ion-align-items-center"><ion-icon name="radio-button-on" class="font-24"></ion-icon><p class="font-16">Exciting News!</p><p class="font-14 font-opacity">Your future Pod is going to review your request! As soon as they\'ve accepted you, you\'ll be able to see their pictures, participate in their events and chat with them!</p></div>',
      buttons: [
        {
          text: 'OKAY',
          handler: () => {
            this.form.reset();
            this.modal.dismiss(null, 'cancel');
            this.podsService.showQuestionsModal.next(false);
          },
        },
      ],
    });
    await alert.present();
  }

  onWillDismiss(e) {
    this.podsService.showQuestionsModal.next(false);
  }

  requestToJoin() {
    this.pod.memberRequests = this.pod.memberRequests
      ? this.pod.memberRequests
      : [];
    const answers = {};
    for (let key in this.form.controls) {
      answers[key] = this.form.controls[key].value;
    }
    if (
      this.pod.memberRequests?.some(item => item.id !== this.userId) ||
      !this.pod.memberRequests.length
    ) {
      if (this.pod.invites) {
        const invites = this.pod.invites.filter(
          invitation => invitation.invited !== this.userId
        );
        const newItem = Object.assign({
          ...this.pod,
          invites,
          memberRequests: [
            ...this.pod.memberRequests,
            {
              id: this.userId,
              answers,
            },
          ],
        });
        this.podStore.updateProfile(newItem);
      } else {
        const newItem = Object.assign({
          ...this.pod,
          memberRequests: [
            ...this.pod.memberRequests,
            {
              id: this.userId,
              answers,
            },
          ],
        });
        this.podStore.updateProfile(newItem);
      }

      this.presentConfirmAlert();
      this.modal.dismiss(null, 'cancel');
    }
  }
}
