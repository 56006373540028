import { Injectable } from '@angular/core';
import { CommonService } from '../shared/component-store/common-service';
import { CrudHelper } from '../firebase/helpers/crudHelper';
import { FormArray, FormBuilder } from '@angular/forms';
import { BehaviorSubject, filter } from 'rxjs';
import { AuthService } from '../firebase/auth.service';
import { DateService } from '../services/date.service';

@Injectable({ providedIn: 'root' })
export class EventsService extends CommonService {
  isOpenForm = new BehaviorSubject(false);
  userId;
  constructor(
    private fb: FormBuilder,
    private firebaseHelperService: CrudHelper,
    private dateService: DateService
  ) {
    super(firebaseHelperService);
  }

  addImage(images) {
    let i = images.length;
    i = this.fb.group({
      image: [null],
    });
    images.push(i);
  }

  deleteImage(images: FormArray, i: number) {
    images.removeAt(i);
  }

  getItems({ collectionName, filter }) {
    const queryFn = ref => {
      let query = ref;

      const { category, location, priceRange, status, creator, myContent } =
        filter;

      if (category && category.length > 0) {
        query = query.where('category', 'in', category);
      }

      if (location && location.length) {
        query = query.where('location.country.region', 'in', location);
      }

      if (status) {
        query = query.where('status', '==', status);
      }

      if (priceRange) {
        if (priceRange === '100+') {
          query = query.where('price.value', '>', 100);
        } else {
          const [min, max] = priceRange.split('-');
          query = query.where('price.value', '>=', Number(min));
          query = query.where('price.value', '<=', Number(max));
        }
      }

      if (creator && !myContent) {
        query = query.where('creator.id', '==', creator);
        query = query.where('status', 'in', [
          'approved',
          'pending',
          'rejected',
        ]);
      }

      if (!status && !creator && !myContent) {
        query = query.where('status', '==', 'approved');
      }

      if (priceRange) {
        query = query.orderBy('price.value', 'desc');
      } else {
        query = query.orderBy('startDate', 'desc');
      }

      return query;
    };

    return this.firebaseHelperService.getCollectionItems({
      collectionName,
      queryFn,
    });
  }

  getPodsWithEvents(authUser) {
    const queryFn = ref => {
      let query = ref;

      return query.where('participants', 'array-contains', authUser);
    };
    return this.firebaseHelperService.getCollectionItems({
      collectionName: 'pods',
      queryFn,
    });
  }

  getMyItems(collectionName, creator) {
    const queryFn = ref => {
      let query = ref;

      query = query.where('creator.id', '==', creator);

      return query;
    };

    return this.firebaseHelperService.getCollectionItems({
      collectionName,
      queryFn,
    });
  }

  getPreviewEvents(limit: number, podId: string) {
    return this.firebaseHelperService.getPreviewEvents({
      limit,
      podId,
    });
  }

  checkFilter(filter) {
    const result =
      filter === false ||
      (!filter.priceRange &&
        (filter.category === null || !filter.category?.length) &&
        !filter.endDate &&
        !filter.startDate &&
        (filter.location === null || !filter.location?.length) &&
        !filter.myContent);
    return !result ? 'success' : 'dark';
  }

  eventsFrontFilter(items, pods, forbidden, userId, authUser?) {
    if (items) {
      const actualItems = this.dateService.getScheduledItems(items, !forbidden);
      if (forbidden) {
        if (actualItems) {
          return items
            .filter(
              item =>
                item.status === 'approved' &&
                !item.suspended &&
                item.participants.find(item => item.id === userId) &&
                this.podMemberCheck(item, pods, authUser)
            )
            .reverse();
        }
      } else {
        return actualItems
          .filter(item => item.participants.find(item => item.id === userId))
          .filter(item => this.podMemberCheck(item, pods, authUser))
          .filter(item =>
            item.creator.id === userId ? item : item.status === 'approved'
          )
          .reverse();
      }
    }
  }

  podMemberCheck(event, pods, userId) {
    if (event.category === 'Pod Activity') {
      if (event.creator.id !== userId) {
        return event.onlyPodMembers
          ? !!pods.find(
              item =>
                event.podName === item.id &&
                item.participants.includes(userId) &&
                item.status === 'approved'
            )
          : !pods.find(
              item => event.podName === item.id && item.status === 'approved'
            );
      } else return true;
    } else return true;
  }
  eventSuspendedCheck(event, currentUserId) {
    if (Array.isArray(event)) {
      const actualItems = this.dateService.getScheduledItems(event, false);
      return actualItems.find(
        item =>
          item.suspended &&
          item.creator.id === currentUserId &&
          item.status === 'approved'
      );
    } else {
      const actualItem = this.dateService.getScheduledItems([event], false);
      return (
        !!event.suspended &&
        actualItem.length &&
        currentUserId === event.creator.id &&
        event.status === 'approved'
      );
    }
  }
}
