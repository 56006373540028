import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WonderPush } from '@awesome-cordova-plugins/wonderpush/ngx';
import { Platform } from '@ionic/angular';
import { httpsCallable, getFunctions } from 'firebase/functions';
import mixpanel from 'mixpanel-browser';
import { filter, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { countries } from '../../auth/sign-up/countries';
import { ProfileStore } from '../store/profile-store';
import { AuthService } from '../../firebase/auth.service';
import { confirmForDelete, confirmModalData } from '../profile.service';
import { IUser } from 'src/app/auth/auth.models';
import { FormService } from 'src/app/shared/components/form/form.service';
import { environment } from '../../../environments/environment';
import { PoolsStore } from 'src/app/merfolk/pools/pools.store';
import { PodStore } from 'src/app/merfolk/pods/pod-store';
import { SecondHandStore } from 'src/app/bazaar/second-hand/second-hand-store';
import { JobsStore } from 'src/app/bazaar/jobs-services/jobs/jobs-store';
import { ServicesStore } from 'src/app/bazaar/jobs-services/services/services.store';
import {
  createToaster,
  positionType,
} from 'src/app/shared/components/modals/toaster';
import { showDeleteLoading } from './../../shared/components/modals/loading';
import { MapService } from 'src/app/services/map.service';
import { PaymentsStore } from '../../payments/payments-store';
import { UserService } from '../../auth/user.service';
import { subscriptionMsg } from '../../payments/payments';
import { PushNotificationsService } from 'src/app/services/push-notifications.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  form: FormGroup;
  countries = countries;
  userId = this.activatedRouter.snapshot.params.id;
  currentUser: IUser;

  updateLoading = false;
  deleteProfileLoading = false;

  isFormSubmitting: string | boolean = 'loaded';
  loader = showDeleteLoading();

  showConfirmModal = false;
  confirmModalData = confirmModalData;
  confirmForDelete = confirmForDelete;
  showPasswordForm = false;
  showForDelete = false;
  loading = false;
  isValidLink = true;
  today = new Date().toISOString();

  userSubscriptionInfo;
  userSubInfo;
  isUserPremium;
  isAppPremium;
  isPersonalPremium;
  isPremium;
  isAdmin;
  decodedToken;
  balanceInfo;
  user;
  authUserId;

  subscriptionMsgAppPremium = subscriptionMsg.APP_PREMIUM;
  subscriptionMsgPersonalPremium = subscriptionMsg.PERSONAL_PREMIUM;

  isMobile = this.platform.platforms().includes('capacitor');
  isIosApp = this.platform.platforms().includes('ios') && this.isMobile;
  private destroy$ = new Subject<void>();

  readonly user$ = this.profileStore.selectItem();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private fb: FormBuilder,
    private profileStore: ProfileStore,
    private authService: AuthService,
    private activatedRouter: ActivatedRoute,
    private formService: FormService,
    private poolsStore: PoolsStore,
    private podsStore: PodStore,
    private secondHandStore: SecondHandStore,
    private servicesStore: ServicesStore,
    private jobsStore: JobsStore,
    private router: Router,
    private platform: Platform,
    private mapService: MapService,
    private paymentsStore: PaymentsStore,
    private userService: UserService,
    private fireStore: AngularFirestore,
    private wonderPush: WonderPush,
    private notificationsService: PushNotificationsService
  ) {}

  ngOnInit() {
    this.createForm();
    const authUser = this.authService.getCurrentUser();
    this.profileStore.getItemByByValue({ uid: authUser.uid });
    this.getUser();
    this.listenUserStoreChanges();
    this.listenStatusChanges();
  }

  async addMapLink(e) {
    e.valueChanges.subscribe(async () => {
      this.isValidLink = true;
      const location = e.get('googleMapLink')?.value;
      const { country } = await this.mapService.getAddress(location);
      if (!country) {
        this.isValidLink = false;
      }

      this.form.get('googleMapLink')?.patchValue(location);
      this.form.patchValue({ country });
      this.form.markAsDirty();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  OpenDeleteModal() {
    this.showForDelete = true;
  }

  async openSubscriptionRef() {
    this.loading = true;
    const functions = getFunctions();
    const openSub = httpsCallable(
      functions,
      'ext-firestore-stripe-payments-createPortalLink'
    );
    const { data } = await openSub({
      returnUrl: environment.hostUrl,
    });

    window.open((data as any).url, '_blank');
    this.loading = false;
  }

  updateUser() {
    const country = countries.find(
      country => country.name === this.form.value.country
    );
    const location = this.mapService.createCoordinates(
      this.form.value.googleMapLink
    );
    const {
      galleryVisibility,
      autoTranslate,
      gender,
      birthday,
      mermaidExpLvl,
      mersonaName,
      bio,
      isMailingEnabled,
    } = this.form.value;
    const newUser = {
      ...this.currentUser,
      location,
      country,
      galleryVisibility,
      autoTranslate,
      gender,
      birthday: birthday || null,
      mermaidExpLvl,
      mersonaName,
      bio,
      isMailingEnabled: isMailingEnabled || false,
    };

    mixpanel.init(environment.mixpanelToken, { debug: true });
    mixpanel.identify(this.currentUser?.uid);
    mixpanel.people.set({
      $mersonaName: mersonaName,
      $birthday: birthday || null,
      $gender: gender,
      $galleryVisibility: galleryVisibility,
      $autoTranslate: autoTranslate,
      $mermaidExpLvl: mermaidExpLvl,
    });

    this.profileStore.updateProfile(newUser);
  }

  signOut() {
    this.profileStore.logOut();
    this.poolsStore.logOut();
    this.authService.signOut();
    this.podsStore.logOut();
    this.servicesStore.logOut();
    this.jobsStore.logOut();
    this.secondHandStore.logOut();
    this.formService.location$.next({ googleMapLink: null });
    this.notificationsService.unsubscribe();
    this.wonderPush.unsubscribeFromNotifications();
    mixpanel.init(environment.mixpanelToken, { debug: true });
    mixpanel.identify(this.user?.uid);
    mixpanel.people.set({
      'Notification Settings Changes': 'disabled',
    });
    this.wonderPush.setUserId(null);
  }

  discardChanges(e) {
    if (e) {
      this.showPasswordForm = false;
      this.showConfirmModal = false;
      this.form.reset();
    }
  }

  closeModal(e) {
    if (e) {
      this.showConfirmModal = false;
    }
  }

  openConfirmModal() {
    this.showConfirmModal = true;
  }

  toggleUpdatePasswordModal(e) {
    this.showPasswordForm = e;
  }

  private listenStatusChanges() {
    this.profileStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => {
        loading
          ? (this.isFormSubmitting = true)
          : (this.isFormSubmitting = false);
      });
  }

  private createForm() {
    this.form = this.fb.group({
      galleryVisibility: [null],
      autoTranslate: [true],
      country: ['', Validators.required],
      googleMapLink: ['', [Validators.required]],
      profilePicture: [''],
      birthday: [''],
      gender: [''],
      mermaidExpLvl: [''],
      mersonaName: [''],
      bio: [''],
      isMailingEnabled: [false],
    });
  }

  async deleteProfile(_e) {
    if (_e) {
      this.deleteProfileLoading = true;
      this.closeDeleteModal(true);
      const data = await this.loader;
      data.present();

      try {
        const isDelete = await this.authService.deleteProfile();
        if (isDelete) {
          this.removeNotificationUserData();
          mixpanel.init(environment.mixpanelToken, { debug: true });
          mixpanel.identify(this.currentUser?.uid);
          mixpanel.people.set({
            $deletedAt: new Date(),
          });
          this.wonderPush.unsubscribeFromNotifications();
          this.signOut();
          const data = await this.loader;
          data.dismiss();
          this.deleteProfileLoading = false;
          createToaster('successfully deleted', 'success', positionType.BOTTOM);
          this.router.navigate(['/auth/login']);
        }
      } catch (e) {
        createToaster(
          'something went wrong, please, try again',
          'danger',
          positionType.BOTTOM
        );
        this.deleteProfileLoading = false;
        console.log(e);
      }
    }
  }

  closeDeleteModal(_e) {
    if (_e) this.showForDelete = false;
  }

  private listenUserStoreChanges() {
    this.user$
      .pipe(
        filter(user => !!user?.id),
        takeUntil(this.destroy$)
      )
      .subscribe((user: any) => {
        this.formService.location$.next({
          googleMapLink: `https://www.google.com/maps/search/?api=1&query=${user.location.lat},${user.location.lng}`,
        });
        this.currentUser = user;
        const {
          galleryVisibility,
          autoTranslate,
          country,
          gender,
          birthday,
          mermaidExpLvl,
          mersonaName,
          bio,
          isMailingEnabled,
        } = user;
        this.form.patchValue({
          galleryVisibility,
          autoTranslate: autoTranslate === undefined ? true : autoTranslate,
          country: country.name,
          gender,
          birthday: birthday || '',
          mermaidExpLvl,
          mersonaName,
          bio: bio || '',
          isMailingEnabled: isMailingEnabled || false,
        });
        // TODO not working. check
        this.form.markAsPristine();
      });
  }

  private getUser() {
    this.loading = true;
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.user = user;
        this.authUserId = user.id;
        this.paymentsStore.getItemByByValue({ documentId: user.uid });
        this.isUserPremium = this.userService.isPaidPremium;
        this.isAdmin = this.userService.isAdmin;
        this.balanceInfo = this.userService.balanceInfo;
        this.isAppPremium = this.userService.isAppPremium;
        this.isPersonalPremium = this.userService.isPersonalPremium;
        this.isPremium = this.userService.isPremium;
        console.log('got user:', user);

        this.getSubscriptionInfo();
        this.loading = false;
      });
  }

  async getSubscriptionInfo() {
    this.userSubInfo = await this.fireStore
      .collection('users')
      .doc(this.authUserId)
      .collection('subscriptions')
      .ref.where('status', 'in', ['trialing', 'active']);

    this.userSubInfo.onSnapshot(async snapshot => {
      if (this.isUserPremium) {
        const doc = snapshot.docs[0];
        this.userSubscriptionInfo = doc?.data();
      } else {
        this.userSubscriptionInfo = false;
      }
    });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  get country() {
    return this.form.get('country');
  }
  get postalCode() {
    return this.form.get('postalCode');
  }
  get city() {
    return this.form.get('city');
  }
  get birthday() {
    return this.form.get('birthday');
  }
  get gender() {
    return this.form.get('gender');
  }
  get mermaidExpLvl() {
    return this.form.get('mermaidExpLvl');
  }
  get mersonaName() {
    return this.form.get('mersonaName');
  }
  get bio() {
    return this.form.get('bio');
  }
  get isMailingEnabled() {
    return this.form.get('isMailingEnabled');
  }
  get autoTranslate() {
    return this.form.get('autoTranslate');
  }

  removeNotificationUserData() {
    if (this.platform.is('capacitor')) {
      this.wonderPush.clearAllData();
    } else {
      this.notificationsService.removeUserData();
    }
  }
}
