import { Component, EventEmitter, Input, Output } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-subscription-notification',
  templateUrl: './subscription-notification.component.html',
  styleUrls: ['./subscription-notification.component.scss'],
})
export class SubscriptionNotificationComponent {
  @Input() subscriptionPeriod;
  @Input() isSubscriptionModalOpen;
  @Output() subscriptionPeriodChange = new EventEmitter<any>();
  @Output() isSubscriptionModalOpenChange = new EventEmitter<any>();
  @Output() getInfoHandler = new EventEmitter<any>();
  constructor(private router: Router) {}

  closeSubscriptionNotification() {
    this.isSubscriptionModalOpenChange.next(false);
    this.subscriptionPeriodChange.next(null);
    setTimeout(() => {
      this.router.navigate(['/?notification=shown']);
      this.getInfoHandler.emit();
    }, 0);
  }
}
