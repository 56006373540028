import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import mixpanel from 'mixpanel-browser';
import { BehaviorSubject, filter, Subject, takeUntil } from 'rxjs';
import {
  confirmDeletingModalData,
  confirmEditingModalData,
  podsFormData,
} from '../pods/pods';
import { IPod } from 'src/app/shared/models/item.models';
import { FormService } from 'src/app/shared/components/form/form.service';
import {
  createToaster,
  positionType,
} from 'src/app/shared/components/modals/toaster';
import { PodStore } from '../pod-store';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../firebase/auth.service';
import { ImagesService } from 'src/app/services/images.service';
import { showLoading } from 'src/app/shared/components/modals/loading';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-edit-pod',
  templateUrl: './edit-pod.component.html',
  styleUrls: ['./edit-pod.component.scss'],
})
export class EditPodComponent implements OnInit, OnDestroy {
  @Output() isClosePod = new EventEmitter<boolean>(false);
  @ViewChild('podForm') podForm;

  item = { icon: 'radio-button-on', title: 'Edit a Pod' };
  podsFormData = podsFormData;
  id = this.activeRoute.snapshot.params.id;
  disabled: boolean;
  newPod: IPod;
  statusDeleting: boolean;
  statusAdding: boolean;
  pod$ = new BehaviorSubject({} as IPod);
  form: FormGroup;
  initialValues: IPod;
  loader = showLoading();

  currentImage: string;
  loadingImage: boolean = false;

  properties;
  confirmModalData = confirmEditingModalData;
  confirmForDelete = confirmDeletingModalData;
  showConfirmModal: boolean = false;
  showForDelete = false;

  private destroy$ = new Subject<void>();

  currentLang: string;

  constructor(
    private formService: FormService,
    private imagesService: ImagesService,
    private podStore: PodStore,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private translationService: TranslationService
  ) {}

  async ngOnInit() {
    // Get language first
    this.currentLang = await this.translationService
      .getCurrentLanguage()
      .toPromise();

    mixpanel.init(environment.mixpanelToken, { debug: true });
    const { uid } = this.authService.isUserAuth();
    mixpanel.identify(uid);

    this.podStore.getItemByByValue({
      documentId: this.id,
    });

    this.imagesListen();
    this.listenStatusChanges();
    this.formService.formData$.next(podsFormData);
  }

  ngOnDestroy() {
    this.imagesService.reset();
    this.destroy$.next();
    this.destroy$.complete();
  }

  trackEvent(event) {
    mixpanel.track(event);
  }

  openConfirmModal() {
    this.showConfirmModal = true;
  }

  close(e) {
    e ? (this.showConfirmModal = true) : null;
  }

  discardChanges(e) {
    if (e) {
      this.isClosePod.next(true);
      this.showConfirmModal = false;
    }
  }

  closeModal(e) {
    if (e) {
      this.showConfirmModal = false;
      this.showForDelete = false;
    }
  }

  onFormGroupChangePod(_event) {
    _event.get('name').validator = Validators.compose([
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50),
    ]);

    _event.valueChanges.subscribe(value => {
      this.disabled = _event.status === 'INVALID';
      this.newPod = {
        ...value,
        image: this.currentImage,
        creator: this.initialValues.creator,
      };
    });

    // Use stored language value
    if (this.currentLang !== 'en') {
      _event
        .get('name')
        .setValidators([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ]);
    }

    _event
      .get('country')
      .valueChanges.pipe(filter(value => value !== this.initialValues.country))
      .subscribe(() => _event.patchValue({ googleMapLink: null }));

    this.podStore
      .selectItem()
      .pipe(
        takeUntil(this.destroy$),
        filter(data => !!data)
      )
      .subscribe(data => {
        this.initialValues = data;
        const {
          image,
          joinMechanism,
          joinMechanismArr,
          name,
          googleMapLink,
          description,
          country,
          memberRights,
          approveMembers,
        } = data;
        this.currentImage = image;
        this.imagesService.compressedImages.next([image]);
        this.pod$.next(data);
        if (joinMechanism) {
          _event.patchValue({ joinMechanism });
          if (joinMechanism !== 'automatic') {
            _event.addControl(
              'approveMembers',
              new FormControl('everyone can approve')
            );
            if (joinMechanismArr.length > 1 && joinMechanism !== 'automatic') {
              const control = <FormArray>_event.controls['joinMechanismArr'];
              for (let i = 0; i < joinMechanismArr.length - 1; i++) {
                const group = new FormGroup({});
                group.addControl('memberQuestion', new FormControl());
                if (control.controls.length < joinMechanismArr.length)
                  control.push(group);
              }
            }
          }

          _event.patchValue({
            name,
            googleMapLink,
            description,
            country,
            memberRights,
            joinMechanism,
            approveMembers,
            joinMechanismArr,
          });
          this.formService.location$.next({ googleMapLink });
          this.disabled = _event.status === 'INVALID';
        }
      });

    this.form = _event;
  }

  imagesListen() {
    this.imagesService.compressedImages
      .pipe(takeUntil(this.destroy$))
      .subscribe(images => {
        this.currentImage = images[0] || null;
      });

    this.imagesService.imagesLoading
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => {
        this.loadingImage = loading;
        this.disabled = loading;
      });
  }

  async updatePod() {
    this.podStore.setLoading();
    this.disabled = true;

    if (this.currentImage) {
      await this.podForm.imageLoader.upLoadImages();
      this.imagesService.imageUrls
        .pipe(takeUntil(this.destroy$))
        .subscribe(urls => {
          if (urls && urls.length) {
            this.setNewItem(urls);
          }
        });
    } else {
      this.setNewItem([]);
    }
  }

  setNewItem(urls) {
    this.newPod.image = urls.length ? urls[0] : null;
    const newPod = Object.assign({ ...this.initialValues, ...this.newPod });

    if (newPod.joinMechanism === 'automatic') {
      newPod.memberRequests.forEach(request =>
        newPod.participants.push(request.id)
      );
      newPod.memberRequests = [];
      newPod.joinMechanismArr = [];
    }
    this.podStore.updateProfile(newPod);
    this.podStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data) {
          this.disabled = true;
          this.statusAdding = true;
        } else {
          this.isClosePod.next(true);
          createToaster(
            'Successfully updated!',
            'success',
            positionType.BOTTOM
          );
          this.disabled = false;
          this.statusAdding = false;
        }
      });
  }

  deletePod(e: boolean) {
    if (e) {
      this.podStore.removeItem(this.id);
      this.podStore
        .selectLoading()
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          this.statusDeleting = !!data;
          if (data) {
            this.disabled = true;
            this.showForDelete = false;
          } else {
            this.isClosePod.next(true);
            createToaster(
              'Successfully deleted!',
              'success',
              positionType.BOTTOM
            );
            setTimeout(() => {
              this.router.navigate(['merfolk/pods']);
            }, 0);
            this.trackEvent('Pod Deleted');
          }
        });
    }
  }

  private listenStatusChanges() {
    this.podStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async loading => {
        loading ? (await this.loader).present() : (await this.loader).dismiss();
      });
  }
}

