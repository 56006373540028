import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanetComponent } from './planet.component';
import { PlanetService } from './planet.service';
import { PlanetRoutingModule } from './planet-routing.module';
import { IonicSelectableModule } from 'ionic-selectable';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../shared/shared.module';
import { PipesModule } from '../pipes/pipes.module';
import { PlanetStore } from './planet-store';
import { PlanetDetailsComponent } from './planet-details/planet-details.component';
import { PlanetItemComponent } from './planet-item/planet-item.component';
import { AddPlanetItemComponent } from './add-planet-item/add-planet-item.component';
import { EditPlanetItemComponent } from './edit-planet-item/edit-planet-item.component';
import { AddExperienceComponent } from './add-experience/add-experience.component';
import { PlanetFilterComponent } from './planet-filter/planet-filter.component';

@NgModule({
  declarations: [
    PlanetComponent,
    PlanetDetailsComponent,
    PlanetItemComponent,
    AddPlanetItemComponent,
    EditPlanetItemComponent,
    AddExperienceComponent,
    PlanetFilterComponent
  ],
  imports: [
    IonicSelectableModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    IonicModule,
    PlanetRoutingModule,
    SharedModule,
    PipesModule,
  ],
    exports: [
        PlanetItemComponent,
        AddPlanetItemComponent,
        AddExperienceComponent
    ],
  providers: [PlanetService, PlanetStore],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PlanetModule {}
