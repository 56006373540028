import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, filter, takeUntil } from 'rxjs';
import { eventFormData, IEventFilter } from '../events';
import { FormService } from '../../shared/components/form/form.service';
import { EventsStore } from '../events-store';
import { UserService } from 'src/app/auth/user.service';

@Component({
  selector: 'app-event-filter',
  templateUrl: './event-filter.component.html',
  styleUrls: ['./event-filter.component.scss'],
})
export class EventFilterComponent implements OnInit, OnDestroy {
  toolbarData = {
    arrowRouter: '/events',
    title: 'Events',
  };
  currentUser;
  eventFormData = eventFormData;
  color = 'dark';
  myContent: boolean = false;
  country: string | null = null;
  user: string;
  creator;
  menuInitialData;
  private destroy$ = new Subject<void>();

  constructor(
    private formService: FormService,
    private eventStore: EventsStore,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.formService.formData$.next(eventFormData);
    this.listenFormChanges();
    this.getUser();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onFormGroupChange(_e) {
    console.log('Form group changed, initial data:', this.menuInitialData);
    if (this.menuInitialData) {
      console.log('Patching form with initial data:', this.menuInitialData);
      _e.patchValue(this.menuInitialData);
    }
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  private listenFormChanges() {
    this.formService.eventMenuData$
      .pipe(
        takeUntil(this.destroy$),
        filter(data => !!data)
      )
      .subscribe((filterData: IEventFilter) => {
        console.log(
          'Received filter data in EventFilterComponent:',
          filterData
        );

        if (filterData.myContent) {
          filterData.creator = this.currentUser?.id;
        }

        // Store the filter data for future reference
        this.menuInitialData = filterData;
        console.log('Updated menuInitialData:', this.menuInitialData);

        // Update the color based on whether there are active filters
        if (
          filterData &&
          (filterData.category ||
            filterData.location ||
            filterData.priceRange ||
            filterData.price ||
            filterData.startDate ||
            filterData.endDate ||
            filterData.myContent)
        ) {
          this.color = 'success';
          console.log('Setting color to success due to active filters');
        } else {
          this.color = 'dark';
          console.log('Setting color to dark due to no active filters');
        }

        // Get items based on the filter
        console.log('Getting items with filter:', filterData);
        this.eventStore.getItems(filterData);
      });
  }
}

