import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MainMenuPage } from './main-menu/main-menu.page';
import { TutorialComponent } from './tutorial/tutorial.component';
import { MoodComponent } from './mood/mood.component';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { EmailSentComponent } from './email-sent/email-sent.component';
import { CheckVersionPage } from './check-version/check-version.page';
import { DeepLinkPageComponent } from './shared/components/deep-link-page/deep-link-page.component';

const redirectUnauthorizedToLogin = () =>
  redirectUnauthorizedTo(['auth/login']);
const routes: Routes = [
  {
    path: '',
    canActivate: [AngularFireAuthGuard],
    component: MainMenuPage,
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'pool',
    canActivate: [AngularFireAuthGuard],
    loadChildren: () =>
      import('./merfolk/pools/pools.module').then(m => m.PoolsPageModule),
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'pod',
    canActivate: [AngularFireAuthGuard],
    loadChildren: () =>
      import('./merfolk/pods/pods/pods.module').then(m => m.PodsPageModule),
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'tutorial',
    component: TutorialComponent,
  },
  { path: 'mood', component: MoodComponent },
  {
    path: 'events',
    canActivate: [AngularFireAuthGuard],
    loadChildren: () =>
      import('./events/events.module').then(m => m.EventsModule),
    data: { authGuardPipe: redirectUnauthorizedToLogin, preload: true },
  },
  {
    path: 'payments',
    canActivate: [AngularFireAuthGuard],
    loadChildren: () =>
      import('./payments/payments.module').then(m => m.PaymentsModule),
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'planet',
    canActivate: [AngularFireAuthGuard],
    loadChildren: () =>
      import('./planet/planet.module').then(m => m.PlanetModule),
    data: { authGuardPipe: redirectUnauthorizedToLogin, preload: true },
  },
  {
    path: 'merfolk',
    canActivate: [AngularFireAuthGuard],
    loadChildren: () =>
      import('./merfolk/merfolk.module').then(m => m.MerfolkModule),
    data: { authGuardPipe: redirectUnauthorizedToLogin, preload: true },
  },
  {
    path: 'profile',
    canActivate: [AngularFireAuthGuard],
    loadChildren: () =>
      import('./profile/profile.module').then(m => m.ProfileModule),
    data: { authGuardPipe: redirectUnauthorizedToLogin, preload: true },
  },
  {
    path: 'media',
    canActivate: [AngularFireAuthGuard],
    loadChildren: () =>
      import('./activity-feed/activity-feed.module').then(
        m => m.ActivityFeedModule
      ),
    data: { authGuardPipe: redirectUnauthorizedToLogin, preload: true },
  },
  {
    path: 'bazaar',
    canActivate: [AngularFireAuthGuard],
    loadChildren: () =>
      import('./bazaar/bazaar.module').then(m => m.BazaarModule),
    data: { authGuardPipe: redirectUnauthorizedToLogin, preload: true },
  },
  {
    path: 'approvement',
    canActivate: [AngularFireAuthGuard],
    loadChildren: () =>
      import('./pending-approval/pending-approval.module').then(
        m => m.PendingApprovalPageModule
      ),
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'chat',
    canActivate: [AngularFireAuthGuard],
    loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule),
    data: { authGuardPipe: redirectUnauthorizedToLogin, preload: true },
  },
  {
    path: 'email-sent',
    component: EmailSentComponent,
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin-page/admin-page.module').then(m => m.AdminPageModule),
  },

  {
    path: 'pending-approval',
    loadChildren: () =>
      import('./pending-approval/pending-approval.module').then(
        m => m.PendingApprovalPageModule
      ),
  },
  {
    path: 'check-version',
    component: CheckVersionPage,
  },
  {
    path: 'deepLink',
    component: DeepLinkPageComponent,
  },
  {
    path: '**',
    redirectTo: '/',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
