import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, forkJoin } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CacheService } from './cache.service';

export interface EventSearchResult {
  title: string;
  description: string;
  date: string;
  time: string;
  location: string;
  url: string;
  imageUrl: string;
  price?: string;
  category?: string;
  isMustGo?: boolean;
  isFlagged?: boolean;
  flagReason?: string;
  source?: string;
}

@Injectable({
  providedIn: 'root',
})
export class EventSearchService {
  private googleApiKey: string;
  private searchEngineId: string;
  private openaiApiKey: string;
  private readonly CACHE_KEY_PREFIX = 'ai_events_';

  // Mermaid-specific keywords for better search results
  private mermaidKeywords = [
    'Mermaid',
    'Mermaids',
    'Merman',
    'Mermen',
    'MermaidCommunity',
    'Merpeople',
    'Merfolk',
    'ProfessionalMermaid',
    'MermaidPod',
    'mermaid events',
    'mermen events',
    'mermaid performances',
    'mermen performances',
    'mermaid meetup',
    'mermen meetup',
    'mermaid convention',
    'mermaid festival',
    'mermaid swimming',
    'mermaid tail',
    'mermen tail',
    'underwater performance',
    'mermaid show',
    'mermen show',
    'mermaid competition',
    'mermaid gathering',
    'mermaid parade',
    'mermaid classes',
    'mermaid workshop',
    'mermaid conference',
    'mermaid expo',
    'mermaid cosplay',
    'mermaid photoshoot',
    // Add more niche event keywords
    'mermaid yoga',
    'mermaid fitness',
    'mermaid retreat',
    'mermaid art',
    'mermaid photography',
    'mermaid conservation',
    'mermaid charity',
    'mermaid fundraiser',
    'mermaid book signing',
    'mermaid author',
    'mermaid podcast',
    'mermaid documentary',
    'mermaid film festival',
    'mermaid music',
    'mermaid concert',
    'mermaid dance',
    'mermaid theater',
    'mermaid play',
    'mermaid musical',
    'mermaid opera',
    'mermaid ballet',
    'mermaid circus',
    'mermaid carnival',
    'mermaid fair',
    'mermaid market',
    'mermaid bazaar',
    'mermaid expo',
    'mermaid symposium',
    'mermaid panel',
    'mermaid discussion',
    'mermaid lecture',
    'mermaid talk',
    'mermaid presentation',
    'mermaid demonstration',
    'mermaid workshop',
    'mermaid masterclass',
    'mermaid training',
    'mermaid certification',
    'mermaid course',
    'mermaid lesson',
    'mermaid tutorial',
    'mermaid instruction',
    'mermaid education',
    'mermaid school',
    'mermaid academy',
    'mermaid university',
    'mermaid college',
    'mermaid institute',
    'mermaid center',
    'mermaid foundation',
    'mermaid society',
    'mermaid association',
    'mermaid organization',
    'mermaid club',
    'mermaid group',
    'mermaid team',
    'mermaid troupe',
    'mermaid ensemble',
    'mermaid collective',
    'mermaid network',
    'mermaid alliance',
    'mermaid coalition',
    'mermaid partnership',
    'mermaid collaboration',
    'mermaid project',
    'mermaid initiative',
    'mermaid program',
    'mermaid series',
    'mermaid season',
    'mermaid tour',
    'mermaid expedition',
    'mermaid adventure',
    'mermaid journey',
    'mermaid quest',
    'mermaid mission',
    'mermaid challenge',
    'mermaid competition',
    'mermaid contest',
    'mermaid tournament',
    'mermaid championship',
    'mermaid olympics',
    'mermaid games',
    'mermaid race',
    'mermaid swim',
    'mermaid dive',
    'mermaid splash',
    'mermaid plunge',
    'mermaid dip',
    'mermaid bath',
    'mermaid spa',
    'mermaid wellness',
    'mermaid healing',
    'mermaid therapy',
    'mermaid meditation',
    'mermaid mindfulness',
    'mermaid relaxation',
    'mermaid rejuvenation',
    'mermaid renewal',
    'mermaid revival',
    'mermaid renaissance',
    'mermaid revolution',
    'mermaid movement',
    'mermaid trend',
    'mermaid fashion',
    'mermaid style',
    'mermaid design',
    'mermaid art',
    'mermaid craft',
    'mermaid DIY',
    'mermaid making',
    'mermaid creation',
    'mermaid production',
    'mermaid development',
    'mermaid innovation',
    'mermaid invention',
    'mermaid discovery',
    'mermaid exploration',
    'mermaid investigation',
    'mermaid research',
    'mermaid study',
    'mermaid analysis',
    'mermaid examination',
    'mermaid observation',
    'mermaid experimentation',
    'mermaid testing',
    'mermaid trial',
    'mermaid experiment',
    'mermaid demonstration',
    'mermaid showcase',
    'mermaid exhibition',
    'mermaid display',
    'mermaid presentation',
    'mermaid performance',
    'mermaid show',
    'mermaid spectacle',
    'mermaid extravaganza',
    'mermaid gala',
    'mermaid ball',
    'mermaid party',
    'mermaid celebration',
    'mermaid commemoration',
    'mermaid anniversary',
    'mermaid birthday',
    'mermaid holiday',
    'mermaid vacation',
    'mermaid getaway',
    'mermaid escape',
    'mermaid retreat',
    'mermaid sanctuary',
    'mermaid haven',
    'mermaid paradise',
    'mermaid utopia',
    'mermaid world',
    'mermaid universe',
    'mermaid realm',
    'mermaid kingdom',
    'mermaid empire',
    'mermaid nation',
    'mermaid state',
    'mermaid city',
    'mermaid town',
    'mermaid village',
    'mermaid community',
    'mermaid neighborhood',
    'mermaid district',
    'mermaid quarter',
    'mermaid zone',
    'mermaid area',
    'mermaid region',
    'mermaid territory',
    'mermaid domain',
    'mermaid land',
    'mermaid country',
    'mermaid continent',
    'mermaid island',
    'mermaid archipelago',
    'mermaid peninsula',
    'mermaid coast',
    'mermaid shore',
    'mermaid beach',
    'mermaid bay',
    'mermaid cove',
    'mermaid lagoon',
    'mermaid reef',
    'mermaid ocean',
    'mermaid sea',
    'mermaid lake',
    'mermaid river',
    'mermaid stream',
    'mermaid creek',
    'mermaid brook',
    'mermaid spring',
    'mermaid fountain',
    'mermaid waterfall',
    'mermaid cascade',
    'mermaid rapids',
    'mermaid current',
    'mermaid tide',
    'mermaid wave',
    'mermaid surf',
    'mermaid swell',
    'mermaid ripple',
    'mermaid splash',
    'mermaid spray',
    'mermaid mist',
    'mermaid fog',
    'mermaid cloud',
    'mermaid sky',
    'mermaid heaven',
    'mermaid paradise',
  ];

  constructor(private http: HttpClient, private cacheService: CacheService) {
    this.googleApiKey = environment.googleApiKey;
    this.searchEngineId = environment.searchEngineId;
    this.openaiApiKey = environment.openaiApiKey;
  }

  /**
   * Search for events using Google Custom Search and OpenAI
   * @param query Search query
   * @param sites Optional array of sites to restrict search to
   * @param bypassCache Optional flag to bypass cache and fetch fresh data
   * @returns Observable of EventSearchResult array
   */
  searchEvents(
    query: string,
    sites?: string[],
    bypassCache?: boolean
  ): Observable<EventSearchResult[]> {
    // Create a cache key based on the query and sites
    const cacheKey = this.createCacheKey(query, sites);

    // Try to get data from cache first (unless bypassCache is true)
    if (!bypassCache) {
      const cachedEvents = this.cacheService.get<EventSearchResult[]>(cacheKey);
      if (cachedEvents) {
        console.log(`Using cached events for query: ${query}`);
        return of(cachedEvents);
      }
    } else {
      console.log(`Bypassing cache for query: ${query}, fetching fresh data`);
    }

    // If not in cache or bypassCache is true, proceed with API call
    console.log(`No cache found for query: ${query}, fetching from API`);

    // Enhance the query to always include mermaid focus
    let enhancedQuery = query.trim();

    // Check if we have specific event names like "merlympics"
    const hasMerlympicsTerm = enhancedQuery
      .toLowerCase()
      .includes('merlympics');

    // Always ensure search is focused on mermaid events unless it's a specific named event
    const hasMermaidTerm = enhancedQuery.toLowerCase().includes('mermaid');
    const hasMermenTerm =
      enhancedQuery.toLowerCase().includes('mermen') ||
      enhancedQuery.toLowerCase().includes('merman');

    // Always force mermaid terms into the query unless it's a specific named event
    if (!hasMermaidTerm && !hasMermenTerm && !hasMerlympicsTerm) {
      enhancedQuery = `mermaid ${enhancedQuery}`;
    }

    // Add "events" if it's not already in the query and doesn't mention events
    const hasEventTerms = [
      'events',
      'parade',
      'festival',
      'convention',
      'competition',
    ].some(term => enhancedQuery.toLowerCase().includes(term));

    if (!hasEventTerms) {
      enhancedQuery = `${enhancedQuery} events`;
    }

    // Limit query length to avoid API errors
    enhancedQuery = enhancedQuery.substring(0, 100);

    // Define essential mermaid sites - prioritize these
    const primarySites = ['eventbrite.com', 'meetup.com', 'facebook.com'];

    // Secondary sites with mermaid content
    const secondarySites = [
      'mermaidconvention.com',
      'coneymermaid.com',
      'ncmerfest.com',
      'mermagic-con.com',
      'themermaidpod.com',
      'instagram.com',
    ];

    // For specific named events like "merlympics", we should search more broadly
    // without restricting to specific sites
    let searchQuery = enhancedQuery;
    let params: any = {
      key: this.googleApiKey,
      cx: this.searchEngineId,
      q: searchQuery,
      num: '10', // Request 10 results
    };

    // Only add site restrictions if not searching for specific named events
    if (!hasMerlympicsTerm) {
      // Combine with existing sites, prioritizing mermaid-specific ones
      let combinedSites = primarySites;

      // Add user-specified sites if provided
      if (sites && sites.length > 0) {
        // Filter out any sites that are already in primary sites
        const newSites = sites.filter(site => !primarySites.includes(site));
        combinedSites = [...primarySites, ...newSites];
      }

      // Add secondary sites if we have space
      const remainingSlots = 10 - combinedSites.length;
      if (remainingSlots > 0) {
        const additionalSites = secondarySites.slice(0, remainingSlots);
        combinedSites = [...combinedSites, ...additionalSites];
      }

      // Add site restrictions in a more compact format
      if (combinedSites && combinedSites.length > 0) {
        // Take only the top 5 sites to avoid query complexity issues
        const topSites = combinedSites.slice(0, 5);
        const siteQuery = topSites.map(site => `site:${site}`).join(' OR ');

        // Append site restrictions only if we have them
        if (topSites.length > 0) {
          params.q = `${params.q} (${siteQuery})`;
        }

        console.log('Using top sites:', topSites.join(', '));
      }
    } else {
      console.log(
        'Searching for specific named event without site restrictions:',
        enhancedQuery
      );
    }

    console.log('Final search query:', params.q);

    return this.http
      .get<any>(`https://www.googleapis.com/customsearch/v1`, {
        params: params,
      })
      .pipe(
        map(response => {
          if (!response.items || response.items.length === 0) {
            console.log('No search results found');
            return [];
          }
          console.log(`Found ${response.items.length} search results`);
          return response.items;
        }),
        switchMap(items => this.extractEventDetails(items)),
        map(events => this.filterPastEvents(events)), // Filter out past events
        map(events => {
          // Cache the results for future use (2 days expiration by default)
          if (events.length > 0) {
            this.cacheService.set(cacheKey, events);
            console.log(`Cached ${events.length} events for query: ${query}`);
          }
          return events;
        }),
        catchError(error => {
          console.error('Error in Google Custom Search API:', error);

          // Try fallback without site restrictions if we get an error and were using site restrictions
          if (params.q.includes('site:') && !hasMerlympicsTerm) {
            console.log('Trying fallback search without site restrictions');
            // Create a new params object without site restrictions
            const fallbackParams = {
              key: this.googleApiKey,
              cx: this.searchEngineId,
              q: searchQuery, // Use the original enhanced query without site restrictions
              num: '10',
            };

            return this.http
              .get<any>(`https://www.googleapis.com/customsearch/v1`, {
                params: fallbackParams,
              })
              .pipe(
                map(response => {
                  if (!response.items || response.items.length === 0) {
                    return [];
                  }
                  return response.items;
                }),
                switchMap(items => this.extractEventDetails(items)),
                map(events => this.filterPastEvents(events)),
                map(events => {
                  if (events.length > 0) {
                    this.cacheService.set(cacheKey, events);
                  }
                  return events;
                }),
                catchError(fallbackError => {
                  console.error('Fallback search also failed:', fallbackError);
                  return of([]);
                })
              );
          }

          return of([]); // Return empty array if search fails
        })
      );
  }

  /**
   * Create a cache key based on the search query and sites
   */
  public createCacheKey(query: string, sites?: string[]): string {
    let key =
      this.CACHE_KEY_PREFIX + query.toLowerCase().trim().replace(/\s+/g, '_');

    if (sites && sites.length > 0) {
      // Add a hash of the sites to the key
      const sitesStr = sites.sort().join('_');
      key += '_' + sitesStr.replace(/[^\w]/g, '_');
    }

    return key;
  }

  /**
   * Filter out past events based on date
   */
  private filterPastEvents(events: EventSearchResult[]): EventSearchResult[] {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to beginning of today

    return events.filter(event => {
      // If date is TBD, keep it
      if (event.date === 'Date TBD') {
        return true;
      }

      // Try to parse the date
      let eventDate: Date | null = null;

      // Try different date formats
      try {
        // Try YYYY-MM-DD format
        if (/^\d{4}-\d{2}-\d{2}$/.test(event.date)) {
          eventDate = new Date(event.date);
        }
        // Try MM/DD/YYYY format
        else if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(event.date)) {
          const parts = event.date.split('/');
          eventDate = new Date(
            parseInt(parts[2]),
            parseInt(parts[0]) - 1,
            parseInt(parts[1])
          );
        }
        // Try Month DD, YYYY format
        else if (/^[A-Za-z]+ \d{1,2},? \d{4}$/.test(event.date)) {
          eventDate = new Date(event.date);
        }
      } catch (e) {
        console.warn('Could not parse date:', event.date);
        return true; // Keep events with unparseable dates
      }

      // If we couldn't parse the date, keep the event
      if (!eventDate || isNaN(eventDate.getTime())) {
        return true;
      }

      // Keep the event if it's today or in the future
      return eventDate >= today;
    });
  }

  /**
   * Extract structured event details from search results
   */
  private extractEventDetails(
    searchResults: any[]
  ): Observable<EventSearchResult[]> {
    if (!searchResults || searchResults.length === 0) {
      return of([]);
    }

    // Prepare the prompt for OpenAI with more specific instructions
    const prompt = `
      Extract structured event information from these search results. Focus EXCLUSIVELY on mermaid and mermen events.
      For each result, provide the following information in a valid JSON array format:

      [
        {
          "title": "The exact event title (without date, time, or location information)",
          "description": "A brief description of the event, focusing on what makes it special for the mermaid/mermen community",
          "date": "The event date in YYYY-MM-DD format if available, or a readable date",
          "time": "The exact event time with AM/PM if available",
          "location": "The specific venue and address of the event",
          "url": "The URL to the specific event page, not a general website homepage. The URL MUST directly link to the actual event.",
          "source": "The name of the website source (e.g., Eventbrite, Facebook Events, Meetup, etc.)",
          "imageUrl": "A URL to an image for the event, or leave blank if none available",
          "price": "Indicate if the event is FREE or PAID. If paid, include the price if available (e.g., PAID: $25, PAID: $10-$50, etc.)",
          "category": "The category of the event (e.g., Workshop, Performance, Competition, Convention, Festival, Meeting, Pod Activity, Class, Other)",
          "isMustGo": true/false (Mark as true if this is a major or significant event for the mermaid community)
        },
        ...
      ]

      EXTREMELY IMPORTANT FILTERING RULES:
      1. ONLY include events that are 100% MERMAID-SPECIFIC - the event MUST be primarily about mermaids, mermen, or mermaid activities.
      2. Reject ANY event that merely mentions mermaids but isn't focused on the mermaid community or mermaid activities.
      3. Reject general swimming, beach, pool, or aquatic events that aren't specifically for mermaids.
      4. Reject costume or cosplay events unless they are specifically for mermaid costumes/cosplay.
      5. If you're unsure if an event is truly mermaid-related, DO NOT include it.
      6. If a search result is about products, services or general information (not an actual event), DO NOT include it.
      7. The word "mermaid" in a business name or venue name is NOT sufficient - the event itself must be mermaid-focused.
      8. ONLY include real, verifiable events with specific dates. Reject placeholder or template events.
      9. If NO valid mermaid events are found, return an empty array [].
      10. For the URL field, always link to the SPECIFIC event page, not to a general website homepage.
      11. All included websites and links must be for real, legitimate websites that actually exist.
      12. INCLUDE ALL VALID EVENTS FOUND IN THE SEARCH RESULTS. Do not arbitrarily limit the number of events returned.

      EVENT DIVERSITY AND SOURCE BALANCING - CRITICAL PRIORITY:
      1. Examine the domain of each result's URL carefully (e.g., eventbrite.com, facebook.com, etc.)
      2. You MUST include ALL events from EACH DIFFERENT DOMAIN found in the search results, if available and valid.
      3. Events must come from a wide variety of different websites - DO NOT just pick events from 1-2 sources.
      4. If you find events from any of these sites, be sure to include them: mermaidconvention.com, coneymermaid.com, ncmerfest.com,
         mermagic-con.com, themermaidpod.com, mermaidcertification.com, professionalmermaid.com, as these are core mermaid sites.
      5. Look for major events like "Merlympics" or other significant gatherings - these MUST be included if found.
      6. If terms like "merlympics", "convention", "festival", "competition", or "parade" appear in the search results, prioritize those events.
      7. ALWAYS analyze ALL search results before deciding which to include - don't just process the first few results.
      8. Your goal is to provide a DIVERSE MIX of events from MANY DIFFERENT SOURCES.
      9. DO NOT limit yourself to just 2-3 events. Include ALL valid mermaid events found in the search results!

      Additional guidelines:
      1. For each event, extract as much detail as possible from the search result.
      2. If a date is not in YYYY-MM-DD format, convert it if possible, otherwise provide it in the original format.
      3. If time information is available, include it in a standardized format (e.g., "7:00 PM - 9:00 PM").
      4. For location, provide as much detail as available (venue name, address, city, state, etc.).
      5. For price, clearly indicate if the event is FREE or PAID. If paid, include the price amount if available.
      6. For category, choose the most appropriate category from: Workshop, Performance, Competition, Convention, Festival, Meeting, Pod Activity, Class, Other.
      7. Mark an event as "isMustGo": true if it appears to be a major event, convention, parade, or significant gathering.
      8. Focus on extracting REAL, UPCOMING events, not past events or general information pages.
      9. Always include the "source" field to identify which website the event comes from.
      10. Remember to include ALL valid mermaid-specific events found in the search results.

      Here are the search results to analyze:
      ${JSON.stringify(searchResults, null, 2)}
    `;

    // Call OpenAI API to extract structured event information
    return this.http
      .post<any>(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4o',
          messages: [{ role: 'user', content: prompt }],
          temperature: 0.3, // Lower temperature for more consistent results
          max_tokens: 4000,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.openaiApiKey}`,
          },
        }
      )
      .pipe(
        map(response => {
          if (
            !response ||
            !response.choices ||
            response.choices.length === 0 ||
            !response.choices[0].message ||
            !response.choices[0].message.content
          ) {
            console.error('Invalid response from OpenAI:', response);
            return this.fallbackExtraction(searchResults);
          }

          const content = response.choices[0].message.content.trim();
          console.log('OpenAI response:', content);

          try {
            // Try to extract JSON from the response
            const jsonMatch = content.match(/\[\s*\{.*\}\s*\]/s);
            if (jsonMatch) {
              const jsonStr = jsonMatch[0];
              const events = JSON.parse(jsonStr);
              console.log(
                `Successfully extracted ${events.length} events using OpenAI`
              );

              // Ensure all events have the required fields
              const validEvents = events.filter(
                event =>
                  event.title &&
                  event.description &&
                  event.date &&
                  event.location &&
                  event.url &&
                  this.isValidEventUrl(event.url)
              );

              // Add default image URLs for events without images
              validEvents.forEach((event, index) => {
                if (!event.imageUrl || event.imageUrl.trim() === '') {
                  event.imageUrl = `https://picsum.photos/seed/mermaid${
                    index + 1
                  }/300/200`;
                }

                // Ensure the source field exists
                if (!event.source) {
                  event.source = this.extractSourceFromUrl(event.url);
                }
              });

              // Return all valid events without additional filtering that could reduce count
              return validEvents;
            } else {
              console.error('No JSON array found in OpenAI response');
              return this.fallbackExtraction(searchResults);
            }
          } catch (error) {
            console.error('Error parsing OpenAI response:', error);
            return this.fallbackExtraction(searchResults);
          }
        }),
        catchError(error => {
          console.error('Error calling OpenAI API:', error);
          return of(this.fallbackExtraction(searchResults));
        })
      );
  }

  /**
   * Check if the event URL is valid and points to a specific event page
   */
  private isValidEventUrl(url: string): boolean {
    if (!url) return false;

    try {
      new URL(url); // Check if URL is valid

      // Check if URL is not just a homepage
      const hostname = new URL(url).hostname;
      const pathname = new URL(url).pathname;

      // URLs should have a path beyond just the domain
      if (pathname === '/' || pathname === '') {
        return false;
      }

      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * Extract the source (website name) from the URL
   */
  private extractSourceFromUrl(url: string): string {
    try {
      const hostname = new URL(url).hostname;

      if (hostname.includes('eventbrite')) return 'Eventbrite';
      if (hostname.includes('facebook')) return 'Facebook Events';
      if (hostname.includes('meetup')) return 'Meetup';
      if (hostname.includes('ticketmaster')) return 'Ticketmaster';
      if (hostname.includes('instagram')) return 'Instagram';
      if (hostname.includes('ncmerfest')) return 'NC Merfest';
      if (hostname.includes('mermagic-con')) return 'MerMagic Con';
      if (hostname.includes('coneymermaid'))
        return 'Coney Island Mermaid Parade';

      // Extract domain name without TLD as fallback
      const domainParts = hostname.split('.');
      if (domainParts.length >= 2) {
        return (
          domainParts[domainParts.length - 2].charAt(0).toUpperCase() +
          domainParts[domainParts.length - 2].slice(1)
        );
      }

      return hostname;
    } catch (e) {
      return 'Unknown Source';
    }
  }

  /**
   * Ensure a balanced mix of event sources
   */
  private ensureBalancedSources(
    events: EventSearchResult[]
  ): EventSearchResult[] {
    if (events.length <= 3) return events;

    // Group events by source
    const eventsBySource: { [source: string]: EventSearchResult[] } = {};
    const eventsByDomain: { [domain: string]: EventSearchResult[] } = {};

    // Group by source and domain (for better site matching)
    events.forEach(event => {
      // Group by source
      const source = event.source || this.extractSourceFromUrl(event.url);
      if (!eventsBySource[source]) {
        eventsBySource[source] = [];
      }
      eventsBySource[source].push(event);

      // Group by domain
      try {
        const url = new URL(event.url);
        const domain = url.hostname.replace('www.', '');
        if (!eventsByDomain[domain]) {
          eventsByDomain[domain] = [];
        }
        eventsByDomain[domain].push(event);
      } catch (e) {
        // If URL parsing fails, ignore this grouping
        console.warn('Could not parse URL for domain grouping:', event.url);
      }
    });

    // Calculate how many events to include
    const maxEvents = Math.min(events.length, 20); // Increased max events
    const result: EventSearchResult[] = [];

    // First pass: Include at least one event from each domain/source
    const domains = Object.keys(eventsByDomain);
    const sources = Object.keys(eventsBySource);

    // Prioritize including at least one event from each domain
    domains.forEach(domain => {
      if (result.length < maxEvents && eventsByDomain[domain].length > 0) {
        // Take the first event from this domain
        const event = eventsByDomain[domain][0];
        result.push(event);

        // Remove this event from other collections
        const eventUrl = event.url;
        Object.keys(eventsByDomain).forEach(d => {
          eventsByDomain[d] = eventsByDomain[d].filter(e => e.url !== eventUrl);
        });
        Object.keys(eventsBySource).forEach(s => {
          eventsBySource[s] = eventsBySource[s].filter(e => e.url !== eventUrl);
        });
      }
    });

    // Second pass: Add events from sources that aren't represented yet
    sources.forEach(source => {
      if (result.length < maxEvents && eventsBySource[source].length > 0) {
        // Check if this source is already represented
        const isSourceIncluded = result.some(
          event =>
            (event.source || this.extractSourceFromUrl(event.url)) === source
        );

        if (!isSourceIncluded) {
          // Take the first event from this source
          const event = eventsBySource[source][0];
          result.push(event);

          // Remove this event from other collections
          const eventUrl = event.url;
          Object.keys(eventsByDomain).forEach(d => {
            eventsByDomain[d] = eventsByDomain[d].filter(
              e => e.url !== eventUrl
            );
          });
          Object.keys(eventsBySource).forEach(s => {
            eventsBySource[s] = eventsBySource[s].filter(
              e => e.url !== eventUrl
            );
          });
        }
      }
    });

    // Third pass: Fill remaining slots by taking remaining events in a round-robin fashion
    while (result.length < maxEvents) {
      let addedEvent = false;

      // Try to add from each domain
      for (const domain of domains) {
        if (result.length < maxEvents && eventsByDomain[domain].length > 0) {
          const event = eventsByDomain[domain][0];
          result.push(event);

          // Remove this event from other collections
          const eventUrl = event.url;
          Object.keys(eventsByDomain).forEach(d => {
            eventsByDomain[d] = eventsByDomain[d].filter(
              e => e.url !== eventUrl
            );
          });
          Object.keys(eventsBySource).forEach(s => {
            eventsBySource[s] = eventsBySource[s].filter(
              e => e.url !== eventUrl
            );
          });

          addedEvent = true;
        }
      }

      if (!addedEvent) break; // If we couldn't add any more events, exit the loop
    }

    return result;
  }

  /**
   * Extract date from search result
   */
  private extractDateFromResult(result: any): string {
    // Try to extract date from various fields
    if (result.pagemap?.event?.[0]?.startdate) {
      return result.pagemap.event[0].startdate;
    }

    // Check title for date patterns
    if (result.title) {
      const datePattern =
        /\b(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)[a-z]* \d{1,2}(?:st|nd|rd|th)?,? \d{4}\b|\b\d{1,2}\/\d{1,2}\/\d{2,4}\b|\b\d{4}-\d{2}-\d{2}\b/i;
      const match = result.title.match(datePattern);
      if (match) return match[0];
    }

    if (result.pagemap?.metatags?.[0]?.['og:title']) {
      const title = result.pagemap.metatags[0]['og:title'];
      const datePattern =
        /\b(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)[a-z]* \d{1,2}(?:st|nd|rd|th)?,? \d{4}\b|\b\d{1,2}\/\d{1,2}\/\d{2,4}\b|\b\d{4}-\d{2}-\d{2}\b/i;
      const match = title.match(datePattern);
      if (match) return match[0];
    }

    // Check snippet for date patterns
    if (result.snippet) {
      const datePattern =
        /\b(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)[a-z]* \d{1,2}(?:st|nd|rd|th)?,? \d{4}\b|\b\d{1,2}\/\d{1,2}\/\d{2,4}\b|\b\d{4}-\d{2}-\d{2}\b/i;
      const match = result.snippet.match(datePattern);
      if (match) return match[0];
    }

    return 'Date TBD';
  }

  /**
   * Extract time from search result
   */
  private extractTimeFromResult(result: any): string | null {
    // Try to extract time from various fields
    if (result.pagemap?.event?.[0]?.starttime) {
      return result.pagemap.event[0].starttime;
    }

    // Check title for time patterns
    if (result.title) {
      const timePattern =
        /\b(?:\d{1,2}:\d{2}(?::\d{2})?\s*(?:am|pm|AM|PM)?)\b|\b(?:\d{1,2}\s*(?:am|pm|AM|PM))\b/;
      const match = result.title.match(timePattern);
      if (match) return match[0];
    }

    // Check snippet for time patterns
    if (result.snippet) {
      const timePattern =
        /\b(?:\d{1,2}:\d{2}(?::\d{2})?\s*(?:am|pm|AM|PM)?)\b|\b(?:\d{1,2}\s*(?:am|pm|AM|PM))\b/;
      const match = result.snippet.match(timePattern);
      if (match) return match[0];
    }

    return 'Time TBD';
  }

  /**
   * Extract location from search result
   */
  private extractLocationFromResult(result: any): string {
    // Try to extract location from various fields
    if (result.pagemap?.event?.[0]?.location) {
      return result.pagemap.event[0].location;
    }

    // Check title for location patterns
    if (result.title) {
      const locationPatterns = [
        /\bin\s+([A-Za-z\s,]+)(?:\s|$)/i,
        /\bat\s+([A-Za-z\s,]+)(?:\s|$)/i,
        /\b([A-Za-z]+,\s*[A-Za-z]{2})(?:\s|$)/i, // City, State format
      ];

      for (const pattern of locationPatterns) {
        const match = result.title.match(pattern);
        if (match && match[1]) {
          return match[1].trim();
        }
      }
    }

    // Check snippet for location patterns
    if (result.snippet) {
      const locationPatterns = [
        /\bin\s+([A-Za-z\s,]+)(?:\s|$)/i,
        /\bat\s+([A-Za-z\s,]+)(?:\s|$)/i,
        /\b([A-Za-z]+,\s*[A-Za-z]{2})(?:\s|$)/i, // City, State format
      ];

      for (const pattern of locationPatterns) {
        const match = result.snippet.match(pattern);
        if (match && match[1]) {
          return match[1].trim();
        }
      }
    }

    return 'Location TBD';
  }

  /**
   * Get an image URL from the search result or use a placeholder
   */
  private getImageUrl(result: any, index: number): string {
    // Try to get image from Google search result in priority order

    // First check for event-specific images
    if (result.pagemap?.event?.[0]?.image) {
      return result.pagemap.event[0].image;
    }

    // Check for structured data images
    if (result.pagemap?.imageobject?.[0]?.url) {
      return result.pagemap.imageobject[0].url;
    }

    // Check for Open Graph images (often high quality)
    if (result.pagemap?.metatags?.[0]?.['og:image']) {
      return result.pagemap.metatags[0]['og:image'];
    }

    // Check for Twitter card images
    if (result.pagemap?.metatags?.[0]?.['twitter:image']) {
      return result.pagemap.metatags[0]['twitter:image'];
    }

    // Check for standard Google CSE images
    if (result.pagemap?.cse_image?.[0]?.src) {
      return result.pagemap.cse_image[0].src;
    }

    if (result.pagemap?.cse_thumbnail?.[0]?.src) {
      return result.pagemap.cse_thumbnail[0].src;
    }

    // If no image found, use themed mermaid placeholder images
    const themes = [
      'mermaid-performance',
      'mermaid-swimming',
      'ocean-conservation',
      'underwater-photography',
      'mermaid-tail',
      'freediving',
      'ocean-cleanup',
      'mermaid-convention',
      'siren-mythology',
      'mermaid-art',
    ];

    const theme = themes[index % themes.length];
    return `https://picsum.photos/seed/${theme}/300/200`;
  }

  /**
   * Fallback extraction method if OpenAI fails
   */
  private fallbackExtraction(searchResults: any[]): EventSearchResult[] {
    console.log('Using fallback extraction for events');
    return searchResults.map((result, index) => {
      // Extract basic information from the search result
      const title = result.title || 'Untitled Event';
      const description = result.snippet || 'No description available';
      const date = this.extractDateFromResult(result) || 'Date TBD';
      const time = this.extractTimeFromResult(result) || 'Time TBD';
      const location = this.extractLocationFromResult(result) || 'Location TBD';
      const price = this.extractPriceFromResult(result) || 'Price TBD';

      // Categorize the event based on content
      let category = '';

      // Check for specific event types in title and description
      const combinedText = `${title} ${description}`.toLowerCase();

      if (combinedText.includes('merlympics')) {
        category = 'Competition';
      } else if (
        combinedText.includes('parade') ||
        combinedText.includes('coney island')
      ) {
        category = 'Performance';
      } else if (
        combinedText.includes('convention') ||
        combinedText.includes('con ') ||
        combinedText.includes('merfest') ||
        combinedText.includes('mermagic')
      ) {
        category = 'Convention';
      } else if (
        combinedText.includes('workshop') ||
        combinedText.includes('class')
      ) {
        category = 'Workshop';
      } else if (
        combinedText.includes('pod') ||
        combinedText.includes('meetup')
      ) {
        category = 'Pod Activity';
      } else if (combinedText.includes('festival')) {
        category = 'Festival';
      } else {
        category = 'Other';
      }

      return {
        title: title,
        description: description,
        date: date,
        time: time,
        location: location,
        url: result.link,
        imageUrl: this.getImageUrl(result, index),
        price: price,
        category: category,
        isMustGo: false,
        source: this.extractSourceFromUrl(result.link),
      };
    });
  }

  /**
   * Extract price information from search result
   */
  private extractPriceFromResult(result: any): string | null {
    try {
      // Try to find price information in the title and snippet
      const text = `${result.title} ${result.snippet}`.toLowerCase();

      // Check for free event indicators
      if (
        text.includes('free') ||
        text.includes('no charge') ||
        text.includes('no cost') ||
        text.includes('complimentary')
      ) {
        return 'FREE';
      }

      // Check for paid event indicators with price
      const priceRegex = /(\$\d+(\.\d{2})?)|(\d+\s*dollars)/g;
      const priceMatches = text.match(priceRegex);

      if (priceMatches && priceMatches.length > 0) {
        return `PAID: ${priceMatches[0]}`;
      }

      // Check for general paid indicators
      if (
        text.includes('ticket') ||
        text.includes('admission') ||
        text.includes('fee') ||
        text.includes('cost') ||
        text.includes('price') ||
        text.includes('purchase')
      ) {
        return 'PAID';
      }

      // Default to unknown if no price information found
      return null;
    } catch (e) {
      console.error('Error extracting price:', e);
      return null;
    }
  }

  /**
   * Parse event date string into Date object
   */
  private parseEventDate(dateStr: string): Date | null {
    if (dateStr === 'Date TBD') {
      return null;
    }

    try {
      // Try YYYY-MM-DD format
      if (/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
        return new Date(dateStr);
      }
      // Try MM/DD/YYYY format
      else if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateStr)) {
        const parts = dateStr.split('/');
        return new Date(
          parseInt(parts[2]),
          parseInt(parts[0]) - 1,
          parseInt(parts[1])
        );
      }
      // Try Month DD, YYYY format
      else if (/^[A-Za-z]+ \d{1,2},? \d{4}$/.test(dateStr)) {
        return new Date(dateStr);
      }
    } catch (e) {
      console.warn('Could not parse date:', dateStr);
    }
    return null;
  }

  /**
   * Helper method to escape special characters in a string for use in a regular expression
   */
  private escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  /**
   * Search for events using multiple Google Custom Search queries and OpenAI
   * @param queries Array of search queries
   * @param sites Optional array of sites to restrict search to
   * @param bypassCache Optional flag to bypass cache and fetch fresh data
   * @returns Observable of EventSearchResult array
   */
  searchMultipleQueries(
    queries: string[],
    sites?: string[],
    bypassCache?: boolean
  ): Observable<EventSearchResult[]> {
    if (!queries || queries.length === 0) {
      console.log('No queries provided');
      return of([]);
    }

    console.log(
      `Searching for events with ${queries.length} queries:`,
      queries
    );

    // Check if any query contains specific event names like "merlympics"
    const containsSpecificEventNames = queries.some(
      q =>
        q.toLowerCase().includes('merlympics') ||
        q.toLowerCase().includes('coney') ||
        q.toLowerCase().includes('nc mer fest') ||
        q.toLowerCase().includes('mermagic')
    );

    // If searching for specific event names, don't use site restrictions
    const sitesToUse = containsSpecificEventNames ? [] : sites;

    // Use forkJoin to execute all queries in parallel
    return forkJoin(
      queries.map(query => this.searchEvents(query, sitesToUse, bypassCache))
    ).pipe(
      map(resultsArray => {
        // Flatten all results into a single array
        const allResults = resultsArray.reduce(
          (acc, curr) => [...acc, ...curr],
          []
        );

        // Deduplicate events by URL
        const uniqueEvents = this.deduplicateEvents(allResults);

        console.log(
          `Combined ${allResults.length} total results into ${uniqueEvents.length} unique events`
        );
        return uniqueEvents;
      }),
      catchError(error => {
        console.error('Error in multiple queries search:', error);
        return of([]);
      })
    );
  }

  /**
   * Remove duplicate events based on URL
   * @param events Array of events that may contain duplicates
   * @returns Array of events with duplicates removed
   */
  private deduplicateEvents(events: EventSearchResult[]): EventSearchResult[] {
    // Use a Map to track unique URLs
    const uniqueUrls = new Map<string, EventSearchResult>();

    // For each event, only keep the first occurrence of each URL
    events.forEach(event => {
      if (event.url && !uniqueUrls.has(event.url)) {
        uniqueUrls.set(event.url, event);
      }
    });

    // Convert back to array
    return Array.from(uniqueUrls.values());
  }
}

