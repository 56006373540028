import { Component, Input, TemplateRef } from '@angular/core';
import { IToolbarData } from '../../models/components-data.models';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  @Input() toolbarData: IToolbarData;
  @Input() template: TemplateRef<any>;

  constructor() {}
}
