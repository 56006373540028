import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Platform } from '@ionic/angular';
import { filter, finalize, Subject, takeUntil, catchError } from 'rxjs';
import { StorageHelper } from 'src/app/firebase/helpers/storageHelper';
import { FormService } from '../form/form.service';
import { createToaster, positionType } from '../modals/toaster';
import { ImagesService } from 'src/app/services/images.service';

const confirmForDiscard = {
  title: 'Discard your changes?',
  icon: 'image',
  okBtn: {
    title: 'YES, DISCARD',
  },
  cancelBtn: { title: 'NO, GO BACK' },
};

@Component({
  selector: 'app-image-loader',
  templateUrl: './image-loader.component.html',
  styleUrls: ['./image-loader.component.scss'],
})
export class ImageLoaderComponent implements OnInit, OnDestroy {
  @Input() field;
  @Input() form;
  @Input() type: string;
  @Input() i: number;
  @Input() userGallery;
  @Input() disabled;
  @Input() isExternalEvent = false;
  @ViewChild('imageDescription') imageDescription;
  @Output() removeFn = new EventEmitter<number>();
  @Output() upload = new EventEmitter<string[]>();
  spinner: boolean = false;
  maxSize: boolean = false;
  thumbnailArr = this.formService.itemGallery;
  imagesArr: string[] = [];
  title: string;
  url: string;
  arrTitle;
  index: number;
  imgs: any[] = [];
  previewImgs: any[] = [];
  currentTime = new Date();
  confirmForDiscard = confirmForDiscard;
  showForDiscard = false;
  imagesLoading;
  imagesCompressing;
  isPortraits = [];
  isMobile = this.platform.platforms().includes('capacitor');

  private destroy$ = new Subject<void>();

  constructor(
    private storageHelper: StorageHelper,
    private formService: FormService,
    private imagesService: ImagesService,
    private fb: FormBuilder,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.form = this.fb.group({ image: [null] });
    // this.formService.itemGallery.subscribe(images => {
    //   this.imagesArr = images;
    // });
    this.listenImages();
    this.listenPortraits();
    this.imagesService.imagesLoading.subscribe(data => {
      this.imagesLoading = data;
    });
    this.imagesService.compressionLoading.subscribe(data => {
      this.imagesCompressing = data;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.imagesService.isPortraits.next([]);
    this.imagesService.compressionLoading.next(false);
    this.imagesService.imagesLoading.next(false);
    this.imagesService.imageUrls.next([]);
    this.imagesService.compressedImages.next([]);
  }

  async compressImage(isMobile: boolean) {
    await this.imagesService.compressImage(isMobile);
  }

  async addToGallery(file, control, i, field) {
    if (!file) {
      this.spinner = false;
      return;
    }
    this.maxSize = false;
    this.spinner = true;
    this.title = file;
    this.formService.loadingImage.next(true);
    const uploadFile = await this.storageHelper.uploadPicture(file);
    uploadFile.task
      .snapshotChanges()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          uploadFile.fileRef
            .getDownloadURL()
            .pipe(
              takeUntil(this.destroy$),
              filter(url => !!url),
              catchError(async e =>
                createToaster(`Error:${e}`, 'danger', positionType.BOTTOM)
              )
            )
            .subscribe(url => {
              this.url = url;
              if (i === this.imagesArr.length) {
                const images = [...this.imagesArr, url];
                this.formService.itemGallery.next(images);
              } else {
                const images = this.imagesArr;
                images[i] = url;
                this.formService.itemGallery.next(images);
              }
              this.spinner = false;
              this.formService.loadingImage.next(false);
            });
        })
      )
      .subscribe();
  }

  isCompleteArray(newPreviewImgs) {
    for (let i = 0; i < newPreviewImgs.length; ++i) {
      if (!newPreviewImgs[i]) {
        return false;
      }
      if (newPreviewImgs[i].length === 0) {
        return false;
      }
      for (let j = 0; j < newPreviewImgs[i].length; ++j) {
        if (!newPreviewImgs[i][j]) {
          return false;
        }
      }
    }
    return true;
  }

  processImageArrays() {
    if (this.imgs.length) {
      let newPreviewImgs = [];
      newPreviewImgs.fill([], 0, this.imgs.length);
      let prevLength = 0;
      for (let i = 0; i < this.imgs.length; ) {
        const curIndex = prevLength;
        const curImgs = [];
        if (
          this.isPortraits[i] &&
          i + 1 < this.imgs.length &&
          this.isPortraits[i + 1]
        ) {
          curImgs.fill(undefined, 0, 2);
          const firstImage = this.imgs[i];
          if (typeof firstImage === 'string') {
            curImgs[0] = { src: firstImage, image: firstImage };
            if (!!curImgs[0] && !!curImgs[1]) {
              newPreviewImgs[curIndex] = curImgs;
              if (this.isCompleteArray(newPreviewImgs)) {
                this.previewImgs = newPreviewImgs;
              }
            }
          } else if (firstImage instanceof File) {
            const reader = new FileReader();
            reader.onload = () => {
              const src = reader.result;
              curImgs[0] = { src, image: firstImage };
              if (!!curImgs[0] && !!curImgs[1]) {
                newPreviewImgs[curIndex] = curImgs;
                if (this.isCompleteArray(newPreviewImgs)) {
                  this.previewImgs = newPreviewImgs;
                }
              }
            };
            reader.readAsDataURL(firstImage);
          }
          ++i;
          const secondImage = this.imgs[i];
          if (typeof secondImage === 'string') {
            curImgs[1] = { src: secondImage, image: secondImage };
            if (!!curImgs[0] && !!curImgs[1]) {
              newPreviewImgs[curIndex] = curImgs;
              if (this.isCompleteArray(newPreviewImgs)) {
                this.previewImgs = newPreviewImgs;
              }
            }
          } else if (secondImage instanceof File) {
            const reader = new FileReader();
            reader.onload = () => {
              const src = reader.result;
              curImgs[1] = { src, image: secondImage };
              if (!!curImgs[0] && !!curImgs[1]) {
                newPreviewImgs[curIndex] = curImgs;
                if (this.isCompleteArray(newPreviewImgs)) {
                  this.previewImgs = newPreviewImgs;
                }
              }
            };
            reader.readAsDataURL(secondImage);
          }
          ++i;
        } else {
          curImgs.fill(undefined, 0, 1);
          const curImage = this.imgs[i];
          if (typeof curImage === 'string') {
            curImgs[0] = { src: curImage, image: curImage };
            newPreviewImgs[curIndex] = curImgs;
            if (this.isCompleteArray(newPreviewImgs)) {
              this.previewImgs = newPreviewImgs;
            }
          } else if (curImage instanceof File) {
            const reader = new FileReader();
            reader.onload = () => {
              const src = reader.result;
              curImgs[0] = { src, image: curImage };
              newPreviewImgs[curIndex] = curImgs;
              if (this.isCompleteArray(newPreviewImgs)) {
                this.previewImgs = newPreviewImgs;
              }
            };
            reader.readAsDataURL(curImage);
          }
          ++i;
        }
        ++prevLength;
      }
      newPreviewImgs.length = prevLength;
      if (this.isCompleteArray(newPreviewImgs)) {
        this.previewImgs = newPreviewImgs;
      }
    } else {
      this.previewImgs = [];
    }
  }

  listenImages() {
    this.imagesService.compressedImages
      .pipe(takeUntil(this.destroy$))
      .subscribe(async images => {
        this.imgs = images;
        this.processImageArrays();
      });
  }

  listenPortraits() {
    this.imagesService.isPortraits
      .pipe(takeUntil(this.destroy$))
      .subscribe(async portraits => {
        this.isPortraits = portraits;
      });
  }

  async compressMultiplyImages(e) {
    if (this.isExternalEvent && this.imgs?.length >= 1) {
      createToaster(
        'Only 1 image is allowed for external events',
        'warning',
        positionType.BOTTOM
      );
      return;
    }
    await this.imagesService.compressMultiplyImages(this.isMobile);
  }

  removeImage(image) {
    this.imgs = this.imgs.filter(img => img !== image.image);
    this.imagesService.compressedImages.next(this.imgs);
    this.processImageArrays();
  }

  async upLoadGalleryImages() {
    this.imagesService.imagesLoading.next(true);
    let urlsArray = [];
    this.imgs.map(async image => {
      const file = await this.storageHelper.uploadPicture(image);
      file.task
        .snapshotChanges()
        .pipe(
          takeUntil(this.destroy$),
          catchError(async e => {
            createToaster(
              `Something went wrong.`,
              'danger',
              positionType.BOTTOM
            );
            this.imagesService.imagesLoading.next(false);
          }),
          finalize(() => {
            return file.fileRef
              .getDownloadURL()
              .pipe(
                takeUntil(this.destroy$),
                catchError(async e => {
                  createToaster(
                    `Something went wrong.`,
                    'danger',
                    positionType.BOTTOM
                  );
                  this.imagesService.imagesLoading.next(false);
                  return;
                })
              )
              .subscribe((url: string) => {
                urlsArray.push({
                  createdAt: this.currentTime,
                  url,
                  feedMessage: this.imageDescription?.el?.value || '',
                });
                if (urlsArray.length === this.imgs.length) {
                  this.upload.next(urlsArray);
                }
              });
          })
        )
        .subscribe();
    });
  }

  async upLoadImages() {
    this.imagesService.imagesLoading.next(true);
    let urlsArray = [];
    this.imgs.map(async image => {
      if (typeof image === 'string') {
        urlsArray.push(image);
        if (urlsArray.length === this.imgs.length) {
          this.imagesService.imageUrls.next(urlsArray);
        }
      } else {
        const file = await this.storageHelper.uploadPicture(image);
        file.task
          .snapshotChanges()
          .pipe(
            takeUntil(this.destroy$),
            catchError(async e => {
              createToaster(
                `Something went wrong.`,
                'danger',
                positionType.BOTTOM
              );
              this.imagesService.imagesLoading.next(false);
            }),
            finalize(() => {
              return file.fileRef
                .getDownloadURL()
                .pipe(
                  filter(item => !!item),
                  takeUntil(this.destroy$),
                  catchError(async e => {
                    createToaster(
                      `Something went wrong.`,
                      'danger',
                      positionType.BOTTOM
                    );
                    this.imagesService.imagesLoading.next(false);
                    return;
                  })
                )
                .subscribe((url: string) => {
                  !!url ? urlsArray.push(url) : null;
                  if (urlsArray.length === this.imgs.length) {
                    this.imagesService.imageUrls.next(urlsArray);
                    this.imagesService.imagesLoading.next(false);
                  }
                });
            })
          )
          .subscribe();
      }
    });
  }

  discardGalleryChanges() {
    this.showForDiscard = false;
    this.imagesService.addImageToGalleryModalIsOpen.next(false);
    this.imgs = [];
    this.imagesService.compressedImages.next([]);
    this.previewImgs = [];
    this.imagesService.compressionLoading.next(false);
  }

  closeModal($event: boolean) {
    this.showForDiscard = false;
  }
}

