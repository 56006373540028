import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  Subject,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { AuthService } from 'src/app/firebase/auth.service';
import {
  FormService,
  linkRegex,
} from 'src/app/shared/components/form/form.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { showLoading } from 'src/app/shared/components/modals/loading';
import { ServicesService } from '../services.service';
import { ServicesStore } from '../services.store';
import { confirmAddingModalData, imageField } from '../services';
import { countries } from 'src/app/auth/sign-up/countries';
import { GoogleMap } from '@capacitor/google-maps';
import { IonModal } from '@ionic/angular';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { environment } from 'src/environments/environment';
import mixpanel from 'mixpanel-browser';
import { ImagesService } from 'src/app/services/images.service';
import { MapService } from 'src/app/services/map.service';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.scss'],
})
export class AddServiceComponent implements OnInit, OnDestroy {
  @Output() isClose = new EventEmitter<boolean>(false);
  @ViewChild(IonModal) modal: IonModal;
  @ViewChild('placesRef', { static: false }) placesRef: GooglePlaceDirective;
  @ViewChild('imageLoader') imageLoader;

  map;
  isOpen = false;

  serviceForm: FormGroup;
  showSuccessModal = false;
  newServiceItem;
  disabled = true;
  addingBtnDisabled = true;
  imageField = imageField;
  typeOptions = ['part-time', 'full-time', 'per hour', 'gig based'];
  id: string;
  successModalData;

  properties;

  businessWebsiteURLValue = null;
  businessNameValue = null;

  imagesControls = 1;
  formIsReset = new BehaviorSubject(false);
  businessAccount = false;
  status;
  currentImage: string;
  loadingImage: boolean = false;
  imagesArr: string[] = [];
  urls;
  isAdded = false;

  today = new Date().toISOString();
  isOnline = false;
  loader = showLoading();
  user;
  countries = countries;
  item = { icon: 'search', title: 'Describe the Service you`re looking for' };
  confirmModalData = confirmAddingModalData;
  showConfirmModal = false;

  private destroy$ = new Subject<void>();

  constructor(
    private formService: FormService,
    private imagesService: ImagesService,
    private router: Router,
    private authService: AuthService,
    private servicesService: ServicesService,
    private servicesStore: ServicesStore,
    private fb: FormBuilder,
    private mapService: MapService
  ) {}

  ngOnInit() {
    this.serviceForm = this.fb.group({
      images: this.fb.array([
        {
          0: this.fb.group({
            image: [null],
          }),
        },
      ]),
      title: ['', [Validators.required, Validators.minLength(7)]],
      type: ['', [Validators.required]],
      salary: this.fb.group({
        value: [null],
        currency: ['$'],
      }),
      completionDate: this.fb.group({
        startDate: [null],
        endDate: [null],
      }),
      description: ['', [Validators.required, Validators.minLength(20)]],
      location: this.fb.group({
        online: [false, [Validators.required]],
        country: [null],
        state: [null],
        googleMapLink: [null, [Validators.required]],
      }),

      account: ['my member account'],
    });

    this.listenStatusChanges();
    this.listenItemId();
    this.getUser();

    combineLatest([
      this.serviceForm.valueChanges,
      this.imagesService.compressedImages,
      this.imagesService.imagesLoading,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([value, images, loading]) => {
        this.loadingImage = loading;
        this.imagesArr = images;
        this.disabled =
          this.serviceForm.status === 'INVALID' ||
          this.status === 'loading' ||
          this.loadingImage;
      });

    this.serviceForm
      .get('location.online')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => {
        if (value) {
          this.isOnline = true;
          (this.serviceForm.get('location') as FormGroup).removeControl(
            'googleMapLink'
          );
        } else {
          this.isOnline = false;
          (this.serviceForm.get('location') as FormGroup).addControl(
            'googleMapLink',
            new FormControl(null)
          );
        }
      });

    this.serviceForm
      .get('account')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => {
        if (value === 'a business account') {
          this.businessAccount = true;
          this.serviceForm.addControl(
            'businessName',
            new FormControl(this.businessNameValue, [Validators.required])
          );
          this.serviceForm.addControl(
            'businessWebsiteUrl',
            new FormControl(this.businessWebsiteURLValue, [
              Validators.required,
              Validators.pattern(linkRegex),
            ])
          );
        } else {
          this.businessAccount = false;
          this.serviceForm.removeControl('businessName');
          this.serviceForm.removeControl('businessWebsiteUrl');
        }
      });

    this.servicesService.isOpenForm.subscribe(
      isOpen => (this.showSuccessModal = isOpen)
    );
  }

  saveBusinessName(e) {
    this.businessNameValue = e.target.value;
  }

  saveBusinessWebsiteURL(e) {
    this.businessWebsiteURLValue = e.target.value;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.businessWebsiteURLValue = null;
    this.businessNameValue = null;
    this.imagesService.reset();
  }

  trackEvent(event, parameter) {
    if (parameter === 'content-type') {
      this.properties = {
        'content-type': 'Job Search',
      };

      mixpanel.track(event, this.properties);
    } else if (parameter === 'new event') {
      const { uid } = this.authService.isUserAuth();
      let myServices = this.servicesService.getMyItems('services', uid);
      myServices.pipe(take(1)).subscribe(data => {
        let servicesIdArray = [];
        data.forEach(pod => {
          servicesIdArray.push(pod.id);
        });
        mixpanel.people.set({ '$service-seeker-id': [...servicesIdArray] });
      });
    }
  }

  getFormsControls(): FormArray {
    return <FormArray>this.serviceForm.controls['images'];
  }

  close(e) {
    e ? (this.showConfirmModal = true) : null;
  }

  discardChanges(e) {
    if (e) {
      this.isClose.next(true);
      this.showConfirmModal = false;
    }
  }

  closeModal(e) {
    if (e) {
      this.showConfirmModal = false;
    }
  }

  addMapLink(e) {
    e.valueChanges.subscribe(async () => {
      const location = e.get('googleMapLink')?.value
      const { country } = await this.mapService.getAdDress(location);

      let currentCountry = countries.find(item => item.name === country);

      this.serviceForm.get('location.googleMapLink')?.patchValue(location);
      this.serviceForm.get('location.country').patchValue(currentCountry);
    });
  }

  async addServiceItem() {
    this.disabled = true;
    this.servicesStore.setLoading();
    if (this.imagesArr.length) {
      await this.imageLoader.upLoadImages();
      this.imagesService.imageUrls
        .pipe(
          filter(data => !!data),
          takeUntil(this.destroy$)
        )
        .subscribe(urls => {
          if (urls.length) {
            this.setNewItem(urls);
          }
        });
    } else {
      this.setNewItem(null);
    }
  }
  setNewItem(urls) {
    const start = this.serviceForm.get('completionDate.startDate').value
      ? new Date(this.serviceForm.get('completionDate.startDate').value)
      : null;
    const end = this.serviceForm.get('completionDate.endDate').value
      ? new Date(this.serviceForm.get('completionDate.endDate').value)
      : null;

    this.newServiceItem = {
      ...this.serviceForm.value,
      createdAt: new Date(),
      completionDate: {
        startDate: start,
        endDate: end,
      },
      creator: {
        id: this.user.id,
      },
    };
    this.newServiceItem.location = this.serviceForm.get('location.online').value
      ? Object.assign({
          ...this.newServiceItem.location,
          country: { region: 'online' },
        })
      : this.newServiceItem.location;

    this.newServiceItem.images = urls ? [...urls] : [];
    this.servicesStore.createItem(this.newServiceItem);
    this.trackEvent('Content Submitted', 'content-type');
    this.trackEvent('', 'new event');
  }

  listenItemId() {
    this.servicesStore
      .selectId()
      .pipe(filter(id => !!id))
      .subscribe(id => {
        this.isClose.next(true);
        this.servicesStore.updateShowFormModal(false);
        this.servicesService.isOpenForm.next(true);
        this.servicesStore.updateShowPendingModal(true);
        setTimeout(() => {
          this.servicesStore.patchState({ createdId: null });
          this.router.navigate(['/bazaar/job&services/services', id]);
        }, 0);
      });
  }

  private listenStatusChanges() {
    this.servicesStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => {
        loading
          ? this.loader.then(data => data.present())
          : this.loader.then(data => data.dismiss());
      });
  }

  private getUser() {
    const { uid } = this.authService.isUserAuth();
    this.user = uid;
    this.authService
      .getUserByUid(uid)
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user)
      )
      .subscribe(user => (this.user = user));
  }
}
