import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import {
  AppUpdate,
  AppUpdateAvailability,
  FlexibleUpdateState,
} from '@capawesome/capacitor-app-update';

@Injectable({
  providedIn: 'root',
})
export class ForceUpdateService {
  constructor() {}

  getCurrentAppVersion = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    if (Capacitor.getPlatform() === 'android') {
      alert(result.currentVersionCode);
      return result.currentVersionCode;
    } else {
      console.log(result.currentVersionName);
      return result.currentVersionName;
    }
  };

  getAvailableAppVersion = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    if (Capacitor.getPlatform() === 'android') {
      alert(result.availableVersionCode);
      return result.availableVersionCode;
    } else {
      console.log(result.availableVersionName);
      return result.availableVersionName;
    }
  };

  async isUpdateAvailability() {
    const result = await AppUpdate.getAppUpdateInfo();
    return result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE;
  }

  async updateApp() {
    await this.openAppStore();
    // if (Capacitor.getPlatform() === 'android') {
    //   await this.performImmediateUpdate();
    // } else {
    //   await this.openAppStore();
    // }
  }

  openAppStore = async () => {
    await AppUpdate.openAppStore();
  };

  performImmediateUpdate = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return;
    }
    if (result.immediateUpdateAllowed) {
      await AppUpdate.performImmediateUpdate().then(result => {
        alert(result.code);
      });
    }
  };

  startFlexibleUpdate = async () => {
    await AppUpdate.startFlexibleUpdate().then(async result => {
      if (result.code === 0) {
        await this.completeFlexibleUpdate();
      }
    });
  };

  completeFlexibleUpdate = async () => {
    await AppUpdate.completeFlexibleUpdate();
  };

  async removeAllListeners() {
    await AppUpdate.removeAllListeners();
  }
}
