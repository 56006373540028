import { Injectable } from '@angular/core';
import {
  FormArray,
  ValidationErrors,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { ISecondHandFilter } from 'src/app/bazaar/second-hand/second-hand';
import { ICountry } from '../../models/item.models';
import { IJobsFilter } from 'src/app/bazaar/jobs-services/jobs/jobs';
import { IEventFilter } from '../../../events/events';
import { IServicesFilter } from 'src/app/bazaar/jobs-services/services/services';
import { IPlanetFilter } from '../../../planet/planet';
import { IFeedFilter } from '../../../activity-feed/activity-feed';
export interface ICommunityFilter {
  country: [ICountry];
  gender: [string];
  experience: [string];
}

export interface IPoolFilter {
  myContent: boolean | null;
  country: [ICountry];
  dogsAllowed: boolean | null;
  finsAllowed: boolean | null;
  freeEntry: boolean | null;
  naturePools: boolean | null;
  creator?: string;
}

export interface IPodFilter {
  myContent: boolean | null;
  approval: boolean | null;
  country: [ICountry];
  creator?: string;
  status?: string;
}

export const linkRegex =
  /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})/;
@Injectable({
  providedIn: 'root',
})
export class FormService {
  formData$ = new BehaviorSubject({});
  communityValue$ = new BehaviorSubject({});
  location$ = new BehaviorSubject(null);
  podMenuValue$ = new BehaviorSubject({});
  poolMenuValue$ = new BehaviorSubject({});
  formMenuData$ = new BehaviorSubject({});
  isMenu$ = new BehaviorSubject(false);
  formValid = new BehaviorSubject(false);
  formInitialValues = new BehaviorSubject({});
  fieldsConditions = new BehaviorSubject([]);
  menuIsClose = new BehaviorSubject(false);
  selectedStartDate = new BehaviorSubject('');
  selectedEndDate = new BehaviorSubject('');
  isOpenStartDateModal = new BehaviorSubject(false);
  isOpenEndDateModal = new BehaviorSubject(false);
  menuColor = new BehaviorSubject('dark');
  podMenuData$: BehaviorSubject<IPodFilter | boolean> = new BehaviorSubject(
    false
  );
  poolMenuData$: BehaviorSubject<IPoolFilter | boolean> = new BehaviorSubject(
    false
  );
  communityMenuData$: BehaviorSubject<ICommunityFilter | boolean> =
    new BehaviorSubject(false);
  secondHandMenuData$: BehaviorSubject<ISecondHandFilter | boolean> =
    new BehaviorSubject(false);
  planetMenuData$: BehaviorSubject<IPlanetFilter | boolean> =
    new BehaviorSubject(false);
  eventMenuData$: BehaviorSubject<IEventFilter | boolean> = new BehaviorSubject(
    false
  );
  activityFeedMenuData$: BehaviorSubject<IFeedFilter | boolean> =
    new BehaviorSubject(false);
  jobsMenuData$: BehaviorSubject<IJobsFilter | boolean> = new BehaviorSubject(
    false
  );
  servicesMenuData$: BehaviorSubject<IServicesFilter | boolean> =
    new BehaviorSubject(false);
  currentPicture = new BehaviorSubject('');
  loadingImage = new BehaviorSubject(false);
  query = window.matchMedia('(max-width: 768px)');
  itemGallery: BehaviorSubject<string[]> = new BehaviorSubject([]);
  private destroy$ = new Subject<void>();

  constructor(private router: Router) {}

  atLeastOne(control): ValidationErrors {
    const invalid = (control as FormArray).value.every(c => {
      return c.memberQuestion === null || c.memberQuestion === '';
    });
    if (invalid) {
      control.controls.forEach(contr => {
        contr.get('memberQuestion').setErrors({ required: true });
      });
    } else {
      control.controls.forEach(contr => {
        contr.get('memberQuestion').setErrors(null);
      });
    }
    return invalid ? { atLeastOneError: true } : null;
  }

  addArrayControl(form, control) {
    if (control.name === 'joinMechanismArr') {
      const formArr = new FormArray([], {
        validators: this.atLeastOne,
        updateOn: 'change',
      });
      control.children.map(child => {
        const groupArr = new FormGroup({});
        const newArrControl = new FormControl();
        groupArr.addControl(child.name, newArrControl);
        formArr.push(groupArr);
      });

      form.addControl(control.name, formArr);
    } else {
      const formArr = new FormArray([], {
        updateOn: 'change',
      });

      control.children.map(child => {
        const groupArr = new FormGroup({});
        const newArrControl = new FormControl();
        groupArr.addControl(child.name, newArrControl);
        formArr.push(groupArr);
      });

      form.addControl(control.name, formArr);
    }
  }

  applyFilter(value) {
    const route = this.router.url.substring(9);
    const secondHandRoute = this.router.url.substring(8) === 'second-hand';
    const eventRoute = this.router.url.substring(1) === 'events';
    const jobsRoute = this.router.url.substring(21) === 'jobs';
    const servicesRoute = this.router.url.substring(21) === 'services';
    const planetRoute = this.router.url.substring(8) === 'challenges';
    const mediaRoute = this.router.url.substring(1) === 'media';
    switch (route) {
      case 'community':
        this.communityMenuData$.next(value);
        break;
      case 'pods':
        this.podMenuData$.next(value);
        break;
      case 'pools':
        this.poolMenuData$.next(value);
        break;
      case 'media':
        this.activityFeedMenuData$.next(value);
        break;
      default:
        break;
    }
    secondHandRoute ? this.secondHandMenuData$.next(value) : null;
    eventRoute ? this.eventMenuData$.next(value) : null;
    mediaRoute ? this.activityFeedMenuData$.next(value) : null;
    jobsRoute ? this.jobsMenuData$.next(value) : null;
    planetRoute ? this.planetMenuData$.next(value) : null;
    servicesRoute
      ? this.servicesMenuData$.next(
          value
            ? value
            : {
                myContent: null,
                creator: null,
                date: null,
                endDate: null,
                startDate: null,
              }
        )
      : null;

    this.menuIsClose.next(true);
  }

  resetFilter() {
    const secondHandRoute = this.router.url.substring(8) === 'second-hand';
    const jobsRoute = this.router.url.substring(21) === 'jobs';
    const servicesRoute = this.router.url.substring(21) === 'services';
    const eventRoute = this.router.url.substring(1) === 'events';
    const planetRoute = this.router.url.substring(8) === 'challenges';
    const mediaRoute = this.router.url.substring(1) === 'media';

    const route = this.router.url.substring(9);
    switch (route) {
      case 'community':
        this.communityMenuData$.next(false);
        break;
      case 'pods':
        this.podMenuData$.next(false);
        break;
      case 'pools':
        this.poolMenuData$.next(false);
        break;
      case 'media':
        this.activityFeedMenuData$.next(false);
        break;
      default:
        break;
    }

    eventRoute ? this.eventMenuData$.next(false) : null;
    planetRoute ? this.planetMenuData$.next(false) : null;
    secondHandRoute ? this.secondHandMenuData$.next(false) : null;
    jobsRoute ? this.jobsMenuData$.next(false) : null;
    mediaRoute ? this.activityFeedMenuData$.next(false) : null;
    servicesRoute
      ? this.servicesMenuData$.next({
          endDate: null,
          location: null,
          myContent: null,
          startDate: null,
          type: null,
          creator: null,
          date: null,
        })
      : null;
  }
}

