import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'myContentFilter',
})
export class MyContentFilterPipe implements PipeTransform {
  transform(events: any[], currentUserId: string): any[] {
    if (!events || !Array.isArray(events) || !currentUserId) {
      return events;
    }

    return events.filter(
      event =>
        event.creator?.id === currentUserId || event.creator === currentUserId
    );
  }
}

