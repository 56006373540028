import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  and,
  collection,
  getCountFromServer,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import isEqual from 'lodash.isequal';
import { EMPTY, from, Observable, Subject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CrudHelper {
  constructor(private fireStore: AngularFirestore) {}

  getHelper = ({ collectionName }) => {
    return this.fireStore
      .collection(collectionName)
      .snapshotChanges()
      .pipe(
        map(events =>
          events.map(a =>
            Object.assign(
              {
                id: a.payload.doc.id,
              },
              a.payload.doc.data()
            )
          )
        ),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr))
      );
  };

  createDocument = ({ collectionName, data }) => {
    const resultSubject = new Subject<string>();
    this.fireStore
      .collection(collectionName)
      .add(data)
      .then((data: any) => {
        resultSubject.next(data.id);
      })
      .catch(error => resultSubject.error(error));

    return resultSubject;
  };

  setDocument = (itemId, { collectionName, data }) => {
    const resultSubject = new Subject<string>();

    this.fireStore
      .collection(collectionName)
      .doc(itemId)
      .set(data)
      .then((data: any) => {
        resultSubject.next(data.id);
      })
      .catch(error => resultSubject.error(error));

    return resultSubject;
  };

  setHelper = ({ collectionName, data }) => {
    return this.fireStore
      .collection(collectionName)
      .add(data)
      .then((data: any) => {
        return data.id;
      });
  };

  getById = ({ id, collection }): Observable<any> => {
    return this.fireStore
      .collection(collection, ref => ref.where('id', '==', id))
      .snapshotChanges()
      .pipe(
        map(doc =>
          Object.assign(
            {
              id: doc[0].payload.doc.id,
            },
            doc[0].payload.doc.data()
          )
        )
      );
  };

  updateItem = ({ data, id, collectionName }) => {
    return from(
      this.fireStore
        .collection(collectionName)
        .doc(id)
        .update(data)
        .finally(() => {
          console.log('Successfully updated');
        })
        .catch(error => {
          console.log(error);
        })
    );
  };

  deleteHelper = ({ collectionName, id }) => {
    // Check if ID is valid
    if (!id) {
      const resultSubject = new Subject<boolean>();
      resultSubject.error(new Error('Invalid ID provided for deletion'));
      resultSubject.complete();
      return resultSubject;
    }

    const resultSubject = new Subject<boolean>();

    // First check if the document exists
    this.fireStore
      .collection(collectionName)
      .doc(id)
      .get()
      .toPromise()
      .then(doc => {
        if (!doc.exists) {
          resultSubject.error(new Error('Document does not exist'));
          resultSubject.complete();
          return;
        }

        // Document exists, proceed with deletion
        this.fireStore
          .collection(collectionName)
          .doc(id)
          .delete()
          .then(() => {
            resultSubject.next(true);
            resultSubject.complete();
          })
          .catch(error => {
            resultSubject.error(error);
            resultSubject.complete();
          });
      })
      .catch(error => {
        resultSubject.error(error);
        resultSubject.complete();
      });

    return resultSubject;
  };

  searchByField(collectionName, searchFieldName, searchValue) {
    return this.fireStore
      .collection(collectionName, ref =>
        ref.where(searchFieldName, '==', searchValue).limit(1)
      )
      .valueChanges({ idField: 'id' });
  }

  searchHelper = async ({ collectionName, searchField, limit, start, end }) => {
    return this.fireStore
      .collection(collectionName, ref =>
        ref.limit(limit).orderBy(searchField).startAt(start).endAt(end)
      )
      .snapshotChanges()
      .pipe(
        map(data =>
          data.map(item =>
            Object.assign(
              {
                id: item.payload.doc.id,
              },
              item.payload.doc.data()
            )
          )
        ),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr))
      );
  };

  getCollectionItems = ({ collectionName, queryFn }) => {
    return this.fireStore
      .collection(collectionName, ref => (queryFn ? queryFn(ref) : ref))
      .snapshotChanges()
      .pipe(
        map(data =>
          data.map(item => {
            return Object.assign(
              {
                id: item.payload.doc.id,
              },
              item.payload.doc.data()
            );
          })
        ),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr))
      );
  };

  getPreviewItems = ({
    collectionName,
    userId,
    limit,
    forbidden,
  }): Observable<any> => {
    const queryFn = ref => {
      let query = ref;
      if (forbidden) {
        query = query
          .where('creator.id', '==', userId)
          .where('status', '==', 'approved');
      } else {
        query = query.where('creator.id', '==', userId);
      }
      return query;
    };

    return this.fireStore
      .collection(collectionName, ref =>
        queryFn(ref).limit(limit).orderBy('createdAt', 'desc')
      )
      .snapshotChanges()
      .pipe(
        map(data =>
          data.map(item =>
            Object.assign(
              {
                id: item.payload.doc.id,
              },
              item.payload.doc.data()
            )
          )
        ),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr))
      );
  };

  getPreviewEvents = ({ limit, podId }): Observable<any> => {
    const queryFn = ref => {
      let query = ref;

      query = query
        .where('podName', '==', podId)
        .where('status', '==', 'approved');
      return query;
    };

    return this.fireStore
      .collection('events', ref =>
        queryFn(ref).limit(limit).orderBy('createdAt', 'desc')
      )
      .snapshotChanges()
      .pipe(
        map(data =>
          data.map(item =>
            Object.assign(
              {
                id: item.payload.doc.id,
              },
              item.payload.doc.data()
            )
          )
        ),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr))
      );
  };

  getFirstPaginatedItems = ({
    collectionName,
    userId,
    limit,
    forbidden,
  }): Observable<any> => {
    const queryFn = ref => {
      let query = ref;
      if (forbidden) {
        query = query
          .where('creator.id', '==', userId)
          .where('status', '==', 'approved');
      } else {
        query = query.where('creator.id', '==', userId);
      }
      return query;
    };

    return this.fireStore
      .collection(collectionName, ref =>
        queryFn(ref).orderBy('createdAt', 'desc').limit(limit)
      )
      .snapshotChanges();
  };

  getFirstPagePaginatedItems = ({
    collectionName,
    queryFn,
    limit,
    sortBy,
  }): Observable<any> => {
    return this.fireStore
      .collection(collectionName, ref =>
        queryFn
          ? queryFn(ref).orderBy(sortBy).limit(limit)
          : ref.orderBy(sortBy).limit(limit)
      )
      .snapshotChanges();
  };

  getNextPaginatedItems = ({
    collectionName,
    userId,
    limit,
    lastInResponse,
    forbidden,
  }): Observable<any> => {
    const queryFn = ref => {
      let query = ref;
      if (forbidden) {
        query = query
          .where('creator.id', '==', userId)
          .where('status', '==', 'approved');
      } else {
        query = query.where('creator.id', '==', userId);
      }
      return query;
    };

    return this.fireStore
      .collection(collectionName, ref =>
        queryFn(ref)
          .orderBy('createdAt', 'desc')
          .startAfter(lastInResponse)
          .limit(limit)
      )
      .snapshotChanges();
  };

  getPrevPaginatedItems = ({
    collectionName,
    userId,
    limit,
    prevFirstInResponse,
    forbidden,
  }): Observable<any> => {
    const queryFn = ref => {
      let query = ref;
      if (forbidden) {
        query = query
          .where('creator.id', '==', userId)
          .where('status', '==', 'approved');
      } else {
        query = query.where('creator.id', '==', userId);
      }
      return query;
    };

    return this.fireStore
      .collection(collectionName, ref =>
        queryFn(ref)
          .orderBy('createdAt', 'desc')
          .endBefore(prevFirstInResponse)
          .limitToLast(limit)
      )
      .snapshotChanges();
  };

  getLastPaginatedItems = ({
    collectionName,
    userId,
    limit,
    forbidden,
  }): Observable<any> => {
    const queryFn = ref => {
      let query = ref;
      if (forbidden) {
        query = query
          .where('creator.id', '==', userId)
          .where('status', '==', 'approved');
      } else {
        query = query.where('creator.id', '==', userId);
      }
      return query;
    };

    return this.fireStore
      .collection(collectionName, ref =>
        queryFn(ref).orderBy('createdAt', 'desc').limitToLast(limit)
      )
      .snapshotChanges();
  };

  getCountItems = ({ collectionName, userId, forbidden }) => {
    const collectionRef = collection(getFirestore(), collectionName);
    if (forbidden) {
      const snapshot = query(
        collectionRef,
        and(
          where('status', '==', 'approved'),
          where('creator.id', '==', userId)
        )
      );
      return from(getCountFromServer(snapshot)).pipe(
        map(items => items.data().count)
      );
    } else {
      const snapshot = query(collectionRef, where('creator.id', '==', userId));
      return from(getCountFromServer(snapshot)).pipe(
        map(items => items.data().count)
      );
    }
  };
}

