import { Component, Input, TemplateRef } from '@angular/core';
import { Timestamp } from 'firebase/firestore';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-item-date',
  templateUrl: './item-date.component.html',
  styleUrls: ['./item-date.component.scss'],
})
export class ItemDateComponent {
  @Input() startDate: string | Timestamp | Date;
  @Input() endDate: string | Timestamp | Date;
  @Input() item: string;
  @Input() template: TemplateRef<any>;

  constructor(private dateService: DateService) {}

  checkDate(startDate: any, endDate: any) {
    // If either date is a string (YYYY-MM-DD format), they are date-only
    if (typeof startDate === 'string' || typeof endDate === 'string') {
      return startDate === endDate;
    }
    return this.dateService.checkSameDate(startDate, endDate);
  }

  isDateString(date: any): boolean {
    return typeof date === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(date);
  }
}

