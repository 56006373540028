import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './components/menu/menu.component';
import { FormComponent } from './components/form/form.component';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { PendingModalComponent } from './components/modals/pending-modal/pending-modal.component';
import { RouterModule } from '@angular/router';
import { DatetimeComponent } from './components/datetime/datetime.component';
import { IonicSelectableModule } from 'ionic-selectable';
import { ConfirmModalComponent } from './components/modals/confirm-modal/confirm-modal.component';
import { EmptyItemComponent } from './components/empty-item/empty-item.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ImageLoaderComponent } from './components/image-loader/image-loader.component';
import { SwiperDirective } from './directives/swiper.directive';
import { ItemFormComponent } from './components/item-form/item-form.component';
import { SwiperComponent } from './components/swiper/swiper.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { GoogleMapComponent } from './components/google-map/google-map.component';
import { LetsGoPremiumModalComponent } from './components/modals/lets-go-premium-modal/lets-go-premium-modal.component';
import { LetsGoChatModalComponent } from './components/modals/lets-go-chat-modal/lets-go-chat-modal.component';
import { StatusModalComponent } from './components/modals/status-modal/status-modal.component';
import { NotificationModalComponent } from './components/modals/notification-modal/notification-modal.component';
import { SubscriptionNotificationComponent } from './components/modals/subscription-notification/subscription-notification.component';
import { BuyPearlsNotificationComponent } from './components/modals/buy-pearls-notification/buy-pearls-notification.component';
import { SubscriptionByPearlsNotificationComponent } from './components/modals/subscription-by-pearls-notification/subscription-by-pearls-notification.component';
import { TravelingModalComponent } from './components/modals/traveling-modal/traveling-modal.component';
import { ItemDateComponent } from './components/item-date/item-date.component';
import { PageSpinnerComponent } from './components/page-spinner/page-spinner.component';
import { AdsenseModule } from 'ng2-adsense';
import { BannerModule } from './components/ads/adsense/banner/banner.module';
import { BannerComponent } from './components/ads/adsense/banner/banner.component';
import { InterstitialModule } from './components/ads/adsense/interstitial/interstitial.module';
import { MrecModule } from './components/ads/adsense/mrec/mrec.module';
import { InterstitialComponent } from './components/ads/adsense/interstitial/interstitial.component';
import { MrecComponent } from './components/ads/adsense/mrec/mrec.component';
import { AdModalComponent } from './components/modals/ad-modal/ad-modal.component';
import { PodInvitationModalComponent } from './components/modals/pod-invitation-modal/pod-invitation-modal.component';
import { PipesModule } from '../pipes/pipes.module';
import { UsersListComponent } from './users-list/users-list.component';
import { SuspendFooterComponent } from './components/suspend-footer/suspend-footer.component';
import { ItemSettingsNotificationsComponent } from './components/item-settings-notifications/item-settings-notifications.component';
import {LikeButtonComponent} from "./components/like-button/like-button.component";
import {DeepLinkPageComponent} from "./components/deep-link-page/deep-link-page.component";
import { UserGeneratedContentModalComponent } from './components/modals/user-generated-content-modal/user-generated-content-modal.component';
import { IsFlaggedModalComponent } from './components/modals/is-flagged-modal/is-flagged-modal.component';

@NgModule({
  declarations: [
    UserGeneratedContentModalComponent,
    IsFlaggedModalComponent,
    LetsGoPremiumModalComponent,
    FormComponent,
    ToolbarComponent,
    MenuComponent,
    PendingModalComponent,
    DatetimeComponent,
    ConfirmModalComponent,
    EmptyItemComponent,
    ImageLoaderComponent,
    ItemFormComponent,
    SwiperComponent,
    PaginationComponent,
    GoogleMapComponent,
    LetsGoChatModalComponent,
    StatusModalComponent,
    NotificationModalComponent,
    SubscriptionNotificationComponent,
    BuyPearlsNotificationComponent,
    SubscriptionByPearlsNotificationComponent,
    TravelingModalComponent,
    ItemDateComponent,
    PageSpinnerComponent,
    AdModalComponent,
    PodInvitationModalComponent,
    UsersListComponent,
    SuspendFooterComponent,
    ItemSettingsNotificationsComponent,
    LikeButtonComponent,
    DeepLinkPageComponent,
  ],
  imports: [
    AdsenseModule.forRoot({
      adClient: 'ca-pub-2609549176457465',
    }),
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    RouterModule,
    IonicSelectableModule,
    GoogleMapsModule,
    GooglePlaceModule,
    SwiperDirective,
    BannerModule,
    InterstitialModule,
    MrecModule,
    PipesModule,
  ],
  exports: [
    ToolbarComponent,
    MenuComponent,
    FormComponent,
    PendingModalComponent,
    DatetimeComponent,
    ConfirmModalComponent,
    EmptyItemComponent,
    ImageLoaderComponent,
    SwiperDirective,
    ItemFormComponent,
    SwiperComponent,
    PaginationComponent,
    GoogleMapComponent,
    LetsGoPremiumModalComponent,
    LetsGoChatModalComponent,
    StatusModalComponent,
    NotificationModalComponent,
    SubscriptionNotificationComponent,
    BuyPearlsNotificationComponent,
    SubscriptionByPearlsNotificationComponent,
    TravelingModalComponent,
    ItemDateComponent,
    PageSpinnerComponent,
    BannerComponent,
    InterstitialComponent,
    MrecComponent,
    AdModalComponent,
    ItemDateComponent,
    PodInvitationModalComponent,
    UsersListComponent,
    SuspendFooterComponent,
    ItemSettingsNotificationsComponent,
    LikeButtonComponent,
    DeepLinkPageComponent,
    UserGeneratedContentModalComponent,
    IsFlaggedModalComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
