import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Ng2ImgMaxService } from 'ng2-img-max';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  Observable,
  of,
  Subject,
} from 'rxjs';
import { catchError, switchMap, take, takeUntil } from 'rxjs/operators';
import { ProfileService } from './profile.service';
import { ProfileStore } from './store/profile-store';
import { StorageHelper } from '../firebase/helpers/storageHelper';
import { User } from '../shared/models/user.model';
import { IPod, IPool } from '../shared/models/item.models';
import { JobItem, SecondHandItem, ServiceItem } from '../bazaar/bazaar';
import {
  createToaster,
  positionType,
} from '../shared/components/modals/toaster';
import { PodStore } from '../merfolk/pods/pod-store';
import { JobsStore } from '../bazaar/jobs-services/jobs/jobs-store';
import { PoolsStore } from '../merfolk/pools/pools.store';
import { ServicesStore } from '../bazaar/jobs-services/services/services.store';
import { SecondHandStore } from '../bazaar/second-hand/second-hand-store';
import { EventsStore } from '../events/events-store';
import { EventsService } from '../events/events.service';
import { IGallery } from './gallery/gallery.component';
import { UserService } from '../auth/user.service';
import { PodService } from '../merfolk/pods/pods.service';
import { successInviteModalData } from '../merfolk/pods/pods/pods';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { IToolbarData } from '../shared/models/components-data.models';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  galleryLoading = true;
  gallery$ = new BehaviorSubject<IGallery | null>(null);
  isProfilePictureModalOpened = false;
  imageChangedEvent = null;
  loading;
  user: User = null;
  userId: string = this.activeRouter.snapshot.params.id;

  forbidden: boolean;
  isAdmin = false;
  isPremium = true;
  itemsLoading = true;
  isOpenShowItems = false;
  currentUser;
  followerUsers;
  followingUser;
  userBlockList;
  isUserBlocked: boolean;

  pods$ = new BehaviorSubject<IPod[] | null>([]);
  pools$ = new BehaviorSubject<IPool[] | null>([]);
  searchers$ = new BehaviorSubject<JobItem[] | null>([]);
  offers$ = new BehaviorSubject<ServiceItem[] | null>([]);
  events$ = new BehaviorSubject<Event[] | null>([]);
  secondHandItems$ = new BehaviorSubject<SecondHandItem[] | null>([]);

  paginatedItems: any[];
  collectionName: string;
  itemsPerPage: number = 7;

  paginatedModalTitle: string;

  podsTitle: string;
  galleryTitle: string;
  poolsTitle: string;
  offersTitle: string;
  eventTitle: string;
  searchersTitle: string;
  secondHandTitle: string;

  count;
  limit = 3;
  start = 0;
  end = 10;
  currentPage = new BehaviorSubject<number>(0);
  countPages: number;
  lastInResponse: any = [];
  firstInResponse: any = [];
  lastItemsCount: number;
  paginatedArr: { page: number; items: any[] }[];
  currentTime: number = new Date().getTime();
  maxSize = false;
  startDate;
  endDate;
  userGalleryLoading = false;
  isNextBtnDisabled = false;
  isPrevBtnDisabled = true;
  isFirstBtnDisabled = true;
  isLastBtnDisabled = false;
  isUserFollowed: boolean;

  successModalData = successInviteModalData;
  showSuccessModal = false;

  userPods: IPod[];
  podsSuggestions$: Observable<IPod[]>;
  isCanInvite = false;

  showInvitationModal;

  toolbarData: IToolbarData = {
    btnSlot: 'end',
    title: 'Profile',
    arrow: true,
    arrowRouter: '/',
  };

  private destroy$ = new Subject();

  constructor(
    private storageHelper: StorageHelper,
    private profileService: ProfileService,
    private profileStore: ProfileStore,
    private activeRouter: ActivatedRoute,
    private ng2ImgMaxService: Ng2ImgMaxService,
    private podStore: PodStore,
    private jobStore: JobsStore,
    private poolStore: PoolsStore,
    private servicesStore: ServicesStore,
    private secondHandStore: SecondHandStore,
    private eventStore: EventsStore,
    private eventsService: EventsService,
    private userService: UserService,
    private podService: PodService
  ) {}

  ngOnInit() {
    this.itemsLoading = true;
    this.userGalleryLoading = true;
    this.listenItemsChanges();
    this.listenPagination();
    this.listenUserChanges();
    this.listenGalleryChanges();
    this.getUser();
    this.saveProfilePicture = this.saveProfilePicture.bind(this);
    this.initUserGallery();
    this.podStore.selectInviteModal().subscribe(isOpen => {
      this.showSuccessModal = isOpen;
    });
  }

  ionViewDidEnter() {
    this.userId = this.activeRouter.snapshot.params.id;
  }

  ngOnDestroy() {
    this.profileStore.logOut();
    this.userId = null;
    this.gallery$.next(null);
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  closeModal() {
    this.isProfilePictureModalOpened = false;
  }

  closeListModal(e) {
    e ? (this.isOpenShowItems = false) : null;
  }

  handleOk(e) {
    e ? this.podStore.updateInviteModal(false) : null;
  }

  handleCancel(e) {
    e ? this.podStore.updateInviteModal(false) : null;
  }

  fileChangeEvent(event: any): void {
    const file = event.target.files[0];
    if (file.size > 6000000) {
      createToaster(
        'File size should not exceed 6 mb',
        'danger',
        positionType.BOTTOM
      );
      this.maxSize = true;
      return;
    }
    const newData = { target: { files: [] } };
    if (!this.forbidden) {
      this.ng2ImgMaxService
        .compressImage(event.target.files[0], 0.3)
        .pipe(
          catchError(async e => {
            console.error(`Error:${e.reason}`);
          })
        )
        .subscribe(image => {
          newData.target.files = [image];
          this.imageChangedEvent = newData;
          this.isProfilePictureModalOpened = true;
        });
    }
  }

  saveProfilePicture(file, filePath, fileName): Observable<any> {
    return this.storageHelper.uploadImageBase64(file, filePath, fileName).pipe(
      takeUntil(this.destroy$),
      switchMap(profilePictureUrl =>
        of(
          this.profileStore.updateProfile(
            Object.assign({
              ...this.user,
              profilePicture: profilePictureUrl,
            })
          )
        )
      )
    );
  }

  listenGalleryChanges() {
    this.userGalleryLoading = true;
    this.profileStore.getGallery(this.activeRouter.snapshot.params.id);
    this.profileStore
      .selectGallery()
      .pipe(
        filter(gallery => !!gallery),
        takeUntil(this.destroy$)
      )
      .subscribe((gallery: any) => {
        this.userGalleryLoading = false;
        if (gallery.userId === this.activeRouter.snapshot.params.id) {
          this.gallery$.next(gallery);
        }
      });
  }

  initUserGallery() {
    this.profileService
      .getProfileGallery(this.userId)
      .pipe(
        filter(gallery => !!gallery),
        take(1)
      )

      .subscribe(gallery =>
        gallery.length ? null : this.profileStore.createGallery(this.userId)
      );
  }

  getPaginatedItems(e, collectionName: string, title: string, items?) {
    if (e) {
      this.paginatedItems = [];
      this.paginatedArr = [];
      this.currentPage.next(0);
      this.start = 1;
      this.end = this.itemsPerPage;
      this.paginatedModalTitle = title;
      this.collectionName = collectionName;
      const itemCount = items.length;
      this.countPages = itemCount
        ? Math.ceil(itemCount / this.itemsPerPage)
        : this.countPages;
      this.count = itemCount ? itemCount : this.count;

      this.paginatedItems = items.filter(item => !!item);
      this.isOpenShowItems = true;
      this.listenPagination();
    }
  }

  private listenUserChanges() {
    this.userService
      .getUserByUid(this.activeRouter.snapshot.params.id)
      .pipe(
        filter(user => !!user),
        takeUntil(this.destroy$)
      )
      .subscribe((user: any) => {
        if (user.uid === this.activeRouter.snapshot.params.id) {
          this.user = user;
          console.log('got user:', this.currentUser);
          this.followingUser = this.user.following;
          this.followerUsers = this.user.followers;

          if (this.followerUsers) {
            this.isUserFollowed = this.followerUsers.includes(
              this.currentUser?.uid
            );
          }

          this.podsSuggestions$ = this.podService.inviteToPod(this.user.uid);

          if (user.galleryVisibility === undefined) {
            user.galleryVisibility = true;
          }
          if (user.autoTranslate === undefined) {
            user.autoTranslate = true;
          }
          const creator = user.id;
          this.profileService.getProfileGallery(creator);
          this.podStore.getItems({ creator });
          this.poolStore.getItems({ creator });
          this.jobStore.getItems({ creator });
          this.servicesStore.getItems({ creator });
          this.eventStore.getItems({ creator, myContent: true });
          this.secondHandStore.getItems({ creator });
        }
      });
  }

  private listenItemsChanges() {
    this.itemsLoading = true;
    combineLatest([
      this.podStore.selectItems(),
      this.poolStore.selectItems(),
      this.jobStore.selectItems(),
      this.servicesStore.selectItems(),
      this.eventStore.selectItems(),
      this.secondHandStore.selectItems(),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ([pods, pools, offers, searchers, events, secondHandItems]: any[]) => {
          if (this.forbidden) {
            this.pods$.next(
              pods?.filter(
                item =>
                  !!item &&
                  item.status === 'approved' &&
                  (item.creator.id === this.user.uid ||
                    item.participants.includes(this.user.uid))
              )
            );
            this.pools$.next(pools?.filter(item => item.status === 'approved'));
            this.secondHandItems$.next(
              secondHandItems?.filter(
                item => item.status === 'approved' && !item.suspended
              )
            );
            this.offers$.next(
              offers?.filter(
                item => item.status === 'approved' && !item.suspended
              )
            );
            this.searchers$.next(
              searchers?.filter(item => !!item && item.status === 'approved')
            );
          } else {
            const filteredPods = this.podService.userProfilePodsFilter(
              pods,
              this.currentUser?.uid
            );
            this.pods$.next(filteredPods);
            this.pools$.next(pools);
            this.secondHandItems$.next(secondHandItems);
            this.offers$.next(offers);
            this.searchers$.next(
              !!searchers ? searchers.filter(item => !!item) : []
            );
          }
          this.events$.next(
            this.eventsService.eventsFrontFilter(
              events,
              pods,
              this.forbidden,
              this.userId,
              this.currentUser?.uid
            )
          );
          this.itemsLoading = false;
        }
      );
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.currentUser = user;

        this.userBlockList = this.currentUser.blockList;

        if (this.userBlockList) {
          this.isUserBlocked = this.userBlockList.includes(this.userId);
        }
        this.isPremium = this.userService.isPremium;
        this.isAdmin = this.userService.isAdmin;
        this.profileStore.getItemByByValue({ uid: this.currentUser.uid });
        this.forbidden = this.userId !== this.currentUser.uid;
        this.initLabels(this.forbidden);
      });
  }

  private initLabels(forbidden) {
    if (forbidden) {
      this.podsTitle = 'Pods';
      this.galleryTitle = 'Gallery';
      this.poolsTitle = 'Pools';
      this.offersTitle = 'Service Offers';
      this.eventTitle = 'Events';
      this.searchersTitle = 'Service Searches';
      this.secondHandTitle = 'Second Hand';
    } else {
      this.podsTitle = 'My Pods';
      this.galleryTitle = 'My Gallery';
      this.poolsTitle = 'My Pools';
      this.offersTitle = 'My Service Offers';
      this.eventTitle = 'My Events';
      this.searchersTitle = 'My Service Searches';
      this.secondHandTitle = 'My Second Hand';
    }
  }

  getNextPage() {
    this.currentPage.next(this.currentPage.value + 1);
  }

  getPrevPage() {
    this.currentPage.next(this.currentPage.value - 1);
  }

  getLastPage() {
    this.currentPage.next(this.countPages - 1);
  }

  getFirstPage() {
    this.currentPage.next(0);
    this.isOpenShowItems = true;
  }

  listenPagination() {
    this.currentPage.subscribe(page => {
      this.start = 1 + page * this.itemsPerPage;
      this.end = (page + 1) * this.itemsPerPage;
      const length = this.paginatedItems?.length;
      if (this.end > length && length) {
        this.end = length;
      }

      this.isFirstBtnDisabled = !!(this.start === 1);
      this.isPrevBtnDisabled = !!(this.start === 1);
      this.isNextBtnDisabled =
        !!(page === this.countPages - 1) || this.countPages === 0;
      this.isLastBtnDisabled =
        !!(page === this.countPages - 1) || this.countPages === 0;
    });
  }

  inviteToPod() {
    this.showInvitationModal = true;
  }

  async follow() {
    this.loading = true;
    const functions = getFunctions();
    const follow = httpsCallable(functions, 'follow');
    try {
      const res: any = await follow({ id: this.userId });
      this.followingUser = res?.followingUser?.following;
      this.followerUsers = res?.followingUser?.followers;
      if (this.followerUsers) {
        this.isUserFollowed = this.followerUsers.includes(
          this.currentUser?.uid
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async unfollow() {
    this.loading = true;
    const functions = getFunctions();
    const unfollow = httpsCallable(functions, 'unfollow');
    try {
      const res: any = await unfollow({ id: this.userId });
      this.followingUser = res.followingUser?.following;
      this.followerUsers = res.followingUser?.followers;
      if (this.followerUsers) {
        this.isUserFollowed = this.followerUsers?.includes(
          this.currentUser?.uid
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async removeUserFromBlockList() {
    this.loading = true;
    const functions = getFunctions();
    const removeFromBlockList = httpsCallable(functions, 'removeFromBlockList');
    try {
      const res: any = await removeFromBlockList({ id: this.userId });
      this.userBlockList = res.data.blockList.blockList;
      if (this.userBlockList) {
        this.isUserBlocked = this.userBlockList.includes(this.userId);
      }
      createToaster(
        'Successfully removed from block list!',
        'success',
        positionType.BOTTOM
      );
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async addToBlockList() {
    this.loading = true;
    const functions = getFunctions();
    const addToBlockList = httpsCallable(functions, 'addToBlockList');
    try {
      const res: any = await addToBlockList({ id: this.user?.uid });
      this.userBlockList = res.data.blockList.blockList;

      if (this.userBlockList) {
        this.isUserBlocked = this.userBlockList.includes(this.userId);
      }
      createToaster(
        'Successfully added to block list!',
        'success',
        positionType.BOTTOM
      );
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
    if (this.isUserFollowed) {
      this.unfollow();
    }
  }
}
