import { Component, OnDestroy, OnInit } from '@angular/core';
import { IToolbarData } from '../../shared/models/components-data.models';
import { ActivatedRoute } from '@angular/router';
import { EventsStore } from '../events-store';
import { AuthService } from '../../firebase/auth.service';
import { filter, Subject, takeUntil } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { confirmAddingParticipantsModalData } from '../events';
import {
  createToaster,
  positionType,
} from '../../shared/components/modals/toaster';
import { UserService } from 'src/app/auth/user.service';

@Component({
  selector: 'app-add-participants',
  templateUrl: './add-participants.component.html',
  styleUrls: ['./add-participants.component.scss'],
})
export class AddParticipantsComponent implements OnInit, OnDestroy {
  id = this.activeRouter.snapshot.params.id;
  showConfirmModal = false;
  confirmModalData = confirmAddingParticipantsModalData;
  checkedUsers = [];
  users;
  allUsers;
  currentUser;
  creator;
  galleryUrls;
  eventItem;
  isOpen = false;
  isClose = false;
  addButtonDisable = false;

  TOOLBAR_DATA: IToolbarData = {
    arrowRouter: '/events/' + this.id + '/participants',
    btnSlot: 'end',
    title: 'Add participants',
  };
  private destroy$ = new Subject<void>();

  constructor(
    private eventStore: EventsStore,
    private authService: AuthService,
    private activeRouter: ActivatedRoute,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.authService
      .getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.allUsers = data;
        this.users = this.allUsers?.filter(
          users =>
            !this.eventItem?.participants.some(
              participant => users.id === participant.id
            )
        );
      });

    this.getUser();

    this.eventStore.getItemByByValue({
      documentId: this.activeRouter.snapshot.params.id,
    });

    this.listenSecondHandDetails();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addParticipants() {
    const newParticipants = Object.assign({
      ...this.eventItem,
      participants: this.eventItem?.participants.length
        ? [...this.eventItem?.participants, ...this.checkedUsers]
        : [...this.checkedUsers],
    });
    if (newParticipants.participants.length <= this.eventItem.attendeeLimit) {
      this.eventStore.updateProfile(newParticipants);
      this.checkedUsers = [];
      this.showConfirmModal = false;
    } else if (this.eventItem.attendeeLimit === '') {
      this.eventStore.updateProfile(newParticipants);
      this.checkedUsers = [];
      this.showConfirmModal = false;
    } else {
      this.showConfirmModal = false;
      createToaster(
        `You cannot add more than ${
          this.eventItem.attendeeLimit - this.eventItem.participants.length
        } members!`,
        'danger',
        positionType.BOTTOM
      );
    }
  }

  addParticipantsToArray($event, user) {
    let checkedUser = this.checkedUsers.find(
      participant => participant.id === user?.id
    );
    let isUserChecked = !!checkedUser;
    if (!isUserChecked) {
      this.checkedUsers.push({
        id: user.id,
        username: user.username,
        profilePicture: user?.profilePicture,
      });
    } else {
      this.checkedUsers.splice(
        this.checkedUsers.findIndex(user => user === checkedUser),
        1
      );
    }
  }

  discardChanges(e) {
    this.isClose = true;
    this.addParticipants();
    this.showConfirmModal = false;
    this.listenSecondHandDetails();
  }

  closeModal(e) {
    if (e) {
      this.showConfirmModal = false;
    }
  }

  openModal() {
    this.showConfirmModal = true;
  }

  searchUser($event) {
    if ($event.target.value) {
      this.users = this.allUsers.filter(item =>
        item.username.toLowerCase().includes($event.target.value.toLowerCase())
      );
    } else {
      this.users = this.allUsers?.filter(
        obj1 => !this.eventItem?.participants.some(obj2 => obj1.id === obj2.id)
      );
    }
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  private listenSecondHandDetails() {
    this.eventStore
      .selectItem()
      .pipe(
        takeUntil(this.destroy$),
        filter(item => !!item),
        switchMap(item => {
          this.eventItem = item;
          this.users = this.allUsers?.filter(
            user =>
              !this.eventItem?.participants.some(
                participant => participant.id === user.id
              )
          );
          return this.authService.getUserByUid(item.creator?.id);
        })
      )
      .subscribe(user => {
        this.creator = user;
        this.galleryUrls = this.eventItem.images.map(
          pic => `url("${pic}") no-repeat center center / cover`
        );
      });
  }
}
