import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { PlanetService } from '../planet.service';
import { PlanetStore } from '../planet-store';
import { Router } from '@angular/router';
import { FormService } from '../../shared/components/form/form.service';
import { filter, takeUntil } from 'rxjs/operators';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../firebase/auth.service';
import { eventFormData } from '../../events/events';
import { confirmAddingPlanetModalData } from '../planet';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../../environments/environment';
import { ImagesService } from 'src/app/services/images.service';
import { showLoading } from 'src/app/shared/components/modals/loading';

@Component({
  selector: 'app-add-planet-item',
  templateUrl: './add-planet-item.component.html',
  styleUrls: ['./add-planet-item.component.scss'],
})
export class AddPlanetItemComponent implements OnInit, OnDestroy {
  @Output() isClose = new EventEmitter<boolean>(false);
  @ViewChild('imageLoader') imageLoader;
  addPlanetItemForm: FormGroup;
  user;
  addPlanetItemFormDisabled = true;
  addingBtnDisabled = true;
  imageField = eventFormData.filter(i => i.type === 'ARRAY');
  imagesArr: string[];
  form: FormGroup;
  imagesControls = 1;
  newPlanetItem;
  showConfirmModal = false;
  confirmModalData = confirmAddingPlanetModalData;
  properties;
  loader = showLoading();
  private destroy$ = new Subject<void>();
  constructor(
    private formService: FormService,
    private imagesService: ImagesService,
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private planetService: PlanetService,
    private planetStore: PlanetStore
  ) {}

  ngOnInit() {
    mixpanel.init(environment.mixpanelToken, { debug: true });
    const { uid } = this.authService.isUserAuth();
    mixpanel.identify(uid);
    this.getUser();
    this.statusListen();
    this.addPlanetItemForm = this.formBuilder.group({
      images: this.formBuilder.array([
        {
          0: this.formBuilder.group({
            image: [null],
          }),
        },
      ]),
      title: ['', Validators.required],
      description: ['', Validators.required],
    });
    this.listenItemId();

    this.planetStore
      .selectId()
      .pipe(takeUntil(this.destroy$))
      .pipe(filter(id => !!id))
      .subscribe(id => {
        this.isClose.next(true);
        this.planetStore.updateShowFormModal(false);
        this.planetService.isOpenForm.next(true);
      });

    this.imagesService.compressedImages
      .pipe(takeUntil(this.destroy$))
      .subscribe(images => {
        this.imagesArr = images;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.imagesArr = [];
    this.imagesService.reset();
  }

  trackEvent(event, parameter) {
    if (parameter === 'content-type') {
      this.properties = {
        'content-type': 'Daily Challenge',
      };
    }

    mixpanel.track(event, this.properties);
  }

  discardChanges(e) {
    if (e) {
      this.isClose.next(true);
      this.showConfirmModal = false;
    }
  }

  closeModal(e) {
    if (e) {
      this.showConfirmModal = false;
    }
  }

  getFormsControls(): FormArray {
    return <FormArray>this.addPlanetItemForm.controls['images'];
  }

  async addPlanetItem($event: MouseEvent) {
    this.planetStore.setLoading();
    await this.imageLoader.upLoadImages();
    this.imagesService.imageUrls
      .pipe(
        takeUntil(this.destroy$),
        filter(data => !!data?.length)
      )
      .subscribe(urls => {
        this.newPlanetItem = {
          image: urls[0],
          title: this.addPlanetItemForm.get('title').value,
          description: this.addPlanetItemForm.get('description').value,
          status: 'pending',
          creator: {
            id: this.user.id,
            photo: this.user.profilePicture,
            username: this.user.username,
          },
          participants: [],
          experiences: [],
          challengeDate: null,
          createdAt: new Date(),
        };

        this.planetStore.createItem(this.newPlanetItem);
        this.trackEvent('Content Submitted', 'content-type');
      });
  }

  listenItemId() {
    this.planetStore
      .selectId()
      .pipe(filter(id => !!id))
      .subscribe(id => {
        this.isClose.next(true);
        this.planetStore.updateShowFormModal(false);
        this.planetService.isOpenForm.next(true);
        setTimeout(() => {
          this.planetStore.patchState({ createdId: null });
          this.planetStore.getItemByByValue({ documentId: id });
          this.router.navigate(['/planet/challenges/', id]);
          this.planetStore.updateShowPendingModal(true);
        }, 0);
      });
  }

  addPlanetItemFormChanges() {
    this.addPlanetItemFormDisabled =
      this.addPlanetItemForm.status === 'INVALID';
  }

  private getUser() {
    const { uid } = this.authService.isUserAuth();

    this.authService
      .getUserByUid(uid)
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user)
      )
      .subscribe(user => (this.user = user));
  }

  statusListen() {
    this.planetStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => {
        loading
          ? this.loader.then(data => data.present())
          : this.loader.then(data => data.dismiss());
      });
  }
}
