import { Component } from '@angular/core';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-auth-common',
  template: '<ion-router-outlet></ion-router-outlet>',
})
export class AuthCommonComponent {
  constructor() {}
}
