import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import mixpanel from 'mixpanel-browser';
import { combineLatest, filter, map, Subject, take, takeUntil } from 'rxjs';
import { FormService } from '../../shared/components/form/form.service';
import { AuthService } from '../../firebase/auth.service';
import { EventsStore } from '../events-store';
import { EventsService } from '../events.service';
import { confirmAddingEventModalData, EventItem } from '../events';
import { showLoading } from '../../shared/components/modals/loading';
import { IPod } from 'src/app/shared/models/item.models';
import { PodStore } from 'src/app/merfolk/pods/pod-store';
import { environment } from '../../../environments/environment';
import { UserService } from 'src/app/auth/user.service';
import { ImagesService } from 'src/app/services/images.service';

@Component({
  selector: 'app-add-events',
  templateUrl: './add-events.component.html',
  styleUrls: ['./add-events.component.scss'],
})
export class AddEventsComponent implements OnInit, OnDestroy {
  @Input() pod: IPod;
  @Input() events: EventItem[];
  @Output() isClose = new EventEmitter<boolean>(false);
  @ViewChild('eventFormComponent') eventFormComponent;
  podConnected;
  eventForm: FormGroup;
  loader = showLoading();
  isOpen = false;
  isOnline = false;
  currentUser;
  _status: string;
  // Getter and setter for status to control modal height when loading
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
    // When status changes to loading, resize the modal to fit the skeleton
    if (value === 'loading') {
      this.adjustModalSize(true);
    } else {
      this.adjustModalSize(false);
    }
  }
  newEventItem: EventItem;
  item = { icon: 'calendar-outline', title: 'Create an Event' };
  pods: IPod[];
  showSuccessModal = false;
  showConfirmModal = false;
  confirmModalData = confirmAddingEventModalData;
  imagesArr: string[];
  disabled = true;
  properties;
  private destroy$ = new Subject<void>();

  constructor(
    private formService: FormService,
    private imagesService: ImagesService,
    private router: Router,
    private podStore: PodStore,
    private authService: AuthService,
    private eventsService: EventsService,
    private eventsStore: EventsStore,
    private userService: UserService
  ) {}

  ngOnInit() {
    mixpanel.init(environment.mixpanelToken, { debug: true });

    this.disabled = true;
    this.eventsStore
      .selectId()
      .pipe(takeUntil(this.destroy$))
      .pipe(filter(id => !!id))
      .subscribe(id => {
        this.isClose.next(true);
        this.eventsStore.updateShowFormModal(false);
        this.eventsService.isOpenForm.next(true);
      });
    this.podStore.getItems({});
    this.listenPodsChanges();
    this.listenStatusChanges();
    this.listenItemId();
    this.getUser();
    this.eventsService.isOpenForm.subscribe(
      isOpen => (this.showSuccessModal = isOpen)
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.eventsService.isOpenForm.subscribe(
      isOpen => (this.showSuccessModal = false)
    );
  }

  trackEvent(event, parameter, eventData) {
    if (parameter === 'content-type') {
      this.properties = {
        'content-type': 'Event',
      };
      mixpanel.track(event, this.properties);
    } else if (parameter === 'events') {
      const { uid } = this.authService.isUserAuth();
      let myEvents = this.eventsService.getMyItems('events', uid);
      myEvents.pipe(take(1)).subscribe(data => {
        let eventsIdArray = [];
        data.forEach((event: any) => {
          eventsIdArray.push(event.id);
        });
        mixpanel.people.set({ '$event-id': [...eventsIdArray] });
      });
    }
  }

  handleOk(e) {
    e ? this.eventsStore.updateShowPendingModal(false) : null;
  }

  handleCancel(e) {
    e ? this.eventsStore.updateShowPendingModal(false) : null;
  }

  close(e) {
    // Only show confirm modal if not in loading state
    e && this.status !== 'loading' ? (this.showConfirmModal = true) : null;
  }

  discardChanges(e) {
    if (e) {
      this.isClose.next(true);
      this.showConfirmModal = false;
    }
  }

  closeModal(e) {
    if (e) {
      this.showConfirmModal = false;
    }
  }

  async addEventItem(e) {
    if (this.eventForm) {
      // Set status to loading to show the skeleton for all event types
      this.status = 'loading';

      // Dismiss any existing loader to avoid conflicts
      try {
        (await this.loader).dismiss().catch(() => {});
      } catch (error) {
        // Ignore errors from dismissing
      }

      // Store original disabled state to restore later if needed
      const originalDisabledState = this.disabled;

      try {
        await this.eventFormComponent.imageLoader.upLoadImages();
        this.imagesService.imageUrls
          .pipe(
            filter(data => !!data),
            takeUntil(this.destroy$)
          )
          .subscribe(
            async urls => {
              if (!urls.length) {
                // Reset status if no images
                this.status = null;
                this.disabled = originalDisabledState;
                return;
              }
              const startDate = this.eventForm.get('startDate').value;
              const endDate = this.eventForm.get('endDate').value;
              const showTimeFields = this.eventFormComponent.showTimeFields;
              const eventType = this.eventForm.get('eventType').value;

              // Ensure status remains 'loading' regardless of event type

              // Handle dates based on whether time fields are enabled
              const processDate = dateValue => {
                if (!dateValue) return null;

                // If time fields are disabled, store only the date part as a string
                if (!showTimeFields) {
                  const date = new Date(dateValue);
                  return date.toISOString().split('T')[0]; // Returns YYYY-MM-DD
                }

                // If time fields are enabled, store as a Date object
                return new Date(dateValue);
              };

              this.newEventItem = {
                ...this.eventForm?.value,
                creator: { id: this.currentUser.id },
                participants: [
                  {
                    id: this.currentUser?.id,
                    profilePicture: this.currentUser?.profilePicture,
                    username: this.currentUser?.username,
                  },
                ],
                location: this.eventForm?.value.online
                  ? Object.assign({
                      ...this.eventForm?.value.location,
                      country: { region: 'online' },
                    })
                  : this.eventForm?.value.location,
                startDate: processDate(startDate),
                endDate: processDate(endDate),
                createdAt: new Date(),
                price:
                  Object.keys(this.eventForm?.value.price).length === 0
                    ? { value: 0 }
                    : this.eventForm?.value.price,
              };

              this.newEventItem.images = [...urls].filter(image => !!image);
              this.formService.itemGallery.next([]);
              this.eventsStore.createItem(this.newEventItem);
              const { title, podName } = this.newEventItem;
              if (podName) {
                this.eventsStore.getItemByByValue({ title });
                this.eventsStore
                  .selectItem()
                  .pipe(
                    filter(item => !!item),
                    take(1),
                    takeUntil(this.destroy$)
                  )
                  .subscribe(item => {
                    this.newEventItem.id = item.id;

                    let currentPod = this.pods.find(
                      item => item.id === podName
                    );

                    currentPod.events.push(this.newEventItem.id);
                    this.podStore.updateProfile(currentPod);
                  });
              }
              this.trackEvent('Content Submitted', 'content-type', '');
              this.trackEvent('', 'events', '');
              (await this.loader).dismiss();
              // Reset status after operation completes
              this.status = null;

              // Close the modal immediately instead of waiting for the success modal
              this.isClose.next(true);

              // Show success modal after closing the form
              this.eventsService.isOpenForm.next(true);
              this.discardChanges(true);
            },
            error => {
              // Reset status if there's an error
              this.status = null;
              this.disabled = originalDisabledState;
            }
          );
      } catch (error) {
        // Reset status if there's an error
        this.status = null;
        this.disabled = originalDisabledState;
      }
    } else {
      this.disabled = true;
    }
  }

  listenItemId() {
    this.eventsStore
      .selectId()
      .pipe(filter(id => !!id))
      .subscribe(id => {
        this.isClose.next(true);
        this.eventsStore.updateShowFormModal(false);
        this.eventsService.isOpenForm.next(true);
        this.eventsStore.updateShowPendingModal(true);
        setTimeout(() => {
          this.eventsStore.patchState({ createdId: null });
          // We don't want to navigate to the event details page after creation
          // just show the congrats modal
        }, 0);
      });
  }

  private listenPodsChanges() {
    combineLatest([this.podStore.selectItems()])
      .pipe(
        takeUntil(this.destroy$),
        map(
          ([items]) =>
            items &&
            items.filter(
              item =>
                (item.participants.includes(this.currentUser?.uid) &&
                  item.memberRights === 'everyone can edit/delete') ||
                item.creator?.id === this.currentUser?.uid
            )
        )
      )
      .subscribe(items => {
        this.pods = items;
      });
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.currentUser = user;

        mixpanel.identify(user.uid);
      });
  }

  private listenStatusChanges() {
    this.eventsStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async loading => {
        // Only show/dismiss the loader if we're not already in a loading state
        // This prevents the loader from interfering with the skeleton loading
        if (this.status !== 'loading') {
          loading
            ? (await this.loader).present()
            : (await this.loader).dismiss();
        }
      });
  }

  // Method to adjust modal size when loading skeleton
  private adjustModalSize(isLoading: boolean) {
    // Get the modal element
    const modal = document.querySelector('ion-modal');
    if (modal) {
      if (isLoading) {
        // When loading, set a fixed height to the modal to avoid scrolling
        modal.style.setProperty('--height', 'auto');
        modal.style.setProperty('--max-height', '80vh');
        modal.style.setProperty('--width', '90%');
        modal.style.setProperty('--max-width', '400px');
        modal.style.setProperty('overflow', 'hidden');
      } else {
        // When not loading, revert to original size
        modal.style.removeProperty('--height');
        modal.style.removeProperty('--max-height');
        modal.style.removeProperty('--width');
        modal.style.removeProperty('--max-width');
        modal.style.removeProperty('overflow');
      }
    }

    // Also adjust item-form container if it exists
    const formContainer = document.querySelector('.form-container');
    if (formContainer && isLoading) {
      formContainer.classList.add('compact-loading');
    } else if (formContainer) {
      formContainer.classList.remove('compact-loading');
    }
  }
}

