import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { format, utcToZonedTime } from 'date-fns-tz';
import { BehaviorSubject, Subject, finalize } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { countries } from 'src/app/auth/sign-up/countries';
import { ICountry } from 'src/app/auth/sign-up/sign-up.component';
import { FormField } from './form';
import { FormService } from './form.service';
import { AuthService } from 'src/app/firebase/auth.service';
import { IonModal } from '@ionic/angular';
import { StorageHelper } from 'src/app/firebase/helpers/storageHelper';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit, OnDestroy {
  @Input() formData: FormField[];
  @Input() buttons: string;
  @Output() formGroupChange = new EventEmitter<FormGroup>();
  @ViewChild(IonModal) modal: IonModal;
  @ViewChild('placesRef', { static: false }) placesRef: GooglePlaceDirective;
  @ViewChild('imageLoader') imageLoader;
  map;
  currentUsers$;
  selectedLocation$ = new BehaviorSubject('');
  isOpen = false;
  initialValues;
  data: FormField[];
  form: FormGroup;
  date: 'date';
  today = new Date().toISOString();
  max = '2025-01-01T23:59:59';
  zonedEventTime: Date;
  userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  zonedTime = utcToZonedTime(this.today, this.userTimeZone);
  zonedMaxTime = utcToZonedTime(this.max, this.userTimeZone);
  maxData = format(this.zonedMaxTime, 'yyyy-MM-dd HH:mm:ssXXX', {
    timeZone: this.userTimeZone,
  });
  formattedDate: string;
  fields;
  countries$ = new BehaviorSubject<ICountry[]>([]);
  countries = countries;
  fieldsConditions: string[];
  fileName: string;
  spinner: boolean = false;
  maxSize = false;
  fileArray = [];
  imagesArr: string[] = [];
  imagesControls = 1;
  addingBtnDisabled = true;

  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private service: FormService,
    private authService: AuthService,
    private storageHelper: StorageHelper
  ) {
    this.service.formData$
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => (this.fields = data));
    this.service.formInitialValues.subscribe(
      data => (this.initialValues = data)
    );
  }

  ngOnInit() {
    this.service.formData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.fields = data;
    });
    this.form = this.fb.group({});
    this.createControls(this.fields);
    this.data = this.formData;
    this.formattedDate = format(this.zonedTime, 'yyyy-MM-dd HH:mm:ssXXX');
    this.formatDateValues();
    this.formGroupChange.emit(this.form);
    this.countries$.next(countries);
    this.service.fieldsConditions
      .pipe(takeUntil(this.destroy$))
      .subscribe(conditions => (this.fieldsConditions = conditions));
    const { uid } = this.authService.isUserAuth();
    this.authService
      .getUserByUid(uid)
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (data: any) => {
        this.currentUsers$ = data;
      });
    this.service.itemGallery.subscribe(images => {
      this.imagesArr = images;
      this.addingBtnDisabled = !!(images.length < this.imagesControls);
    });
  }

  ngOnDestroy() {
    this.map ? this.map.destroy() : null;
    this.map ? (this.map = undefined) : null;
    this.destroy$.next();
    this.destroy$.complete();
  }

  createControls(controls: Array<FormField>) {
    for (let control of controls) {
      if (control.type === 'ARRAY') {
        if (control.name !== 'joinMechanismArr') {
          this.service.addArrayControl(this.form, control);
        }
      } else if (control.type === 'GROUP') {
        const newGroup = new FormGroup({});

        control.children.map(child => {
          if (child.type === 'GROUP') {
            const newSubGroup = new FormGroup({});

            child.children.map(subChild => {
              const newSubControl = new FormControl(
                subChild.defaultValue ?? null,
                child.required ? Validators.required : null
              );
              newSubGroup.addControl(subChild.name, newSubControl);
            });
            newGroup.addControl(child.name, newSubGroup);
            this.form.addControl(control.name, newGroup);
          } else {
            const newControl = new FormControl(
              child.defaultValue ?? null,
              child.required ? Validators.required : null
            );
            newGroup.addControl(child.name, newControl);
            this.form.addControl(control.name, newGroup);
          }
        });
      } else if (control.type === 'CHECKBOX') {
        control.options.map(item => {
          const newFormControl = new FormControl(
            false,
            control.required ? Validators.required : null
          );
          this.form.addControl(item.name, newFormControl);
        });
      } else {
        const newFormControl = new FormControl(
          control.defaultValue || null,
          control.required ? Validators.required : null
        );
        this.form.addControl(control.name, newFormControl);
      }
    }
  }

  addArrayGroup(arrayName, child) {
    const control = this.getFormArray(arrayName);
    this.imagesControls = control.controls.length;

    if (control.controls.length == this.imagesArr.length) {
      this.addingBtnDisabled = !!(this.imagesArr.length == this.imagesControls);
    }
    const group = new FormGroup({});
    group.addControl(child.name, new FormControl());
    control.push(group);
  }

  removeControl(i) {
    const control = this.getFormArray('joinMechanismArr');
    control.removeAt(i);
    this.imagesControls = control.length;
  }

  removeImage(i) {
    const control = this.getFormArray('images');
    if (control) {
      this.addingBtnDisabled = !!(
        this.imagesArr.length == control.controls.length
      );
      control.removeAt(i);
    } else {
      this.imagesArr = [];
    }
  }

  removeImageItem(e, i) {
    if (e) {
      const newArr = this.imagesArr;
      newArr.splice(i, 1);
      this.service.itemGallery.next(newArr);
      this.removeImage(i);
    }
  }

  getFormArray(name) {
    return <FormArray>this.form.controls[name];
  }

  addMapLink(e) {
    e.valueChanges.subscribe(() => {
      this.form.patchValue({
        googleMapLink: e.get('googleMapLink').value,
      });
    });
  }
  getRandomId() {
    return (Math.random() + 1).toString(36).substring(7);
  }

  async uploadPicture(initFile) {
    if (initFile.target.files[0].size > 2000000) {
      this.maxSize = true;
      console.log('File size should not exceed 6 mb');
    } else {
      this.maxSize = false;
      this.spinner = true;
      this.service.loadingImage.next(true);
      this.fileName = initFile.target.files[0].name;
      this.storageHelper.uploadPicture(initFile).then(file => {
        file.task
          .snapshotChanges()
          .pipe(
            takeUntil(this.destroy$),
            finalize(() => {
              file.fileRef
                .getDownloadURL()
                .pipe(takeUntil(this.destroy$))
                .subscribe(url => {
                  this.service.currentPicture.next(url);
                  this.service.loadingImage.next(false);
                  this.spinner = false;
                });
            })
          )
          .subscribe();
      });
    }
  }

  resetFilter() {
    this.form.reset();
    this.service.resetFilter();
  }

  applyFilter() {
    this.service.applyFilter(this.form.value);
  }

  formatPorts(countries) {
    if (Array.isArray(countries)) {
      return countries.map(country => country.name).join(', ');
    } else return countries.name;
  }

  changeMyContentToggle($event) {
    if (this.form.get('myContent').value) {
      this.applyFilter();
    } else {
      this.resetFilter();
    }
  }

  private formatDateValues() {
    if (this.form.get('date')) {
      const dateControl = this.form.get('date').value;
    }
  }
}
