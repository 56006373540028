import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { EventsDetailsComponent } from './events-details/events-details.component';
import { EventsComponent } from './events.component';
import { SharedModule } from '../shared/shared.module';
import { EventsRoutingModule } from './events-routing.module';
import { AddEventsComponent } from './add-events/add-events.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IonicSelectableModule } from 'ionic-selectable';
import { EventFilterComponent } from './event-filter/event-filter.component';
import { EventSearchComponent } from './event-search/event-search.component';
import { PipesModule } from '../pipes/pipes.module';
import { EventItemComponent } from './event-item/event-item.component';
import { EditEventsComponent } from './edit-events/edit-events.component';
import { EventParticipantsComponent } from './event-participants/event-participants.component';
import { AddParticipantsComponent } from './add-participants/add-participants.component';
import { EventParticipantsItemComponent } from './event-participants-item/event-participants-item.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { EventFormComponent } from './event-form/event-form.component';
import { CommunityPageModule } from '../merfolk/community/community.module';
import { AiEventsComponent } from './ai-events/ai-events.component';
import { EventSearchService } from '../services/event-search.service';

@NgModule({
  declarations: [
    EventsDetailsComponent,
    EventsComponent,
    AddEventsComponent,
    EventFilterComponent,
    EventSearchComponent,
    EventItemComponent,
    EditEventsComponent,
    EventParticipantsComponent,
    AddParticipantsComponent,
    EventParticipantsItemComponent,
    EventFormComponent,
    AiEventsComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    EventsRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    IonicSelectableModule,
    GoogleMapsModule,
    GooglePlaceModule,
    PipesModule,
    HttpClientModule,
    HttpClientJsonpModule,
    CommunityPageModule,
  ],
  providers: [EventSearchService],
  exports: [EventItemComponent, AddEventsComponent],
})
export class EventsModule {}

