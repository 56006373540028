import { FormField, FormFieldTypes } from 'src/app/shared/components/form/form';

export enum AcceptingMember {
  AUTO = 'auto',
  REQUEST = 'request',
}

export enum MemberRights {
  ALL = 'all',
  CREATOR = 'creator',
}

export enum ApprovingMember {
  ALL = 'all',
  CREATOR = 'creator',
}

export const podsFormMenuData: FormField[] = [
  {
    name: 'myContent',
    type: FormFieldTypes.TOGGLE,
    label: 'See my content only',
    required: false,
    icon: 'person',
    line: 'full',
  },
  {
    name: 'country',
    type: FormFieldTypes.SELECT,
    label: 'Country',
    multiple: true,
    required: false,
    icon: 'location',
  },
  {
    name: 'approval',
    type: FormFieldTypes.TOGGLE,
    label: 'Automatic approval only',
    required: false,
    icon: 'checkmark-circle',
    line: 'full',
  },
];

export const podsFormData: FormField[] = [
  {
    name: 'name',
    type: FormFieldTypes.TEXT_INPUT,
    label: 'Name*',
    required: true,
    icon: 'person',
  },
  {
    name: 'image',
    type: FormFieldTypes.FILE,
    label: 'Pod Profile image',
    required: false,
    icon: 'image',
  },
  {
    id: 'open-modal',
    name: 'googleMapLink',
    type: FormFieldTypes.TEXT_INPUT,
    label: 'Google Maps link*',
    required: true,
    disabled: true,
    icon: 'navigate',
  },
  {
    name: 'description',
    type: FormFieldTypes.TEXT_AREA,
    label: 'Description*',
    required: true,
    icon: 'clipboard',
  },
  {
    name: 'memberRights',
    type: FormFieldTypes.RADIO,
    label: 'Member rights*',
    defaultValue: 'everyone can edit/delete',
    options: ['everyone can edit/delete', 'only Pod creator can edit/delete'],
  },
  {
    name: 'joinMechanism',
    type: FormFieldTypes.RADIO,
    label: 'Join Mechanism*',

    defaultValue: 'automatic',
    options: ['automatic', 'request based'],
  },
  {
    name: 'approveMembers',
    type: FormFieldTypes.RADIO,
    label: 'Approving Members*',
    defaultValue: 'everyone can approve',
    if: 'automatic',

    options: ['everyone can approve', 'only Pod creator can approve'],
  },
  {
    name: 'joinMechanismArr',
    type: FormFieldTypes.ARRAY,
    icon: 'help',
    label: 'joinMechanismArr',
    if: 'automatic',
    children: [
      {
        name: 'memberQuestion',
        type: FormFieldTypes.TEXT_INPUT,
        label: 'Member Question',
        required: false,
        icon: 'help',
      },
    ],
    template: {
      name: 'memberQuestion',
      type: FormFieldTypes.TEXT_INPUT,
      label: 'Member Question',
      required: false,
      icon: 'help',
    },
  },
];

export const successPodsModalData = {
  title: 'Thanks for adding content!',
  pic: 'some sea animal with magnifying glass',
  description:
    "You'll get rewarded as soon as your content has been verified by 3 moderators.",
  okBtn: {
    title: 'CONTINUE',
  },
  cancelBtn: {
    title: 'SPLASH MERFRIENDS',
  },
};

export const confirmModalData = {
  title: 'Add a Pod',
  icon: 'radio-button-on',
  okBtn: {
    title: 'YES, DISCARD',
  },
  cancelBtn: { title: 'NO, GO BACK' },
};

export const confirmDeletingModalData = {
  title: 'Deleting Pod?',
  icon: 'radio-button-on',
  description:
    "Are you sure you want to delete the Pod? That way you'll delete its events and pictures for every former Pod member.",
  okBtn: {
    title: 'YES, DELETE',
  },
  cancelBtn: { title: 'NO, KEEP' },
};

export const confirmInvitationModalData = {
  title: 'Deleting the invitation to the Pod?',
  icon: 'radio-button-on',
  description: 'Are you sure you want to delete the invitation to the Pod?',
  okBtn: {
    title: 'YES, DELETE',
  },
  cancelBtn: { title: 'NO, KEEP' },
};
export const confirmForDelete = {
  title: 'Delete a Pod picture',
  icon: 'image',
  okBtn: {
    title: 'YES, DELETE',
  },
  cancelBtn: { title: 'NO, KEEP' },
};

export const confirmForAnswers = {
  title: 'Users answers',
  icon: 'radio-button-on',
  okBtn: {
    title: 'Approve',
  },
  cancelBtn: { title: 'Block' },
};

export const invitationModalData = {
  title: 'Invite to pod',
  iconPath: 'radio-button-on',
  okBtn: {
    title: 'No, Discard',
  },
  cancelBtn: {
    title: 'Yes, Invite',
  },
};

export const invitationEmptyModalData = {
  title: 'Invite to pod',
  icon: 'radio-button-on',
  okBtn: {
    title: 'Understood',
  },
};

export const successInviteModalData = {
  title: 'Congrats! You just sent out an invitation to join your Pod!',
  pic: 'some sea animal with magnifying glass',
  description:
    "That's it - the more the merrier! :) Your MerFriend(s) will receive a notification about your invite and will then be able to (request to) join your Pod.",
  okBtn: {
    title: 'CONTINUE',
  },
  cancelBtn: {
    title: 'SPLASH MERFRIENDS',
  },
};
export const confirmEditingModalData = {
  title: 'Edit a Pod',
  icon: 'radio-button-on',
  okBtn: {
    title: 'YES, DISCARD',
  },
  cancelBtn: {
    title: 'NO, GO BACK',
  },
};
