import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(value: any[], status: string, user: string): any[] {
    if (!Array.isArray(value) || !value.length) {
      return [];
    }

    // Filter by status and user ID
    return value.filter(item => {
      if (status === 'pending') {
        // For pending, include both 'pending' and 'pending-repeat' statuses
        return (
          ['pending', 'pending-repeat'].includes(item.status) &&
          item.creator?.id === user
        );
      } else {
        // For other statuses (approved, rejected), match exactly
        return item.status === status && item.creator?.id === user;
      }
    });
  }
}

