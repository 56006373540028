import { Injectable } from '@angular/core';
import {
  DataUrl,
  NgxImageCompressService,
  UploadResponse,
} from 'ngx-image-compress';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImagesService {
  compressionLoading = new BehaviorSubject<boolean>(false);
  imagesLoading = new BehaviorSubject<boolean>(false);
  compressedImages = new BehaviorSubject<any[]>([]);
  isPortraits = new BehaviorSubject<boolean[]>([]);
  addImageToGalleryModalIsOpen = new BehaviorSubject<boolean>(false);
  imageUrls = new BehaviorSubject<any[]>(null);

  constructor(private imageCompress: NgxImageCompressService) {}

  getImageDimensions(base64Image) {
    return new Promise((resolve, reject) => {
      const img = new Image();

      // Handle the load event to get dimensions
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };

      // Handle errors
      img.onerror = err => {
        reject(err);
      };

      // Set the base64 string as the source
      img.src = base64Image;
    });
  }

  getImageSize(image) {
    const base64Image = image;
    const yourBase64String = base64Image.substring(
      base64Image.indexOf(',') + 1
    );
    // const bits = yourBase64String.length * 6;
    // const bytes = bits / 8;
    // const kb1 = Math.ceil(bytes / 1000);
    const kb = Math.ceil((yourBase64String.length * 6) / 8 / 1000);
    return kb;
  }

  dataURItoBlob(dataURI: DataUrl, name: string) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new File([new Blob([ab], { type: mimeString })], name);
  }

  async compressMultiplyImages(isMobile: boolean) {
    this.compressionLoading.next(true);
    const imgs = [];
    const portraits = [];
    const multipleOrientedFiles: UploadResponse[] =
      (await this.imageCompress.uploadMultipleFiles()) || [];

    if (!multipleOrientedFiles.length) {
      return;
    }
    multipleOrientedFiles.map(async (image, index) => {
      const quality = this.getImageSize(image.image) > 500 ? 50 : 100;
      const dimension: any = await this.getImageDimensions(image.image);
      let data;
      if (isMobile) {
        data = await this.imageCompress.compressFile(
          image.image,
          image.orientation,
          quality,
          quality,
          1080,
          1080
        );
      } else {
        data = await this.imageCompress.compressFile(
          image.image,
          image.orientation,
          quality,
          quality,
          1920,
          1920
        );
      }
      const file = this.dataURItoBlob(data, image.fileName);
      imgs.push(file);
      portraits.push(dimension.height > dimension.width);
      if (index === multipleOrientedFiles.length - 1) {
        this.isPortraits.next([...this.isPortraits.value, ...portraits]);
        this.compressedImages.next([...this.compressedImages.value, ...imgs]);
        this.compressionLoading.next(false);
      }
    });
  }

  async compressImage(isMobile: boolean) {
    const file: UploadResponse = await this.imageCompress.uploadFile();
    if (!file) return;
    this.compressedImages.next([]);
    this.compressionLoading.next(true);
    const quality = this.getImageSize(file.image) > 500 ? 50 : 100;
    let result;
    if (isMobile) {
      result = await this.imageCompress.compressFile(
        file.image,
        file.orientation,
        quality,
        quality,
        1080,
        1080
      );
    } else {
      result = await this.imageCompress.compressFile(
        file.image,
        file.orientation,
        quality,
        quality,
        1920,
        1920
      );
    }
    const fileResult = this.dataURItoBlob(result, file.fileName);
    this.compressedImages.next([fileResult]);
    this.compressionLoading.next(false);
  }

  reset() {
    this.compressedImages.next([]);
    this.imageUrls.next([]);
    this.imagesLoading.next(false);
    this.compressionLoading.next(false);
  }
}
