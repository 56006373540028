export const travelingQuotes = [
  {
    quote:
      '“I have a new respect for the mermaids… because it’s very tough what they do.”',
    author: 'Gaz Coombes',
  },

  {
    quote:
      '“The ocean makes me feel really small and it makes me put my whole life into perspective.”',
    author: 'Beyoncé Knowles',
  },
  {
    quote:
      '“She was born to be free, let her run wild in her own way and you will never lose her.”',
    author: 'Nikki Rowe',
  },
  {
    quote: '“She loved mysteries so much that she became one.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea does not reward those who are too anxious, too greedy, or too impatient. One should lie empty, open, choiceless as a beach–waiting for a gift from the sea.”',
    author: 'Anne Morrow Lindbergh',
  },
  {
    quote: '“The ocean is a mighty harmonist”',
    author: 'William Wordsworth',
  },
  {
    quote:
      '“The ocean is a place of skin, rich outer membranes hiding thick juicy insides, laden with the soup of being”',
    author: 'Vernor Vinge',
  },
  {
    quote: '“She is a mermaid on dry land and the world is her ocean”',
    author: 'J. Iron Word',
  },
  {
    quote:
      '“The sea beckoned to me, and all reality was lost-swept away in the entrancing song of the tide.”',
    author: 'Meredith T. Taylor',
  },
  {
    quote:
      '“If you are curious you might never come home, like all the men who now live with mermaids at the bottom of the sea.”',
    author: 'Jeanette Winterson',
  },
  {
    quote:
      '“Mermaid tears were treasure, meant to be guarded close to one’s heart. They brought wishes of true love and kept you safe.”',
    author: 'Kerry Lonsdale',
  },
  {
    quote: '“Where there’s water, we find mermaids.”',
    author: 'Mira Grant',
  },
  {
    quote:
      'The difference between a Miracle and a Fact is exactly the difference between a mermaid and a seal.”',
    author: 'Mark Twain',
  },
  {
    quote:
      '“The sea, once it casts its spell, holds one in its net of wonder forever.” ',
    author: 'Jacques Yves Cousteau',
  },
  {
    quote:
      '“You are not a drop in the ocean. You are the entire ocean in a drop.”',
    author: 'Rumi',
  },
  {
    quote:
      '“She is a mermaid, but approach her with caution. Her mind swims at a depth most would drown in.”',
    author: 'J. Iron Word',
  },
  {
    quote:
      '“The ocean is everything I want to be. Beautiful, mysterious, wild, and free.”',
    author: 'Unknown',
  },
  {
    quote:
      '“I must be a mermaid, Rango. I have no fear of depths and a great fear of shallow living.”',
    author: 'Anaïs Nin',
  },
  {
    quote: '“The cure for anything is salt water: sweat, tears or the sea.”',
    author: 'Isak Dinesen',
  },
  {
    quote:
      '“The ocean stirs the heart, inspires the imagination and brings eternal joy to the soul.”',
    author: 'Robert Wyland',
  },
  {
    quote: '“Mermaids don’t lose sleep over the opinion of shrimp.”',
    author: 'Unknown',
  },
  {
    quote:
      '“You can either see yourself as a wave in the ocean or you can see yourself as the ocean.”',
    author: 'Oprah Winfrey1',
  },
  {
    quote: '“Wild and free, just like the sea.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is emotion incarnate. It loves, hates, and weeps. It defies all attempts to capture it with words and rejects all shackles. No matter what you say about it, there is always that which you can’t.”',
    author: 'Christopher Paolini',
  },
  {
    quote:
      '“The ocean is more ancient than the mountains and freighted with the memories and the dreams of Time.”',
    author: 'H.P. Lovecraft',
  },
  {
    quote: '“The voice of the sea speaks to the soul.”',
    author: 'Kate Chopin',
  },
  {
    quote:
      '“The ocean has a life of its own. Its tides, whirlpools, currents and eddies are a testament to its conflicting emotions.”',
    author: 'Anthony T. Hincks',
  },
  {
    quote: '“The sea is as near as we come to another world.”',
    author: 'Anne Stevenson',
  },
  {
    quote:
      '“You must not lose faith in humanity. Humanity is like an ocean; if a few drops of the ocean are dirty, the ocean does not become dirty.”',
    author: 'Mahatma Gandhi',
  },
  {
    quote:
      '“The sea is everything. It covers seven tenths of the terrestrial globe. Its breath is pure and healthy. It is an immense desert, where man is never lonely, for he feels life stirring on all sides.”',
    author: 'Jules Verne',
  },
  {
    quote: '“The sea lives in every one of us.”',
    author: 'Robert Wyland',
  },
  {
    quote: '“I am not afraid of storms for I am learning how to sail my ship.”',
    author: 'Louisa May Alcott',
  },
  {
    quote: '“We dream in colors borrowed from the sea.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The ocean is a central image. It is the symbolism of a great journey.”',
    author: 'Enya',
  },
  {
    quote:
      '“I wonder if the ocean smells different on the other side of the world.”',
    author: 'J.A. Redmerski',
  },
  {
    quote: '“The waves of the sea help me get back to me.”',
    author: 'Jill Davis',
  },
  {
    quote:
      '“There is nothing more enticing, disenchanting, and enslaving than the life at sea.”',
    author: 'Joseph Conrad',
  },
  {
    quote: '“I want to be wild, beautiful and free just like the sea.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The ocean was magical to her, its depths and mysteries were boundless, its call irresistible.” ',
    author: 'Jeff Mariotte',
  },
  {
    quote:
      '“In one drop of water are found all the secrets of all the oceans; in one aspect of You are found all the aspects of existence.”',
    author: 'Kahlil Gibran',
  },
  {
    quote:
      '“You never really know what’s coming. A small wave, or maybe a big one. All you can really do is hope that when it comes, you can surf over it, instead of drown in its monstrosity.”',
    author: 'Alysha Speer',
  },
  {
    quote:
      '“To me, the sea is like a person–like a child that I’ve known a long time. It sounds crazy, I know, but when I swim in the sea I talk to it. I never feel alone when I’m out there.”',
    author: 'Gertrude Ederle',
  },
  {
    quote: '“The ocean was my first love story.”',
    author: 'Michelle K.',
  },
  {
    quote: '“If there is magic on this planet, it is contained in water.”',
    author: 'Loren Eiseley',
  },
  {
    quote: '“Live in the sunshine, swim in the sea, drink in the wild air.”',
    author: 'Ralph Waldo Emerson',
  },
  {
    quote:
      '“We know only too well that what we are doing is nothing more than a drop in the ocean. But if the drop were not there, the ocean would be missing something.”',
    author: 'Mother Teresa',
  },
  {
    quote: '“I need vitamin SEA”',
    author: 'Unknown',
  },
  {
    quote:
      '“You can’t cross the sea merely by standing and staring at the water” ',
    author: 'Rabindranath Tagore',
  },
  {
    quote:
      '“The ocean has always been a salve to my soul…the best thing for a cut or abrasion was to go swimming in salt water. Later down the road of life, I made the discovery that salt water was also good for the mental abrasions one inevitably acquires on land.”',
    author: 'Jimmy Buffett',
  },
  {
    quote:
      '“I’m always happy when I’m surrounded by water, I think I’m a Mermaid or I was a mermaid.”',
    author: 'Beyoncé Knowles-Carter',
  },
  {
    quote: '“I believe in mermaids because they believe in me.”',
    author: 'Unknown',
  },
  {
    quote: '“The ocean is a poem without words.”',
    author: 'Horace',
  },
  {
    quote: '“The sea is a desert of waves, A wilderness of water.”',
    author: 'Langston Hughes',
  },
  {
    quote: '“The sea is not a bargain basement.”',
    author: 'Jacques Yves Cousteau',
  },
  {
    quote:
      '“The sea is only the embodiment of a supernatural and wonderful existence.”',
    author: 'Jules Verne',
  },
  {
    quote: '“The sea is as near as we come to another world.”',
    author: 'Anne Stevenson',
  },
  {
    quote:
      '“The sea is everything. It covers seven tenths of the terrestrial globe. Its breath is pure and healthy. It is an immense desert, where man is never lonely, for he feels life stirring on all sides.”',
    author: 'Jules Verne',
  },
  {
    quote: '“The sea is a big blue heart that beats in sync with mine.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is my brother, my sister, my mother, my father. The sea is my home.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is a symphony of sounds, colors, and movements. It is a source of inspiration, wonder, and joy.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is a mirror of the sky. It reflects the mood, the beauty, and the mystery of the heavens.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is a place of mystery and magic, where anything can happen and dreams can come true.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is a friend that never betrays you. It listens to your secrets, comforts your sorrows, and celebrates your joys.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is a teacher that never stops teaching. It shows you the power of nature, the value of patience, and the importance of respect.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is a lover that never leaves you. It kisses your skin, caresses your hair, and embraces your soul.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is a healer that never fails you. It cleanses your wounds, soothes your pain, and restores your balance.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is a playground that never bores you. It offers you adventure, challenge, and fun. It invites you to explore, discover, and enjoy.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is a canvas that never runs out of paint. It paints you pictures of sunrise and sunset, storm and calm, moonlight and starlight. It paints you with colors of blue and green, gold and silver, purple and pink.”',
    author: 'Unknown',
  },
  {
    quote:
      '“The sea is a song that never ends. It sings you melodies of waves and wind, rain and thunder, dolphins and whales. It sings you with words of love and peace, hope and faith, gratitude and joy.”',
    author: 'Unknown',
  },
];
