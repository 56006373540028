// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[0]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[1]!../../../../node_modules/flag-icons/css/flag-icons.min.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fi {\n  margin-right: 3px;\n}\n\n.external-event-icon {\n  font-size: 16px;\n  margin-left: 5px;\n  vertical-align: middle;\n  color: var(--ion-color-primary);\n  font-weight: bold;\n}", "",{"version":3,"sources":["webpack://./src/app/pending-approval/pending-approval-item/pending-approval-item.component.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;AAAF;;AAGA;EACE,eAAA;EACA,gBAAA;EACA,sBAAA;EACA,+BAAA;EACA,iBAAA;AAAF","sourcesContent":["@import \"flag-icons/css/flag-icons.min.css\";\r\n\r\n.fi {\r\n  margin-right: 3px;\r\n}\r\n\r\n.external-event-icon {\r\n  font-size: 16px;\r\n  margin-left: 5px;\r\n  vertical-align: middle;\r\n  color: var(--ion-color-primary);\r\n  font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
