import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap, switchMap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { getAuth } from 'firebase/auth';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  private apiUrl = `${environment.apiUrl}/favorites`;
  private favoriteIds: string[] = [];
  private favoritesSubject = new BehaviorSubject<string[]>([]);
  private isInitialized = false;

  constructor(private http: HttpClient, private fireStore: AngularFirestore) {
    this.initializeFavorites();
  }

  private initializeFavorites(): void {
    // Listen for auth state changes
    getAuth().onAuthStateChanged(user => {
      if (user) {
        // User is signed in, load favorites from Firestore
        this.loadUserFavorites(user.uid);
      } else {
        // User is signed out, load from localStorage
        this.loadLocalFavorites();
      }
    });
  }

  private loadUserFavorites(userId: string): void {
    console.log('Loading user favorites from Firestore for user:', userId);

    this.fireStore
      .collection('users')
      .doc(userId)
      .collection('favorites')
      .doc('events')
      .valueChanges()
      .pipe(
        catchError(error => {
          console.error('Error loading user favorites from Firestore', error);
          // Fall back to local storage if Firestore fails
          this.loadLocalFavorites();
          return of({ eventIds: [] });
        })
      )
      .subscribe((data: any) => {
        console.log('Received Firestore favorites data:', data);

        if (data && data.eventIds) {
          this.favoriteIds = data.eventIds;
          this.favoritesSubject.next(this.favoriteIds);
          console.log('Updated favorites from Firestore:', this.favoriteIds);

          // Sync with localStorage for offline access
          localStorage.setItem('favorites', JSON.stringify(this.favoriteIds));
        } else {
          // If no favorites document exists yet, check if we have local favorites to migrate
          const localFavorites = this.getLocalFavorites();
          console.log(
            'No Firestore favorites found, checking local favorites:',
            localFavorites
          );

          if (localFavorites.length > 0) {
            // Migrate local favorites to user account
            console.log('Migrating local favorites to Firestore');
            this.saveUserFavorites(userId, localFavorites);
            this.favoriteIds = localFavorites;
            this.favoritesSubject.next(this.favoriteIds);
          } else {
            // Initialize with empty array
            console.log(
              'No local favorites found, initializing with empty array'
            );
            this.favoriteIds = [];
            this.favoritesSubject.next(this.favoriteIds);
          }
        }
        this.isInitialized = true;
        console.log('Favorites initialization completed');
      });
  }

  private loadLocalFavorites(): void {
    this.favoriteIds = this.getLocalFavorites();
    this.favoritesSubject.next(this.favoriteIds);
    this.isInitialized = true;
  }

  private getLocalFavorites(): string[] {
    const storedFavorites = localStorage.getItem('favorites');
    return storedFavorites ? JSON.parse(storedFavorites) : [];
  }

  private saveUserFavorites(userId: string, eventIds: string[]): void {
    console.log(
      'Saving user favorites to Firestore for user:',
      userId,
      'Favorites:',
      eventIds
    );

    this.fireStore
      .collection('users')
      .doc(userId)
      .collection('favorites')
      .doc('events')
      .set({ eventIds })
      .then(() => {
        console.log('Successfully saved favorites to Firestore');
      })
      .catch(error => {
        console.error('Error saving favorites to Firestore', error);
        // Ensure local storage is at least updated
        localStorage.setItem('favorites', JSON.stringify(eventIds));
      });
  }

  getFavorites(): Observable<string[]> {
    return this.favoritesSubject.asObservable();
  }

  isFavorite(eventId: string): boolean {
    return this.favoriteIds.includes(eventId);
  }

  addFavorite(eventId: string): Observable<boolean> {
    if (!this.favoriteIds.includes(eventId)) {
      this.favoriteIds.push(eventId);

      // Update the subject
      this.favoritesSubject.next(this.favoriteIds);

      // Save to localStorage for offline access
      localStorage.setItem('favorites', JSON.stringify(this.favoriteIds));

      // If user is logged in, save to Firestore
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        this.saveUserFavorites(currentUser.uid, this.favoriteIds);
      }
    }

    // For API integration in production
    if (environment.production) {
      return this.http.post<any>(`${this.apiUrl}/${eventId}`, {}).pipe(
        tap(() => {
          // Already handled above
        }),
        map(() => true),
        catchError(error => {
          console.error(`Error adding favorite with id ${eventId}`, error);
          return of(false);
        })
      );
    }

    return of(true);
  }

  removeFavorite(eventId: string): Observable<boolean> {
    const index = this.favoriteIds.indexOf(eventId);
    if (index !== -1) {
      this.favoriteIds.splice(index, 1);

      // Update the subject
      this.favoritesSubject.next(this.favoriteIds);

      // Save to localStorage for offline access
      localStorage.setItem('favorites', JSON.stringify(this.favoriteIds));

      // If user is logged in, save to Firestore
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        this.saveUserFavorites(currentUser.uid, this.favoriteIds);
      }
    }

    // For API integration in production
    if (environment.production) {
      return this.http.delete<any>(`${this.apiUrl}/${eventId}`).pipe(
        tap(() => {
          // Already handled above
        }),
        map(() => true),
        catchError(error => {
          console.error(`Error removing favorite with id ${eventId}`, error);
          return of(false);
        })
      );
    }

    return of(true);
  }

  // Sync local favorites with user account when they log in
  syncFavoritesOnLogin(userId: string): void {
    console.log('Syncing favorites on login for user:', userId);

    if (!this.isInitialized) {
      // Wait for initialization to complete
      console.log('FavoritesService not initialized yet, retrying in 100ms');
      setTimeout(() => this.syncFavoritesOnLogin(userId), 100);
      return;
    }

    // Get local favorites
    const localFavorites = this.getLocalFavorites();
    console.log('Local favorites:', localFavorites);

    // Get user favorites from Firestore
    this.fireStore
      .collection('users')
      .doc(userId)
      .collection('favorites')
      .doc('events')
      .get()
      .pipe(
        catchError(error => {
          console.error('Error getting user favorites for sync', error);
          return of({ exists: false, data: () => ({ eventIds: [] }) });
        })
      )
      .subscribe((doc: any) => {
        let userFavorites: string[] = [];

        if (doc.exists && doc.data().eventIds) {
          userFavorites = doc.data().eventIds;
          console.log('User favorites from Firestore:', userFavorites);
        } else {
          console.log('No user favorites found in Firestore');
        }

        // Merge local and user favorites (removing duplicates)
        const mergedFavorites = [
          ...new Set([...localFavorites, ...userFavorites]),
        ];
        console.log('Merged favorites:', mergedFavorites);

        // Update local state
        this.favoriteIds = mergedFavorites;
        this.favoritesSubject.next(this.favoriteIds);

        // Save merged favorites to localStorage
        localStorage.setItem('favorites', JSON.stringify(mergedFavorites));

        // Save merged favorites to Firestore
        this.saveUserFavorites(userId, mergedFavorites);

        console.log('Favorites sync completed');
      });
  }
}

