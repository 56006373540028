import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { register } from 'swiper/element/bundle';
import { Platform } from '@ionic/angular';
import { AppService } from './app.service';
import { AdMob } from '@capacitor-community/admob';
import { SplashScreen } from '@capacitor/splash-screen';
import { UserService } from './auth/user.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Router } from '@angular/router';
import { ForceUpdateService } from './services/force-update.service';
import { Plugins } from '@capacitor/core';
const { TrackingTransparency, CordovaPlugin } = Plugins;
register();
import { Geolocation } from '@capacitor/geolocation';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  background: string;
  content: HTMLElement | undefined;
  isPremium: boolean;
  isAdmin: boolean;

  showTravelingModal = false;
  travelingData = {
    travelingRandom: null,
    travelingTitle: '',
    travelingLink: '',
    travelingBg: '',
  };

  constructor(
    private service: AppService,
    private platform: Platform,
    private userService: UserService,
    private router: Router,
    private zone: NgZone,
    private forceUpdateService: ForceUpdateService
  ) {
    this.initializeAdv();
  }

  async ngOnInit() {
    this.service.appBackground.subscribe(bg => {
      this.background = bg;
    });
    this.service.showTravelingModal.subscribe(isOpen => {
      this.showTravelingModal = isOpen;
    });
    this.service.travelingData.subscribe(data => (this.travelingData = data));

    if (this.platform.platforms().includes('ios')) {
      await this.printCurrentPosition();
    }

    this.service.detectUrl();
  }

  printCurrentPosition = async () => {
    const coordinates = await Geolocation.getCurrentPosition();

    console.log('Current position:', coordinates);
  };

  initializeAdv() {
    this.userService.setUser();
    this.platform.ready().then(async () => {
      setTimeout(async () => {
        SplashScreen.hide({
          fadeOutDuration: 0,
        });
        // if (
        //   this.platform.platforms().includes('capacitor') &&
        //   this.forceUpdateService.isUpdateAvailability()
        // ) {
        //   this.router.navigateByUrl('check-version');
        // }
      }, 1000);
      if (this.platform.platforms().includes('capacitor')) {
        AdMob.initialize({});

        AdMob.setApplicationMuted({
          muted: false,
        });

        AdMob.setApplicationVolume({
          volume: 0.5,
        });
      }
    });

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://my-ionic.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split('.app').pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }
  ngOnDestroy() {
    App.removeAllListeners();
  }

  closeTravelingModal(e) {
    this.service.showTravelingModal.next(false);
  }
}
