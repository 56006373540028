export const quotes = [
  {
    quote:
      '“You will love the ocean. It makes you feel small, but not in a bad way. Small because you realize you’re part of something bigger.” ',
    author: 'Lauren Myracle',
    img: './../../assets/mood-pics/dancing_fish_background_watercolor_format.webp',
  },
  {
    quote:
      '“We are like islands in the sea, separate on the surface but connected on the deep.” ',
    author: 'William James',
    img: './../../assets/mood-pics/turtle_watercolor_background.webp',
  },
  {
    quote:
      '“Dance with the waves, move with the sea, let the rhythm of the water set your soul free.” ',
    author: 'Christy Ann Martine',
    img: './../../assets/mood-pics/dolphines_watercolor_background.webp',
  },
  {
    quote:
      '“The sea, once it casts its spell, holds one in its net of wonder forever.” ',
    author: 'Jacques Cousteau',
    img: './../../assets/mood-pics/jellyfish_watercolor_background.webp',
  },
  {
    quote:
      '“If the ocean can calm itself, so can you. We are both salt water mixed with air” ',
    author: 'Nayyirah Waheed',
    img: './../../assets/mood-pics/seahorse_watercolor_background.webp',
  },
  {
    quote:
      '“We ourselves feel what we are doing is just a drop in the ocean. But the ocean would be less because of that missing drop.” ',
    author: 'Mother Teresa',
    img: './../../assets/mood-pics/whale_watercolor_background.webp',
  },
  {
    quote:
      '“The ocean stirs the heart, inspires the imagination and brings eternal joy to the soul.” ',
    author: 'Robert Wylan',
    img: './../../assets/mood-pics/stingray_watercolor_background.webp',
  },
];
