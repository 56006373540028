import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-empty-item',
  templateUrl: './empty-item.component.html',
  styleUrls: ['./empty-item.component.scss'],
})
export class EmptyItemComponent {
  @Input() emptyType: string;
  @Output() showInvite = new EventEmitter<Event>();
  constructor() {}

  showInvitation(event: Event) {
    this.showInvite.emit(event);
  }
}
