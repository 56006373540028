import { NgModule } from '@angular/core';
import { BazaarRoutingModule } from './bazaar-routing.module';
import { IonicModule } from '@ionic/angular';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ShopsComponent } from './shops/shops.component';
import { BazaarMenuComponent } from './bazaar-menu/bazaar-menu.component';
import { SecondHandComponent } from './second-hand/second-hand.component';
import { AddSecondHandItemComponent } from './second-hand/add-second-hand-item/add-second-hand-item.component';
import { EditSecondHandItemComponent } from './second-hand/edit-second-hand-item/edit-second-hand-item.component';
import { JobsServicesModule } from './jobs-services/jobs-services.module';
import { JobsService } from './jobs-services/jobs/jobs.service';
import { JobsStore } from './jobs-services/jobs/jobs-store';
import { ServicesService } from './jobs-services/services/services.service';
import { ServicesStore } from './jobs-services/services/services.store';
import { PipesModule } from '../pipes/pipes.module';
import { SecondHandDetailsComponent } from './second-hand/second-hand-details/second-hand-details.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { IonicSelectableModule } from 'ionic-selectable';
import { SecondHandFilterComponent } from './second-hand/second-hand-filter/second-hand-filter/second-hand-filter.component';
import { SecondHandStore } from './second-hand/second-hand-store';
import { SecondHandItemComponent } from './second-hand/second-hand-item/second-hand-item.component';
import { SecondHandSearchComponent } from './second-hand/second-hand-search/second-hand-search.component';
import { SecondHandService } from './second-hand/second-hand.service';
import { ShopService } from './shops/shop.service';
import { ShopStore } from './shops/shop-store';
import { AdsenseModule } from 'ng2-adsense';
import { BannerModule } from '../shared/components/ads/adsense/banner/banner.module';

@NgModule({
  declarations: [
    BazaarMenuComponent,
    ShopsComponent,
    SecondHandComponent,
    AddSecondHandItemComponent,
    EditSecondHandItemComponent,
    SecondHandDetailsComponent,
    SecondHandFilterComponent,
    SecondHandItemComponent,
    SecondHandSearchComponent,
  ],
  imports: [
    AdsenseModule.forRoot({
      adClient: 'ca-pub-2609549176457465',
    }),
    IonicSelectableModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    IonicModule,
    BazaarRoutingModule,
    SharedModule,
    PipesModule,
    JobsServicesModule,
  ],
  exports: [SecondHandItemComponent, AddSecondHandItemComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BazaarModule {}
