export interface ISlide {
  title: string;
  icon: string;
  description: string;
}

export class TutorialService {
  constructor() {}

  tutorialData = [
    {
      title: 'World Community',
      icon: '/assets/tutorial/tutorial-community.webp',
      description:
        'Connect with fellow merpeople all around the globe - online and offline.',
    },
    {
      title: 'MerMarketplace',
      icon: '/assets/tutorial/tutorial-marketplace.webp',
      description:
        'Your marketplace for new and 2nd hand articles as well as for finding and offering jobs and services.',
    },
    {
      title: 'Information Hub',
      icon: '/assets/tutorial/tutorial-info-hub.webp',
      description:
        "Know what's going on where &when - media,events and environmental projects easily accessible in one place for you to participate on- and offline.",
    },
    {
      title: 'Be part of the game',
      icon: '/assets/tutorial/tutorial-gamification.webp',
      description:
        'Contribute to the community by moderating and adding content yourself while earning rewards and unlocking surprises',
    },
  ];
}
