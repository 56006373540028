import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-event-search',
  templateUrl: './event-search.component.html',
  styleUrls: ['./event-search.component.scss'],
})
export class EventSearchComponent {
  @Input() color;
  @ViewChild('searchInput') searchInput: ElementRef;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSearchChange = new EventEmitter<string>();

  toolbarData = {
    arrowRouter: '/events',
    title: 'Events',
  };

  constructor() {}

  searchItem(event) {
    const value = event?.target?.value || '';
    this.onSearchChange.emit(value);
  }
}

