import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import mixpanel from 'mixpanel-browser';
import { AuthService } from '../../../../firebase/auth.service';
import { IUser } from 'src/app/auth/auth.models';
import { FormService } from '../../form/form.service';
import { CrudHelper } from 'src/app/firebase/helpers/crudHelper';
import { DateService } from 'src/app/services/date.service';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent implements OnInit {
  @Input() notifications;
  @Input() isOpen;
  @Input() config;
  @Input() inviters: any[];
  @Input() subscriptionPeriod;
  @Output() isOpenChange = new EventEmitter<any>();

  isMobile = this.platform.platforms().includes('capacitor');
  isIosApp = this.platform.platforms().includes('ios') && this.isMobile;
  properties;
  currentUserId: string;
  constructor(
    private authService: AuthService,
    private router: Router,
    private crudHelper: CrudHelper,
    private formService: FormService,
    private dateService: DateService,
    private platform: Platform
  ) {}

  ngOnInit(): void {
    mixpanel.init(environment.mixpanelToken, { debug: true });
    const { uid } = this.authService.isUserAuth();
    this.currentUserId = uid;

    mixpanel.identify(uid);
  }

  navigateToModerationItem(itemType, itemId, id, shells?) {
    switch (itemType) {
      case 'event':
        this.isOpenChange.next(false);
        setTimeout(() => {
          this.router.navigate(['/events/', itemId]);
          this.viewNotificationFromClick(id, shells);
        }, 0);
        break;
      case 'planet':
        this.isOpenChange.next(false);
        setTimeout(() => {
          this.router.navigate(['/planet/challenges/', itemId]);
          this.viewNotificationFromClick(id, shells);
        }, 0);
        break;
      case 'pod':
        this.isOpenChange.next(false);
        setTimeout(() => {
          this.router.navigate(['/pod/', itemId]);
          this.viewNotificationFromClick(id, shells);
        }, 0);
        break;
      case 'pool':
        this.isOpenChange.next(false);
        setTimeout(() => {
          this.router.navigate(['/pool/', itemId]);
          this.viewNotificationFromClick(id, shells);
        }, 0);
        break;
      case 'job':
        this.isOpenChange.next(false);
        setTimeout(() => {
          this.router.navigate(['/bazaar/job&services/jobs/', itemId]);
          this.viewNotificationFromClick(id, shells);
        }, 0);
        break;
      case 'service':
        this.isOpenChange.next(false);
        setTimeout(() => {
          this.router.navigate(['/bazaar/job&services/services/', itemId]);
          this.viewNotificationFromClick(id, shells);
        }, 0);
        break;
      case 'second hand':
        this.isOpenChange.next(false);
        setTimeout(() => {
          this.router.navigate(['/bazaar/second-hand/', itemId]);
          this.viewNotificationFromClick(id, shells);
        }, 0);
        break;
      case 'podRejected':
        this.isOpenChange.next(false);
        setTimeout(() => {
          this.router.navigate(['/merfolk/pods']);
          this.viewNotificationFromClick(id, shells);
        }, 0);
        break;
      case 'podApproved':
        this.isOpenChange.next(false);
        setTimeout(() => {
          this.router.navigate(['/pod/', itemId]);
          this.viewNotificationFromClick(id, shells);
        }, 0);
        break;
      case 'podInvite':
        this.isOpenChange.next(false);
        if (itemId.length > 1) {
          this.formService.formInitialValues.next({ myContent: true });
          this.formService.podMenuData$.next({
            myContent: true,
            country: null,
            approval: null,
            creator: this.currentUserId,
          });
          setTimeout(() => {
            this.router.navigate(['/merfolk/pods']);
            this.viewNotificationFromClick(id);
          }, 0);
        } else {
          setTimeout(() => {
            this.router.navigate(['/pod/', itemId[0].podId]);
            this.viewNotificationFromClick(id);
          }, 0);
        }

      case 'premium':
        this.isOpenChange.next(false);
        setTimeout(() => {
          this.viewNotificationFromClick(id);
        }, 0);
        break;
      case 'podInvite':
        this.isOpenChange.next(false);
        if (itemId.length > 1) {
          this.formService.formInitialValues.next({ myContent: true });
          this.formService.podMenuData$.next({
            myContent: true,
            country: null,
            approval: null,
            creator: this.currentUserId,
          });
          setTimeout(() => {
            this.router.navigate(['/merfolk/pods']);
            this.viewNotificationFromClick(id);
          }, 0);
        } else {
          setTimeout(() => {
            this.router.navigate(['/pod/', itemId[0].podId]);
            this.viewNotificationFromClick(id);
          }, 0);
        }

      case 'premium':
        this.isOpenChange.next(false);
        setTimeout(() => {
          this.viewNotificationFromClick(id);
        }, 0);
        break;
      default:
        break;
    }
  }

  async viewNotificationFromClick(id, shells?, type?) {
    const functions = getFunctions();
    const viewNotification = httpsCallable(functions, 'viewNotification');
    this.isOpenChange.next(false);
    await viewNotification({ id: id });
    if (shells !== 0) {
      this.properties = {
        'reward-type': 'rewarded-content-addition',
      };
      mixpanel.track('Reward Received', this.properties);
    } else if (type === 'subRenewal') {
      let subPeriod = '';
      if (shells === 40) {
        subPeriod = '1-Month-Premium';
      } else if (shells === 120) {
        subPeriod = '3-Months-Premium';
      } else if (shells === 480) {
        subPeriod = '1-Year-Premium';
      }
      let properties = {
        content: subPeriod,
        'pearls-spent': 'Pearls',
        amount: shells,
      };
      mixpanel.track('Pearls Spent', properties);
    }
  }

  remindLater(item, notifications) {
    const newItem = notifications.map(notification => {
      return notification.id === item.id
        ? (notification = {
            ...notification,
            suspendedUntil: this.dateService.now,
          })
        : notification;
    });
    this.crudHelper.updateItem({
      data: { id: this.currentUserId, notifications: newItem },
      id: this.currentUserId,
      collectionName: 'notifications',
    });

    this.isOpenChange.next(false);
  }

  reactivateSubscription(id) {
    this.isOpenChange.next(false);
    if (this.isIosApp) {
      window.open('https://www.mermapp.com/rewards-and-payment-faq', '_blank');
    } else {
      this.router.navigate(['/payments/subscription']);
    }
  }
}
