import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, IonPopover } from '@ionic/angular';
import { Timestamp } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import {
  BehaviorSubject,
  combineLatest,
  Subject,
  takeUntil,
  filter,
} from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IEventFilter,
  eventDiscordModalData,
  eventPremiumModalData,
  confirmAddingEventModalData,
  successEventModalData,
} from './events';
import { EventsService } from './events.service';
import { FormService } from '../shared/components/form/form.service';
import { EventsStore } from './events-store';
import { showLoading } from '../shared/components/modals/loading';
import { environment } from '../../environments/environment';
import { IPod } from '../shared/models/item.models';
import { DateService } from '../services/date.service';
import { UserService } from '../auth/user.service';
import { includesIgnoreCase } from 'src/utils';
@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit, OnDestroy {
  @ViewChild('filterPopover') filterPopover: IonPopover;

  // Add today property for date validation
  today: string = new Date().toISOString().split('T')[0];

  searchString: BehaviorSubject<string> = new BehaviorSubject<string>('');
  status;
  loader = showLoading();
  loading: boolean;
  myContent: boolean = false;
  showPastEvents: boolean = false;
  country: string | null = null;
  currentUserID;
  currentUser;
  isAdmin: boolean;
  creator;

  isOpenFormModal = false;
  isOpenEditFormModal = false;
  items;
  startDate: number;
  endDate: number;
  color = 'dark';
  isPremium: boolean;
  selectedTab: string = 'events';
  isTabLoading: boolean = true;
  isOpenDiscordModal = false;
  premiumModalData = eventPremiumModalData;
  eventDiscordModalData = eventDiscordModalData;
  isUserPremiumModalOpen = false;
  infoSnapshot;
  userInfo;
  properties;
  allEvents;
  isMobile: boolean;
  isIosApp: boolean;
  isUserHasSuspendedContent: any;
  showConfirmModal = false;
  confirmModalData = confirmAddingEventModalData;
  showSuccessModal = false;
  successModalData = successEventModalData;

  usersPods = new BehaviorSubject<IPod[]>([]);
  private destroy$ = new Subject<void>();

  // Filter properties
  activeFilters: {
    category: string[];
    priceRange: string;
    location: string[];
    startDate: string;
    endDate: string;
  } = {
    category: [],
    priceRange: null,
    location: [],
    startDate: null,
    endDate: null,
  };

  // Category options
  categoryOptions: string[] = [
    'Convention',
    'Course',
    'Competition',
    'Festival',
    'Meeting',
    'Pod Activity',
    'Other',
  ];

  // Price range options
  priceRangeOptions: string[] = ['Free', '0-50', '50-100', '100+'];

  // Location options (example)
  locationOptions: string[] = [
    'North America',
    'Europe',
    'Asia',
    'Australia',
    'Africa',
    'South America',
    'Online',
  ];

  // Add pagination properties
  itemsPerPage = 5;
  currentPage = 1;
  totalPages = 1;

  // Add pagination for status sections
  statusSections = {
    approved: { itemsPerPage: 4, currentPage: 1, totalPages: 1 },
    pending: { itemsPerPage: 4, currentPage: 1, totalPages: 1 },
    rejected: { itemsPerPage: 4, currentPage: 1, totalPages: 1 },
  };

  isMyContentLoading: boolean = false;

  // Add property for warning bar
  showAiWarning = true;

  constructor(
    private eventsStore: EventsStore,
    private eventsService: EventsService,
    private dateService: DateService,
    private formService: FormService,
    private platform: Platform,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    mixpanel.init(environment.mixpanelToken, { debug: true });

    // Initialize platform-dependent properties
    this.isMobile = this.platform.platforms().includes('capacitor');
    this.isIosApp = this.platform.platforms().includes('ios') && this.isMobile;

    // Initialize success modal data
    this.successModalData = successEventModalData;

    // Initialize with default filter to ensure events load
    const initialFilter: IEventFilter = {
      myContent: false,
      category: null,
      priceRange: null,
      location: null,
      startDate: null,
      endDate: null,
    };

    // Initialize loading states
    this.isTabLoading = true;
    this.isMyContentLoading = false;

    // Get user info
    this.getUser();
    this.listenItemsChanges();
    this.listenStatusChanges();
    this.listenFilter();

    // Subscribe to the showFormModal state
    this.eventsStore.selectShowFormModal().subscribe(isOpen => {
      this.isOpenEditFormModal = isOpen;
    });

    // Initial loading simulation
    this.simulateLoading();

    // Trigger initial events load
    this.eventsStore.getItems(initialFilter);
  }

  ngOnDestroy() {
    // Clean up all subscriptions
    this.destroy$.next();
    this.destroy$.complete();

    // Reset form service state
    this.formService.eventMenuData$.next(false);
    this.formService.formInitialValues.next({ myContent: null });

    // Reset component state
    this.searchString.next('');
    this.usersPods.next([]);
    this.items = null;
    this.allEvents = null;
    this.loading = false;
    this.myContent = false;
    this.showPastEvents = false;
  }

  onTabChange(event) {
    // Update selected tab
    this.selectedTab = event.detail.value;

    // Reset pagination
    this.currentPage = 1;

    // Show loading state
    this.loading = true;
    this.isTabLoading = true;

    try {
      // Track tab change in analytics
      if (this.selectedTab === 'events') {
        this.trackEvent(
          'view_community_events',
          'tab',
          'community',
          'community'
        );
      } else if (this.selectedTab === 'aiEvents') {
        this.trackEvent('view_curated_events', 'tab', 'curated', 'curated');
      }

      // Refresh items when switching tabs
      if (this.allEvents) {
        const isArchiveTab = false;
        this.items = this.dateService.getScheduledItems(
          this.allEvents,
          this.myContent,
          isArchiveTab,
          this.showPastEvents
        );
      }
    } catch (error) {
    } finally {
      // Hide loading state after a short delay
      setTimeout(() => {
        this.isTabLoading = false;
        this.loading = false;
      }, 300);
    }
  }

  simulateLoading() {
    this.isTabLoading = true;
    setTimeout(() => {
      this.isTabLoading = false;
    }, 1000);
  }

  trackEvent(event, parameter, parameterValue, eventValue) {
    if (parameter === 'screen-name') {
      this.properties = {
        'screen-name': parameterValue,
        'event title': eventValue?.title,
        'event id': eventValue?.id,
      };
    }

    mixpanel.track(event, this.properties);
  }

  openModal() {
    if (this.currentUser.discordLink) {
      if (!this.isPremium && !this.isAdmin) {
        this.isUserPremiumModalOpen = true;
      } else {
        this.isOpenFormModal = true;
      }
    } else if (this.isAdmin) {
      this.isOpenFormModal = true;
    } else {
      this.isOpenDiscordModal = true;
    }
  }

  closeModal(e) {
    e ? (this.isOpenFormModal = false) : null;
  }

  closeEditModal(e) {
    e ? this.eventsStore.updateShowFormModal(false) : null;
  }

  showDiscardConfirmation(e) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.showConfirmModal = true;
  }

  confirmCloseModal(e) {
    if (e) {
      this.showConfirmModal = false;
      this.closeModal(true);
    }
  }

  cancelCloseModal(e) {
    if (e) {
      this.showConfirmModal = false;
    }
  }

  handleSuccessModalOk(e) {
    if (e) {
      this.showSuccessModal = false;
      this.eventsStore.updateShowPendingModal(false);
      this.isOpenFormModal = false;

      this.formService.itemGallery.next([]);

      // Ensure we're on the Events tab
      this.selectedTab = 'events';

      // Set myContent to true to show user's events (pending, approved, rejected)
      this.myContent = true;

      // Create filter to show user's content
      const filter: IEventFilter = {
        myContent: true,
      };

      // Update form service filter
      this.formService.eventMenuData$.next(filter);

      // Get items with the updated filter
      this.eventsStore.getItems(filter);

      // Navigate to the main events page with the "my content" filter
      if (
        !this.router.url.includes('/events') ||
        this.router.url.includes('/events/')
      ) {
        this.router.navigate(['/events']);
      }
    }
  }

  handleSuccessModalCancel(e) {
    if (e) {
      this.showSuccessModal = false;
      this.eventsStore.updateShowPendingModal(false);
    }
  }

  onSearchChange(searchString: string) {
    this.searchString.next(searchString);
  }

  private listenFilter() {
    this.formService.eventMenuData$
      .pipe(
        takeUntil(this.destroy$),
        filter(data => data !== undefined) // Changed from !!data to allow initial null/false values
      )
      .subscribe((filterData: IEventFilter) => {
        if (filterData) {
          this.startDate = filterData.startDate
            ? Date.parse(filterData.startDate)
            : null;
          this.endDate = filterData.endDate
            ? Date.parse(filterData.endDate)
            : null;

          // Update active filters from the filter data
          if (filterData.category) {
            this.activeFilters.category = filterData.category.split(',');
          }

          if (filterData.priceRange) {
            this.activeFilters.priceRange = filterData.priceRange;
          }

          if (filterData.location) {
            this.activeFilters.location = filterData.location.split(',');
          }

          if (filterData.startDate) {
            this.activeFilters.startDate = filterData.startDate;
          }

          if (filterData.endDate) {
            this.activeFilters.endDate = filterData.endDate;
          }
        } else {
          // Reset dates and filters
          this.startDate = null;
          this.endDate = null;
          this.activeFilters = {
            category: [],
            priceRange: null,
            location: [],
            startDate: null,
            endDate: null,
          };
        }

        // Always update these properties
        this.color = this.eventsService.checkFilter(filterData);

        // Only update myContent if it's explicitly set in the filter data
        // This prevents it from being reset to false when other filters are applied
        if (filterData && filterData.myContent !== undefined) {
          this.myContent = filterData.myContent;
        }

        // Ensure we fetch items even with empty/reset filters
        // Make sure to include the current myContent state in the filter
        const updatedFilter = filterData || { myContent: false };
        updatedFilter.myContent = this.myContent;
        this.eventsStore.getItems(updatedFilter);
      });
  }

  private listenStatusChanges() {
    // Listen for loading state
    this.eventsStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async loading => {
        const data = await this.loader;
        if (loading) {
          this.loading = true;
          data.present();
        } else {
          this.loading = false;
          data.dismiss();
        }
      });

    // Listen for success modal state
    this.eventsStore
      .selectShowPendingModal()
      .pipe(takeUntil(this.destroy$))
      .subscribe(isOpen => {
        this.showSuccessModal = isOpen;
      });
  }

  private listenItemsChanges() {
    combineLatest([
      this.eventsStore.selectItems(),
      this.searchString,
      this.usersPods,
    ])
      .pipe(
        takeUntil(this.destroy$),
        filter(([items]) => items !== undefined), // Allow empty arrays but filter undefined
        map(([items, searchString, pods]) => {
          // Set loading state to false when data is loaded
          this.isMyContentLoading = false;

          const itemsArray = Array.isArray(items) ? items : [];

          // First filter out null/undefined items
          // Only filter by approved status when not viewing "My Content"
          const validItems = itemsArray.filter(
            item =>
              item &&
              (this.myContent ||
                item.status === 'approved' ||
                (item.isFlagged &&
                  item.participants?.some(
                    participant => participant.id === this.currentUserID
                  )))
          );

          // Apply search filter if there's a search string
          const searchFiltered = searchString
            ? validItems.filter(
                item =>
                  includesIgnoreCase(item.title, searchString) ||
                  includesIgnoreCase(item.description, searchString)
              )
            : validItems;

          // Apply pod membership check only if we have pods
          return pods && pods.length
            ? searchFiltered.filter(item =>
                this.eventsService.podMemberCheck(
                  item,
                  pods,
                  this.currentUserID
                )
              )
            : searchFiltered;
        })
      )
      .subscribe(filteredItems => {
        // Store the current myContent state to restore it later
        const currentMyContent = this.myContent;

        // Handle loading state
        this.loading = true;

        try {
          // Apply additional filters based on content type
          if (!this.myContent) {
            this.allEvents = filteredItems
              .filter(
                item =>
                  !this.currentUser?.blockList?.includes(item?.creator?.id)
              )
              .filter(
                item =>
                  item.suspended === false || item?.suspended === undefined
              )
              .filter(
                item =>
                  item.status === 'approved' ||
                  (item.isFlagged &&
                    item.participants?.some(
                      participant => participant.id === this.currentUserID
                    ))
              );
          } else {
            // When in My Content view, only show events created by the current user
            this.allEvents = filteredItems.filter(
              item =>
                item.creator?.id === this.currentUserID ||
                item.creator === this.currentUserID
            );
          }

          // Get scheduled items based on current view
          const isArchiveTab = false;
          this.items = this.dateService.getScheduledItems(
            this.allEvents,
            this.myContent,
            isArchiveTab,
            this.showPastEvents,
            this.activeFilters.startDate,
            this.activeFilters.endDate,
            this.activeFilters.location
          );

          // Reset to first page when items change
          this.currentPage = 1;

          // Ensure pagination is updated
          this.updatePagination();

          // Restore the myContent state if it was changed
          if (this.myContent !== currentMyContent) {
            this.myContent = currentMyContent;
          }
        } catch (error) {
          // Set empty arrays on error to prevent undefined states
          this.allEvents = [];
          this.items = [];
        } finally {
          this.loading = false;
        }
      });
  }

  private updatePagination() {
    if (!this.items) {
      this.items = [];
    }
    this.totalPages = Math.ceil(this.items.length / this.itemsPerPage);
    if (this.currentPage > this.totalPages) {
      this.currentPage = 1;
    }

    // Update status section pagination if in My Content view
    if (this.myContent && this.allEvents) {
      // Group items by status, ensuring we only include the current user's events
      const itemsByStatus = {
        approved: this.allEvents.filter(
          item =>
            (item.status === 'approved' &&
              (item.creator?.id === this.currentUserID ||
                item.creator === this.currentUserID)) ||
            (item.isFlagged &&
              item.participants?.some(
                participant => participant.id === this.currentUserID
              ))
        ),
        pending: this.allEvents.filter(
          item =>
            item.status === 'pending' &&
            (item.creator?.id === this.currentUserID ||
              item.creator === this.currentUserID)
        ),
        rejected: this.allEvents.filter(
          item =>
            item.status === 'rejected' &&
            (item.creator?.id === this.currentUserID ||
              item.creator === this.currentUserID)
        ),
      };

      // Update pagination for each status section
      Object.keys(this.statusSections).forEach(status => {
        const items = itemsByStatus[status] || [];
        this.statusSections[status].totalPages = Math.ceil(
          items.length / this.statusSections[status].itemsPerPage
        );
        if (
          this.statusSections[status].currentPage >
          this.statusSections[status].totalPages
        ) {
          this.statusSections[status].currentPage = 1;
        }
      });
    }
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.currentUser = user;
        this.currentUserID = user.uid;
        this.isPremium = this.userService.isPremium;
        this.isAdmin = this.userService.isAdmin;
        this.userInfo = this.userService.balanceInfo;
        this.eventsService
          .getPodsWithEvents(user.uid)
          .subscribe((items: any) => this.usersPods.next(items));
        mixpanel.identify(user.uid);
      });
  }
  checkDate(startDate: Timestamp, endDate: Timestamp) {
    return this.dateService.checkSameDate(startDate, endDate);
  }

  /**
   * Check if any filters are active
   */
  hasActiveFilters(): boolean {
    // Check if any non-date filters are active
    const hasNonDateFilters =
      this.myContent ||
      (this.activeFilters.category && this.activeFilters.category.length > 0) ||
      this.activeFilters.priceRange !== null ||
      (this.activeFilters.location && this.activeFilters.location.length > 0);

    // Check if date filters are valid
    const hasValidStartDate =
      this.activeFilters.startDate !== null &&
      this.activeFilters.startDate !== undefined &&
      this.activeFilters.startDate !== '';

    const hasValidEndDate =
      this.activeFilters.endDate !== null &&
      this.activeFilters.endDate !== undefined &&
      this.activeFilters.endDate !== '';

    // Return true if any filter is active
    return hasNonDateFilters || hasValidStartDate || hasValidEndDate;
  }

  /**
   * Reset all active filters
   */
  resetFilters(): void {
    this.activeFilters = {
      category: [],
      priceRange: null,
      location: [],
      startDate: null,
      endDate: null,
    };
    this.myContent = false;
    this.showPastEvents = false;
    this.applyFilters();

    // Force the datetime components to reset
    const startDatetime = document.getElementById('startDate') as any;
    const endDatetime = document.getElementById('endDate') as any;
    if (startDatetime) startDatetime.value = null;
    if (endDatetime) endDatetime.value = null;
  }

  /**
   * Apply the active filters
   */
  applyFilters(): void {
    const filter: IEventFilter = {
      myContent: this.myContent,
    };

    // Add category filter if active
    if (this.activeFilters.category && this.activeFilters.category.length > 0) {
      filter.category = this.activeFilters.category.join(',');
    }

    // Add price range filter if active
    if (this.activeFilters.priceRange) {
      // Handle "Free" as a special case
      if (this.activeFilters.priceRange.toLowerCase() === 'free') {
        filter.price = 'free';
      } else {
        filter.priceRange = this.activeFilters.priceRange.toLowerCase();
      }
    }

    // Add location filter if active
    if (this.activeFilters.location && this.activeFilters.location.length > 0) {
      filter.location = this.activeFilters.location.join(',');
    }

    // Add date filters if active
    if (this.activeFilters.startDate && this.activeFilters.startDate !== '') {
      try {
        // Use the date string directly if it's already in YYYY-MM-DD format
        if (this.activeFilters.startDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
          filter.startDate = this.activeFilters.startDate;
        } else {
          // Convert to ISO string and take only the date part
          const startDate = new Date(this.activeFilters.startDate);
          if (!isNaN(startDate.getTime())) {
            filter.startDate = startDate.toISOString().split('T')[0];
          }
        }
      } catch (error) {
        // Clear the invalid date
        this.activeFilters.startDate = null;
      }
    }

    if (this.activeFilters.endDate && this.activeFilters.endDate !== '') {
      try {
        // Use the date string directly if it's already in YYYY-MM-DD format
        if (this.activeFilters.endDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
          filter.endDate = this.activeFilters.endDate;
        } else {
          // Convert to ISO string and take only the date part
          const endDate = new Date(this.activeFilters.endDate);
          if (!isNaN(endDate.getTime())) {
            filter.endDate = endDate.toISOString().split('T')[0];
          }
        }
      } catch (error) {
        // Clear the invalid date
        this.activeFilters.endDate = null;
      }
    }

    // Update the form service with the new filter
    this.formService.eventMenuData$.next(filter);

    // Reset pagination to first page when filters are applied
    this.currentPage = 1;

    // Force refresh of the filtered items
    if (this.allEvents) {
      const isArchiveTab = false;
      this.items = this.dateService.getScheduledItems(
        this.allEvents,
        this.myContent,
        isArchiveTab,
        this.showPastEvents,
        this.activeFilters.startDate,
        this.activeFilters.endDate,
        this.activeFilters.location
      );
    }
  }

  /**
   * Handle date changes from the datetime picker
   */
  handleDateChange(event: any, type: 'start' | 'end'): void {
    if (!event || !event.detail) {
      return;
    }

    const value = event.detail.value;

    if (!value) {
      if (type === 'start') {
        this.activeFilters.startDate = null;
      } else {
        this.activeFilters.endDate = null;
      }
      this.applyFilters();
      return;
    }

    try {
      // Log the raw value to debug

      // Convert to YYYY-MM-DD format
      const date = new Date(value);

      // Validate date
      if (isNaN(date.getTime())) {
        if (type === 'start') {
          this.activeFilters.startDate = null;
        } else {
          this.activeFilters.endDate = null;
        }
        this.applyFilters();
        return;
      }

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const dateString = `${year}-${month}-${day}`;

      // Validate date range
      if (type === 'end' && this.activeFilters.startDate) {
        const startDate = new Date(this.activeFilters.startDate);
        if (date < startDate) {
          console.warn('End date cannot be before start date');
          this.activeFilters.endDate = null;
          this.applyFilters();
          return;
        }
      }

      if (type === 'start' && this.activeFilters.endDate) {
        const endDate = new Date(this.activeFilters.endDate);
        if (date > endDate) {
          console.warn('Start date cannot be after end date');
          this.activeFilters.startDate = null;
          this.applyFilters();
          return;
        }
      }

      // Set the date
      if (type === 'start') {
        this.activeFilters.startDate = dateString;
      } else {
        this.activeFilters.endDate = dateString;
      }

      // Apply filters with a small delay to ensure state is updated
      setTimeout(() => {
        this.applyFilters();
      }, 100);
    } catch (error) {
      if (type === 'start') {
        this.activeFilters.startDate = null;
      } else {
        this.activeFilters.endDate = null;
      }
      this.applyFilters();
    }
  }

  /**
   * Toggle category selection
   */
  toggleCategory(category: string): void {
    const index = this.activeFilters.category.indexOf(category);
    if (index > -1) {
      this.activeFilters.category.splice(index, 1);
    } else {
      this.activeFilters.category.push(category);
    }
  }

  /**
   * Set price range
   */
  setPriceRange(range: string): void {
    this.activeFilters.priceRange = range;
  }

  /**
   * Toggle location selection
   */
  toggleLocation(location: string): void {
    const index = this.activeFilters.location.indexOf(location);
    if (index > -1) {
      this.activeFilters.location.splice(index, 1);
    } else {
      this.activeFilters.location.push(location);
    }
  }

  /**
   * Check if an event is online based on keywords in title, description, or location
   * @param event Event to check
   * @returns True if the event is online
   */
  private isOnlineEvent(event: any): boolean {
    const onlineKeywords = [
      'online',
      'virtual',
      'zoom',
      'webinar',
      'web conference',
      'livestream',
      'live stream',
      'remote',
      'digital',
      'stream',
      'streaming',
      'webcast',
    ];

    // Check title, description, and location for online keywords
    const titleLower = (event.title || '').toLowerCase();
    const descriptionLower = (event.description || '').toLowerCase();
    const locationLower = (event.location || '').toLowerCase();

    // Check if any online keywords are present in the event details
    return onlineKeywords.some(
      keyword =>
        titleLower.includes(keyword) ||
        descriptionLower.includes(keyword) ||
        locationLower.includes(keyword)
    );
  }

  /**
   * Set the start date filter
   */
  setStartDate(date: string): void {
    this.activeFilters.startDate = date;
  }

  /**
   * Set end date
   */
  setEndDate(date: string): void {
    this.activeFilters.endDate = date;
  }

  closeFilterPopover(): void {
    if (this.filterPopover) {
      this.filterPopover
        .dismiss()
        .then(() => {
          // Ensure the popover is fully dismissed
        })
        .catch(err => {});
    }
  }

  toggleMyContent(): void {
    // Toggle the myContent flag
    this.myContent = !this.myContent;

    // Set loading state for My Content
    if (this.myContent) {
      this.isMyContentLoading = true;
      // Simulate loading delay (remove this in production if real loading is fast enough)
      setTimeout(() => {
        this.isMyContentLoading = false;
      }, 1000);
    } else {
      // Reset loading state when toggling off
      this.isMyContentLoading = false;
    }

    // Create a filter object with the current myContent state
    const filter: IEventFilter = {
      myContent: this.myContent,
    };

    // Add any existing active filters
    if (this.activeFilters.category && this.activeFilters.category.length > 0) {
      filter.category = this.activeFilters.category.join(',');
    }

    if (this.activeFilters.priceRange) {
      if (this.activeFilters.priceRange.toLowerCase() === 'free') {
        filter.price = 'free';
      } else {
        filter.priceRange = this.activeFilters.priceRange.toLowerCase();
      }
    }

    if (this.activeFilters.location && this.activeFilters.location.length > 0) {
      filter.location = this.activeFilters.location.join(',');
    }

    if (this.activeFilters.startDate) {
      filter.startDate = this.activeFilters.startDate;
    }

    if (this.activeFilters.endDate) {
      filter.endDate = this.activeFilters.endDate;
    }

    // Update the form service with the new filter
    this.formService.eventMenuData$.next(filter);

    // Force refresh of the filtered items without calling applyFilters
    // This prevents potential race conditions or overwriting of the myContent flag
    if (this.allEvents) {
      // If in My Content view, ensure we're only showing the current user's events
      if (this.myContent) {
        this.refreshStatusSectionCounts();
      }

      const isArchiveTab = false;
      this.items = this.dateService.getScheduledItems(
        this.allEvents,
        this.myContent,
        isArchiveTab,
        this.showPastEvents,
        this.activeFilters.startDate,
        this.activeFilters.endDate,
        this.activeFilters.location
      );

      // Ensure pagination is updated
      this.updatePagination();
    }
  }

  // Helper method to refresh status section counts
  private refreshStatusSectionCounts(): void {
    if (!this.allEvents || !this.myContent) return;

    // Filter for user's events only
    const userEvents = this.allEvents.filter(
      item =>
        item.creator?.id === this.currentUserID ||
        item.creator === this.currentUserID
    );

    // Update each status section with the current user's events only
    Object.keys(this.statusSections).forEach(status => {
      const statusItems = userEvents.filter(item => item.status === status);
      this.statusSections[status].totalPages = Math.ceil(
        statusItems.length / this.statusSections[status].itemsPerPage
      );
      if (
        this.statusSections[status].currentPage >
        this.statusSections[status].totalPages
      ) {
        this.statusSections[status].currentPage = 1;
      }
    });
  }

  toggleShowPastEvents(): void {
    this.showPastEvents = !this.showPastEvents;
    this.applyFilters();
  }

  openAddEventModal(): void {
    this.openModal();
  }

  // Add pagination methods
  goToPage(page: number) {
    this.currentPage = page;
  }

  getPageNumbers(): number[] {
    const pages = [];
    const maxVisiblePages = 5;

    if (this.totalPages <= maxVisiblePages) {
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (this.currentPage <= 3) {
        for (let i = 1; i <= 4; i++) {
          pages.push(i);
        }
        pages.push(-1); // Represents ellipsis
        pages.push(this.totalPages);
      } else if (this.currentPage >= this.totalPages - 2) {
        pages.push(1);
        pages.push(-1);
        for (let i = this.totalPages - 3; i <= this.totalPages; i++) {
          pages.push(i);
        }
      } else {
        pages.push(1);
        pages.push(-1);
        for (let i = this.currentPage - 1; i <= this.currentPage + 1; i++) {
          pages.push(i);
        }
        pages.push(-1);
        pages.push(this.totalPages);
      }
    }
    return pages;
  }

  get paginatedItems() {
    if (!this.items) return [];

    // Update total pages
    this.totalPages = Math.max(
      1,
      Math.ceil(this.items.length / this.itemsPerPage)
    );

    // If current page is greater than total pages, reset to page 1
    if (this.currentPage > this.totalPages) {
      this.currentPage = 1;
    }

    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;

    // If current page has no items but we have items on other pages,
    // and we're not on page 1, reset to page 1
    const currentPageItems = this.items.slice(startIndex, endIndex);
    if (
      currentPageItems.length === 0 &&
      this.items.length > 0 &&
      this.currentPage > 1
    ) {
      this.currentPage = 1;
      return this.items.slice(0, this.itemsPerPage);
    }

    return currentPageItems;
  }

  // Add pagination methods for status sections
  goToStatusPage(status: string, page: number) {
    this.statusSections[status].currentPage = page;
  }

  getStatusPageNumbers(status: string): number[] {
    const pages = [];
    const maxVisiblePages = 3;
    const totalPages = this.statusSections[status].totalPages;
    const currentPage = this.statusSections[status].currentPage;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 2) {
        for (let i = 1; i <= 3; i++) {
          pages.push(i);
        }
        pages.push(-1); // Represents ellipsis
        pages.push(totalPages);
      } else if (currentPage >= totalPages - 1) {
        pages.push(1);
        pages.push(-1);
        for (let i = totalPages - 2; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        pages.push(1);
        pages.push(-1);
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pages.push(i);
        }
        pages.push(-1);
        pages.push(totalPages);
      }
    }
    return pages;
  }

  // Get paginated items for a specific status
  getPaginatedStatusItems(status: string) {
    if (!this.allEvents) return [];

    // For approved status, also include flagged events where the user is a participant
    if (status === 'approved') {
      const statusItems = this.allEvents.filter(
        item =>
          // Include events with matching status where user is creator
          (item.status === status &&
            (item.creator?.id === this.currentUserID ||
              item.creator === this.currentUserID)) ||
          // OR include flagged events where user is a participant
          (item.isFlagged &&
            item.participants?.some(
              participant => participant.id === this.currentUserID
            ))
      );

      const startIndex =
        (this.statusSections[status].currentPage - 1) *
        this.statusSections[status].itemsPerPage;
      const endIndex = startIndex + this.statusSections[status].itemsPerPage;

      return statusItems.slice(startIndex, endIndex);
    } else {
      // Only include events where the user is the creator (using currentUserID)
      const statusItems = this.allEvents.filter(
        item =>
          item.status === status &&
          (item.creator?.id === this.currentUserID ||
            item.creator === this.currentUserID)
      );

      // Sort pending events by date (oldest to newest)
      if (status === 'pending') {
        statusItems.sort((a, b) => {
          // Get dates for comparison (using startDate, fallback to date property)
          const dateA = this.getEventDate(a);
          const dateB = this.getEventDate(b);

          // If both dates are valid, compare them (ascending order - oldest first)
          if (dateA && dateB) {
            return dateA - dateB;
          }

          // If only one date is valid, prioritize the event with a valid date
          if (dateA && !dateB) return -1;
          if (!dateA && dateB) return 1;

          // If neither date is valid, keep original order
          return 0;
        });
      }

      const startIndex =
        (this.statusSections[status].currentPage - 1) *
        this.statusSections[status].itemsPerPage;
      const endIndex = startIndex + this.statusSections[status].itemsPerPage;

      return statusItems.slice(startIndex, endIndex);
    }
  }

  // Helper method to get event date timestamp for sorting
  private getEventDate(event: any): number | null {
    if (!event) return null;

    // Try to get the date from different possible properties
    let date = null;

    // First try startDate (preferred)
    if (event.startDate) {
      // Handle Firebase Timestamp objects
      if (event.startDate.seconds) {
        return event.startDate.seconds * 1000;
      } else if (typeof event.startDate === 'string') {
        date = new Date(event.startDate);
        if (!isNaN(date.getTime())) {
          return date.getTime();
        }
      }
    }

    // Then try date property
    if (event.date) {
      // Handle Firebase Timestamp objects
      if (event.date.seconds) {
        return event.date.seconds * 1000;
      } else if (typeof event.date === 'string') {
        date = new Date(event.date);
        if (!isNaN(date.getTime())) {
          return date.getTime();
        }
      }
    }

    // Finally try endDate as last resort
    if (event.endDate) {
      // Handle Firebase Timestamp objects
      if (event.endDate.seconds) {
        return event.endDate.seconds * 1000;
      } else if (typeof event.endDate === 'string') {
        date = new Date(event.endDate);
        if (!isNaN(date.getTime())) {
          return date.getTime();
        }
      }
    }

    return null;
  }

  // Method to dismiss the warning bar
  dismissWarning(): void {
    this.showAiWarning = false;
  }
}

