import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormService } from '../../../../shared/components/form/form.service';
import { filter, Subject, takeUntil } from 'rxjs';
import { IJobsFilter, jobsMenuData } from '../jobs';
import { AuthService } from '../../../../firebase/auth.service';
import { JobsStore } from '../jobs-store';
@Component({
  selector: 'app-jobs-filter',
  templateUrl: './jobs-filter.component.html',
  styleUrls: ['./jobs-filter.component.scss'],
})
export class JobsFilterComponent implements OnInit, OnDestroy {
  toolbarData = {
    arrowRouter: '/bazaar',
    title: 'Jobs',
  };
  jobsMenuData = jobsMenuData;
  myContent: boolean = false;
  location: string | null = null;
  menuInitialData;
  allItems = [];

  private destroy$ = new Subject<void>();

  constructor(private formService: FormService, private jobsStore: JobsStore) {}

  ngOnInit() {
    this.formService.formData$.next(jobsMenuData);
    this.listenFormChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onFormGroupChange(_e) {
    _e.patchValue(this.menuInitialData);
  }

  private listenFormChanges() {
    this.formService.jobsMenuData$
      .pipe(
        takeUntil(this.destroy$),
        filter(data => !!data)
      )
      .subscribe((filterData: IJobsFilter) => {
        this.menuInitialData = filterData;

        this.jobsStore.getItems(filterData);
      });
  }
}
