import { Component, Input, OnInit } from '@angular/core';
import { Timestamp } from 'firebase/firestore';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-services-item',
  templateUrl: './services-item.component.html',
  styleUrls: ['./services-item.component.scss'],
})
export class ServicesItemComponent {
  @Input() items;
  @Input() feedItemId: string;
  @Input() divider: { title: string; icon: string; color: string };
  @Input() transparent: boolean;
  @Input() status: string;

  showStatusModal: boolean = false;
  itemStatus;

  constructor() {}

  openStatusModal(status) {
    if (status) this.itemStatus = status;
    this.showStatusModal = !this.showStatusModal;
  }
}
