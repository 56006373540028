import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'activeEvents',
})
export class ActiveEventsPipe implements PipeTransform {
  transform(events: any[]): any[] {
    if (!events || !Array.isArray(events)) {
      return events;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return events.filter(event => {
      if (!event.startDate && !event.endDate) {
        return true;
      }

      let eventStartDate: Date;

      // Check by start date instead of end date
      if (event.startDate) {
        eventStartDate = this.parseEventDate(event.startDate);
      } else if (event.endDate) {
        // If no start date is available, fall back to end date
        eventStartDate = this.parseEventDate(event.endDate);
      } else {
        return true;
      }

      if (!eventStartDate || isNaN(eventStartDate.getTime())) {
        return true;
      }

      // Show event if start date is today or in the future
      return eventStartDate >= today;
    });
  }

  private parseEventDate(date: any): Date | null {
    if (!date) return null;

    try {
      // Handle Firebase Timestamp objects
      if (date.seconds) {
        return new Date(date.seconds * 1000);
      }
      if (date._seconds) {
        return new Date(date._seconds * 1000);
      }

      // Handle ISO string date (YYYY-MM-DD format)
      if (typeof date === 'string' && date.match(/^\d{4}-\d{2}-\d{2}$/)) {
        const [year, month, day] = date.split('-').map(Number);
        return new Date(year, month - 1, day);
      }

      // Handle regular date strings or objects
      return new Date(date);
    } catch (e) {
      console.warn('Could not parse date:', date);
      return null;
    }
  }
}

