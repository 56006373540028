import { Component, OnInit } from '@angular/core';
import { Navigation } from 'swiper';
import { ForceUpdateService } from '../services/force-update.service';

@Component({
  selector: 'app-check-version',
  templateUrl: './check-version.page.html',
  styleUrls: ['./check-version.page.scss'],
})
export class CheckVersionPage {
  notifications = [{ type: 'force-update', id: '1' }];
  isOpen = true;

  config = {
    modules: [Navigation],
    allowTouchMove: false,
    slideNextClass: '.continue__button',
    navigation: {
      nextEl: '.continue__button',
    },
  };
  constructor(private forceUpdateService: ForceUpdateService) {}

  async updateApp() {
    await this.forceUpdateService.updateApp();
  }
}
