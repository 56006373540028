import { Component, OnInit } from '@angular/core';
import { MoodService } from './mood.service';
import { quotes } from './mood-data';
import { Router } from '@angular/router';

const MOOD_READ_TIME = 6 * 1000;

@Component({
  selector: 'app-mood',
  templateUrl: './mood.component.html',
  styleUrls: ['./mood.component.scss'],
})
export class MoodComponent implements OnInit {
  background: string;

  quote: string;
  author: string;

  constructor(private service: MoodService, private route: Router) {}

  ngOnInit() {
    const randomQuote = this.service.getRandomQuote();
    this.quote = quotes[randomQuote].quote;
    this.author = quotes[randomQuote].author;
    this.background = `url(${quotes[randomQuote].img})`;

    setTimeout(() => {
      this.route.navigate(['/'], { replaceUrl: true });
    }, MOOD_READ_TIME);
  }
}
