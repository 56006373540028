// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[0]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[1]!../../../../../node_modules/flag-icons/css/flag-icons.min.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fi {\n  margin-right: 3px;\n}\n\n.invitation-btn {\n  height: auto;\n}\n\n.close-btn::part(native) {\n  --padding-top: 0;\n  --padding-end: 0;\n  --padding-bottom: 0;\n}\n\n.loading-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  z-index: 9999;\n}\n.loading-overlay .loading-spinner {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  z-index: 10000;\n}", "",{"version":3,"sources":["webpack://./src/app/merfolk/community/community-item/community-item.component.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;AAAF;;AAGA;EACE,YAAA;AAAF;;AAGA;EACE,gBAAA;EACA,gBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,oCAAA;EACA,aAAA;AAAF;AAGE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,cAAA;AADJ","sourcesContent":["@import \"flag-icons/css/flag-icons.min.css\";\r\n\r\n.fi {\r\n  margin-right: 3px;\r\n}\r\n\r\n.invitation-btn {\r\n  height: auto\r\n}\r\n\r\n.close-btn::part(native) {\r\n  --padding-top: 0;\r\n  --padding-end: 0;\r\n  --padding-bottom: 0;\r\n}\r\n\r\n.loading-overlay {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  right: 0;\r\n  bottom: 0;\r\n  background-color: rgba(0, 0, 0, 0.5);\r\n  z-index: 9999;\r\n\r\n\r\n  .loading-spinner {\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n    z-index: 10000;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
