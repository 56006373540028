import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import {
  ToastController,
  ModalController,
  AlertController,
  IonContent,
  IonPopover,
  IonInput,
  IonInfiniteScroll,
} from '@ionic/angular';
import {
  EventSearchService,
  EventSearchResult,
} from '../../services/event-search.service';
import { catchError, finalize, of, forkJoin } from 'rxjs';
import { ToastService } from '../../services/toast.service';
import { CacheService } from '../../services/cache.service';
import { getFunctions, httpsCallable } from 'firebase/functions';

// Extend the EventSearchResult interface to include category
declare module '../../services/event-search.service' {
  interface EventSearchResult {
    category?: string;
  }
}

@Component({
  selector: 'app-ai-events',
  templateUrl: './ai-events.component.html',
  styleUrls: ['./ai-events.component.scss'],
})
export class AiEventsComponent implements OnInit, AfterViewInit {
  @ViewChild(IonContent, { static: false }) content: IonContent;
  @ViewChild(IonPopover) filterPopover: IonPopover;
  @ViewChild('searchInput', { static: false })
  searchInput: ElementRef<IonInput>;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  showScrollTopButton = false;
  scrollThreshold = 300; // Show button after scrolling this many pixels

  // Filter properties
  activeFilters: {
    category: string[];
    priceRange: string;
    location: string[];
    startDate: string;
    endDate: string;
  } = {
    category: [],
    priceRange: null,
    location: [],
    startDate: null,
    endDate: null,
  };

  // Category options
  categoryOptions: string[] = [
    'Workshop',
    'Performance',
    'Competition',
    'Convention',
    'Pod Activity',
    'Class',
    'Other',
  ];

  // Price range options
  priceRangeOptions: string[] = ['Free', '0-50', '50-100', '100+'];

  // Location options
  locationOptions: string[] = [
    'North America',
    'Europe',
    'Asia',
    'Australia',
    'Africa',
    'South America',
    'Online',
  ];

  // Old price filter (to be removed)
  priceFilter: string = 'all';

  // Add scroll event listener for non-Ionic containers
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    // Check if we're near the bottom of the page
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const isNearBottom = scrollPosition + windowHeight >= documentHeight - 150;

    // Only show button when near the bottom
    this.showScrollTopButton = isNearBottom;
  }

  // Improved scroll to top method
  scrollToTop() {
    if (this.content) {
      this.content.scrollToTop(800); // Smoother scroll with 800ms duration
    } else {
      // Fallback for non-Ionic scroll with smooth behavior
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  // Listen for Ionic content scroll events
  ngAfterViewInit() {
    // Remove the forced button visibility for testing
    // setTimeout(() => {
    //   this.showScrollTopButton = true;
    //   console.log('Forced scroll button visibility:', this.showScrollTopButton);
    // }, 2000);

    // Wait for content to be available
    setTimeout(() => {
      if (this.content) {
        this.content.ionScroll.subscribe(event => {
          // Get scroll element
          this.content.getScrollElement().then(scrollElement => {
            // Calculate if we're near the bottom (within 150px of the bottom)
            const isNearBottom =
              event.detail.scrollTop + scrollElement.clientHeight >=
              scrollElement.scrollHeight - 150;

            // Only show button when near bottom
            this.showScrollTopButton = isNearBottom;
          });
        });

        // Also check when scrolling ends (for more reliable bottom detection)
        this.content.ionScrollEnd.subscribe(() => {
          this.checkIfAtBottom();
        });
      } else {
      }
    }, 500);
  }

  // Check if we're at the bottom of the content
  private checkIfAtBottom() {
    if (this.content) {
      this.content.getScrollElement().then(scrollElement => {
        const isAtBottom =
          Math.abs(
            scrollElement.scrollHeight -
              scrollElement.scrollTop -
              scrollElement.clientHeight
          ) < 50; // Within 50px of the bottom

        if (isAtBottom) {
          this.showScrollTopButton = true;
        }
      });
    }
  }

  isLoading = true;
  searchResults: EventSearchResult[] = [];
  errorMessage: string = '';
  customSearchQuery: string = '';
  isSearching = false;
  showFavorites = false;
  hasFavorites = false;

  // Pagination
  currentPage = 1;
  itemsPerPage = 5;
  totalPages = 1;

  // Default search sites - focus strictly on event-specific sites
  searchSites = [
    'eventbrite.com',
    'meetup.com',
    'google.com',
    'facebook.com/events',
    'officialmerlympics.com',
  ];

  // Suggested search terms
  suggestedSearchTerms = [
    'merlympics 2025',
    'mermaid conventions',
    'mermaid parade coney island',
    'mermagic con',
    'nc merfest 2025',
    'mermaid festivals',
  ];

  // Private property to cache favorites
  private _cachedFavorites: EventSearchResult[] | null = null;

  // Store original search results when switching to favorites view
  private _originalSearchResults: EventSearchResult[] | null = null;

  constructor(
    private toastController: ToastController,
    private modalController: ModalController,
    private eventSearchService: EventSearchService,
    private alertController: AlertController,
    private toastService: ToastService,
    private cacheService: CacheService
  ) {}

  ngOnInit() {
    const cacheKey = this.eventSearchService.createCacheKey(
      'mermaid events',
      this.searchSites
    );

    // Check if we have cached events that aren't stale
    const isCacheStale = this.cacheService.isStale(cacheKey);
    const cachedEvents = this.cacheService.get<EventSearchResult[]>(cacheKey);

    // Debug: Log the number of cached events

    if (cachedEvents && cachedEvents.length > 0 && !isCacheStale) {
      this.isLoading = false;

      // Filter out past events and non-event items before setting searchResults
      const filteredEvents = this.filterPastEvents(cachedEvents);
      this.searchResults = filteredEvents;

      // Filter and sort events by date (closest date first)
      this.sortEventsByDate();

      // Store original search results after filtering
      this._originalSearchResults = [...this.searchResults];

      // Calculate total pages
      this.totalPages = Math.ceil(
        this.searchResults.length / this.itemsPerPage
      );
      this.currentPage = 1;

      // Update favorites status
      this.loadFavorites();
    } else {
      // No cached events or cache is stale, load from API
      if (isCacheStale) {
      } else {
      }
      this.searchMermaidCommunityEvents();
    }

    // Load favorites from localStorage if available
    this.loadFavorites();
  }

  /**
   * Search specifically for mermaid costume events and community gatherings
   * @param mergeWithExisting Whether to merge with existing events or replace them
   */
  searchMermaidCommunityEvents(mergeWithExisting: boolean = false) {
    // Create a more comprehensive search query to find more events
    const queries = [
      'mermaid parade events 2025',
      'mermaid convention 2025',
      'mermaid festival 2025',
      'mermaid meetup events 2025',
      'mermaid swimming class events 2025',
      'mermaid performance events 2025',
      'mermaid workshop events 2025',
      'mermaid competition events 2025',
      'mermaid gathering events',
      'mermaid community events 2025',
      'merlympics 2025',
    ];

    // Use the multi-query search for better results
    this.searchMultipleQueries(queries, false, true); // Set bypassCache to false to use cache
  }

  /**
   * Search for events using the EventSearchService with Google API
   * @param query Search query
   * @param mergeWithExisting Whether to merge with existing events or replace them
   * @param bypassCache Whether to bypass the cache and fetch fresh data
   */
  searchEventsFromAPI(
    query: string,
    bypassCache: boolean = false,
    mergeWithExisting: boolean = false
  ) {
    // Store current filter state
    const currentPriceFilter = this.priceFilter;

    this.isLoading = true;
    this.errorMessage = '';
    this.isSearching = true;
    this.showFavorites = false;

    if (mergeWithExisting) {
      // Get the cache key
      const cacheKey = this.eventSearchService.createCacheKey(
        query,
        this.searchSites
      );

      // Get existing cached events
      const existingEvents =
        this.cacheService.get<EventSearchResult[]>(cacheKey) || [];

      // Fetch new events with cache bypass
      this.eventSearchService
        .searchEvents(query + ' event', this.searchSites, true)
        .pipe(
          catchError(error => {
            this.errorMessage =
              'Failed to search for events. Please try again later or try a different search term.';
            // Return empty array
            return of([]);
          }),
          finalize(() => {
            this.isLoading = false;
            this.isSearching = false;
          })
        )
        .subscribe(newEvents => {
          if (newEvents.length === 0) {
            this.errorMessage =
              'No new events found. Please try a different search term.';
          } else {
            // Pre-process events to extract categories
            this.preprocessEvents(newEvents);

            // Filter out past events and non-events
            const filteredEvents = this.filterPastEvents(newEvents);

            // Merge with existing events
            this.mergeAndUpdateEvents(existingEvents, filteredEvents, cacheKey);

            // Update category options based on all events
            this.updateCategoryOptions([...existingEvents, ...filteredEvents]);
          }
        });
    } else {
      // Original behavior - replace all events
      this.eventSearchService
        .searchEvents(query + ' event', this.searchSites, bypassCache)
        .pipe(
          catchError(error => {
            this.errorMessage =
              'Failed to search for events. Please try again later or try a different search term.';
            // Return empty array
            return of([]);
          }),
          finalize(() => {
            this.isLoading = false;
            this.isSearching = false;
          })
        )
        .subscribe(results => {
          if (results.length === 0) {
            this.errorMessage =
              'No events found. Please try a different search term.';
            this.searchResults = [];
          } else {
            // Pre-process events to extract categories
            this.preprocessEvents(results);

            // Filter out past events and non-events
            const filteredEvents = this.filterPastEvents(results);

            this.searchResults = filteredEvents;
            this.errorMessage = '';

            // Sort events by date (closest date first)
            this.sortEventsByDate();

            // Store original search results
            this._originalSearchResults = [...this.searchResults];

            // Update category options based on fetched events
            this.updateCategoryOptions(filteredEvents);

            // Calculate total pages
            this.totalPages = Math.ceil(
              this.searchResults.length / this.itemsPerPage
            );
            this.currentPage = 1;

            // Update favorites status
            this.loadFavorites();
          }
        });
    }
  }

  /**
   * Sort events by date (closest date first) and filter out past events
   */
  private sortEventsByDate() {
    // Get today's date for comparison
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // First filter out past events
    this.searchResults = this.searchResults.filter(event => {
      // If date is TBD, keep it
      if (event.date === 'Date TBD') {
        return true;
      }

      // Try to parse the date
      const eventDate = this.parseEventDate(event.date);

      // If we couldn't parse the date, keep the event
      if (!eventDate) {
        return true;
      }

      // Only keep events that are today or in the future
      return eventDate >= today;
    });

    // Then sort the remaining events by date (closest future date first)
    this.searchResults.sort((a, b) => {
      // If either date is TBD, put it at the end
      if (a.date === 'Date TBD' && b.date !== 'Date TBD') return 1;
      if (a.date !== 'Date TBD' && b.date === 'Date TBD') return -1;
      if (a.date === 'Date TBD' && b.date === 'Date TBD') return 0;

      // Try to parse dates
      const dateA = this.parseEventDate(a.date);
      const dateB = this.parseEventDate(b.date);

      // If both dates are valid, compare them
      if (dateA && dateB) {
        return dateA.getTime() - dateB.getTime();
      }

      // If we can't parse one of the dates, keep original order
      return 0;
    });
  }

  /**
   * Parse event date string into Date object
   */
  private parseEventDate(dateStr: string): Date | null {
    try {
      // Try YYYY-MM-DD format
      if (/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
        return new Date(dateStr);
      }
      // Try MM/DD/YYYY format
      else if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateStr)) {
        const parts = dateStr.split('/');
        return new Date(
          parseInt(parts[2]),
          parseInt(parts[0]) - 1,
          parseInt(parts[1])
        );
      }
      // Try Month DD, YYYY format (e.g., "January 1, 2023" or "Jan 1, 2023")
      else if (/^[A-Za-z]+ \d{1,2},? \d{4}$/.test(dateStr)) {
        return new Date(dateStr);
      }
      // Try Day Month Year format (e.g., "1 January 2023" or "1 Jan 2023")
      else if (/^\d{1,2} [A-Za-z]+ \d{4}$/.test(dateStr)) {
        return new Date(dateStr);
      }
    } catch (e) {}
    return null;
  }

  /**
   * Search for events using the EventSearchService
   * @param query Search query
   * @param bypassCache Whether to bypass the cache and fetch fresh data
   */
  searchEvents(query: string, bypassCache: boolean = false) {
    // Add search history
    this.searchEventsFromAPI(query, bypassCache);
  }

  /**
   * Handle custom search submission
   */
  onSearchSubmit() {
    if (!this.customSearchQuery || !this.customSearchQuery.trim()) {
      return;
    }

    // Clear any previous error
    this.errorMessage = '';

    // Parse the input for multiple queries
    const rawInput = this.customSearchQuery.trim();
    let queries: string[] = [];

    // Check if the search term is a specific named event
    const isSpecificNamedEvent = [
      'merlympics',
      'mermagic',
      'merfest',
      'coney island parade',
      'mermaid parade',
    ].some(term => rawInput.toLowerCase().includes(term));

    // If it's a specific named event, don't modify the query too much
    if (isSpecificNamedEvent) {
      console.log(
        'Detected specific named event, using minimal query enhancements'
      );
      queries = [rawInput];
    }
    // Check if the input contains OR operator or separators (semicolons, commas, or newlines)
    else if (rawInput.includes(' OR ')) {
      // First handle explicit OR operators in the query
      const orParts = rawInput
        .split(' OR ')
        .map(q => q.trim())
        .filter(q => q.length > 0);

      // For each OR part, expand to a full search term
      orParts.forEach(part => {
        // Add mermaid terms if needed
        const hasMermaidTerm = part.toLowerCase().includes('mermaid');
        const hasMermenTerm =
          part.toLowerCase().includes('mermen') ||
          part.toLowerCase().includes('merman');

        if (!hasMermaidTerm && !hasMermenTerm) {
          part = `mermaid ${part}`;
        }

        // Add events if not included
        if (
          !part.toLowerCase().includes('event') &&
          !part.toLowerCase().includes('parade') &&
          !part.toLowerCase().includes('festival') &&
          !part.toLowerCase().includes('convention')
        ) {
          part = `${part} events`;
        }

        queries.push(part);
      });
    } else if (
      rawInput.includes(';') ||
      rawInput.includes(',') ||
      rawInput.includes('\n')
    ) {
      // Split by semicolons, commas, or newlines and filter out empty queries
      queries = rawInput
        .split(/[;,\n]/)
        .map(q => q.trim())
        .filter(q => q.length > 0)
        .map(q => {
          // Add mermaid terms if needed
          const hasMermaidTerm = q.toLowerCase().includes('mermaid');
          const hasMermenTerm =
            q.toLowerCase().includes('mermen') ||
            q.toLowerCase().includes('merman');

          if (!hasMermaidTerm && !hasMermenTerm) {
            q = `mermaid ${q}`;
          }

          // Add events if not included
          if (
            !q.toLowerCase().includes('event') &&
            !q.toLowerCase().includes('parade') &&
            !q.toLowerCase().includes('festival') &&
            !q.toLowerCase().includes('convention')
          ) {
            q = `${q} events`;
          }

          return q;
        });
    } else {
      // Single query with expanded search terms
      let query = rawInput;

      // Check if the query already includes mermaid-related terms
      const hasMermaidTerm = query.toLowerCase().includes('mermaid');
      const hasMermenTerm =
        query.toLowerCase().includes('mermen') ||
        query.toLowerCase().includes('merman');

      // If not, add "mermaid" to the beginning of the query
      if (!hasMermaidTerm && !hasMermenTerm) {
        query = `mermaid ${query}`;
      }

      // Add "events" if it's not already in the query and doesn't have event-related terms
      if (
        !query.toLowerCase().includes('event') &&
        !query.toLowerCase().includes('parade') &&
        !query.toLowerCase().includes('festival') &&
        !query.toLowerCase().includes('convention')
      ) {
        query = `${query} events`;
      }

      // Create enhanced queries for better search coverage
      queries = [query];

      // Add additional related search terms if the query is simple enough
      if (query.split(' ').length <= 3) {
        if (query.toLowerCase().includes('parade')) {
          queries.push(query.replace('parade', 'festival'));
        } else if (query.toLowerCase().includes('festival')) {
          queries.push(query.replace('festival', 'parade'));
        }
        if (!query.toLowerCase().includes('conference')) {
          queries.push(`${query.replace('events', '')} conference`);
        }
      }
    }

    // Use the multi-query search for better results
    if (queries.length >= 1) {
      console.log(`Searching with ${queries.length} queries:`, queries);

      // Show loading state
      this.isLoading = true;
      this.errorMessage = '';
      this.showToast(`Searching for mermaid events...`);

      // searchMultipleQueries returns an Observable, we need to subscribe to it
      this.searchMultipleQueries(queries, true);
    }

    // Dismiss keyboard on mobile
    this.dismissKeyboard();
  }

  /**
   * Use a suggested search term
   * @param term The suggested term to search for
   */
  useSuggestedTerm(term: string) {
    this.customSearchQuery = term;
    this.onSearchSubmit();

    // Dismiss keyboard on mobile
    this.dismissKeyboard();
  }

  /**
   * Clear the search input
   */
  clearSearch() {
    this.customSearchQuery = '';

    // Focus on the input after clearing
    setTimeout(() => {
      if (this.searchInput) {
        this.searchInput.nativeElement.setFocus();
      }
    }, 100);
  }

  /**
   * Dismiss the keyboard on mobile devices
   */
  private dismissKeyboard() {
    // Blur the input element to dismiss the keyboard
    if (this.searchInput && this.searchInput.nativeElement) {
      this.searchInput.nativeElement.getInputElement().then(inputEl => {
        if (inputEl) {
          inputEl.blur();
        }
      });
    }

    // For iOS devices, we can also try this approach
    if (typeof (window as any).Keyboard !== 'undefined') {
      (window as any).Keyboard.hide();
    }
  }

  /**
   * Show a toast message
   * @param message The message to display
   * @param color The color of the toast (optional)
   */
  async showToast(message: string, color: string = 'primary') {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: 'bottom',
      color: color,
    });

    toast.present();
  }

  /**
   * Open the event URL in a new tab
   */
  openEventUrl(url: string, event?: Event): void {
    if (event) {
      event.stopPropagation(); // Prevent event bubbling
    }

    if (!url) {
      this.toastService.presentToast(
        'No URL available for this event',
        'warning'
      );
      return;
    }

    // Add https:// prefix only if the URL doesn't already have a protocol
    const formattedUrl = url.startsWith('http') ? url : 'https://' + url;
    window.open(formattedUrl, '_blank');
  }

  /**
   * Get paginated events based on current page and filter settings
   */
  get paginatedEvents(): EventSearchResult[] {
    // Determine base results based on whether we're showing favorites or search results
    const baseResults = this.showFavorites
      ? this.getFavoriteEvents()
      : this.searchResults;

    // Calculate start and end indices for pagination
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = Math.min(
      startIndex + this.itemsPerPage,
      baseResults.length
    );

    // Update total pages based on filtered results
    this.totalPages = Math.ceil(baseResults.length / this.itemsPerPage);

    // If current page is greater than total pages, reset to page 1
    if (this.currentPage > this.totalPages && this.totalPages > 0) {
      this.currentPage = 1;
      return this.paginatedEvents; // Recursive call with updated page
    }

    // Return the paginated slice of filtered results
    return baseResults.slice(startIndex, endIndex);
  }

  /**
   * Go to a specific page
   */
  goToPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  /**
   * Get an array of page numbers for pagination
   */
  get pageNumbers(): number[] {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  /**
   * Save favorites and flagged events to localStorage
   */
  saveFavorites() {
    // Save favorite events
    const favorites = this.searchResults
      .filter(event => event.isMustGo)
      .map(event => ({
        title: event.title,
        description: event.description,
        date: event.date,
        time: event.time,
        location: event.location,
        url: event.url,
        imageUrl: event.imageUrl,
        isMustGo: true,
        isFlagged: event.isFlagged || false,
        flagReason: event.flagReason || '',
      }));

    // Use localStorage directly for favorites instead of cache service
    localStorage.setItem('favoriteEvents', JSON.stringify(favorites));

    // Save all flagged events separately (regardless of favorite status)
    const flaggedEvents = this.searchResults
      .filter(event => event.isFlagged)
      .map(event => ({
        title: event.title,
        description: event.description,
        date: event.date,
        time: event.time,
        location: event.location,
        url: event.url,
        imageUrl: event.imageUrl,
        isMustGo: event.isMustGo || false,
        isFlagged: true,
        flagReason: event.flagReason,
      }));

    // Calculate expiry time based on event date
    // For each flagged event, set expiry to either:
    // 1. End of the event day if date is parseable and in the future
    // 2. Default to a longer expiry (7 days) if date can't be parsed or is "Date TBD"
    if (flaggedEvents.length > 0) {
      // Store flagged events with their individual expiry times
      flaggedEvents.forEach(event => {
        const eventKey = `flagged_event_${event.title
          .replace(/\s+/g, '_')
          .toLowerCase()}`;

        // Calculate expiry time based on event date
        let expiryTime = 7 * 24 * 60 * 60 * 1000; // Default: 7 days in milliseconds

        // Try to parse the event date
        const eventDate = this.parseEventDate(event.date);
        if (eventDate) {
          // Set expiry to end of the event day
          const endOfEventDay = new Date(eventDate);
          endOfEventDay.setHours(23, 59, 59, 999);

          // Calculate milliseconds from now until end of event day
          const now = new Date();
          expiryTime = endOfEventDay.getTime() - now.getTime();

          // If event date has already passed, use a short expiry (1 hour)
          if (expiryTime <= 0) {
            expiryTime = 60 * 60 * 1000; // 1 hour
          }
        }

        // Store the individual flagged event with its calculated expiry
        this.cacheService.set(eventKey, event, expiryTime);
      });

      // Also store the list of all flagged events with a longer expiry
      this.cacheService.set(
        'flaggedEvents',
        flaggedEvents,
        7 * 24 * 60 * 60 * 1000
      );
    } else {
      // Clear flagged events if none exist
      this.cacheService.remove('flaggedEvents');
    }

    // Update hasFavorites property
    this.hasFavorites = favorites.length > 0;

    // Clear the cached favorites to ensure fresh data next time
    this._cachedFavorites = null;
  }

  /**
   * Load favorites and flagged events from localStorage
   */
  loadFavorites() {
    // Load favorites from localStorage directly
    const favoritesJson = localStorage.getItem('favoriteEvents');
    const favorites = favoritesJson ? JSON.parse(favoritesJson) : [];

    // Load flagged events from cache service
    let flaggedEvents =
      this.cacheService.get<EventSearchResult[]>('flaggedEvents') || [];

    // Also check for individually stored flagged events
    const individualFlaggedEvents: EventSearchResult[] = [];

    // Get all cache keys
    const allKeys = Object.keys(localStorage).filter(key =>
      key.startsWith(this.cacheService.getCachePrefix() + 'flagged_event_')
    );

    // Load each individual flagged event
    allKeys.forEach(fullKey => {
      // Remove the cache prefix to get the actual key
      const key = fullKey.substring(this.cacheService.getCachePrefix().length);
      const event = this.cacheService.get<EventSearchResult>(key);
      if (event) {
        individualFlaggedEvents.push(event);
      }
    });

    // Filter out any flagged events that don't exist in the individual cache
    // This ensures that if an event was unflagged, it won't appear in the flagged list
    const validFlaggedEventTitles = new Set(
      individualFlaggedEvents.map(event => event.title)
    );

    flaggedEvents = flaggedEvents.filter(event =>
      validFlaggedEventTitles.has(event.title)
    );

    // If the filtered list is different from the original, update the cache
    if (
      flaggedEvents.length <
      this.cacheService.get<EventSearchResult[]>('flaggedEvents')?.length
    ) {
      if (flaggedEvents.length > 0) {
        this.cacheService.set(
          'flaggedEvents',
          flaggedEvents,
          7 * 24 * 60 * 60 * 1000
        );
      } else {
        this.cacheService.remove('flaggedEvents');
      }
    }

    // Merge with the main flagged events list, prioritizing individual events
    // (as they might be more up-to-date)
    if (individualFlaggedEvents.length > 0) {
      // Create a map of existing flagged events by title
      const flaggedMap = new Map<string, EventSearchResult>();
      flaggedEvents.forEach(event => {
        flaggedMap.set(event.title, event);
      });

      // Add or update with individual events
      individualFlaggedEvents.forEach(event => {
        flaggedMap.set(event.title, event);
      });

      // Convert map back to array
      flaggedEvents = Array.from(flaggedMap.values());
    }

    // Update UI state
    this.hasFavorites = favorites.length > 0;

    // Update current search results with favorite and flag status
    if (this.searchResults.length > 0) {
      this.updateFavoritesAndFlagStatus(favorites, flaggedEvents);
    }
  }

  /**
   * Update the favorite and flag status of search results
   */
  updateFavoritesAndFlagStatus(
    favorites: EventSearchResult[],
    flaggedEvents: EventSearchResult[]
  ) {
    // Create maps for faster lookup
    const favoriteMap = new Map<string, EventSearchResult>();
    favorites.forEach(favorite => {
      favoriteMap.set(favorite.title, favorite);
    });

    const flaggedMap = new Map<string, EventSearchResult>();
    flaggedEvents.forEach(flagged => {
      flaggedMap.set(flagged.title, flagged);
    });

    // Reset all isMustGo flags first to avoid stale state
    this.searchResults.forEach(result => {
      result.isMustGo = false;
      result.isFlagged = false;
      result.flagReason = '';
    });

    // Update search results with favorite and flag status
    this.searchResults.forEach(result => {
      // Check if it's a favorite
      const favorite = favoriteMap.get(result.title);
      if (favorite) {
        result.isMustGo = true;
        // Also update flag status from favorites
        if (favorite.isFlagged) {
          result.isFlagged = true;
          result.flagReason = favorite.flagReason;
        }
      }

      // Check if it's flagged (this will override the favorite flag status if both exist)
      const flagged = flaggedMap.get(result.title);
      if (flagged) {
        // Double-check if this event is still flagged in the individual cache
        const eventKey = `flagged_event_${result.title
          .replace(/\s+/g, '_')
          .toLowerCase()}`;
        const individualFlagged =
          this.cacheService.get<EventSearchResult>(eventKey);

        // Only mark as flagged if it exists in the individual cache
        if (individualFlagged && individualFlagged.isFlagged) {
          result.isFlagged = true;
          result.flagReason = flagged.flagReason;
        }
      }
    });
  }

  /**
   * Toggle between favorites view and search results
   */
  toggleFavoritesView() {
    this.showFavorites = !this.showFavorites;
    this.currentPage = 1; // Reset to first page

    if (this.showFavorites) {
      // If we're showing favorites, get the favorite events
      const favoriteEvents = this.getFavoriteEvents();

      this.totalPages = Math.ceil(favoriteEvents.length / this.itemsPerPage);
    } else {
      // If we're showing all events, use the filtered search results
      this.totalPages = Math.ceil(
        this.searchResults.length / this.itemsPerPage
      );
    }

    // Close the filter popover if it's open
    this.closeFilterPopover();
  }

  /**
   * Helper method to apply price filter to an event
   */
  private applyPriceFilter(event: EventSearchResult): boolean {
    if (this.priceFilter === 'all') {
      return true;
    } else if (this.priceFilter === 'free') {
      return event.price?.toLowerCase().includes('free');
    } else if (this.priceFilter === 'paid') {
      return event.price && !event.price.toLowerCase().includes('free');
    }
    return true;
  }

  /**
   * Get favorite events from localStorage and flagged events from cache
   */
  private getFavoriteEvents(): EventSearchResult[] {
    // Use cached results if available
    if (this._cachedFavorites) {
      return this._cachedFavorites;
    }

    // Load favorites from localStorage directly
    const favoritesJson = localStorage.getItem('favoriteEvents');
    const favorites = favoritesJson ? JSON.parse(favoritesJson) : [];

    // Load flagged events from main cache
    let flaggedEvents =
      this.cacheService.get<EventSearchResult[]>('flaggedEvents') || [];

    // Also check for individually stored flagged events
    const individualFlaggedEvents: EventSearchResult[] = [];

    // Get all cache keys
    const allKeys = Object.keys(localStorage).filter(key =>
      key.startsWith(this.cacheService.getCachePrefix() + 'flagged_event_')
    );

    // Load each individual flagged event
    allKeys.forEach(fullKey => {
      // Remove the cache prefix to get the actual key
      const key = fullKey.substring(this.cacheService.getCachePrefix().length);
      const event = this.cacheService.get<EventSearchResult>(key);
      if (event) {
        individualFlaggedEvents.push(event);
      }
    });

    // Merge with the main flagged events list, prioritizing individual events
    if (individualFlaggedEvents.length > 0) {
      // Create a map of existing flagged events by title
      const flaggedMap = new Map<string, EventSearchResult>();
      flaggedEvents.forEach(event => {
        flaggedMap.set(event.title, event);
      });

      // Add or update with individual events
      individualFlaggedEvents.forEach(event => {
        flaggedMap.set(event.title, event);
      });

      // Convert map back to array
      flaggedEvents = Array.from(flaggedMap.values());
    }

    // Combine favorites and flagged events, removing duplicates
    const combinedEvents = [...favorites];

    // Add flagged events that aren't already in favorites
    flaggedEvents.forEach(flagged => {
      const existingIndex = combinedEvents.findIndex(
        e => e.title === flagged.title
      );
      if (existingIndex === -1) {
        combinedEvents.push(flagged);
      }
    });

    // Sort by date and cache the result
    this._cachedFavorites = this.sortEventsByDateHelper([...combinedEvents]);
    return this._cachedFavorites;
  }

  /**
   * Helper method to sort events by date without modifying the original array
   */
  private sortEventsByDateHelper(
    events: EventSearchResult[]
  ): EventSearchResult[] {
    return events.sort((a, b) => {
      const dateA = a.date ? new Date(a.date).getTime() : 0;
      const dateB = b.date ? new Date(b.date).getTime() : 0;
      return dateA - dateB;
    });
  }

  /**
   * Fallback to load mock events if API fails
   */
  loadMockEvents() {
    this.isLoading = true;
    this.errorMessage = '';

    setTimeout(() => {
      this.searchResults = this.generateMockEvents('mermaid events');
      this.isLoading = false;

      // Calculate total pages
      this.totalPages = Math.ceil(
        this.searchResults.length / this.itemsPerPage
      );

      // Update favorites status
      this.loadFavorites();
    }, 1500);
  }

  /**
   * Generate mock events based on the search query
   * @param query Search query
   * @returns Array of mock events
   */
  private generateMockEvents(query: string): EventSearchResult[] {
    console.warn(
      'Mock events generation is disabled. Using real events from API.'
    );
    return [];
  }

  /**
   * Toggle favorite status for an event
   */
  toggleFavorite(event: EventSearchResult, clickEvent?: Event): void {
    if (clickEvent) {
      clickEvent.stopPropagation(); // Prevent opening the URL when clicking the favorite button
    }

    event.isMustGo = !event.isMustGo;

    // Save to localStorage
    this.saveFavorites();

    // Show a toast message
    const message = event.isMustGo
      ? `Added "${event.title}" to your favorites!`
      : `Removed "${event.title}" from your favorites`;

    this.toastService.presentToast(
      message,
      event.isMustGo ? 'success' : 'medium'
    );
  }

  /**
   * Show flag options for an event
   */
  showFlagOptions(event: EventSearchResult, clickEvent?: Event): void {
    if (clickEvent) {
      clickEvent.stopPropagation();
      clickEvent.preventDefault();
    }

    if (event.isFlagged) {
      // If already flagged, show the reason and option to unflag
      const alert = this.alertController.create({
        header: 'Flagged Event',
        message: `This event was flagged as: ${event.flagReason}`,
        buttons: [
          {
            text: 'Keep Flag',
            role: 'cancel',
          },
          {
            text: 'Remove Flag',
            handler: () => {
              event.isFlagged = false;
              event.flagReason = null;
              this.saveFavorites();
              this.showToast('Flag removed from event', 'success');
            },
          },
        ],
      });

      alert.then(alertElement => {
        alertElement.present();
      });
      return;
    }

    // First show options to select a reason for flagging
    const initialAlert = this.alertController.create({
      header: 'Flag This Event',
      message: 'Please select a reason for flagging this event:',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Flag',
          handler: reason => {
            if (reason === 'Other issue') {
              // If "Other issue" is selected, show a second alert for custom reason
              this.showOtherReasonInput(event);
            } else if (reason) {
              // If any other reason, process as before
              event.isFlagged = true;
              event.flagReason = reason;
              this.saveFavorites();
              this.showToast(`Event flagged as ${reason}`, 'danger');

              // Send flagged events to OpenAI for learning
              // This is done asynchronously to not block the UI
              setTimeout(() => {
                this.sendFlaggedEventsForLearning();
              }, 500);
            }
          },
        },
      ],
      inputs: [
        {
          name: 'not-mermaid',
          type: 'radio',
          label: 'Not mermaid-related',
          value: 'Not mermaid-related',
        },
        {
          name: 'scam',
          type: 'radio',
          label: 'Potential scam',
          value: 'Potential scam',
        },
        {
          name: 'low-quality',
          type: 'radio',
          label: 'Low-quality event',
          value: 'Low-quality event',
        },
        {
          name: 'incorrect-info',
          type: 'radio',
          label: 'Incorrect information',
          value: 'Incorrect information',
        },
        {
          name: 'other',
          type: 'radio',
          label: 'Other issue',
          value: 'Other issue',
        },
      ],
    });

    initialAlert.then(alertElement => {
      alertElement.present();
    });
  }

  // Show second alert for custom reason input when "Other" is selected
  private showOtherReasonInput(event: EventSearchResult) {
    const otherReasonAlert = this.alertController.create({
      header: 'Other Issue',
      message: 'Please describe the issue:',
      inputs: [
        {
          name: 'customReason',
          type: 'text',
          placeholder: 'Describe the issue...',
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Submit',
          handler: data => {
            if (data.customReason && data.customReason.trim() !== '') {
              event.isFlagged = true;
              event.flagReason = `Other: ${data.customReason}`;
              this.saveFavorites();
              this.showToast(`Event flagged`, 'danger');

              // Send flagged events to OpenAI for learning
              setTimeout(() => {
                this.sendFlaggedEventsForLearning();
              }, 500);
            } else {
              this.showToast(
                'Please provide a description of the issue',
                'warning'
              );
              return false; // Keep the dialog open if no description
            }
          },
        },
      ],
    });

    otherReasonAlert.then(alertElement => {
      alertElement.present();
    });
  }

  // Send email notification to admin about flagged event
  private notifyAdminAboutFlaggedEvent(
    event: EventSearchResult,
    reason: string
  ) {
    // Get Firebase functions
    const functions = getFunctions();
    const sendAdminNotification = httpsCallable(functions, 'sendMail');

    // Prepare notification data
    const notificationData = {
      recipientEmail: 'admin@mermapp.com', // Replace with actual admin email
      subject: 'AI Event Flagged: Action Required',
      message: `
        An AI-generated event has been flagged by a user:

        Event Title: ${event.title}
        Event Description: ${event.description?.substring(0, 100)}...
        Flagged Reason: ${reason}

        Please review this event at your earliest convenience.
      `,
      sender: 'Mermapp System',
    };

    // Send the notification
    sendAdminNotification(notificationData).catch(error => {
      console.error('Error sending admin notification:', error);
    });
  }

  /**
   * Send flagged events to OpenAI for learning
   * This method would typically call a backend API that would then communicate with OpenAI
   */
  sendFlaggedEventsForLearning() {
    const flaggedEvents =
      this.cacheService.get<EventSearchResult[]>('flaggedEvents') || [];

    if (flaggedEvents.length === 0) {
      return;
    }

    // Format the data for OpenAI learning
    const trainingData = flaggedEvents.map(event => ({
      event_title: event.title,
      event_description: event.description,
      flag_reason: event.flagReason,
      event_url: event.url,
      // Add metadata to help OpenAI understand the context
      metadata: {
        date_flagged: new Date().toISOString(),
        app_version: '1.0.0',
      },
    }));

    // Here you would typically send this data to your backend API
    console.log('Sending flagged events to OpenAI for learning', trainingData);

    // Also notify admin about the most recently flagged event
    if (flaggedEvents.length > 0) {
      const mostRecentEvent = flaggedEvents[flaggedEvents.length - 1];
      this.notifyAdminAboutFlaggedEvent(
        mostRecentEvent,
        mostRecentEvent.flagReason
      );
    }
  }

  /**
   * Refresh events by merging new events with existing cached events
   */
  refreshEvents() {
    // Store current filter state
    const currentPriceFilter = this.priceFilter;

    // Show loading indicator and toast
    this.isLoading = true;
    this.showToast('Refreshing events...', 'primary');

    // Use the custom search query if available, otherwise use the default query
    if (this.customSearchQuery) {
      this.searchEventsFromAPI(this.customSearchQuery, true, true);
    } else {
      this.searchMermaidCommunityEvents(true);
    }

    // Restore price filter after refresh
    // Note: This will be applied automatically through the paginatedEvents getter
  }

  /**
   * Merge existing events with new events and update the cache
   * @param existingEvents Existing events from cache
   * @param newEvents New events from API
   * @param cacheKey Cache key for storing merged events
   */
  private mergeAndUpdateEvents(
    existingEvents: EventSearchResult[],
    newEvents: EventSearchResult[],
    cacheKey: string
  ) {
    // Create a map of existing events by title to avoid duplicates
    const existingMap = new Map<string, EventSearchResult>();
    existingEvents.forEach(event => {
      existingMap.set(event.title, event);
    });

    // Add or update events from newEvents
    newEvents.forEach(event => {
      existingMap.set(event.title, event);
    });

    // Convert map back to array
    const mergedEvents = Array.from(existingMap.values());

    // Sort events by date
    this.sortEventsByDate();

    // Update search results
    this.searchResults = mergedEvents;
    this._originalSearchResults = [...this.searchResults];

    // Update category options
    this.updateCategoryOptions(mergedEvents);

    // Calculate total pages
    this.totalPages = Math.ceil(mergedEvents.length / this.itemsPerPage);
    this.currentPage = 1;

    // Update favorites status
    this.loadFavorites();

    // Cache the merged results with a longer expiry (24 hours)
    this.cacheService.set(cacheKey, mergedEvents, 24 * 60 * 60 * 1000);
  }

  /**
   * Set the price filter and reset to first page
   * @deprecated This method is deprecated and will be removed in future versions
   */
  setPriceFilter(filter: 'all' | 'free' | 'paid') {
    if (this.priceFilter !== filter) {
      this.priceFilter = filter;
      this.currentPage = 1;

      // Get the base results based on current view
      const baseResults = this.showFavorites
        ? this.getFavoriteEvents()
        : this.searchResults;

      // Apply the new filter and update total pages
      const filteredResults = baseResults.filter(
        this.applyPriceFilter.bind(this)
      );
      this.totalPages = Math.ceil(filteredResults.length / this.itemsPerPage);
    }
  }

  /**
   * Close the filter popover
   */
  closeFilterPopover() {
    if (this.filterPopover) {
      this.filterPopover.dismiss();
    }
  }

  /**
   * Handle date picker modal dismiss event
   * This prevents the filter popover from automatically closing when a date is selected
   */
  handleDateModalDidDismiss(event: any): void {
    // Stop propagation to prevent the parent popover from closing
    if (event) {
      event.stopPropagation();
    }
  }

  /**
   * Check if any filters are active
   */
  hasActiveFilters(): boolean {
    // Check if any non-date filters are active
    const hasNonDateFilters =
      (this.activeFilters.category && this.activeFilters.category.length > 0) ||
      this.activeFilters.priceRange !== null ||
      (this.activeFilters.location && this.activeFilters.location.length > 0);

    // Check if date filters are valid
    const hasValidStartDate =
      this.activeFilters.startDate !== null &&
      this.activeFilters.startDate !== undefined;
    const hasValidEndDate =
      this.activeFilters.endDate !== null &&
      this.activeFilters.endDate !== undefined;

    // Return true if any filter is active
    return hasNonDateFilters || hasValidStartDate || hasValidEndDate;
  }

  /**
   * Apply the active filters
   */
  applyFilters() {
    this.currentPage = 1;
    if (!this._originalSearchResults) {
      return;
    }

    // Start with all results
    let filteredResults = [...this._originalSearchResults];

    // Apply category filter
    if (this.activeFilters.category && this.activeFilters.category.length > 0) {
      filteredResults = filteredResults.filter(event => {
        // If event doesn't have categories, extract them from title and description
        if (!event.category || event.category.trim() === '') {
          const extractedCategories = this.extractCategoriesFromText(
            `${event.title} ${event.description}`
          );

          // Assign the extracted categories to the event
          if (extractedCategories.length > 0) {
            event.category = extractedCategories.join(', ');
          } else {
            event.category = 'Other';
          }
        }

        // Check if event category matches any of the selected categories
        const eventCategories = event.category
          .split(',')
          .map(cat => cat.trim());

        // Check if any of the selected categories match the event categories
        return this.activeFilters.category.some(selectedCat =>
          eventCategories.some(
            eventCat =>
              eventCat.toLowerCase() === selectedCat.toLowerCase() ||
              eventCat.toLowerCase().includes(selectedCat.toLowerCase()) ||
              selectedCat.toLowerCase().includes(eventCat.toLowerCase())
          )
        );
      });
    }

    // Apply price range filter
    if (this.activeFilters.priceRange) {
      filteredResults = filteredResults.filter(event => {
        // Extract numeric price if it's a string
        let numericPrice = 0;
        let isFree = false;

        // Check if price is a string
        if (typeof event.price === 'string') {
          // Check if it's explicitly marked as free
          if (event.price.toLowerCase().includes('free')) {
            isFree = true;
          } else {
            // Try to extract numeric value using regex
            const priceMatch = event.price.match(/\$?(\d+(\.\d+)?)/);
            numericPrice = priceMatch ? parseFloat(priceMatch[1]) : 0;
          }
        }
        // Check if price is an object with value property (from database events)
        else if (
          event.price &&
          typeof event.price === 'object' &&
          'value' in event.price
        ) {
          const priceObj = event.price as {
            value: string | number;
            currency?: string;
          };
          if (typeof priceObj.value === 'string') {
            if (priceObj.value.toLowerCase().includes('free')) {
              isFree = true;
            } else {
              numericPrice = parseFloat(priceObj.value);
            }
          } else if (typeof priceObj.value === 'number') {
            numericPrice = priceObj.value;
          }
        }
        // Check if price is a number
        else if (typeof event.price === 'number') {
          numericPrice = event.price;
        }
        // If no price info is found, check title and description for free indicators
        else if (!event.price) {
          const combinedText =
            `${event.title} ${event.description}`.toLowerCase();
          if (
            combinedText.includes('free') ||
            combinedText.includes('no charge') ||
            combinedText.includes('complimentary')
          ) {
            isFree = true;
          }
        }

        // Apply filter based on price range
        const priceRange = this.activeFilters.priceRange;
        if (priceRange === 'Free') {
          return isFree || numericPrice === 0;
        } else if (priceRange === '0-50') {
          return !isFree && numericPrice > 0 && numericPrice <= 50;
        } else if (priceRange === '50-100') {
          return numericPrice > 50 && numericPrice <= 100;
        } else if (priceRange === '100+') {
          return numericPrice > 100;
        }
        return false;
      });
    }

    // Apply location filter
    if (this.activeFilters.location && this.activeFilters.location.length > 0) {
      filteredResults = filteredResults.filter(event => {
        // Check for online events if "Online" is selected
        if (
          this.activeFilters.location.includes('Online') &&
          this.isOnlineEvent(event)
        ) {
          return true;
        }

        // If no location is specified in the event, try to extract it from title and description
        if (!event.location || event.location.trim() === '') {
          const combinedText = `${event.title || ''} ${
            event.description || ''
          }`;

          // Check for online events
          if (
            this.activeFilters.location.includes('Online') &&
            this.isOnlineEvent(event)
          ) {
            return true;
          }

          // Extract location info from the combined text
          const locationInfo = this.extractLocationInfo(combinedText);

          // Check if the extracted continent matches any of the selected locations
          return this.activeFilters.location.some(location => {
            return location === locationInfo.continent;
          });
        }

        // Extract location info from the event location
        const locationInfo = this.extractLocationInfo(event.location);

        // Check if the event's location matches any of the selected locations
        return this.activeFilters.location.some(location => {
          // Direct match
          if (event.location.toLowerCase().includes(location.toLowerCase())) {
            return true;
          }

          // Check if the extracted continent matches the selected location
          if (locationInfo.continent === location) {
            return true;
          }

          return false;
        });
      });
    }

    // Apply date filters
    if (this.activeFilters.startDate) {
      const startDate = new Date(this.activeFilters.startDate);
      filteredResults = filteredResults.filter(event => {
        if (!event.date) {
          return true; // Keep events without dates
        }
        const eventDate = new Date(event.date);
        return eventDate >= startDate;
      });
    }

    if (this.activeFilters.endDate) {
      const endDate = new Date(this.activeFilters.endDate);
      filteredResults = filteredResults.filter(event => {
        if (!event.date) {
          return true; // Keep events without dates
        }
        const eventDate = new Date(event.date);
        return eventDate <= endDate;
      });
    }

    this.searchResults = filteredResults;

    // Calculate total pages
    this.totalPages = Math.ceil(this.searchResults.length / this.itemsPerPage);

    // Close the filter popover
    this.closeFilterPopover();
  }

  /**
   * Update category options based on the actual events
   * @param events Events to extract categories from
   */
  private updateCategoryOptions(events: EventSearchResult[] = null): void {
    // Use provided events or original search results
    const eventsToProcess = events || this._originalSearchResults;

    if (!eventsToProcess || eventsToProcess.length === 0) {
      return;
    }

    // Extract categories from all events
    const allCategories = new Set<string>();

    // Add default categories
    const defaultCategories = [
      'Workshop',
      'Performance',
      'Competition',
      'Convention',
      'Class',
      'Festival',
      'Meeting',
      'Retreat',
      'Seminar',
      'Photoshoot',
      'Fundraiser',
      'Social',
      'Concert',
      'Party',
      'Demonstration',
      'Swimming',
      'Market',
      'Film',
      'Other',
    ];

    defaultCategories.forEach(category => allCategories.add(category));

    // Add categories from events
    eventsToProcess.forEach(event => {
      // If event doesn't have categories, extract them
      if (!event.category || event.category.trim() === '') {
        const extractedCategories = this.extractCategoriesFromText(
          `${event.title} ${event.description}`
        );

        // Assign the extracted categories to the event
        if (extractedCategories.length > 0) {
          event.category = extractedCategories.join(', ');
        } else {
          event.category = 'Other';
        }
      }

      // Check if this is an online event and update location if needed
      if (
        this.isOnlineEvent(event) &&
        (!event.location || event.location.trim() === '')
      ) {
        event.location = 'Online';
      }

      // Add event categories to the set
      if (event.category) {
        event.category
          .split(',')
          .map(cat => cat.trim())
          .forEach(cat => {
            if (cat) allCategories.add(this.capitalizeFirstLetter(cat));
          });
      }
    });

    // Update category options
    this.categoryOptions = Array.from(allCategories).sort();
  }

  /**
   * Extract potential categories from text
   * @param text Text to extract categories from
   * @returns Array of potential categories
   */
  private extractCategoriesFromText(text: string): string[] {
    if (!text) return [];

    const potentialCategories = new Set<string>();
    const lowerText = text.toLowerCase();

    // Expanded list of category keywords with variations
    const categoryKeywords = [
      {
        keyword: 'Workshop',
        variations: [
          'workshop',
          'hands-on',
          'interactive session',
          'learn how to',
        ],
      },
      {
        keyword: 'Performance',
        variations: [
          'performance',
          'show',
          'exhibition',
          'display',
          'showcase',
          'presentation',
          'performing',
          'perform',
        ],
      },
      {
        keyword: 'Competition',
        variations: [
          'competition',
          'contest',
          'championship',
          'tournament',
          'challenge',
          'compete',
          'competing',
          'award',
          'prize',
        ],
      },
      {
        keyword: 'Convention',
        variations: [
          'convention',
          'conference',
          'expo',
          'symposium',
          'summit',
          'con',
        ],
      },
      {
        keyword: 'Pod Activity',
        variations: [
          'pod activity',
          'pod event',
          'pod meeting',
          'pod gathering',
          'pod',
          'merpod',
        ],
      },
      {
        keyword: 'Class',
        variations: [
          'class',
          'lesson',
          'course',
          'training',
          'tutorial',
          'instruction',
          'teaching',
          'learn',
          'education',
          'educational',
          'instructor',
        ],
      },
      {
        keyword: 'Festival',
        variations: [
          'festival',
          'celebration',
          'fair',
          'carnival',
          'gala',
          'fest',
        ],
      },
      {
        keyword: 'Meeting',
        variations: [
          'meeting',
          'gathering',
          'assembly',
          'congregation',
          'meetup',
          'meet and greet',
          'meet-up',
        ],
      },
      {
        keyword: 'Retreat',
        variations: ['retreat', 'getaway', 'escape', 'vacation', 'camp'],
      },
      {
        keyword: 'Seminar',
        variations: [
          'seminar',
          'lecture',
          'talk',
          'presentation',
          'discussion',
          'panel',
          'forum',
        ],
      },
      {
        keyword: 'Parade',
        variations: ['parade', 'procession', 'march', 'marching'],
      },
      {
        keyword: 'Photoshoot',
        variations: [
          'photoshoot',
          'photo session',
          'photography',
          'photo op',
          'photo shoot',
          'photographer',
        ],
      },
      {
        keyword: 'Fundraiser',
        variations: [
          'fundraiser',
          'charity event',
          'benefit',
          'donation drive',
          'fundraising',
          'charity',
          'donate',
        ],
      },
      {
        keyword: 'Social',
        variations: [
          'social',
          'mixer',
          'networking',
          'get-together',
          'mingle',
          'socializing',
        ],
      },
      {
        keyword: 'Concert',
        variations: [
          'concert',
          'music event',
          'live music',
          'musical performance',
          'band',
          'singer',
        ],
      },
      {
        keyword: 'Party',
        variations: ['party', 'celebration', 'bash', 'gala', 'fiesta'],
      },
      {
        keyword: 'Demonstration',
        variations: ['demonstration', 'demo', 'showing', 'exhibit', 'showcase'],
      },
      {
        keyword: 'Swimming',
        variations: ['swimming', 'swim', 'pool', 'aquatic', 'water activity'],
      },
      {
        keyword: 'Market',
        variations: [
          'market',
          'marketplace',
          'bazaar',
          'fair',
          'vendor',
          'shopping',
        ],
      },
      {
        keyword: 'Film',
        variations: [
          'film',
          'movie',
          'screening',
          'cinema',
          'documentary',
          'video',
        ],
      },
    ];

    // Check for each keyword and its variations
    categoryKeywords.forEach(category => {
      if (lowerText.includes(category.keyword.toLowerCase())) {
        potentialCategories.add(this.capitalizeFirstLetter(category.keyword));
      } else {
        // Check variations
        for (const variation of category.variations) {
          if (lowerText.includes(variation.toLowerCase())) {
            potentialCategories.add(
              this.capitalizeFirstLetter(category.keyword)
            );
            break;
          }
        }
      }
    });

    // Check for specific event types in the text
    // Check for educational events
    if (
      lowerText.includes('learn') ||
      lowerText.includes('education') ||
      lowerText.includes('tutorial') ||
      lowerText.includes('teaching') ||
      lowerText.includes('instruction') ||
      lowerText.includes('instructor') ||
      lowerText.includes('beginner') ||
      lowerText.includes('advanced') ||
      lowerText.includes('certification')
    ) {
      potentialCategories.add('Class');
    }

    // Check for competitive events
    if (
      lowerText.includes('compete') ||
      lowerText.includes('win') ||
      lowerText.includes('prize') ||
      lowerText.includes('award') ||
      lowerText.includes('champion') ||
      lowerText.includes('competition') ||
      lowerText.includes('contest')
    ) {
      potentialCategories.add('Competition');
    }

    // Check for social gatherings
    if (
      lowerText.includes('social') ||
      lowerText.includes('gather') ||
      lowerText.includes('meet') ||
      lowerText.includes('together') ||
      lowerText.includes('community') ||
      lowerText.includes('networking') ||
      lowerText.includes('mixer')
    ) {
      potentialCategories.add('Meeting');
    }

    // Check for performances
    if (
      lowerText.includes('perform') ||
      lowerText.includes('show') ||
      lowerText.includes('stage') ||
      lowerText.includes('act') ||
      lowerText.includes('present') ||
      lowerText.includes('display') ||
      lowerText.includes('entertainment')
    ) {
      potentialCategories.add('Performance');
    }

    // Check for festivals
    if (
      lowerText.includes('festival') ||
      lowerText.includes('fest') ||
      lowerText.includes('celebration') ||
      lowerText.includes('annual') ||
      lowerText.includes('fair')
    ) {
      potentialCategories.add('Festival');
    }

    // If no categories were found, add "Other"
    if (potentialCategories.size === 0) {
      potentialCategories.add('Other');
    }

    return Array.from(potentialCategories);
  }

  /**
   * Capitalize the first letter of a string
   * @param str String to capitalize
   * @returns Capitalized string
   */
  private capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  /**
   * Check if a country is in Europe
   * @param location Location string to check
   * @returns True if the country is in Europe
   */
  private isCountryInEurope(location: string): boolean {
    const europeanCountries = [
      'albania',
      'andorra',
      'austria',
      'belarus',
      'belgium',
      'bosnia',
      'bulgaria',
      'croatia',
      'cyprus',
      'czech',
      'denmark',
      'estonia',
      'finland',
      'france',
      'germany',
      'greece',
      'hungary',
      'iceland',
      'ireland',
      'italy',
      'latvia',
      'liechtenstein',
      'lithuania',
      'luxembourg',
      'malta',
      'moldova',
      'monaco',
      'montenegro',
      'netherlands',
      'north macedonia',
      'norway',
      'poland',
      'portugal',
      'romania',
      'russia',
      'san marino',
      'serbia',
      'slovakia',
      'slovenia',
      'spain',
      'sweden',
      'switzerland',
      'ukraine',
      'united kingdom',
      'uk',
      'england',
      'scotland',
      'wales',
      'northern ireland',
    ];

    return this.isLocationInRegion(location, europeanCountries);
  }

  /**
   * Check if a country is in North America
   * @param location Location string to check
   * @returns True if the country is in North America
   */
  private isCountryInNorthAmerica(location: string): boolean {
    const northAmericanCountries = [
      'united states',
      'usa',
      'u.s.a.',
      'u.s.',
      'america',
      'canada',
      'mexico',
      'guatemala',
      'belize',
      'el salvador',
      'honduras',
      'nicaragua',
      'costa rica',
      'panama',
      'bahamas',
      'cuba',
      'jamaica',
      'haiti',
      'dominican republic',
      'puerto rico',
      'antigua',
      'barbuda',
      'st. kitts',
      'nevis',
      'dominica',
      'st. lucia',
      'st. vincent',
      'grenadines',
      'barbados',
      'grenada',
      'trinidad',
      'tobago',
    ];

    return this.isLocationInRegion(location, northAmericanCountries);
  }

  /**
   * Check if a country is in South America
   * @param location Location string to check
   * @returns True if the country is in South America
   */
  private isCountryInSouthAmerica(location: string): boolean {
    const southAmericanCountries = [
      'argentina',
      'bolivia',
      'brazil',
      'chile',
      'colombia',
      'ecuador',
      'guyana',
      'paraguay',
      'peru',
      'suriname',
      'uruguay',
      'venezuela',
    ];

    return this.isLocationInRegion(location, southAmericanCountries);
  }

  /**
   * Check if a country is in Asia
   * @param location Location string to check
   * @returns True if the country is in Asia
   */
  private isCountryInAsia(location: string): boolean {
    const asianCountries = [
      'afghanistan',
      'armenia',
      'azerbaijan',
      'bahrain',
      'bangladesh',
      'bhutan',
      'brunei',
      'cambodia',
      'china',
      'georgia',
      'india',
      'indonesia',
      'iran',
      'iraq',
      'israel',
      'japan',
      'jordan',
      'kazakhstan',
      'kuwait',
      'kyrgyzstan',
      'laos',
      'lebanon',
      'malaysia',
      'maldives',
      'mongolia',
      'myanmar',
      'nepal',
      'north korea',
      'oman',
      'pakistan',
      'palestine',
      'philippines',
      'qatar',
      'saudi arabia',
      'singapore',
      'south korea',
      'sri lanka',
      'syria',
      'taiwan',
      'tajikistan',
      'thailand',
      'timor-leste',
      'turkey',
      'turkmenistan',
      'united arab emirates',
      'uae',
      'uzbekistan',
      'vietnam',
      'yemen',
    ];

    return this.isLocationInRegion(location, asianCountries);
  }

  /**
   * Check if a country is in Africa
   * @param location Location string to check
   * @returns True if the country is in Africa
   */
  private isCountryInAfrica(location: string): boolean {
    const africanCountries = [
      'algeria',
      'angola',
      'benin',
      'botswana',
      'burkina faso',
      'burundi',
      'cabo verde',
      'cameroon',
      'central african republic',
      'chad',
      'comoros',
      'congo',
      'djibouti',
      'egypt',
      'equatorial guinea',
      'eritrea',
      'eswatini',
      'ethiopia',
      'gabon',
      'gambia',
      'ghana',
      'guinea',
      'guinea-bissau',
      'ivory coast',
      'kenya',
      'lesotho',
      'liberia',
      'libya',
      'madagascar',
      'malawi',
      'mali',
      'mauritania',
      'mauritius',
      'morocco',
      'mozambique',
      'namibia',
      'niger',
      'nigeria',
      'rwanda',
      'sao tome and principe',
      'senegal',
      'seychelles',
      'sierra leone',
      'somalia',
      'south africa',
      'south sudan',
      'sudan',
      'tanzania',
      'togo',
      'tunisia',
      'uganda',
      'zambia',
      'zimbabwe',
    ];

    return this.isLocationInRegion(location, africanCountries);
  }

  /**
   * Check if a country is in Oceania
   * @param location Location string to check
   * @returns True if the country is in Oceania
   */
  private isCountryInOceania(location: string): boolean {
    const oceaniaCountries = [
      'australia',
      'fiji',
      'kiribati',
      'marshall islands',
      'micronesia',
      'nauru',
      'new zealand',
      'palau',
      'papua new guinea',
      'samoa',
      'solomon islands',
      'tonga',
      'tuvalu',
      'vanuatu',
    ];

    return this.isLocationInRegion(location, oceaniaCountries);
  }

  /**
   * Check if a location string contains any of the countries in a region
   * @param location Location string to check
   * @param countries Array of country names to check against
   * @returns True if the location contains any of the countries
   */
  private isLocationInRegion(location: string, countries: string[]): boolean {
    if (!location) return false;

    const locationLower = location.toLowerCase();
    return countries.some(country => locationLower.includes(country));
  }

  /**
   * Pre-process events to extract categories and detect online events
   * @param events Events to pre-process
   */
  private preprocessEvents(events: EventSearchResult[]): void {
    events.forEach(event => {
      // Extract categories from title and description
      if (!event.category || event.category.trim() === '') {
        const extractedCategories = this.extractCategoriesFromText(
          `${event.title} ${event.description}`
        );

        // Assign the extracted categories to the event
        if (extractedCategories.length > 0) {
          event.category = extractedCategories.join(', ');
        } else {
          event.category = 'Other';
        }
      }

      // Check if this is an online event and update location if needed
      if (
        this.isOnlineEvent(event) &&
        (!event.location || event.location.trim() === '')
      ) {
        event.location = 'Online';
      }
    });
  }

  /**
   * Check if an event is an online event
   * @param event Event to check
   * @returns True if the event is online
   */
  private isOnlineEvent(event: EventSearchResult): boolean {
    const onlineKeywords = [
      'online',
      'virtual',
      'zoom',
      'webinar',
      'web conference',
      'livestream',
      'live stream',
      'remote',
      'digital',
      'stream',
      'streaming',
      'webcast',
    ];

    // Check title, description, and location for online keywords
    const titleLower = (event.title || '').toLowerCase();
    const descriptionLower = (event.description || '').toLowerCase();
    const locationLower = (event.location || '').toLowerCase();

    // Check if any online keywords are present in the event details
    return onlineKeywords.some(
      keyword =>
        titleLower.includes(keyword) ||
        descriptionLower.includes(keyword) ||
        locationLower.includes(keyword)
    );
  }

  /**
   * Reset all active filters
   */
  resetFilters(): void {
    this.activeFilters = {
      category: [],
      priceRange: null,
      location: [],
      startDate: null,
      endDate: null,
    };
    this.applyFilters();
    this.closeFilterPopover();
  }

  /**
   * Check if text contains references to North American locations
   */
  private containsNorthAmericanReference(text: string): boolean {
    const northAmericanKeywords = [
      'north america',
      'usa',
      'united states',
      'canada',
      'mexico',
      'american',
      'canadian',
      'mexican',
      'chicago',
      'new york',
      'toronto',
      'los angeles',
      'san francisco',
      'las vegas',
      'miami',
      'vancouver',
      'montreal',
      'mexico city',
      'washington',
      'boston',
      'seattle',
      'dallas',
      'houston',
      'atlanta',
      'denver',
      'philadelphia',
      'phoenix',
      'san diego',
      'california',
      'texas',
      'florida',
      'ontario',
      'quebec',
      'alberta',
    ];

    const textLower = text.toLowerCase();
    return northAmericanKeywords.some(keyword => textLower.includes(keyword));
  }

  /**
   * Check if text contains references to European locations
   */
  private containsEuropeanReference(text: string): boolean {
    const europeanKeywords = [
      'europe',
      'european',
      'uk',
      'united kingdom',
      'england',
      'france',
      'germany',
      'italy',
      'spain',
      'london',
      'paris',
      'berlin',
      'rome',
      'madrid',
      'amsterdam',
      'brussels',
      'vienna',
      'prague',
      'budapest',
      'warsaw',
      'dublin',
      'lisbon',
      'athens',
      'stockholm',
      'copenhagen',
      'oslo',
      'helsinki',
      'zurich',
      'geneva',
      'barcelona',
      'munich',
      'british',
      'french',
      'german',
      'italian',
      'spanish',
      'dutch',
      'belgium',
      'netherlands',
      'switzerland',
      'austria',
      'portugal',
      'greece',
      'sweden',
      'denmark',
      'norway',
      'finland',
    ];

    const textLower = text.toLowerCase();
    return europeanKeywords.some(keyword => textLower.includes(keyword));
  }

  /**
   * Check if text contains references to Asian locations
   */
  private containsAsianReference(text: string): boolean {
    const asianKeywords = [
      'asia',
      'asian',
      'china',
      'japan',
      'india',
      'korea',
      'singapore',
      'malaysia',
      'indonesia',
      'thailand',
      'vietnam',
      'philippines',
      'hong kong',
      'taiwan',
      'beijing',
      'tokyo',
      'seoul',
      'mumbai',
      'delhi',
      'bangkok',
      'jakarta',
      'manila',
      'shanghai',
      'chinese',
      'japanese',
      'indian',
      'korean',
      'singapore',
      'malaysian',
      'indonesian',
      'thai',
      'vietnamese',
      'filipino',
      'taiwanese',
      'middle east',
      'dubai',
      'uae',
      'saudi arabia',
      'qatar',
      'israel',
      'turkey',
    ];

    const textLower = text.toLowerCase();
    return asianKeywords.some(keyword => textLower.includes(keyword));
  }

  /**
   * Check if text contains references to Oceania locations
   */
  private containsOceaniaReference(text: string): boolean {
    const oceaniaKeywords = [
      'australia',
      'new zealand',
      'oceania',
      'australian',
      'sydney',
      'melbourne',
      'brisbane',
      'perth',
      'adelaide',
      'auckland',
      'wellington',
      'queensland',
      'victoria',
      'new south wales',
      'western australia',
      'tasmania',
      'fiji',
      'papua new guinea',
      'samoa',
      'solomon islands',
      'vanuatu',
      'new caledonia',
    ];

    const textLower = text.toLowerCase();
    return oceaniaKeywords.some(keyword => textLower.includes(keyword));
  }

  /**
   * Check if text contains references to African locations
   */
  private containsAfricanReference(text: string): boolean {
    const africanKeywords = [
      'africa',
      'african',
      'south africa',
      'egypt',
      'morocco',
      'kenya',
      'nigeria',
      'ghana',
      'ethiopia',
      'tanzania',
      'uganda',
      'zimbabwe',
      'cape town',
      'johannesburg',
      'cairo',
      'nairobi',
      'lagos',
      'accra',
      'addis ababa',
      'casablanca',
      'tunis',
      'algiers',
      'khartoum',
      'south african',
      'egyptian',
      'moroccan',
      'kenyan',
      'nigerian',
      'ghanaian',
      'ethiopian',
      'tanzanian',
      'ugandan',
      'zimbabwean',
    ];

    const textLower = text.toLowerCase();
    return africanKeywords.some(keyword => textLower.includes(keyword));
  }

  /**
   * Check if text contains references to South American locations
   */
  private containsSouthAmericanReference(text: string): boolean {
    const southAmericanKeywords = [
      'south america',
      'brazil',
      'argentina',
      'colombia',
      'chile',
      'peru',
      'venezuela',
      'ecuador',
      'bolivia',
      'uruguay',
      'paraguay',
      'rio de janeiro',
      'sao paulo',
      'buenos aires',
      'bogota',
      'santiago',
      'lima',
      'caracas',
      'quito',
      'la paz',
      'montevideo',
      'asuncion',
      'brazilian',
      'argentinian',
      'colombian',
      'chilean',
      'peruvian',
      'venezuelan',
      'ecuadorian',
      'bolivian',
      'uruguayan',
      'paraguayan',
    ];

    const textLower = text.toLowerCase();
    return southAmericanKeywords.some(keyword => textLower.includes(keyword));
  }

  /**
   * Extract location information from a city/state or city/country string
   * @param locationText The location text to analyze
   * @returns An object with continent and country information
   */
  private extractLocationInfo(locationText: string): {
    continent: string;
    country: string;
  } {
    if (!locationText) {
      return { continent: null, country: null };
    }

    const textLower = locationText.toLowerCase();

    // US States abbreviations and their full names
    const usStates = {
      al: 'alabama',
      ak: 'alaska',
      az: 'arizona',
      ar: 'arkansas',
      ca: 'california',
      co: 'colorado',
      ct: 'connecticut',
      de: 'delaware',
      fl: 'florida',
      ga: 'georgia',
      hi: 'hawaii',
      id: 'idaho',
      il: 'illinois',
      in: 'indiana',
      ia: 'iowa',
      ks: 'kansas',
      ky: 'kentucky',
      la: 'louisiana',
      me: 'maine',
      md: 'maryland',
      ma: 'massachusetts',
      mi: 'michigan',
      mn: 'minnesota',
      ms: 'mississippi',
      mo: 'missouri',
      mt: 'montana',
      ne: 'nebraska',
      nv: 'nevada',
      nh: 'new hampshire',
      nj: 'new jersey',
      nm: 'new mexico',
      ny: 'new york',
      nc: 'north carolina',
      nd: 'north dakota',
      oh: 'ohio',
      ok: 'oklahoma',
      or: 'oregon',
      pa: 'pennsylvania',
      ri: 'rhode island',
      sc: 'south carolina',
      sd: 'south dakota',
      tn: 'tennessee',
      tx: 'texas',
      ut: 'utah',
      vt: 'vermont',
      va: 'virginia',
      wa: 'washington',
      wv: 'west virginia',
      wi: 'wisconsin',
      wy: 'wyoming',
      dc: 'district of columbia',
    };

    // Canadian provinces and territories
    const canadianProvinces = {
      ab: 'alberta',
      bc: 'british columbia',
      mb: 'manitoba',
      nb: 'new brunswick',
      nl: 'newfoundland and labrador',
      ns: 'nova scotia',
      nt: 'northwest territories',
      nu: 'nunavut',
      on: 'ontario',
      pe: 'prince edward island',
      qc: 'quebec',
      sk: 'saskatchewan',
      yt: 'yukon',
    };

    // Major US cities
    const usCities = [
      'new york',
      'los angeles',
      'chicago',
      'houston',
      'phoenix',
      'philadelphia',
      'san antonio',
      'san diego',
      'dallas',
      'san jose',
      'austin',
      'jacksonville',
      'fort worth',
      'columbus',
      'charlotte',
      'san francisco',
      'indianapolis',
      'seattle',
      'denver',
      'washington',
      'boston',
      'el paso',
      'nashville',
      'detroit',
      'portland',
      'las vegas',
      'oklahoma city',
      'memphis',
      'louisville',
      'baltimore',
      'milwaukee',
      'albuquerque',
      'tucson',
      'fresno',
      'sacramento',
      'atlanta',
      'kansas city',
      'miami',
      'tampa',
      'orlando',
      'pittsburgh',
      'cincinnati',
      'minneapolis',
      'cleveland',
      'new orleans',
      'st. louis',
      'honolulu',
      'anchorage',
    ];

    // Major Canadian cities
    const canadianCities = [
      'toronto',
      'montreal',
      'vancouver',
      'calgary',
      'edmonton',
      'ottawa',
      'winnipeg',
      'quebec city',
      'hamilton',
      'kitchener',
      'london',
      'victoria',
      'halifax',
      'oshawa',
      'windsor',
      'saskatoon',
      'regina',
      "st. john's",
    ];

    // Check for US state abbreviations (e.g., "Atlanta, GA" or "White Oak, WV")
    for (const [abbr, fullName] of Object.entries(usStates)) {
      const statePattern = new RegExp(`\\b${abbr}\\b`, 'i');
      if (statePattern.test(textLower) || textLower.includes(fullName)) {
        return { continent: 'North America', country: 'United States' };
      }
    }

    // Check for Canadian province abbreviations
    for (const [abbr, fullName] of Object.entries(canadianProvinces)) {
      const provincePattern = new RegExp(`\\b${abbr}\\b`, 'i');
      if (provincePattern.test(textLower) || textLower.includes(fullName)) {
        return { continent: 'North America', country: 'Canada' };
      }
    }

    // Check for major US cities
    for (const city of usCities) {
      if (textLower.includes(city)) {
        return { continent: 'North America', country: 'United States' };
      }
    }

    // Check for major Canadian cities
    for (const city of canadianCities) {
      if (textLower.includes(city)) {
        return { continent: 'North America', country: 'Canada' };
      }
    }

    // Check for country names
    if (this.isCountryInNorthAmerica(textLower)) {
      return { continent: 'North America', country: textLower };
    } else if (this.isCountryInEurope(textLower)) {
      return { continent: 'Europe', country: textLower };
    } else if (this.isCountryInAsia(textLower)) {
      return { continent: 'Asia', country: textLower };
    } else if (this.isCountryInOceania(textLower)) {
      return { continent: 'Australia', country: textLower };
    } else if (this.isCountryInAfrica(textLower)) {
      return { continent: 'Africa', country: textLower };
    } else if (this.isCountryInSouthAmerica(textLower)) {
      return { continent: 'South America', country: textLower };
    }

    // If no specific location is detected, check for general continent references
    if (this.containsNorthAmericanReference(textLower)) {
      return { continent: 'North America', country: null };
    } else if (this.containsEuropeanReference(textLower)) {
      return { continent: 'Europe', country: null };
    } else if (this.containsAsianReference(textLower)) {
      return { continent: 'Asia', country: null };
    } else if (this.containsOceaniaReference(textLower)) {
      return { continent: 'Australia', country: null };
    } else if (this.containsAfricanReference(textLower)) {
      return { continent: 'Africa', country: null };
    } else if (this.containsSouthAmericanReference(textLower)) {
      return { continent: 'South America', country: null };
    }

    return { continent: null, country: null };
  }

  /**
   * Handle date changes from the datetime picker
   */
  handleDateChange(event: any, type: 'start' | 'end'): void {
    const value = event.detail.value;

    if (value) {
      try {
        // Convert to YYYY-MM-DD format
        const date = new Date(value);

        // Validate date
        if (isNaN(date.getTime())) {
          if (type === 'start') {
            this.activeFilters.startDate = null;
          } else {
            this.activeFilters.endDate = null;
          }
          return;
        }

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const dateString = `${year}-${month}-${day}`;

        if (type === 'start') {
          this.activeFilters.startDate = dateString;
          // If end date is before start date, clear end date
          if (
            this.activeFilters.endDate &&
            this.activeFilters.endDate < dateString
          ) {
            this.activeFilters.endDate = null;
          }
        } else {
          this.activeFilters.endDate = dateString;
          // If start date is after end date, clear start date
          if (
            this.activeFilters.startDate &&
            this.activeFilters.startDate > dateString
          ) {
            this.activeFilters.startDate = null;
          }
        }
      } catch (error) {
        if (type === 'start') {
          this.activeFilters.startDate = null;
        } else {
          this.activeFilters.endDate = null;
        }
      }
    } else {
      // If value is null/undefined, clear the filter
      if (type === 'start') {
        this.activeFilters.startDate = null;
      } else {
        this.activeFilters.endDate = null;
      }
    }

    // Removed the automatic applyFilters() call
    // The filters will now only be applied when the user clicks the "Apply Filter" button
  }

  /**
   * Search for multiple queries and merge the results
   * @param queries Array of search queries
   * @param bypassCache Whether to bypass the cache
   * @param mergeWithExisting Whether to merge with existing events
   */
  searchMultipleQueries(
    queries: string[],
    bypassCache: boolean = false,
    mergeWithExisting: boolean = false
  ) {
    // Store current filter state
    const currentPriceFilter = this.priceFilter;

    this.isLoading = true;
    this.errorMessage = '';
    this.isSearching = true;
    this.showFavorites = false;

    // Get the cache key for the first query (used as base key)
    const cacheKey = this.eventSearchService.createCacheKey(
      queries[0],
      this.searchSites
    );

    // Get existing cached events if merging
    const existingEvents = mergeWithExisting
      ? this.cacheService.get<EventSearchResult[]>(cacheKey) || []
      : [];

    // Filter out past events from existing events
    const filteredExistingEvents = this.filterPastEvents(existingEvents);

    // If we have existing events and don't need to bypass cache, show them immediately
    if (filteredExistingEvents.length > 0 && !bypassCache) {
      this.searchResults = filteredExistingEvents;
      this._originalSearchResults = [...this.searchResults];
      this.updateCategoryOptions(filteredExistingEvents);
      this.totalPages = Math.ceil(
        this.searchResults.length / this.itemsPerPage
      );
      this.currentPage = 1;
      this.isLoading = false;
      this.isSearching = false;
    }

    // Create an array of observables for each query
    const searchObservables = queries.map(query => {
      const queryCacheKey = this.eventSearchService.createCacheKey(
        query,
        this.searchSites
      );
      const cachedQueryEvents =
        this.cacheService.get<EventSearchResult[]>(queryCacheKey);

      // If we have cached events for this query and don't need to bypass cache, use them
      if (cachedQueryEvents && !bypassCache) {
        return of(cachedQueryEvents);
      }

      // Otherwise, fetch from API
      return this.eventSearchService.searchEvents(
        query + ' event',
        this.searchSites,
        bypassCache
      );
    });

    // Combine all search results
    forkJoin(searchObservables)
      .pipe(
        catchError(error => {
          this.errorMessage =
            'Failed to search for events. Please try again later or try a different search term.';
          return of([]);
        }),
        finalize(() => {
          this.isLoading = false;
          this.isSearching = false;
        })
      )
      .subscribe(results => {
        // Flatten all results into a single array using reduce
        const allEvents = (results as EventSearchResult[][]).reduce(
          (acc, curr) => acc.concat(curr),
          [] as EventSearchResult[]
        );

        if (allEvents.length === 0 && filteredExistingEvents.length === 0) {
          this.errorMessage =
            'No events found. Please try a different search term.';
          this.searchResults = [];
        } else {
          // Pre-process events to extract categories
          this.preprocessEvents(allEvents);

          // First filter out past events and non-events
          const filteredEvents = this.filterPastEvents(allEvents);

          if (mergeWithExisting) {
            // Merge with existing events
            this.mergeAndUpdateEvents(
              filteredExistingEvents,
              filteredEvents,
              cacheKey
            );
          } else {
            // Replace all events
            this.searchResults = filteredEvents;
            this._originalSearchResults = [...this.searchResults];

            // Sort events by date (closest date first)
            this.sortEventsByDate();

            // Update category options based on fetched events
            this.updateCategoryOptions(filteredEvents);

            // Calculate total pages
            this.totalPages = Math.ceil(
              this.searchResults.length / this.itemsPerPage
            );
            this.currentPage = 1;

            // Update favorites status
            this.loadFavorites();
          }
        }
      });
  }

  /**
   * Filter out past events from an array of events
   * @param events The events to filter
   * @returns Only upcoming events
   */
  private filterPastEvents(events: EventSearchResult[]): EventSearchResult[] {
    // Get today's date for comparison
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return events.filter(event => {
      // If title or URL contains profile indicators, it's likely not an event
      if (
        event.title?.toLowerCase().includes('profile') ||
        event.title?.toLowerCase().includes(' page') ||
        event.url?.toLowerCase().includes('facebook.com/profile') ||
        event.url?.toLowerCase().includes('facebook.com/people') ||
        (event.description?.toLowerCase().includes('profile') &&
          !event.description?.toLowerCase().includes('event'))
      ) {
        return false;
      }

      // If date is TBD, keep it
      if (event.date === 'Date TBD') {
        return true;
      }

      // Try to parse the date
      const eventDate = this.parseEventDate(event.date);

      // If we couldn't parse the date, check the description and title for past indicators
      if (!eventDate) {
        // If description mentions past year, likely an old event
        const currentYear = today.getFullYear();
        const pastYears = [
          currentYear - 1,
          currentYear - 2,
          currentYear - 3,
          currentYear - 4,
          currentYear - 5,
        ];

        // Check for past years in title or description
        for (const year of pastYears) {
          if (
            event.description?.includes(year.toString()) ||
            event.title?.includes(year.toString())
          ) {
            return false;
          }
        }

        // Check for past-tense verbs that indicate completed events
        const pastTenseIndicators = [
          ' was ',
          ' were ',
          ' happened ',
          ' took place ',
          ' concluded ',
          ' ended ',
        ];
        for (const indicator of pastTenseIndicators) {
          if (event.description?.toLowerCase().includes(indicator)) {
            return false;
          }
        }

        return true;
      }

      // Only keep events that are today or in the future
      return eventDate >= today;
    });
  }
}

