import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-empty-item',
  templateUrl: './empty-item.component.html',
  styleUrls: ['./empty-item.component.scss'],
})
export class EmptyItemComponent {
  @Input() emptyType: string;
  constructor() {}
}
