import { Injectable, NgZone } from '@angular/core';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root',
})
export class DomTranslationService {
  private excludedTags = ['SCRIPT', 'STYLE', 'NOSCRIPT', 'INPUT', 'TEXTAREA'];
  private processedNodes = new WeakSet();

  constructor(
    private translationService: TranslationService,
    private ngZone: NgZone
  ) {
    this.initObserver();
  }

  private initObserver() {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        mutation.addedNodes.forEach(node => {
          if (node.nodeType === Node.ELEMENT_NODE) {
            this.processNode(node as Element);
          }
        });
      });
    });

    // Start observing the document
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Initial scan of existing content
    this.processNode(document.body);
  }

  private async processNode(node: Element | Node) {
    if (this.processedNodes.has(node)) return;

    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as Element;

      // Handle input placeholders
      if (element instanceof HTMLInputElement && element.placeholder) {
        await this.translateAttribute(element, 'placeholder');
      }

      // Handle labels
      if (
        element instanceof HTMLLabelElement ||
        element.hasAttribute('aria-label')
      ) {
        await this.translateAttribute(element, 'aria-label');
      }

      // Handle button values
      if (element instanceof HTMLButtonElement && element.value) {
        await this.translateAttribute(element, 'value');
      }

      // Process text content
      if (!this.excludedTags.includes(element.tagName)) {
        element.childNodes.forEach(child => this.processNode(child));
      }
    } else if (node.nodeType === Node.TEXT_NODE && node.textContent?.trim()) {
      const text = node.textContent.trim();
      if (text && !this.isNumeric(text)) {
        await this.translateTextNode(node as Text);
      }
    }

    this.processedNodes.add(node);
  }

  private async translateAttribute(element: Element, attributeName: string) {
    const text = element.getAttribute(attributeName);
    if (!text) return;

    this.translationService.getCurrentLanguage().subscribe(async lang => {
      if (lang === 'en') return;

      try {
        const translated = await this.translationService.translateText(
          text,
          lang
        );
        if (translated && translated !== text) {
          this.ngZone.run(() => {
            element.setAttribute(attributeName, translated);
          });
        }
      } catch (error) {
        console.error('Translation error:', error);
      }
    });
  }

  private async translateTextNode(node: Text) {
    const text = node.textContent?.trim();
    if (!text) return;

    this.translationService.getCurrentLanguage().subscribe(async lang => {
      if (lang === 'en') return;

      try {
        const translated = await this.translationService.translateText(
          text,
          lang
        );
        if (translated && translated !== text) {
          this.ngZone.run(() => {
            node.textContent = translated;
          });
        }
      } catch (error) {
        console.error('Translation error:', error);
      }
    });
  }

  private isNumeric(value: string): boolean {
    return /^-?\d+(\.\d+)?$/.test(value.trim());
  }
}

