import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonModal } from '@ionic/angular';
import { IPod } from 'src/app/shared/models/item.models';
import {
  filter,
  finalize,
  Subject,
  takeUntil,
  switchMap,
  combineLatest,
  skip,
  catchError,
  tap,
} from 'rxjs';
import { Navigation, SwiperOptions } from 'swiper';
import { AuthService } from 'src/app/firebase/auth.service';
import { StorageHelper } from 'src/app/firebase/helpers/storageHelper';
import { AlertController } from '@ionic/angular';
import {
  confirmForAnswers,
  confirmForDelete,
  successPodsModalData,
} from '../pods/pods';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { PodService } from '../pods.service';
import { PodStore } from '../pod-store';
import { Ng2ImgMaxService } from 'ng2-img-max';

import { EventsStore } from 'src/app/events/events-store';
import {
  createToaster,
  positionType,
} from 'src/app/shared/components/modals/toaster';
import { environment } from '../../../../environments/environment';
import mixpanel from 'mixpanel-browser';
import { eventPremiumModalData } from 'src/app/events/events';
import { getAuth } from 'firebase/auth';
import { PaymentsStore } from 'src/app/payments/payments-store';
import { PaymentsService } from 'src/app/payments/payments.service';
import { DateService } from 'src/app/services/date.service';
import { UserService } from 'src/app/auth/user.service';
import { ImagesService } from 'src/app/services/images.service';

@Component({
  selector: 'app-pod-details',
  templateUrl: './pod-details.component.html',
  styleUrls: ['./pod-details.component.scss'],
})
export class PodDetailsComponent implements OnInit, OnDestroy {
  @Output() isClose = new EventEmitter<boolean>(false);
  @ViewChild('questionModal') questionModal: IonModal;
  isContentFlaggedModal: boolean = false;
  pod: IPod;
  podId = this.activatedRouter.snapshot.params.id;
  events;
  participants = [];
  memberRequests = [];
  userId: string;
  user;
  currentTime = new Date().getTime();
  config: SwiperOptions;
  slideIndex: number;
  gallery: string[] = [];
  galleryUrls: string[];

  isCreator: boolean;
  isParticipant: boolean;
  isCanApprove: boolean;
  detailVisible: boolean;
  isCanEdit: boolean;

  isParticipantExist = false;
  isPendingJoinRequest: boolean;
  isCanDeleteImage = false;
  isCanFlagImage = false;

  showForDelete = false;
  showForFlag = false;
  showForAnswers = false;
  isOpenPodFormModal = false;
  isOpenAnswersModal = false;
  isOpen = false;
  isOpenFormEventModal = false;
  showSuccessModal = false;
  successModalData = successPodsModalData;
  confirmForDelete = confirmForDelete;
  confirmForAnswers = confirmForAnswers;
  showStatusModal: boolean = false;
  itemStatus;
  maxSize = false;

  isUserPremiumModalOpen = false;
  premiumModalData = eventPremiumModalData;
  isUserPremium = false;
  isAdmin = false;
  isHasDiscord = false;

  requestIndex: number;
  toolbarData = {
    arrowRouter: '/merfolk/pods',
    btnSlot: 'end',
    title: 'Pod',
  };

  imageLoading: boolean = false;
  properties;

  discordServerInviteLink = environment.discordServerInviteLink;
  isOpenFlagModal: boolean = false;
  flagData;

  itemId = this.activatedRouter.snapshot.params.id;

  private destroy$ = new Subject<void>();

  constructor(
    private podStore: PodStore,
    private activatedRouter: ActivatedRoute,
    private imagesService: ImagesService,
    private podsService: PodService,
    private userService: UserService,
    private storageHelper: StorageHelper,
    private alertController: AlertController,
    private eventsStore: EventsStore,
    private ng2ImgMaxService: Ng2ImgMaxService,
    private paymentsStore: PaymentsStore,
    private paymentService: PaymentsService,
    private dateService: DateService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    mixpanel.init(environment.mixpanelToken, { debug: true });
    this.getUser();

    this.podStore.getItemByByValue({
      documentId: this.activatedRouter.snapshot.params.id,
    });

    this.listenPodDetails();

    this.podStore.selectShowFormModal().subscribe(isOpen => {
      this.isOpenPodFormModal = isOpen;
    });

    this.podStore.selectShowPendingModal().subscribe(isOpen => {
      this.showSuccessModal = isOpen;
    });

    this.eventsStore.selectShowFormModal().subscribe(isOpen => {
      this.isOpenFormEventModal = isOpen;
    });

    this.eventsStore.getPreviewEvents({
      limit: 3,
      podId: this.activatedRouter.snapshot.params.id,
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  trackEvent(event) {
    mixpanel.track(event);
  }

  openStatusModal() {
    this.showStatusModal = !this.showStatusModal;
  }

  async flagContent(slide) {
    slide.type = 'pods';

    this.flagData = slide;

    const functions = getFunctions();
    const isContentFlagged = httpsCallable(functions, 'isContentFlagged');
    await isContentFlagged({
      itemId: slide.id,
      url: slide.image,
      type: this.flagData.type
    }).then(res => {
      if(res.data === true) {
        this.isContentFlaggedModal = true;
      } else {
        this.isOpenFlagModal = true;
      }
    });
  }

  joinPod() {
    if (this.pod?.invites?.length) {
      const invites = this.pod.invites.filter(
        invite => invite.invited !== this.userId
      );
      const newPod = Object.assign({
        ...this.pod,
        invites,
        participants: [...this.pod?.participants, this.userId],
      });
      this.updateItem(newPod, 'Successfully joined');
    } else {
      const newPod = Object.assign({
        ...this.pod,
        participants: [...this.pod?.participants, this.userId],
      });
      this.updateItem(newPod, 'Successfully joined');
    }
  }

  requestToJoin() {
    if (
      this.pod.joinMechanismArr.some(
        question => question.memberQuestion !== null
      )
    ) {
      this.podsService.showQuestionsModal.next(true);
    } else {
      this.presentConfirmAlert();
    }
  }

  async compressImage() {
    this.imagesService.compressedImages
      .pipe(takeUntil(this.destroy$))
      .subscribe(images => {
        if (images && images.length) {
          images.map(image => {
            return this.addToGallery(image);
          });
        }
      });
    await this.imagesService.compressMultiplyImages();
  }

  async addToGallery(file) {
    if (!file) return;
    await this.storageHelper.uploadPicture(file).then(file => {
      this.imageLoading = true;
      file.task
        .snapshotChanges()
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            file.fileRef
              .getDownloadURL()
              .pipe(takeUntil(this.destroy$))
              .subscribe(url => {
                const newPod = Object.assign({
                  ...this.pod,
                  gallery: [{ userId: this.userId, url }, ...this.pod.gallery],
                });
                this.gallery.push(url);

                this.updateItem(newPod, 'Successfully added');
              });
          })
        )
        .subscribe();
    });
  }

  acceptModal(e) {
    if (e) {
      this.acceptRequest(this.requestIndex);
    }
  }

  rejectModal(e) {
    if (e) {
      this.rejectRequest(this.requestIndex);
    }
  }

  checkAnswersModal(i) {
    this.isOpenAnswersModal = !this.isOpenAnswersModal;
    this.requestIndex = i;
  }

  acceptRequest(i) {
    const newParticipant = this.pod?.memberRequests[i].id;
    const denied = this.pod.denied
      ? this.pod?.denied.filter(id => id !== newParticipant)
      : [];
    this.pod.memberRequests.splice(i, 1);
    if (
      this.pod?.denied?.length &&
      this.pod?.denied.find(item => item === newParticipant)
    ) {
      const newPod = Object.assign({
        ...this.pod,
        denied,
        participants: [...this.pod?.participants, newParticipant],
      });
      this.updateItem(newPod, 'Successfully added');
    } else {
      const newPod = Object.assign({
        ...this.pod,
        participants: [...this.pod?.participants, newParticipant],
      });
      this.updateItem(newPod, 'Successfully added');
    }
    this.isOpenAnswersModal = false;
  }

  rejectRequest(i: number) {
    const blockedUser = this.pod?.memberRequests[i].id;
    const blockedUsers = this.pod?.denied
      ? this.pod?.denied.filter(id => id !== this.pod?.memberRequests[i].id)
      : [];
    this.pod.memberRequests.splice(i, 1);
    if (
      this.pod?.denied?.length &&
      this.pod?.denied.find(item => item === blockedUser)
    ) {
      const newPod = Object.assign({
        ...this.pod,
      });

      this.updateItem(newPod, 'Successfully denied');
    } else {
      const newPod = Object.assign({
        ...this.pod,
        denied: [...blockedUsers, blockedUser],
      });

      this.updateItem(newPod, 'Successfully denied');
    }

    this.isOpenAnswersModal = false;
  }

  setEvent = () => {
    if (!this.isUserPremium && !this.isAdmin) {
      this.isUserPremiumModalOpen = true;
    } else {
      this.eventsStore.updateShowFormModal(true);
    }
  };

  closeEventModal(e) {
    e ? this.eventsStore.updateShowFormModal(false) : null;
  }

  async presentConfirmAlert() {
    const alert = await this.alertController.create({
      cssClass: 'alert',
      message:
        '<div class="color-dark d-flex flex-column ion-align-items-center"><ion-icon name="radio-button-on" class="font-24"></ion-icon><p class="font-16">Exciting News!</p><p class="font-14 font-opacity">Your future Pod is going to review your request! As soon as they\'ve accepted you, you\'ll be able to see their pictures, participate in their events and chat with them!</p></div>',
      buttons: [
        {
          text: 'OKAY',
        },
      ],
    });
    await alert.present();
  }

  async presentLeaveAlert() {
    const alert = await this.alertController.create({
      message:
        '<div class="color-dark d-flex flex-column ion-align-items-center"><ion-icon name="radio-button-on" class="font-24"></ion-icon><p class="font-16">Leaving a Pod?</p><p class="font-14">Are you sure you want to leave the Pod? That way you will no longer have access to the Pod\'s events, chat or pictures?</p></div>',
      buttons: [
        {
          text: 'NO, STAY',
        },
        {
          text: 'YES, LEAVE',
          handler: () => {
            this.leavePod();
            alert.dismiss('cancel', 'cancel');
            this.trackEvent('Pod Left');
          },
        },
      ],
    });
    await alert.present();
  }

  leavePod() {
    const newParticipants = this.pod.participants.filter(
      user => user !== this.userId
    );

    const newItem = Object.assign({
      ...this.pod,
      participants: newParticipants,
    });
    this.updateItem(newItem);
  }

  close(_e) {}

  listenPodDetails() {
    combineLatest([this.podStore.selectItem(), this.eventsStore.selectItems()])
      .pipe(
        skip(1),
        takeUntil(this.destroy$),
        filter(item => !!item),
        switchMap(([pod, events]) => {
          //? maybe letter
          // if (pod?.status !== 'approved' && pod?.creator.id !== this.userId) {
          //   this.route.navigate(['/merfolk/pods']);
          // }
          const userId = this.authService.isUserAuth().uid;
          this.events = this.dateService.getScheduledItems(events, false);

          this.pod = pod;
          pod?.gallery?.length
            ? (this.isCanDeleteImage = this.pod.gallery[0].userId === userId)
            : null;
          this.isPendingJoinRequest = pod?.memberRequests
            ? pod?.memberRequests?.some(item => item.id === userId)
            : false;
          this.galleryUrls = pod?.gallery.map(
            pic => `url("${pic.url}") no-repeat center center / cover`
          );

          this.isCreator = pod?.creator.id === userId;
          this.isParticipant = this.pod?.participants.includes(userId);
          this.isCanApprove =
            (this.pod?.approveMembers === 'everyone can approve' ||
              pod?.creator.id === userId) &&
            this.isParticipant;

          this.isCanEdit =
            pod?.memberRights === 'everyone can edit/delete' &&
            this.isParticipant;
          if (pod?.participants?.length) {
            const members$ = pod.participants.map(user =>
              this.authService.getUserByUid(user)
            );
            combineLatest(members$).subscribe(result => {
              this.participants = result;
            });
          }

          const memberRequests$ = pod?.memberRequests?.map(user => {
            return this.authService.getUserByUid(user.id);
          });

          if (memberRequests$) {
            combineLatest(memberRequests$)
              .pipe(filter(result => !!result))
              .subscribe(result => {
                this.memberRequests = result.map((user, index) =>
                  Object.assign({
                    ...user,
                    answers: pod?.memberRequests[index]?.answers,
                  })
                );
              });
          }
          return pod?.creator.id
            ? this.authService.getUserByUid(pod?.creator.id)
            : null;
        }),
        filter(user => !!user)
      )
      .subscribe(user => (this.user = user));
  }

  closeEditModal(e) {
    e ? this.podStore.updateShowFormModal(false) : null;
  }

  handleOk(e) {
    e ? this.podStore.updateShowPendingModal(false) : null;
  }

  handleCancel(e) {
    e ? this.podStore.updateShowPendingModal(false) : null;
  }
  openModal() {
    this.podStore.updateShowFormModal(true);
  }
  toggleGallery(i?) {
    if (i !== undefined) {
      this.slideIndex = i;
    }
    if (
      this.pod.gallery.length === 1 &&
      this.pod.gallery[0].userId === this.userId
    ) {
      this.isCanDeleteImage = true;
    }
    this.isOpen = !this.isOpen;

    this.config = {
      modules: [Navigation],
      loop: true,
      navigation: true,
      initialSlide: i,
      on: {
        activeIndexChange: function (swiper) {
          this.slideIndex = swiper.realIndex;
          this.isCanDeleteImage =
            this.pod.gallery[swiper.realIndex].userId === this.userId;
        }.bind(this),
      },
    };
  }

  removeImg(e) {
    if (e) {
      const gallery = this.pod.gallery;
      const removedImg = gallery[this.slideIndex];
      gallery.splice(this.slideIndex, 1);
      const newItem = Object.assign({
        ...this.pod,
        gallery,
      });
      this.storageHelper.deletePicture(removedImg);
      this.updateItem(newItem);
    }
  }

  async flagImg(e) {
    if (e) {
      const gallery = this.pod.gallery;
      const removedImg = gallery[this.slideIndex];
      this.flagData = removedImg;
      this.flagData.podId = this.podId;
      this.flagData.type = 'podGallery';
      const functions = getFunctions();
      const isContentFlagged = httpsCallable(functions, 'isContentFlagged');
      await isContentFlagged({
        itemId: this.podId,
        url: removedImg.url,
        type: this.flagData.type
      }).then(res => {
        if(res.data === true) {
          this.isContentFlaggedModal = true;
        } else {
          this.isOpenFlagModal = true;
        }
      });
      
    }
  }

  closeModalDeleting(e) {
    if (e) this.showForDelete = false;
  }

  updateItem(newItem, msg?) {
    this.podStore.updateProfile(newItem);
    this.showForDelete = false;
    this.isOpen = false;
    this.podStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data) {
        } else {
          this.isClose.next(true);
          this.imageLoading = false;
        }
      });
  }

  async goToDiscordChanel(link) {
    await this.podsService.givePermission(this.podId);
    window.open(link, '_blank');
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.user = user;
        this.userId = user.uid;
        this.isUserPremium = this.userService.isPremium;
        this.isHasDiscord = !!user.discordLink;
        this.isAdmin = this.userService.isAdmin;
        mixpanel.identify(user.uid);
      });
  }
}
