import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { WonderPush } from '@awesome-cordova-plugins/wonderpush/ngx';
import { Platform } from '@ionic/angular';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import mixpanel from 'mixpanel-browser';
import {
  map,
  catchError,
  filter,
  BehaviorSubject,
  switchMap,
  combineLatest,
} from 'rxjs';
import { IBalanceInfo, IUser } from './auth.models';
import { AppConfigService } from '../services/config.service';
import { PushNotificationsService } from '../services/push-notifications.service';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  initialUser: IUser = {
    id: null,
    uid: null,
    email: null,
    username: null,
    discordLink: null,
    discordUserName: null,
    password: null,
    image: null,
    country: null,
    city: null,
    postalCode: null,
    birthday: null,
    galleryVisibility: null,
    autoTranslate: true,
    gender: 'Other',
    googleMapLink: null,
    mermaidExpLvl: null,
    mersonaName: null,
    profilePicture: null,
    isMailingEnabled: true,
    isPersonalPremium: null,
    platform: null,
    address: '',
    affiliateCode: '',
  };
  user: IUser;
  currentUser$ = new BehaviorSubject<IUser>(this.initialUser);
  isPremium: boolean;
  isAdmin: boolean;
  isAppPremium: boolean;
  isPaidPremium: boolean;
  isPersonalPremium: boolean;
  balanceInfo: IBalanceInfo;
  userSubscriptionInfo;

  constructor(
    private fireStore: AngularFirestore,
    private configService: AppConfigService,
    private wonderPush: WonderPush,
    private platform: Platform,
    private notificationsService: PushNotificationsService
  ) {}

  setUser = async () => {
    getAuth().onAuthStateChanged(async currentUser => {
      if (!currentUser) {
        return;
      }
      this.getSubscriptionInfo(currentUser);
      this.getUserByUid(currentUser.uid)
        .pipe(
          map(async (user: IUser) => {
            this.user = Object.assign({
              ...this.initialUser,
              ...user,
            });
          }),
          switchMap((user: any) =>
            combineLatest([
              this.listenBalanceInfoDetails(currentUser.uid),
              this.configService.getConfig(),
            ]).pipe(
              filter(([user, appPremium]) => !!user && appPremium !== undefined)
            )
          )
        )
        .subscribe(async ([info, config]) => {
          this.balanceInfo = info as any;
          this.isAdmin = this.balanceInfo?.role === 'admin';
          this.setIsUserPremium(config);
          this.currentUser$.next(this.user);
          await this.platform.ready();
          if (this.platform.is('capacitor')) {
            this.wonderPush.setUserConsent(true);
            this.wonderPush.setUserId(this.user.uid);
            this.wonderPush.subscribeToNotifications();
            this.notificationsService.getUserProperties(
              this.wonderPush,
              'capacitor'
            );
            mixpanel.init(environment.mixpanelToken, { debug: true });
            mixpanel.identify(this.user?.uid);
            mixpanel.people.set({
              'Notification Settings Changes': 'enabled',
            });
          } else {
            this.notificationsService.initWonderPush(this.user.uid);
            mixpanel.init(environment.mixpanelToken, { debug: true });
            mixpanel.identify(this.user?.uid);
            mixpanel.people.set({
              'Notification Settings Changes': 'enabled',
            });
          }
        });
    });
  };

  getUserByUid = (uid: string) =>
    this.fireStore
      .collection('users', ref => ref.where('uid', '==', uid))
      .snapshotChanges()
      .pipe(
        filter(user => !!user),
        map(user =>
          Object.assign(
            { id: user[0].payload.doc.id },
            user[0].payload.doc.data()
          )
        ),

        catchError(async e => {
          console.log(`User with uid ${uid} not found or not exist`);
          return { id: '' };
        })
      );

  deleteProfile = async () => {
    this.wonderPush.clearAllData();
    const functions = getFunctions();
    const isDeleted = httpsCallable(functions, 'deleteUser');
    const data = await isDeleted();
    if (data) {
      return data;
    } else {
      throw 'Firebase error';
    }
  };

  async getSubscriptionInfo(currentUser) {
    if (this.isPaidPremium) {
      return true;
    }
    // const decodedToken = await currentUser.getIdTokenResult();
    // const premium = decodedToken.claims.stripeRole === 'premium';
    await this.fireStore
      .collection('users')
      .doc(currentUser.uid)
      .collection('subscriptions')
      .ref.where('status', 'in', ['trialing', 'active'])

      .onSnapshot(async snapshot => {
        // if (premium) {
        //   const doc = snapshot.docs[0];
        //   this.userSubscriptionInfo = doc?.data();
        // } else {
        //   this.userSubscriptionInfo = false;
        // }
        this.isPaidPremium = !!snapshot.docs[0]?.data();
      });
  }

  listenBalanceInfoDetails(uid) {
    return this.fireStore.collection('info').doc(uid).valueChanges();
  }

  isUserAuth() {
    return getAuth().currentUser;
  }

  setIsUserPremium = async config => {
    console.log('current config:', config);
    if (config[0]) {
      this.isAppPremium = config[0].isAppPremium;
    }
    this.isPersonalPremium = this.user.isPersonalPremium;
    this.isPremium =
      this.isPersonalPremium || this.isAppPremium || this.isPaidPremium;
  };

  setPearlsAndShellsBalance = (pearls, shells) => {
    this.balanceInfo.balancePearls = pearls;
    this.balanceInfo.balanceShells = shells;
  };

  setPaidPremium = () => {
    this.isPaidPremium = true;
    this.isPremium = true;
  };
}
