import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ISlide, TutorialService } from 'src/app/tutorial/tutorial.service';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TutorialComponent implements OnInit {
  slides$ = new BehaviorSubject<ISlide[]>([]);
  isOpen = true;

  constructor(private service: TutorialService, private router: Router) {}

  ngOnInit(): void {
    this.slides$.next(this.service.tutorialData);
    this.isOpen = true;
  }

  toHome() {
    this.isOpen = false;
    setTimeout(() => {
      this.router.navigate(['/mood'], { replaceUrl: true });
    }, 0);
  }
}
