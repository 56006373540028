import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { GoogleMap } from '@capacitor/google-maps';
import { IonDatetimeButton, IonModal } from '@ionic/angular';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { filter, map, Subject, takeUntil } from 'rxjs';
import {
  ACCOUNT_EVENT_TYPE_ENUMS,
  categoryOptions,
  EVENT_TYPE_ENUMS,
  eventFormData,
} from '../events';
import { countries } from '../../auth/sign-up/countries';
import {
  FormService,
  linkRegex,
} from '../../shared/components/form/form.service';
import { AuthService } from '../../firebase/auth.service';
import { EventsService } from '../events.service';
import { environment } from '../../../environments/environment';
import { PodStore } from 'src/app/merfolk/pods/pod-store';
import { DateService } from 'src/app/services/date.service';
import { UserService } from 'src/app/auth/user.service';
import { ImagesService } from 'src/app/services/images.service';
import { MapService } from 'src/app/services/map.service';
import { PodService } from 'src/app/merfolk/pods/pods.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss'],
})
export class EventFormComponent implements OnInit, OnDestroy {
  @Input() eventForm;
  @Input() eventItem;
  @Input() imagesArr;
  @Input() disabled;
  @Input() comePod;
  @Output() eventFormChange = new EventEmitter<any>();
  @Output() imagesArrChange = new EventEmitter<any>();
  @Output() disabledChange = new EventEmitter<any>();
  @ViewChild(IonModal) modal: IonModal;
  @ViewChild('datetime') datetime: IonDatetimeButton;
  @ViewChild('map') mapRef;
  @ViewChild('placesRef', { static: false }) placesRef: GooglePlaceDirective;
  @ViewChild('imageLoader') imageLoader;
  currentUser;
  map;
  creator;
  pods;
  form: FormGroup;
  status;
  isOpen = false;
  isOnline = false;
  postingEventOptions = [
    ACCOUNT_EVENT_TYPE_ENUMS.MY_MEMBER_ACCOUNT,
    ACCOUNT_EVENT_TYPE_ENUMS.MY_BUSINESS_ACCOUNT,
  ];
  categoryOptions = categoryOptions;
  userHasPods = false;
  eventTypeOptions = [
    {
      name: EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_PAID,
    },
    {
      name: EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_FREE,
    },
    {
      name: EVENT_TYPE_ENUMS.INTERNALLY_HOSTED_AND_PAID,
    },
    {
      name: EVENT_TYPE_ENUMS.INTERNALLY_HOSTED_AND_FREE,
    },
  ];
  today = new Date().toISOString();
  paymentLinkLabel =
    "*If you don't add a payment link, a member that buys your ticket will message you via a private Discord message to pay you privately.";
  countries = countries;
  meetingLink = false;
  businessName = false;
  businessWebsiteURLValue = null;
  businessNameValue = null;
  businessWebsiteURL = false;
  addingBtnDisabled = true;
  concreteAddress = false;
  googleMapsLink = false;
  externalEventLink = true;
  paymentLink = false;
  priceContainer = true;
  paymentLinkLabelVisible = false;
  loadingImage = false;
  currentImage: string;
  imageField = eventFormData.filter(i => i.type === 'ARRAY');
  imagesControls = 1;
  isPodActivity = false;
  properties;
  showStartTime = true;
  showEndTime = true;
  showTimeFields = false;
  EVENT_TYPE_ENUMS = EVENT_TYPE_ENUMS;
  isExternalEvent = false;
  private destroy$ = new Subject<void>();
  private _mapLinkSubscription: any;
  private _countrySubscription: any;
  isMobile = false;
  constructor(
    private formService: FormService,
    private imagesService: ImagesService,
    private authService: AuthService,
    private podStore: PodStore,
    private eventsService: EventsService,
    private dateService: DateService,
    private userService: UserService,
    private fb: FormBuilder,
    private mapService: MapService,
    private podService: PodService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.createForm();
    this.podStore.getItems({});
    this.getUser();
    this.checkUserPods();

    // Set up the online toggle listener
    this.eventForm
      .get('online')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.showLocation();
      });

    // Add event type change listener to handle image restrictions
    this.eventForm
      .get('eventType')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(eventType => {
        this.isExternalEvent =
          eventType === EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_PAID ||
          eventType === EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_FREE;

        // If switching to external event and more than 1 image exists
        if (this.isExternalEvent && this.imagesArr?.length > 1) {
          // Keep only the first image
          this.imagesArr = [this.imagesArr[0]];
          this.imagesService.compressedImages.next(this.imagesArr);
          this.processImageArrays();
          this.toastService.presentToast(
            'Only 1 image is allowed for external events',
            'warning',
            'bottom'
          );
        }
      });

    if (this.eventItem) {
      this.eventForm.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(value => {
          if (value) {
            this.eventForm.value = value;
            this.imagesArrChange.next(this.imagesArr);
            this.eventFormChange.next(this.eventForm);
            this.disabled =
              this.eventForm.status === 'INVALID' ||
              this.status === 'loading' ||
              this.loadingImage ||
              !this.imagesArr ||
              !this.imagesArr.length;
          }
        });

      const {
        businessName,
        businessWebsiteURL,
        account,
        createdAt,
        startDate,
        endDate,
        images,
        podName,
        onlyPodMembers,
        meetingLink,
        eventType,
        paymentLink,
      } = this.eventItem;

      businessName ? (this.businessNameValue = businessName) : null;

      businessWebsiteURL
        ? (this.businessWebsiteURLValue = businessWebsiteURL)
        : null;

      if (account === ACCOUNT_EVENT_TYPE_ENUMS.MY_MEMBER_ACCOUNT) {
        this.businessName = false;
        this.businessWebsiteURL = false;
        this.eventForm.removeControl('businessName');
        this.eventForm.removeControl('businessWebsiteURL');
      } else if (account === ACCOUNT_EVENT_TYPE_ENUMS.MY_BUSINESS_ACCOUNT) {
        this.businessName = true;
        this.businessWebsiteURL = true;
        this.eventForm.addControl(
          'businessName',
          new FormControl(this.businessNameValue, [Validators.required])
        );
        this.eventForm.addControl(
          'businessWebsiteURL',
          new FormControl(this.businessWebsiteURLValue, [
            Validators.required,
            Validators.pattern(linkRegex),
          ])
        );
      }

      this.eventForm.patchValue(
        Object.assign({
          ...this.eventItem,
          createdAt,
          startDate: this.dateService.formatDate(startDate)
            ? new Date(this.dateService.formatDate(startDate)).toISOString()
            : null,
          endDate: endDate
            ? new Date(this.dateService.formatDate(endDate)).toISOString()
            : null,
        })
      );
      this.imagesService.compressedImages.next(images);

      this.imagesService.compressedImages
        .pipe(takeUntil(this.destroy$))
        .subscribe(images => {
          this.imagesArrChange.next(images);
          this.imagesArr = images;
        });

      if (this.eventForm.get('category').value === 'Pod Activity') {
        const newPodName = this.eventForm.addControl(
          'podName',
          new FormControl(null, [Validators.required])
        );
        this.eventForm.addControl('onlyPodMembers', new FormControl(false));
        this.isPodActivity = true;
      } else {
        this.isPodActivity = false;
        this.eventForm.removeControl('podName');
        this.eventForm.removeControl('onlyPodMembers');
      }

      if (podName) {
        this.eventForm.get('podName')?.patchValue(podName);
        this.eventForm.get('onlyPodMembers')?.patchValue(onlyPodMembers);
      }

      this.showLocation();
      this.eventForm.get('meetingLink')?.patchValue(meetingLink);
      this.eventTypesChanges(eventType);
      this.eventForm.get('paymentLink')?.patchValue(paymentLink);
    } else {
      this.eventForm
        .get('eventType')
        .setValue(EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_PAID);
      this.eventForm
        .get('account')
        .setValue(ACCOUNT_EVENT_TYPE_ENUMS.MY_MEMBER_ACCOUNT);

      this.formService.location$.next({ googleMapLink: null });
      this.eventForm.patchValue({
        location: {
          state: null,
          googleMapLink: null,
        },
      });
    } // if end

    this.eventForm.get('startDate').valueChanges.subscribe(() => {
      this.eventForm.get('endDate').patchValue(null);
      if (this.showTimeFields) {
        this.eventForm.get('startTime').patchValue(null);
        this.eventForm.get('endTime').patchValue(null);
      }
    });

    this.eventForm
      .get('eventType')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.eventTypesChanges(value);
      });
    if (this.comePod) {
      this.eventForm.patchValue({ category: 'Pod Activity' });
      this.eventForm.addControl(
        'podName',
        new FormControl(this.comePod?.id, [Validators.required])
      );
      this.eventForm.addControl('onlyPodMembers', new FormControl(false));
      this.isPodActivity = true;
    }
    this.eventForm.get('category').valueChanges.subscribe(value => {
      if (value === 'Pod Activity') {
        this.eventForm.addControl(
          'podName',
          new FormControl(null, [Validators.required])
        );
        this.eventForm.addControl('onlyPodMembers', new FormControl(false));
        this.isPodActivity = true;
      } else {
        this.isPodActivity = false;
        this.eventForm.removeControl('podName');
        this.eventForm.removeControl('onlyPodMembers');
      }
    });

    this.eventForm
      .get('account')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => {
        if (value === ACCOUNT_EVENT_TYPE_ENUMS.MY_MEMBER_ACCOUNT) {
          this.businessName = false;
          this.businessWebsiteURL = false;
          this.eventForm.removeControl('businessName');
          this.eventForm.removeControl('businessWebsiteURL');
        } else if (value === ACCOUNT_EVENT_TYPE_ENUMS.MY_BUSINESS_ACCOUNT) {
          this.businessName = true;
          this.businessWebsiteURL = true;
          this.eventForm.addControl(
            'businessName',
            new FormControl(this.businessNameValue, [Validators.required])
          );
          this.eventForm.addControl(
            'businessWebsiteURL',
            new FormControl(this.businessWebsiteURLValue, [
              Validators.required,
              Validators.pattern(linkRegex),
            ])
          );
        }
      });

    this.eventForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.disabledChange.next(
          this.eventForm.status === 'INVALID' ||
            this.status === 'loading' ||
            this.loadingImage ||
            !this.imagesArr?.length
        );
      });

    this.imagesService.compressedImages
      .pipe(takeUntil(this.destroy$))
      .subscribe(images => {
        this.imagesArrChange.next(images);
        this.imagesArr = images;
      });

    this.imagesService.imagesLoading.subscribe(loading => {
      this.loadingImage = loading;
      this.disabled =
        loading ||
        this.eventForm.status === 'INVALID' ||
        !this.imagesArr?.length;
      this.disabledChange.next(loading || this.eventForm.status === 'INVALID');
    });

    this.eventForm.get('endDate').valueChanges.subscribe(() => {
      if (this.showTimeFields) {
        this.eventForm.get('endTime').patchValue(null);
      }
    });

    // Update description validation based on eventType
    this.eventForm.get('eventType').valueChanges.subscribe(eventType => {
      const descriptionControl = this.eventForm.get('description');

      if (
        eventType === EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_PAID ||
        eventType === EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_FREE
      ) {
        // Subtitle for external events - optional with max length
        descriptionControl.setValidators([Validators.maxLength(150)]);
      } else {
        // Description for internal events - required with min length
        descriptionControl.setValidators([
          Validators.required,
          Validators.minLength(20),
          Validators.maxLength(150),
        ]);
      }

      // Mark the field as touched to show validation messages
      descriptionControl.markAsTouched();
      descriptionControl.updateValueAndValidity();
    });
  }

  saveBusinessName(e) {
    this.businessNameValue = e.target.value;
  }

  saveBusinessWebsiteURL(e) {
    this.businessWebsiteURLValue = e.target.value;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    // Clean up all subscriptions
    if (this._mapLinkSubscription) {
      this._mapLinkSubscription.unsubscribe();
      this._mapLinkSubscription = null;
    }

    if (this._countrySubscription) {
      this._countrySubscription.unsubscribe();
      this._countrySubscription = null;
    }

    // Reset the form
    if (this.eventForm) {
      this.eventForm.reset();
    }

    // Clear the form service data
    this.formService.itemGallery.next([]);
    this.businessWebsiteURLValue = null;
    this.businessNameValue = null;

    // Clear the location data
    this.formService.location$.next({
      googleMapLink: null,
    });

    this.processImageArrays();
  }

  public async handleAddressChange(address: any) {
    await this.createMap({
      lat: address.geometry.location.lat(),
      lng: address.geometry.location.lng(),
    });
    this.map.setOnMapClickListener(async ({ latitude, longitude }) => {
      const baseMapUrl = 'https://www.google.com/maps/search/?api=1&query=';
      this.eventForm.patchValue({
        googleMapLink: `${baseMapUrl}${latitude},${longitude}`,
      });

      this.isOpen = false;
    });
  }

  showLocation() {
    // Store the current Google Map link before making changes
    let currentGoogleMapLink = null;
    if (!this.isOnline && this.eventForm.get('location.googleMapLink')) {
      currentGoogleMapLink = this.eventForm.get('location.googleMapLink').value;
    }

    if (this.eventForm.get('online').value === true) {
      // Switching to online mode
      this.isOnline = true;

      // Store the Google Map link in a temporary variable for later use
      if (this.eventForm.get('location.googleMapLink')) {
        this.formService.location$.next({
          googleMapLink:
            currentGoogleMapLink || this.eventItem?.location?.googleMapLink,
        });
      }

      (this.eventForm.get('location') as FormGroup).removeControl(
        'googleMapLink'
      );

      this.meetingLink = true;
      this.eventForm.addControl(
        'meetingLink',
        new FormControl(null, [
          Validators.required,
          Validators.pattern(linkRegex),
        ])
      );
    } else {
      // Switching to offline mode
      const country = this.eventItem?.location?.country || null;
      this.isOnline = false;

      // Get the stored Google Map link from the form service
      const storedLink = this.formService.location$.getValue()?.googleMapLink;
      const linkToUse =
        storedLink ||
        currentGoogleMapLink ||
        this.eventItem?.location?.googleMapLink;

      (this.eventForm.get('location') as FormGroup).addControl(
        'googleMapLink',
        new FormControl(linkToUse, [Validators.required])
      );

      // Only update the form service if we have a link to use
      if (linkToUse) {
        this.formService.location$.next({
          googleMapLink: linkToUse,
        });
      }

      this.meetingLink = false;
      this.eventForm.removeControl('meetingLink');
    }

    if (this.eventForm.get('location.country')) {
      // Remove any existing subscription to avoid memory leaks
      if (this._countrySubscription) {
        this._countrySubscription.unsubscribe();
      }

      this._countrySubscription = this.eventForm
        .get('location.country')
        .valueChanges.pipe(
          filter(value => !!value),
          filter(
            (value: any) =>
              value.name !== this.eventItem?.location?.country?.name
          )
        )
        .subscribe(() => {
          this.formService.location$.next({ googleMapLink: null });
          this.eventForm.patchValue({
            location: {
              state: null,
              googleMapLink: null,
            },
          });
        });
    }
  }

  addMapLink(e) {
    // Use a single subscription to avoid multiple calls
    if (this._mapLinkSubscription) {
      this._mapLinkSubscription.unsubscribe();
    }

    // Store the initial value to avoid loops
    const initialValue = e.get('googleMapLink')?.value;

    this._mapLinkSubscription = e.valueChanges.subscribe(async values => {
      // Only process if the value is not null and has changed
      const location = e.get('googleMapLink')?.value;

      if (location && location !== initialValue) {
        try {
          const { country } = await this.mapService.getAddress(location);

          let currentCountry = countries.find(item => item.name === country);

          // Update the form without triggering another valueChanges event
          this.eventForm
            .get('location.googleMapLink')
            ?.setValue(location, { emitEvent: false });
          this.eventForm
            .get('location.country')
            ?.setValue(currentCountry, { emitEvent: false });

          // Store the current value in the form service for persistence
          this.formService.location$.next({
            googleMapLink: location,
          });
        } catch (error) {
          console.error('Error processing map link:', error);
        }
      }
    });
  }

  getFormsControls(): FormArray {
    return <FormArray>this.eventForm.controls['images'];
  }

  createMapHandler() {
    this.isOpen = true;
    setTimeout(async () => {
      await this.createMap(this.currentUser.location);
      this.map.setOnMapClickListener(({ latitude, longitude }) => {
        const baseMapUrl = 'https://www.google.com/maps/search/?api=1&query=';
        this.eventForm.get('location').patchValue({
          googleMapLink: `${baseMapUrl}${latitude},${longitude}`,
        });
        this.isOpen = false;
      });
    });
  }

  getRandomId() {
    return (Math.random() + 1).toString(36).substring(7);
  }

  eventTypesChanges(value) {
    if (value === EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_PAID) {
      this.hidePaymentLink();
      this.showPriceContainer();
      this.showExternalEventLink(value);
    } else if (value === EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_FREE) {
      this.hidePaymentLink();
      this.hidePriceContainer();
      this.showExternalEventLink(value);
    } else if (value === EVENT_TYPE_ENUMS.INTERNALLY_HOSTED_AND_PAID) {
      this.showPaymentLink();
      this.showPriceContainer();
      this.hideExternalEventLink(value);
    } else if (value === EVENT_TYPE_ENUMS.INTERNALLY_HOSTED_AND_FREE) {
      this.hidePaymentLink();
      this.hidePriceContainer();
      this.hideExternalEventLink(value);
    }
  }

  eventFormSubmit($event) {
    this.imagesArrChange.next(this.imagesArr);
    this.eventFormChange.next(this.eventForm);
  }

  async createMap({ lat, lng }) {
    this.map = await GoogleMap.create({
      id: this.getRandomId(),
      apiKey: environment.googleMapKey,
      element: this.mapRef.nativeElement,
      config: {
        center: { lat, lng },
        zoom: 10,
        keyboardShortcuts: false,
        disableDefaultUI: true,
      },
    });
  }

  private listenPodsChanges() {
    this.podStore
      .selectItems()
      .pipe(
        takeUntil(this.destroy$),
        filter(item => !!item),
        map(
          items =>
            items &&
            items.filter(
              item =>
                (item.participants.includes(this.currentUser?.uid) &&
                  item.memberRights === 'everyone can edit/delete') ||
                item.creator?.id === this.currentUser?.uid
            )
        )
      )
      .subscribe(items => {
        this.pods = items;
      });
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.currentUser = user;
        this.listenPodsChanges();
      });
  }

  private showExternalEventLink(value) {
    if (
      (value.includes('A different website informs') ||
        [
          EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_PAID,
          EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_FREE,
        ].includes(value)) &&
      !this.eventForm.get('externalEventLink')
    ) {
      this.externalEventLink = true;
      this.eventForm.addControl(
        'externalEventLink',
        new FormControl(null, [
          Validators.required,
          Validators.pattern(linkRegex),
        ])
      );
    }
  }

  private hideExternalEventLink(value) {
    if (
      (value.includes('I want to inform') ||
        [
          EVENT_TYPE_ENUMS.INTERNALLY_HOSTED_AND_PAID,
          EVENT_TYPE_ENUMS.INTERNALLY_HOSTED_AND_FREE,
        ].includes(value)) &&
      this.eventForm.get('externalEventLink')
    ) {
      this.externalEventLink = false;
      this.eventForm.removeControl('externalEventLink');
    }
  }

  private showPaymentLink() {
    this.paymentLink = true;
    this.eventForm.addControl(
      'paymentLink',
      new FormControl(null, [Validators.pattern(linkRegex)])
    );
    this.paymentLinkLabelVisible = true;
  }

  private showPriceContainer() {
    this.priceContainer = true;
    (this.eventForm.get('price') as FormGroup).addControl(
      'value',
      new FormControl('', [Validators.required])
    );
    (this.eventForm.get('price') as FormGroup).addControl(
      'currency',
      new FormControl('$', [Validators.required])
    );
  }

  private hidePaymentLink() {
    this.paymentLink = false;
    this.eventForm.removeControl('paymentLink');
    this.paymentLinkLabelVisible = false;
  }

  private hidePriceContainer() {
    this.priceContainer = false;
    (this.eventForm.get('price') as FormGroup).removeControl('value');
    (this.eventForm.get('price') as FormGroup).removeControl('currency');
  }

  private createForm() {
    // Create a proper ISO string for today
    const today = new Date();
    const todayIso = today.toISOString();

    this.eventForm = this.fb.group({
      images: this.fb.array([
        {
          0: this.fb.group({
            image: [null],
          }),
        },
      ]),
      title: ['', [Validators.required, Validators.minLength(7)]],
      startDate: [todayIso, [Validators.required]],
      endDate: [null],
      startTime: [null],
      endTime: [null],
      showTimeFields: [false],
      category: ['', [Validators.required]],
      online: [false, [Validators.required]],
      location: this.fb.group({
        country: [null],
        googleMapLink: [null, [Validators.required]],
      }),
      eventType: ['', [Validators.required]],
      externalEventLink: [
        '',
        [Validators.required, Validators.pattern(linkRegex)],
      ],
      price: this.fb.group({
        value: [null, [Validators.required]],
        currency: ['$'],
      }),
      description: ['', [Validators.required]],
      attendeeLimit: [''],
      account: ['', [Validators.required]],
      participants: [],
    });

    // Update description validation based on eventType
    this.eventForm.get('eventType').valueChanges.subscribe(eventType => {
      const descriptionControl = this.eventForm.get('description');

      if (
        eventType === EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_PAID ||
        eventType === EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_FREE
      ) {
        // Subtitle for external events - optional with max length
        descriptionControl.setValidators([Validators.maxLength(150)]);
      } else {
        // Description for internal events - required with min length
        descriptionControl.setValidators([
          Validators.required,
          Validators.minLength(20),
          Validators.maxLength(150),
        ]);
      }

      // Mark the field as touched to show validation messages
      descriptionControl.markAsTouched();
      descriptionControl.updateValueAndValidity();
    });

    this.eventForm.get('startDate').valueChanges.subscribe(() => {
      this.eventForm.get('endDate').patchValue(null);
      if (this.showTimeFields) {
        this.eventForm.get('startTime').patchValue(null);
        this.eventForm.get('endTime').patchValue(null);
      }
    });

    this.eventForm.get('endDate').valueChanges.subscribe(() => {
      if (this.showTimeFields) {
        this.eventForm.get('endTime').patchValue(null);
      }
    });
  }

  handleTimeToggle(event: any) {
    this.showTimeFields = event.detail.checked;

    if (this.showTimeFields) {
      // When time is toggled ON, initialize time fields if needed
      const startDate = this.eventForm.get('startDate').value;
      const endDate = this.eventForm.get('endDate').value;

      if (startDate) {
        // Ensure startDate is a valid ISO string
        try {
          const date = new Date(startDate);
          if (!isNaN(date.getTime())) {
            // Valid date, use it as is or format properly
            this.eventForm.get('startDate').patchValue(date.toISOString());

            // Set a default time (noon) for start time if not set
            if (!this.eventForm.get('startTime').value) {
              const startTime = new Date();
              startTime.setHours(12, 0, 0, 0);
              this.eventForm
                .get('startTime')
                .patchValue(startTime.toISOString());
            }
          }
        } catch (error) {
          console.error('Error processing start date:', error);
        }
      }

      if (endDate) {
        // Ensure endDate is a valid ISO string
        try {
          const date = new Date(endDate);
          if (!isNaN(date.getTime())) {
            // Valid date, use it as is or format properly
            this.eventForm.get('endDate').patchValue(date.toISOString());

            // Set a default time (hour after start time) for end time if not set
            if (!this.eventForm.get('endTime').value) {
              const endTime = new Date();
              endTime.setHours(13, 0, 0, 0);
              this.eventForm.get('endTime').patchValue(endTime.toISOString());
            }
          }
        } catch (error) {
          console.error('Error processing end date:', error);
        }
      }
    } else {
      const startDate = this.eventForm.get('startDate').value;
      const endDate = this.eventForm.get('endDate').value;

      if (startDate) {
        // Store only the date part when time fields are disabled
        try {
          const date = new Date(startDate);
          if (!isNaN(date.getTime())) {
            const dateOnly = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate()
            );
            this.eventForm.get('startDate').patchValue(dateOnly.toISOString());
            this.eventForm.get('startTime').patchValue(null);
          }
        } catch (error) {
          console.error('Error processing start date:', error);
        }
      }

      if (endDate) {
        // Store only the date part when time fields are disabled
        try {
          const date = new Date(endDate);
          if (!isNaN(date.getTime())) {
            const dateOnly = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate()
            );
            this.eventForm.get('endDate').patchValue(dateOnly.toISOString());
            this.eventForm.get('endTime').patchValue(null);
          }
        } catch (error) {
          console.error('Error processing end date:', error);
        }
      }
    }

    // Ensure the showTimeFields value is in the form data to be saved to Firebase
    if (!this.eventForm.contains('showTimeFields')) {
      this.eventForm.addControl(
        'showTimeFields',
        new FormControl(this.showTimeFields)
      );
    } else {
      this.eventForm.get('showTimeFields').patchValue(this.showTimeFields);
    }
  }

  handleDateChange(event: any, type: 'start' | 'end') {
    const value = event.detail.value;
    if (!value) return;

    try {
      // Ensure we have a valid date object
      const date = new Date(value);
      if (isNaN(date.getTime())) return;

      // Get a proper ISO string
      const isoString = date.toISOString();

      if (type === 'start') {
        // When start date changes, update it and clear end date if it's before the new start date
        this.eventForm.get('startDate').patchValue(isoString);

        const endDate = this.eventForm.get('endDate').value;
        if (endDate) {
          const endDateObj = new Date(endDate);
          if (!isNaN(endDateObj.getTime()) && endDateObj < date) {
            // If end date is before the new start date, reset it
            this.eventForm.get('endDate').patchValue(null);
          }
        }
      } else {
        // When end date changes, validate it's not before start date
        const startDate = this.eventForm.get('startDate').value;
        if (startDate) {
          const startDateObj = new Date(startDate);
          if (!isNaN(startDateObj.getTime())) {
            if (date < startDateObj) {
              // If end date is before start date, don't update it
              return;
            }
            // Valid end date, update it
            this.eventForm.get('endDate').patchValue(isoString);
          }
        } else {
          // If no start date, just set the end date
          this.eventForm.get('endDate').patchValue(isoString);
        }
      }
    } catch (error) {
      console.error('Error handling date change:', error);
    }
  }

  private checkUserPods() {
    // Check if user is part of any pods
    this.podStore.state$
      .pipe(
        takeUntil(this.destroy$),
        filter(state => !!state.items)
      )
      .subscribe(state => {
        const pods = state.items;
        if (this.currentUser && pods && pods.length) {
          // Use the userPodsFilter method from podService to check if user is in any pods
          const userPods = this.podService.userPodsFilter(
            pods,
            this.currentUser.uid
          );
          this.userHasPods = userPods && userPods.length > 0;

          // Remove Pod Activity from options if user has no pods
          if (!this.userHasPods && !this.comePod) {
            this.categoryOptions = this.categoryOptions.filter(
              cat => cat !== 'Pod Activity'
            );
          }
        } else {
          this.userHasPods = false;
          // Remove Pod Activity from options if user has no pods
          if (!this.comePod) {
            this.categoryOptions = this.categoryOptions.filter(
              cat => cat !== 'Pod Activity'
            );
          }
        }
      });
  }

  async compressMultiplyImages(e) {
    if (this.isExternalEvent && this.imagesArr?.length >= 1) {
      this.toastService.presentToast(
        'Only 1 image is allowed for external events',
        'warning',
        'bottom'
      );
      return;
    }
    await this.imagesService.compressMultiplyImages(this.isMobile);
  }

  processImageArrays() {
    if (this.imagesArr) {
      this.imagesArrChange.emit(this.imagesArr);
    }
  }

  handleDisabledClick() {
    let message = '';

    if (this.eventForm.status === 'INVALID') {
      message = 'Please fill in all required fields correctly';
    } else if (this.status === 'loading' || this.loadingImage) {
      message = 'Please wait while we process your request';
    } else if (!this.imagesArr?.length) {
      message = 'Please add at least one image';
    }

    if (message) {
      this.toastService.presentToast(message, 'warning', 'bottom');
    }
  }

  // Method to get all missing required fields
  getMissingRequiredFields(): string[] {
    const missingFields: string[] = [];

    if (!this.imagesArr || !this.imagesArr.length) {
      missingFields.push('Image');
    }

    // Check form controls
    if (this.eventForm) {
      if (this.eventForm.get('title')?.errors) {
        missingFields.push('Title');
      }

      if (this.eventForm.get('startDate')?.errors) {
        missingFields.push('Start date');
      }

      if (this.eventForm.get('category')?.errors) {
        missingFields.push('Category');
      }

      const isOnline = this.eventForm.get('online')?.value;
      if (!isOnline && !this.eventForm.get('location.googleMapLink')?.value) {
        missingFields.push('Location');
      }

      if (this.eventForm.get('eventType')?.errors) {
        missingFields.push('Event type');
      }

      // Check conditionally required fields
      const eventType = this.eventForm.get('eventType')?.value;
      const isExternal =
        eventType === EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_PAID ||
        eventType === EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_FREE;

      if (
        this.externalEventLink &&
        this.eventForm.get('externalEventLink')?.errors
      ) {
        missingFields.push('Event site');
      }

      if (this.priceContainer && this.eventForm.get('price.value')?.errors) {
        missingFields.push('Price');
      }

      if (this.eventForm.get('description')?.errors && !isExternal) {
        missingFields.push('Description');
      }

      if (this.eventForm.get('account')?.errors) {
        missingFields.push('Account type');
      }

      if (this.businessName && this.eventForm.get('businessName')?.errors) {
        missingFields.push('Business name');
      }

      if (
        this.businessWebsiteURL &&
        this.eventForm.get('businessWebsiteURL')?.errors
      ) {
        missingFields.push('Website URL');
      }
    }

    return missingFields;
  }
}

