import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventApproved',
})
export class EventApprovedPipe implements PipeTransform {
  transform(value: unknown, user: string): unknown {
    if (Array.isArray(value) && value) {
      return value.filter(
        item =>
          // Include events that are approved OR
          // Events that are flagged but the user is still a participant
          (item.status === 'approved' ||
            (item.isFlagged &&
              item.participants?.some(
                participant => participant.id === user
              ))) &&
          // Also ensure the user is either the creator or a participant
          (item.creator.id === user ||
            item.participants?.some(participant => participant.id === user))
      );
    } else return value;
  }
}

