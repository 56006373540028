import { Injectable } from '@angular/core';

interface CacheItem<T> {
  data: T;
  timestamp: number;
  expiresAt: number;
}

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private readonly CACHE_PREFIX = 'mermapp_cache_';
  private readonly DEFAULT_EXPIRY_MS = 2 * 24 * 60 * 60 * 1000; // 2 days in milliseconds

  constructor() {
    // Clean up expired cache items on service initialization
    this.cleanExpiredCache();
  }

  /**
   * Get the cache prefix used for all cache keys
   * @returns The cache prefix string
   */
  getCachePrefix(): string {
    return this.CACHE_PREFIX;
  }

  /**
   * Set data in cache with expiration
   * @param key Cache key
   * @param data Data to cache
   * @param expiryMs Expiry time in milliseconds (defaults to 2 days)
   */
  set<T>(
    key: string,
    data: T,
    expiryMs: number = this.DEFAULT_EXPIRY_MS
  ): void {
    const now = Date.now();
    const cacheKey = this.CACHE_PREFIX + key;

    const cacheItem: CacheItem<T> = {
      data,
      timestamp: now,
      expiresAt: now + expiryMs,
    };

    localStorage.setItem(cacheKey, JSON.stringify(cacheItem));
    console.log(
      `Cached data for key: ${key}, expires in ${
        expiryMs / (1000 * 60 * 60)
      } hours`
    );
  }

  /**
   * Get data from cache
   * @param key Cache key
   * @returns Cached data or null if not found or expired
   */
  get<T>(key: string): T | null {
    const cacheKey = this.CACHE_PREFIX + key;
    const cachedItem = localStorage.getItem(cacheKey);

    if (!cachedItem) {
      return null;
    }

    try {
      const cacheItem = JSON.parse(cachedItem) as CacheItem<T>;
      const now = Date.now();

      // Check if cache has expired
      if (now > cacheItem.expiresAt) {
        console.log(`Cache expired for key: ${key}`);
        localStorage.removeItem(cacheKey);
        return null;
      }

      console.log(
        `Cache hit for key: ${key}, age: ${
          (now - cacheItem.timestamp) / (1000 * 60)
        } minutes`
      );
      return cacheItem.data;
    } catch (error) {
      console.error(`Error parsing cache for key: ${key}`, error);
      localStorage.removeItem(cacheKey);
      return null;
    }
  }

  /**
   * Remove item from cache
   * @param key Cache key
   */
  remove(key: string): void {
    const cacheKey = this.CACHE_PREFIX + key;
    localStorage.removeItem(cacheKey);
  }

  /**
   * Clear all cache items
   */
  clear(): void {
    Object.keys(localStorage)
      .filter(key => key.startsWith(this.CACHE_PREFIX))
      .forEach(key => localStorage.removeItem(key));
  }

  /**
   * Clean expired cache items
   */
  private cleanExpiredCache(): void {
    const now = Date.now();
    let expiredCount = 0;

    Object.keys(localStorage)
      .filter(key => key.startsWith(this.CACHE_PREFIX))
      .forEach(key => {
        try {
          const cachedItem = localStorage.getItem(key);
          if (cachedItem) {
            const cacheItem = JSON.parse(cachedItem) as CacheItem<any>;
            if (now > cacheItem.expiresAt) {
              localStorage.removeItem(key);
              expiredCount++;
            }
          }
        } catch (error) {
          // Remove invalid cache items
          localStorage.removeItem(key);
        }
      });

    if (expiredCount > 0) {
      console.log(`Cleaned up ${expiredCount} expired cache items`);
    }
  }

  /**
   * Check if a cache item is stale (older than specified time)
   * @param key Cache key
   * @param staleTimeMs Time in milliseconds after which the cache is considered stale
   * @returns True if cache is stale or doesn't exist, false otherwise
   */
  isStale(key: string, staleTimeMs: number = this.DEFAULT_EXPIRY_MS): boolean {
    const cacheKey = this.CACHE_PREFIX + key;
    const cachedItem = localStorage.getItem(cacheKey);

    if (!cachedItem) {
      return true;
    }

    try {
      const cacheItem = JSON.parse(cachedItem) as CacheItem<any>;
      const now = Date.now();
      const age = now - cacheItem.timestamp;

      return age > staleTimeMs;
    } catch (error) {
      console.error(`Error parsing cache for key: ${key}`, error);
      localStorage.removeItem(cacheKey);
      return true;
    }
  }
}

