import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { filter, finalize, Subject, takeUntil, catchError, tap } from 'rxjs';
import { StorageHelper } from 'src/app/firebase/helpers/storageHelper';
import { FormService } from '../form/form.service';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { createToaster, positionType } from '../modals/toaster';
import { Camera, CameraResultType } from '@capacitor/camera';
import { ImagesService } from 'src/app/services/images.service';
import { ProfileStore } from 'src/app/profile/store/profile-store';

const confirmForDiscard = {
  title: 'Discard your changes?',
  icon: 'image',
  okBtn: {
    title: 'YES, DISCARD',
  },
  cancelBtn: { title: 'NO, GO BACK' },
};

@Component({
  selector: 'app-image-loader',
  templateUrl: './image-loader.component.html',
  styleUrls: ['./image-loader.component.scss'],
})
export class ImageLoaderComponent implements OnInit, OnDestroy {
  @Input() field;
  @Input() form;
  @Input() type: string;
  @Input() i: number;
  @Input() userGallery;
  @Input() disabled;
  @ViewChild('imageDescription') imageDescription;
  @Output() removeFn = new EventEmitter<number>();
  @Output() upload = new EventEmitter<string[]>();
  spinner: boolean = false;
  maxSize: boolean = false;
  thumbnailArr = this.formService.itemGallery;
  imagesArr: string[] = [];
  title: string;
  url: string;
  arrTitle;
  index: number;
  imgs: any[] = [];
  previewImgs = [];
  currentTime = new Date();
  confirmForDiscard = confirmForDiscard;
  showForDiscard = false;
  imagesLoading;
  imagesCompressing;

  private destroy$ = new Subject<void>();

  constructor(
    private storageHelper: StorageHelper,
    private formService: FormService,
    private imagesService: ImagesService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.form = this.fb.group({ image: [null] });
    // this.formService.itemGallery.subscribe(images => {
    //   this.imagesArr = images;
    // });
    this.listenImages();
    this.imagesService.imagesLoading.subscribe(data => {
      this.imagesLoading = data;
    });
    this.imagesService.compressionLoading.subscribe(data => {
      this.imagesCompressing = data;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.imagesService.compressionLoading.next(false);
    this.imagesService.imagesLoading.next(false);
    this.imagesService.imageUrls.next([]);
    this.imagesService.compressedImages.next([]);
  }

  async compressImage() {
    await this.imagesService.compressImage();
  }

  addToGallery(file, control, i, field) {
    if (!file) {
      this.spinner = false;
      return;
    }
    this.maxSize = false;
    this.spinner = true;
    this.title = file;
    this.formService.loadingImage.next(true);
    this.storageHelper.uploadPicture(file).then(file => {
      file.task
        .snapshotChanges()
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            file.fileRef
              .getDownloadURL()
              .pipe(
                takeUntil(this.destroy$),
                filter(url => !!url),
                catchError(async e =>
                  createToaster(`Error:${e}`, 'danger', positionType.BOTTOM)
                )
              )
              .subscribe(async url => {
                this.url = url;
                if (i === this.imagesArr.length) {
                  const images = [...this.imagesArr, url];
                  this.formService.itemGallery.next(images);
                } else {
                  const images = this.imagesArr;
                  images[i] = url;
                  this.formService.itemGallery.next(images);
                }
                this.spinner = false;
                this.formService.loadingImage.next(false);
              });
          })
        )
        .subscribe();
    });
  }

  listenImages() {
    this.imagesService.compressedImages
      .pipe(takeUntil(this.destroy$))
      .subscribe(images => {
        this.imgs = images;
        if (images.length) {
          this.previewImgs = [];
          images.map(image => {
            if (typeof image === 'string') {
              this.previewImgs.push({ src: image, image });
            } else if (image instanceof File) {
              const reader = new FileReader();
              reader.onload = () => {
                const src = reader.result;
                this.previewImgs.push({ src, image });
              };
              reader.readAsDataURL(image);
            }
          });
        } else {
          this.previewImgs = [];
        }
      });
  }

  async compressMultiplyImages(e) {
    await this.imagesService.compressMultiplyImages();
  }

  removeImage(image) {
    this.imgs = this.imgs.filter(img => img !== image.image);
    this.imagesService.compressedImages.next(this.imgs);
  }

  async upLoadGalleryImages() {
    this.imagesService.imagesLoading.next(true);
    let urlsArray = [];
    this.imgs.map(image => {
      this.storageHelper.uploadPicture(image).then(file => {
        file.task
          .snapshotChanges()
          .pipe(
            takeUntil(this.destroy$),
            catchError(async e => {
              createToaster(
                `Something went wrong.`,
                'danger',
                positionType.BOTTOM
              );
              this.imagesService.imagesLoading.next(false);
            }),
            finalize(() => {
              return file.fileRef
                .getDownloadURL()
                .pipe(
                  takeUntil(this.destroy$),
                  catchError(async e => {
                    createToaster(
                      `Something went wrong.`,
                      'danger',
                      positionType.BOTTOM
                    );
                    this.imagesService.imagesLoading.next(false);
                    return;
                  })
                )
                .subscribe((url: string) => {
                  urlsArray.push({
                    createdAt: this.currentTime,
                    url,
                    feedMessage: this.imageDescription?.el?.value || '',
                  });
                  if (urlsArray.length === this.imgs.length) {
                    this.upload.next(urlsArray);
                  }
                });
            })
          )
          .subscribe();
      });
    });
  }

  async upLoadImages() {
    this.imagesService.imagesLoading.next(true);
    let urlsArray = [];
    this.imgs.map(image => {
      if (typeof image === 'string') {
        urlsArray.push(image);
        if (urlsArray.length === this.imgs.length) {
          this.imagesService.imageUrls.next(urlsArray);
        }
      } else {
        this.storageHelper.uploadPicture(image).then(file => {
          file.task
            .snapshotChanges()
            .pipe(
              takeUntil(this.destroy$),
              catchError(async e => {
                createToaster(
                  `Something went wrong.`,
                  'danger',
                  positionType.BOTTOM
                );
                this.imagesService.imagesLoading.next(false);
              }),
              finalize(() => {
                return file.fileRef
                  .getDownloadURL()
                  .pipe(
                    filter(item => !!item),
                    takeUntil(this.destroy$),
                    catchError(async e => {
                      createToaster(
                        `Something went wrong.`,
                        'danger',
                        positionType.BOTTOM
                      );
                      this.imagesService.imagesLoading.next(false);
                      return;
                    })
                  )
                  .subscribe((url: string) => {
                    !!url ? urlsArray.push(url) : null;
                    if (urlsArray.length === this.imgs.length) {
                      this.imagesService.imageUrls.next(urlsArray);
                      this.imagesService.imagesLoading.next(false);
                      
                    }
                  });
              })
            )
            .subscribe();
        });
      }
    });
  }

  discardGalleryChanges() {
    this.showForDiscard = false;
    this.imagesService.addImageToGalleryModalIsOpen.next(false);
    this.imgs = [];
    this.imagesService.compressedImages.next([]);
    this.previewImgs = [];
    this.imagesService.compressionLoading.next(false);
  }

  closeModal($event: boolean) {
    this.showForDiscard = false;
  }
}
