import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastController: ToastController) {}

  /**
   * Present a toast notification
   * @param message The message to display
   * @param durationOrColor Duration in milliseconds or color of the toast
   * @param position Position of the toast (default: 'bottom')
   * @param color Color of the toast (default: 'primary')
   */
  async presentToast(
    message: string,
    durationOrColor: number | string = 2000,
    position: 'top' | 'bottom' | 'middle' = 'bottom',
    color?: string
  ) {
    let duration = 2000;
    let toastColor = 'primary';

    // Handle the case where durationOrColor is a color string
    if (typeof durationOrColor === 'string') {
      toastColor = durationOrColor;
    } else {
      duration = durationOrColor;
      if (color) {
        toastColor = color;
      }
    }

    const toast = await this.toastController.create({
      message,
      duration,
      position,
      color: toastColor,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
        },
      ],
    });

    await toast.present();
    return toast;
  }
}

