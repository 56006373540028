import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';
import { FormService } from '../../shared/components/form/form.service';
import { EventsStore } from '../events-store';
import { EventsService } from '../events.service';
import { countries } from '../../auth/sign-up/countries';
import {
  ACCOUNT_EVENT_TYPE_ENUMS,
  categoryOptions,
  confirmDeletingModalData,
  confirmEditEventModalData,
  EVENT_TYPE_ENUMS,
  eventFormData,
  EventItem,
} from '../events';
import {
  createToaster,
  positionType,
} from '../../shared/components/modals/toaster';
import { UserService } from 'src/app/auth/user.service';
import { ImagesService } from 'src/app/services/images.service';
import { showLoading } from 'src/app/shared/components/modals/loading';

@Component({
  selector: 'app-edit-events',
  templateUrl: './edit-events.component.html',
  styleUrls: ['./edit-events.component.scss'],
})
export class EditEventsComponent implements OnInit, OnDestroy {
  @Output() isClose = new EventEmitter<boolean>(false);
  @ViewChild('map') mapRef;
  @ViewChild('eventFormComponent') eventFormComponent;

  map;
  isOpen = false;
  isOnline = false;
  currentUser;
  status;
  eventItem;
  loader = showLoading();
  newEventItem: EventItem;
  item = { icon: 'calendar-outline', title: 'Edit an Event' };
  categoryOptions = categoryOptions;
  showForDelete = false;

  eventTypeOptions = [
    {
      name: EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_PAID,
    },
    {
      name: EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_FREE,
    },
    {
      name: EVENT_TYPE_ENUMS.INTERNALLY_HOSTED_AND_PAID,
    },
    {
      name: EVENT_TYPE_ENUMS.INTERNALLY_HOSTED_AND_FREE,
    },
  ];

  id: string = this.activatedRouter.snapshot.params.id;
  showSuccessModal = false;
  paymentLinkLabel =
    "*If you don't add a payment link, a member that buys your ticket will message you via a private Discord message to pay you privately.";
  form: FormGroup;
  countries = countries;
  showConfirmModal = false;
  confirmModalData = confirmEditEventModalData;
  eventForm: FormGroup;
  imagesControls = 1;
  meetingLink = false;
  businessName = false;
  businessWebsiteURL = false;
  concreteAddress = false;
  googleMapsLink = false;
  externalEventLink = true;
  paymentLink = false;
  priceContainer = true;
  paymentLinkLabelVisible = false;
  loadingImage = false;
  addingBtnDisabled = true;
  disabled = true;
  statusDeleting = false;
  statusAdding = false;
  currentImage: string;
  imagesArr: string[];
  imageField = eventFormData.filter(i => i.type === 'ARRAY');
  postingEventOptions = [
    ACCOUNT_EVENT_TYPE_ENUMS.MY_MEMBER_ACCOUNT,
    ACCOUNT_EVENT_TYPE_ENUMS.MY_BUSINESS_ACCOUNT,
  ];
  confirmForDelete = confirmDeletingModalData;
  today = new Date().toISOString();

  private destroy$ = new Subject<void>();

  constructor(
    private formService: FormService,
    private imagesService: ImagesService,
    private router: Router,
    private userService: UserService,
    private activatedRouter: ActivatedRoute,
    private eventsService: EventsService,
    private eventsStore: EventsStore
  ) {}

  ngOnInit() {
    this.listenStatusChanges();
    this.eventsStore
      .selectId()
      .pipe(filter(id => !!id))
      .subscribe(id => {
        this.isClose.next(true);
        this.eventsStore.updateShowFormModal(false);
        this.eventsService.isOpenForm.next(true);
      });

    this.initialDataListener();
    this.listenItemId();
    this.getUser();

    this.eventsService.isOpenForm.subscribe(
      isOpen => (this.showSuccessModal = isOpen)
    );

    // Add class to modals to ensure proper styling
    document.addEventListener('ionModalDidPresent', (event: any) => {
      if (event.target && event.target.classList) {
        event.target.classList.add('edit-events-modal');

        // Add curvy styling class
        event.target.classList.add('curvy-modal');

        // Add scrollable class
        event.target.classList.add('scrollable-modal');

        // If this is an external event, add a specific class
        if (this.isExternalEvent()) {
          event.target.classList.add('external-event-modal');
        }

        // Ensure modal is scrollable
        const modalWrapper = event.target.querySelector('.modal-wrapper');
        if (modalWrapper) {
          modalWrapper.style.overflowY = 'scroll';
          modalWrapper.style.webkitOverflowScrolling = 'touch';
        }

        // Ensure ion-content is scrollable
        const ionContent = event.target.querySelector('ion-content');
        if (ionContent) {
          ionContent.style.setProperty('--overflow', 'scroll');
        }
      }
    });
  }

  ngOnDestroy() {
    this.formService.itemGallery.next([]);
    this.destroy$.next();
    this.destroy$.complete();
  }

  initialDataListener() {
    this.eventsStore
      .selectItem()
      .pipe(
        filter(item => !!item),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        this.eventItem = data;
      });
  }

  close(e) {
    e ? (this.showConfirmModal = true) : null;
  }

  discardChanges(e) {
    if (e) {
      this.isClose.next(true);
      this.showConfirmModal = false;
    }
  }

  closeModal(e) {
    if (e) {
      this.showConfirmModal = false;
      this.showForDelete = false;
    }
  }

  async updateItem() {
    if (!this.eventForm) {
      return;
    }

    // Set status to loading to show the skeleton for all event types
    this.status = 'loading';
    console.log(
      'Setting status to loading for edit, skeleton should appear now'
    );

    this.eventsStore.setLoading();
    await this.eventFormComponent.imageLoader.upLoadImages();
    this.imagesService.imageUrls
      .pipe(
        filter(data => !!data),
        takeUntil(this.destroy$)
      )
      .subscribe(urls => {
        if (!urls.length) {
          // Reset status if no images
          this.status = null;
          return;
        }
        this.disabled = true;
        this.newEventItem = this.eventForm.value;
        this.newEventItem.images = [...urls].filter(image => !!image);
        const startDate = this.eventForm.get('startDate').value;
        const endDate = this.eventForm.get('endDate').value;
        const eventType = this.eventForm.get('eventType').value;

        // Ensure status remains 'loading' regardless of event type
        console.log('Processing edit for event of type:', eventType);

        // Preserve the creator from the original event
        this.newEventItem.creator = this.eventItem?.creator;

        // Format dates
        this.newEventItem.startDate = startDate ? new Date(startDate) : null;
        this.newEventItem.endDate = endDate ? new Date(endDate) : null;

        // Handle price formatting
        this.newEventItem.price =
          Object.keys(this.eventForm?.value.price).length === 0
            ? { value: 0 }
            : this.eventForm?.value.price;

        // Handle location formatting
        this.newEventItem.location = this.newEventItem.online
          ? Object.assign({
              ...this.newEventItem.location,
              country: { region: 'online' },
            })
          : this.newEventItem?.location;

        // Clear the gallery
        this.formService.itemGallery.next([]);

        // Handle status based on current status
        const originalStatus = this.eventItem?.status;

        // If the event was already approved, set it back to pending
        if (originalStatus === 'approved') {
          this.newEventItem.status = 'pending';
        } else if (originalStatus === 'pending') {
          // If it was pending, keep it as pending
          this.newEventItem.status = 'pending';
        }

        // Update the event
        this.eventsStore.updateProfile(this.newEventItem);
        this.eventsStore
          .selectLoading()
          .pipe(takeUntil(this.destroy$))
          .subscribe(data => {
            if (data) {
              this.disabled = true;
              this.addingBtnDisabled = true;
              this.statusAdding = true;
            } else {
              // Reset loading status
              this.status = null;
              this.isClose.next(true);

              // Show appropriate toast message based on status
              if (originalStatus === 'approved') {
                createToaster(
                  'Successfully edited. Moderator approval needed before going live',
                  'success',
                  positionType.BOTTOM
                );
              } else if (originalStatus === 'pending') {
                createToaster(
                  'Successfully edited. Moderator approval needed before going live',
                  'success',
                  positionType.BOTTOM
                );
              } else {
                createToaster(
                  'Successfully updated!',
                  'success',
                  positionType.BOTTOM
                );
              }

              // Navigate to event details page
              this.router.navigate(['/events', this.id]);

              this.disabled = false;
              this.addingBtnDisabled = false;
              this.statusAdding = false;
            }
          });
      });
  }

  removeItem(e) {
    console.log('Attempting to delete event:', {
      id: this.id,
      isExternal: this.isExternalEvent(),
      eventType: this.eventItem?.eventType,
      event: this.eventItem,
      eventId: this.eventItem?.id,
    });

    const idToUse = this.eventItem?.id || this.id;
    console.log('Using ID for deletion:', idToUse);

    if (this.isExternalEvent()) {
      this.deleteExternalEvent(idToUse);
    } else {
      this.eventsStore.removeItem(idToUse);
      this.eventsStore
        .selectLoading()
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          console.log('Delete loading state:', data);
          this.statusDeleting = data;
          if (data) {
            this.disabled = true;
            this.showForDelete = false;
          } else {
            console.log('Delete completed, navigating away');
            this.isClose.next(true);
            createToaster(
              'Successfully deleted!',
              'success',
              positionType.BOTTOM
            );
            setTimeout(() => {
              this.router.navigate(['/events']);
            }, 0);
          }
        });
    }
  }

  deleteExternalEvent(eventId: string) {
    console.log('Using direct approach to delete external event:', eventId);
    this.statusDeleting = true;
    this.disabled = true;
    this.showForDelete = false;

    // Use the new method in EventsService
    this.eventsService.deleteEventById(eventId).subscribe(
      () => {
        console.log('External event successfully deleted:', eventId);
        this.statusDeleting = false;
        this.disabled = false;
        this.isClose.next(true);
        createToaster('Successfully deleted!', 'success', positionType.BOTTOM);
        setTimeout(() => {
          this.router.navigate(['/events']);
        }, 0);
      },
      error => {
        console.error('Error deleting external event:', error);
        this.statusDeleting = false;
        this.disabled = false;
        createToaster(
          'Error deleting event. Please try again.',
          'danger',
          positionType.BOTTOM
        );
      }
    );
  }

  listenItemId() {
    this.eventsStore
      .selectId()
      .pipe(filter(id => !!id))
      .subscribe(id => {
        this.isClose.next(true);
        this.eventsStore.updateShowFormModal(false);
        this.eventsService.isOpenForm.next(true);
        this.eventsStore.updateShowPendingModal(true);
        setTimeout(() => {
          this.eventsStore.patchState({ createdId: null });
          this.eventsStore.getItemByByValue({ documentId: id });
          this.router.navigate(['/events', id]);
        }, 0);
      });
  }
  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  private listenStatusChanges() {
    this.eventsStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async loading => {
        loading ? (await this.loader).present() : (await this.loader).dismiss();
      });
  }

  // Add this method to check if the event is external
  isExternalEvent() {
    if (!this.eventItem || !this.eventItem.eventType) {
      return false;
    }

    return (
      this.eventItem.eventType ===
        EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_PAID ||
      this.eventItem.eventType === EVENT_TYPE_ENUMS.EXTERNALLY_HOSTED_AND_FREE
    );
  }
}

