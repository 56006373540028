import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-item-form',
  templateUrl: './item-form.component.html',
  styleUrls: ['./item-form.component.scss'],
})
export class ItemFormComponent {
  @Input() template;
  @Input() item;
  @Output() cancel = new EventEmitter<boolean>(false);
  constructor() {}
  OnInit() {}

  openConfirmModal() {
    this.cancel.next(true);
  }
}

