import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import {
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import mixpanel from 'mixpanel-browser';
import { IUser } from './auth.models';
import { MapService } from '../services/map.service';
import { countries } from '../auth/sign-up/countries';
import { environment } from 'src/environments/environment';
import { ProfileStore } from '../profile/store/profile-store';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  newUser = {
    uid: null,
    email: null,
    username: null,
    country: null,
    city: null,
    postalCode: null,
    location,
    profilePicture: null,
    gender: 'Other',
    mermaidExpLvl: null,
    mersonaName: null,
    affiliateCode: null,
    galleryVisibility: true,
    autoTranslate: true,
    isMailingEnabled: true,
    platform: Capacitor.getPlatform(),
  };

  properties: {
    id: any;
    country: any;
    region: any;
    city: any;
    email: any;
    'marketing-accept': any;
    'premium-active': boolean;
  };
  userUID = '';
  affiliateCode = '';

  constructor(
    private fireStore: AngularFirestore,
    private profileStore: ProfileStore,
    private mapService: MapService,
    private router: Router
  ) {}

  signUp = async (user: IUser) => {
    const {
      address,
      username,
      email,
      password,
      isMailingEnabled,
      affiliateCode,
    } = user;
    const country = countries.find(country => country.name === address.country);
    const location = this.mapService.createCoordinates(address.googleMapLink);

    const auth = getAuth();
    const functions = getFunctions();

    const isUsernameTaken = httpsCallable(functions, 'isUsernameTaken');

    const { data } = await isUsernameTaken({ username });
    if (data) {
      throw 'auth/username-already-exist';
    }
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    this.fireStore.doc(`users/${userCredential.user.uid}`).set({
      ...this.newUser,
      uid: userCredential.user.uid,
      email,
      username,
      country,
      location,
      isMailingEnabled,
      affiliateCode: affiliateCode || '',
    });
    this.affiliateCode = affiliateCode;
    this.userUID = userCredential.user.uid;

    mixpanel.init(environment.mixpanelToken, { debug: true });
    mixpanel.identify(userCredential.user.uid);
    this.trackEvent('New User', userCredential, user, country);

    mixpanel.people.set({
      Map_Region: country.region,
      Map_Country_Code: country.code,
      Map_Country: address.country,
      $email: email,
      $name: username,
      $createdAt: new Date(),
      'marketing-accept': user.isMailingEnabled,
      $subscription_status: 'not subscribed',
      'premium-active': false,
      'affiliate-code': affiliateCode || '',
    });
  };

  signIn = async ({ email, password, rememberMe }) => {
    const auth = getAuth();
    const sessionType = browserLocalPersistence;

    await setPersistence(auth, sessionType);
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    return userCredential;
  };

  signOut = async () => {
    await signOut(getAuth());
    this.router.navigate(['/'], { replaceUrl: true });
    this.profileStore.logOut();
    console.log('successfully sign out');
  };

  async sendResetEmail({ email }) {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      console.log('Password reset email sent');
    } catch (error) {
      console.log(error.message);
    }
  }

  trackEvent(event, userCredential, user, country) {
    this.properties = {
      id: userCredential.user.uid,
      country: country,
      region: country.region,
      city: user.address.city,
      email: user.email,
      'marketing-accept': user.isMailingEnabled,
      'premium-active': false,
    };

    mixpanel.track(event, this.properties);
  }

  collectReward = async () => {
    // Update the balance fields in the "info" collection for the user
    this.fireStore.doc(`info/${this.userUID}`).update({
      balancePearls: 42, // Set the initial balance for Pearls
      balanceShells: 0, // Set the initial balance for Shells
    });

    mixpanel.track('Reward Received', {
      'reward-type': 'affiliate-sign-up',
      '$reward-currency': 'Pearls',
      'affiliate-code': this.affiliateCode || '',
      amount: 42,
    });

    this.closePopup();
  };

  closePopup(): void {
    const popup = document.getElementById('popup');
    if (popup) {
      popup.style.display = 'none';
      this.router.navigate(['/tutorial'], { replaceUrl: true });
    }
  }
}
