import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AuthService } from 'src/app/firebase/auth.service';
import { User } from '../../models/user.model';
import { Subject, filter, takeUntil } from 'rxjs';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormService } from '../form/form.service';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent implements OnInit, OnDestroy {
  @Input() form;
  @Input() required: boolean;
  @Output() formGroupChange = new EventEmitter<FormGroup>();

  @ViewChild('placesRef', { static: false }) placesRef: GooglePlaceDirective;
  @ViewChild('places') places: ElementRef;
  @ViewChild('markerElem') markerElem;

  isOpen = false;
  currentUser: User;
  center: google.maps.LatLngLiteral;
  zoom = 10;
  options = {
    disableDefaultUI: true,
  };
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  markerPositions: google.maps.LatLngLiteral[] = [];
  chooseBtnDisabled = !this.markerPositions.length;
  address = '';

  private destroy$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private formService: FormService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.getUser();

    this.form = this.fb.group({ googleMapLink: [null] });
    this.formGroupChange.emit(this.form);
    this.formService.location$
      .pipe(
        filter(
          value =>
            !!value &&
            value.googleMapLink !== this.form.get('googleMapLink').value
        )
      )
      .subscribe(value => {
        this.form.patchValue(value);
        this.address = value.googleMapLink;
        if (value.googleMapLink) {
          const lng = +value.googleMapLink.slice(
            value.googleMapLink.lastIndexOf(',') + 1
          );
          const lat = +value.googleMapLink.slice(
            value.googleMapLink.lastIndexOf('=') + 1,
            value.googleMapLink.lastIndexOf(',') - 1
          );
          this.markerPositions = [
            {
              lat,
              lng,
            },
          ];
          this.center = { lat, lng };
          this.zoom = 17;
        }
      });
  }

  ngOnDestroy(): void {
    this.formService.location$.next({ googleMapLink: null });
    this.destroy$.next();
    this.destroy$.complete();
  }

  public handleAddressChange(address: any) {
    const lat =
      address?.geometry?.location.lat() || this.currentUser?.location.lat;
    const lng =
      address?.geometry?.location.lng() || this.currentUser?.location.lng;

    this.zoom = 17;
    this.center = { lat, lng };

    this.markerPositions = [
      {
        lat,
        lng,
      },
    ];
    this.chooseBtnDisabled = false;
    const baseMapUrl = 'https://www.google.com/maps/search/?api=1&query=';
    this.address = `${baseMapUrl}${lat},${lng}`;
  }

  createMapHandler() {
    this.isOpen = true;
  }

  moveMap(e) {
    const { lat, lng } = e.latLng.toJSON();
    const baseMapUrl = 'https://www.google.com/maps/search/?api=1&query=';

    this.address = `${baseMapUrl}${lat},${lng}`;

    this.center = { lat, lng };
    this.markerPositions = [
      {
        lat,
        lng,
      },
    ];
    this.chooseBtnDisabled = false;
  }

  cancelModal() {
    this.isOpen = false;
    if (!this.form.get('googleMapLink').value) {
      this.center = this.currentUser?.location;
      this.chooseBtnDisabled = true;
      this.markerPositions = [];
      this.address = '';
    } else {
      const lng = +this.form
        .get('googleMapLink')
        .value.slice(this.form.get('googleMapLink').value.lastIndexOf(',') + 1);
      const lat = +this.form
        .get('googleMapLink')
        .value.slice(
          this.form.get('googleMapLink').value.lastIndexOf('=') + 1,
          this.form.get('googleMapLink').value.lastIndexOf(',') - 1
        );
      this.address = this.form.get('googleMapLink').value;
      this.markerPositions = [
        {
          lat,
          lng,
        },
      ];
      this.center = { lat, lng };
      this.zoom = 17;
    }
  }

  resetLocation() {
    this.address = '';
    this.markerPositions = [];
    this.chooseBtnDisabled = true;
    this.formService.location$.next({
      googleMapLink: null,
    });
    this.form.get('googleMapLink').patchValue(null);
  }

  chooseLocation() {
    const { lat, lng } = this.markerPositions[0];
    const baseMapUrl = 'https://www.google.com/maps/search/?api=1&query=';

    this.formService.location$.next({
      googleMapLink: `${baseMapUrl}${lat},${lng}`,
    });
    this.cancelModal();
  }

  getUser() {
    if (!this.authService.isUserAuth()) return;
    const { uid } = this.authService.isUserAuth();
    this.authService
      .getUserByUid(uid)
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (data: any) => {
        this.currentUser = data;
        this.center = this.center ? this.center : this.currentUser.location;
      });
  }
}
