import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'archivedEvents',
})
export class ArchivedEventsPipe implements PipeTransform {
  transform(events: any[]): any[] {
    if (!events || !Array.isArray(events)) {
      return events;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return events.filter(event => {
      if (!event.startDate && !event.endDate) {
        return false;
      }

      let eventStartDate: Date;

      if (event.startDate) {
        eventStartDate = this.parseEventDate(event.startDate);
      } else if (event.endDate) {
        eventStartDate = this.parseEventDate(event.endDate);
      } else {
        return false;
      }

      if (!eventStartDate || isNaN(eventStartDate.getTime())) {
        return false;
      }

      return eventStartDate < today;
    });
  }

  private parseEventDate(date: any): Date | null {
    if (!date) return null;

    try {
      // Handle Firebase Timestamp objects
      if (date.seconds) {
        return new Date(date.seconds * 1000);
      }
      if (date._seconds) {
        return new Date(date._seconds * 1000);
      }

      // Handle ISO string date (YYYY-MM-DD format)
      if (typeof date === 'string' && date.match(/^\d{4}-\d{2}-\d{2}$/)) {
        const [year, month, day] = date.split('-').map(Number);
        return new Date(year, month - 1, day);
      }

      // Handle regular date strings or objects
      return new Date(date);
    } catch (e) {
      console.warn('Could not parse date:', date);
      return null;
    }
  }
}

