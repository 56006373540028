import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, filter, Subject, takeUntil } from 'rxjs';
import { AuthService } from '../../firebase/auth.service';
import { User } from '../../shared/models/user.model';
import { CommunityStore } from '../../merfolk/community/community-store';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { switchMap, take } from 'rxjs/operators';
import { UserService } from 'src/app/auth/user.service';
import { CrudHelper } from 'src/app/firebase/helpers/crudHelper';
import { IPod } from 'src/app/shared/models/item.models';
import { successInviteModalData } from 'src/app/merfolk/pods/pods/pods';
import { PodStore } from 'src/app/merfolk/pods/pod-store';

@Component({
  selector: 'app-followers-list',
  templateUrl: './followers-list.component.html',
  styleUrls: ['./followers-list.component.scss'],
})
export class FollowersListComponent implements OnInit, OnDestroy {
  loading: boolean;
  id: string = this.activeRouter.snapshot.params.id;
  currentUser;
  updatedUser;
  users;
  itemsPerPage = 10;
  start = 0;
  end = 10;
  currentPage = new BehaviorSubject<number>(0);
  countPages: number;
  previewUsers$ = new BehaviorSubject([]);
  followersLength$ = new BehaviorSubject(0);
  isLastBtnDisabled = false;
  isNextBtnDisabled = false;
  isFirstBtnDisabled = true;
  isPrevBtnDisabled = true;
  users$ = new BehaviorSubject<User[]>([]);
  allUsers$ = new BehaviorSubject<User[]>([]);
  searchString = new BehaviorSubject<string>('');
  userPods = new BehaviorSubject<IPod[]>([]);
  followersInfo: any[] = [];
  usersCount: number;

  successModalData = successInviteModalData;
  showSuccessModal = false;

  private destroy$ = new Subject<void>();

  constructor(
    public activeRouter: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private crudHelper: CrudHelper,
    private podStore: PodStore
  ) {}

  ngOnInit() {
    this.loading = true;
    const currentRoute = this.router.url;
    const parts = currentRoute.split('/');
    const idIndex = parts.indexOf('profile') + 1;
    this.id = parts[idIndex];
    this.getUser();
    this.podStore.selectInviteModal().subscribe(isOpen => {
      this.showSuccessModal = isOpen;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleOk(e) {
    e ? this.podStore.updateInviteModal(false) : null;
  }

  handleCancel(e) {
    e ? this.podStore.updateInviteModal(false) : null;
  }

  searchUser($event) {
    this.searchString.next($event.target.value);
    this.followersInfo = [];
    this.currentPage.next(0);
  }

  getUser() {
    this.userService.currentUser$
      .pipe(
        filter(user => !!user.uid),
        takeUntil(this.destroy$),
        switchMap(async user => {
          this.currentUser = user;
          await this.getCurrentPage();
          return this.crudHelper.getCollectionItems({
            collectionName: 'pods',
            queryFn: query =>
              query
                .where('status', '==', 'approved')
                .where('participants', 'array-contains', user.id),
          });
        })
      )
      .subscribe(pods => this.userPods.next(pods as any));
  }

  async getCurrentPage() {
    await this.currentPage.subscribe(page => {
      this.start = 1 + page * this.itemsPerPage;
      this.end = (page + 1) * this.itemsPerPage;

      if (this.searchString.value.length > 0) {
        this.getFollowersSearchedUsers(page);
      } else {
        this.getFollowersUsers(page);
      }

      if (this.end > this.usersCount && this.usersCount) {
        this.end = this.usersCount;
      }

      this.isFirstBtnDisabled = !!(this.start === 1);
      this.isPrevBtnDisabled = !!(this.start === 1);
      this.isNextBtnDisabled =
        !!(page === this.countPages) || this.countPages === 0;
      this.isLastBtnDisabled =
        !!(page === this.countPages) || this.countPages === 0;
    });
  }

  getFollowersSearchedUsers(page) {
    this.loading = true;
    const functions = getFunctions();
    const followSearch = httpsCallable(functions, 'followSearch');
    followSearch({
      id: this.id,
      page: this.currentPage.value,
      type: 'followers',
      message: this.searchString.value,
    })
      .then(res => {
        this.followersInfo.push(res);
        this.previewUsers$.next((res as any).data.data);
        this.followersLength$.next((res as any).data.totalCount);

        this.countPages = Math.ceil(
          (res as any).data.totalCount / this.itemsPerPage
        );

        this.usersCount = (res as any).data.totalCount;
        if (
          this.end > (res as any).data.totalCount &&
          (res as any).data.totalCount
        ) {
          this.end = (res as any).data.totalCount;
        }
        this.isFirstBtnDisabled = !!(this.start === 1);
        this.isPrevBtnDisabled = !!(this.start === 1);
        this.isNextBtnDisabled =
          !!(page === this.countPages) || this.countPages === 0;
        this.isLastBtnDisabled =
          !!(page === this.countPages) || this.countPages === 0;
      })
      .catch(error => console.log(error))
      .finally(() => {
        this.loading = false;
      });
  }

  async getFollowersUsers(page) {
    this.loading = true;
    const functions = getFunctions();
    const getFollowersUsers = httpsCallable(functions, 'getFollowersUsers');
    getFollowersUsers({
      id: this.id,
      page: page,
    })
      .then(res => {
        this.followersInfo.push(res);
        this.previewUsers$.next((res as any).data.data);
        this.followersLength$.next((res as any).data.totalCount);

        this.countPages = Math.ceil(
          (res as any).data.totalCount / this.itemsPerPage
        );

        this.usersCount = (res as any).data.totalCount;
        if (
          this.end > (res as any).data.totalCount &&
          (res as any).data.totalCount
        ) {
          this.end = (res as any).data.totalCount;
        }

        this.isFirstBtnDisabled = !!(this.start === 1);
        this.isPrevBtnDisabled = !!(this.start === 1);
        this.isNextBtnDisabled =
          !!(page === this.countPages - 1) || this.countPages === 0;
        this.isLastBtnDisabled =
          !!(page === this.countPages - 1) || this.countPages === 0;
      })
      .catch(error => console.log(error))
      .finally(() => {
        this.loading = false;
      });
  }

  getNextPage(e) {
    this.currentPage.next(this.currentPage.value + 1);
  }

  getPrevPage(e) {
    this.currentPage.next(this.currentPage.value - 1);
  }

  getLastPage() {
    this.currentPage.next(this.countPages - 1);
  }

  getFirstPage() {
    this.currentPage.next(0);
  }
}
