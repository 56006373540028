import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-is-flagged-modal',
  templateUrl: './is-flagged-modal.component.html',
  styleUrls: ['./is-flagged-modal.component.scss'],
})
export class IsFlaggedModalComponent implements OnInit {
  @Input() isContentFlaggedModal: boolean;
  @Output() isContentFlaggedModalChange = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {}

}
