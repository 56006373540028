import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { IonModal } from '@ionic/angular';

export interface ISuccessModal {
  title: string;
  pic: string;
  description: string;
  okBtn: {
    title: string;
    link: string;
  };
  cancelBtn: {
    title: string;
    link: string;
  };
}
@Component({
  selector: 'app-pending-modal',
  templateUrl: './pending-modal.component.html',
  styleUrls: ['./pending-modal.component.scss'],
})
export class PendingModalComponent {
  @Input() showSuccessModal: boolean;
  @Input() successModalData: ISuccessModal;
  @Output() ok: EventEmitter<boolean> = new EventEmitter();
  @Output() cancel: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('modal') modal: IonModal;

  constructor() {}

  handleOk() {
    this.ok.emit(true);
    if (this.modal) {
      this.modal.dismiss(null, 'ok');
    }
  }

  handleCancel() {
    this.cancel.emit(true);
    if (this.modal) {
      this.modal.dismiss(null, 'cancel');
    }
  }
}
